import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import useToggle from "hooks/useToggle";
import * as React from "react";
import { useDispatch } from "react-redux";
import { logOut } from "utils/authentication";
import { getLogosGeneric } from "utils/resolveLogos";
import OPMenu from "library/form/OPMenu";
import PngContainer from "library/containers/PngContainer";
import {
  DARK_GREY,
  OP_COLORS,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
} from "assets/palette";

const styles = {
  appBarContainerBox: {
    flexGrow: 1,
    height: "56px",
    padding: "0px",
  },
  appBarStyles: {
    backgroundColor: OP_COLORS.WHITE_TRANSLUCENT,
    backdropFilter: "blur(5px)",
    borderBottom: 1,
    borderColor: OP_COLORS.LAVENDER_WEB,
    boxShadow: "none",
    height: "56px",
    minHeight: "56px",
  },
  toolbarStyles: {
    paddingBottom: "6px",
  },
  pngContainerStyles: {
    width: "94px",
    flexGrow: 1,
    marginBottom: "6px",
  },
  profileButton: {
    width: "76px",
    height: "44px",
    borderRadius: 100,
    "&:hover": {
      backgroundColor: PRIMARY_LIGHT,
    },
    marginBottom: "8px",
  },
  arrowCircleIcon: { color: PRIMARY_DARK, width: "32px" },
  arrowDropDownIcon: { color: DARK_GREY, width: "30px" },
};

export default function OPAppBar() {
  const { state: menuState, toggleOff, toggle } = useToggle();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const profileClickHandler = (event = {}) => {
    toggle();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
    toggleOff();
  }, [anchorEl]);

  const menuOptions = [
    {
      display: "Log out",
      onClick: () => {
        logOut(dispatch);
      },
      "data-cy": "appbar-logout",
    },
  ];

  return (
    <Box sx={styles.appBarContainerBox}>
      <AppBar position="fixed" sx={styles.appBarStyles}>
        <Toolbar sx={styles.toolbarStyles}>
          <PngContainer
            icon={getLogosGeneric("internalOrgLogo")}
            boxStyle={styles.pngContainerStyles}
          />
          <Button
            data-cy="avatar-button"
            sx={styles.profileButton}
            onClick={profileClickHandler}
          >
            <AccountCircleIcon sx={styles.arrowCircleIcon} />
            <ArrowDropDownIcon sx={styles.arrowDropDownIcon} />
          </Button>
          <OPMenu
            anchorEl={anchorEl}
            menuState={menuState}
            handleMenuClose={handleMenuClose}
            menuOptions={menuOptions}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
