import React, { useState } from "react";
import { Typography, CircularProgress, Backdrop, Grid } from "@mui/material";
import { useSelector } from "react-redux";

import OPDrawer from "library/surface/Drawer";
import OPDialog from "library/surface/OutPointDialog";
import CampaignActionsButtons from "pages/campaigns/components/CampaignActionsButtons";
import GroupsDisplay from "pages/campaigns/components/GroupsDisplay";
import CampaignsFinder from "pages/campaigns/components/CampaignsFinder";
import CampaignGroupCreator from "pages/campaigns/components/CampaignGroupCreator";
import CampaignGroupDeletor from "pages/campaigns/components/CampaignGroupDeletor";
import CampaignGroupEditor from "pages/campaigns/components/CampaignGroupEditor";
import StrategyCreator from "pages/campaigns/components/StrategyCreator";
import StrategyDeletor from "pages/campaigns/components/StrategyDeletor";
import StrategyEditor from "pages/campaigns/components/StrategyEditor";
import { campaignsPageStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import BodyText from "library/text/body/BodyText";
import Header4 from "library/text/headers/Header4";

const groupMenuOptions = [
  {
    command: "addGroup",
    label: "Add group",
  },
  {
    command: "deleteGroup",
    label: "Delete group",
  },
  {
    command: "editGroups",
    label: "Edit groups",
  },
];
const strategyMenuOptions = [
  {
    command: "addStrategy",
    label: "Add strategy",
  },
  {
    command: "deleteStrategy",
    label: "Delete strategy",
  },
  {
    command: "editStrategy",
    label: "Edit strategies",
  },
];

function CampaignsPage() {
  const campaignsData = useSelector((state) => state.campaignsData);
  const doneProcessing = campaignsData.status !== "loading";
  const dataExists = campaignsData.currentGrouping.campaigns.length > 0;
  const isSubmitting = campaignsData?.isSubmitting;

  const [activeActions, setActiveActions] = useState([]);

  const handleCloseActionComponent = (targetAction) => {
    setActiveActions(activeActions.filter((action) => action !== targetAction));
  };

  const handleActionSelection = (action) => {
    setActiveActions([...activeActions, action]);
  };

  /**
   * Keyed by the action / type that is selected, returns the props
   * that are to be passed to OPDrawer to render the correct component in the drawer.
   * */
  const actionComponents = {
    find: {
      component: (
        <OPDrawer
          key="find"
          isOpen={activeActions.includes("find")}
          handleClose={() => handleCloseActionComponent("find")}
          anchor="right"
        >
          <CampaignsFinder
            handleActionSelection={handleActionSelection}
            handleCloseDrawer={() => {
              handleCloseActionComponent("addGroup");
              handleCloseActionComponent("find");
            }}
          />
        </OPDrawer>
      ),
    },
    addGroup: {
      component: (
        <OPDialog
          key="addGroup"
          isOpen={activeActions.includes("addGroup")}
          handleClose={() => handleCloseActionComponent("addGroup")}
          maxWidth="md"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>
              Add a new campaign group
            </Typography>
          }
        >
          <CampaignGroupCreator
            handleCloseDrawer={() => handleCloseActionComponent("addGroup")}
          />
        </OPDialog>
      ),
    },
    deleteGroup: {
      component: (
        <OPDialog
          key="deleteGroup"
          isOpen={activeActions.includes("deleteGroup")}
          handleClose={() => handleCloseActionComponent("deleteGroup")}
          scroll="body"
          maxWidth="lg"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>
              Delete a campaign group
            </Typography>
          }
        >
          <CampaignGroupDeletor
            handleCloseDrawer={() => handleCloseActionComponent("deleteGroup")}
          />
        </OPDialog>
      ),
    },
    editGroups: {
      component: (
        <OPDialog
          key="editGroups"
          isOpen={activeActions.includes("editGroups")}
          handleClose={() => handleCloseActionComponent("editGroups")}
          scroll="body"
          maxWidth="lg"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>Edit campaign groups</Typography>
          }
        >
          <CampaignGroupEditor
            handleCloseDrawer={() => handleCloseActionComponent("editGroups")}
          />
        </OPDialog>
      ),
    },
    addStrategy: {
      component: (
        <OPDialog
          key="addStrategy"
          isOpen={activeActions.includes("addStrategy")}
          handleClose={() => handleCloseActionComponent("addStrategy")}
          maxWidth="md"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>Add a new strategy</Typography>
          }
        >
          <StrategyCreator
            handleCloseDrawer={() => handleCloseActionComponent("addStrategy")}
          />
        </OPDialog>
      ),
    },
    deleteStrategy: {
      component: (
        <OPDialog
          key="deleteStrategy"
          isOpen={activeActions.includes("deleteStrategy")}
          handleClose={() => handleCloseActionComponent("deleteStrategy")}
          scroll="body"
          maxWidth="lg"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>Delete a strategy</Typography>
          }
        >
          <StrategyDeletor
            handleCloseDrawer={() =>
              handleCloseActionComponent("deleteStrategy")
            }
          />
        </OPDialog>
      ),
    },
    editStrategy: {
      component: (
        <OPDialog
          key="editStrategy"
          isOpen={activeActions.includes("editStrategy")}
          handleClose={() => handleCloseActionComponent("editStrategy")}
          scroll="body"
          maxWidth="lg"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>Edit strategies</Typography>
          }
        >
          <StrategyEditor
            handleCloseDrawer={() => handleCloseActionComponent("editStrategy")}
          />
        </OPDialog>
      ),
    },
  };

  if (doneProcessing) {
    if (dataExists) {
      return (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isSubmitting}
          >
            <CircularProgress sx={styles.progressCircle} />
          </Backdrop>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between"
            style={{ marginBottom: "60px" }}
          >
            <CampaignActionsButtons
              groupMenuOptions={groupMenuOptions}
              strategyMenuOptions={strategyMenuOptions}
              handleActionSelection={handleActionSelection}
            />
          </Grid>
          <GroupsDisplay />
          {activeActions.map((action) => actionComponents[action]?.component)}
        </>
      );
    }
    return (
      <>
        <Header4 style={{ marginBottom: "20px" }}>
          It looks like we haven't processed your data yet.{" "}
        </Header4>
        <BodyText>
          It takes about a day from when you connect your accounts to be able to
          select your campaign groupings. If it has been longer than a day since
          you successfully connected your accounts, please contact us.
        </BodyText>
      </>
    );
  }

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isSubmitting}
    >
      <CircularProgress sx={styles.progressCircle} />
    </Backdrop>
  );
}

export default CampaignsPage;
