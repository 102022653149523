import React from "react";
import PropTypes from "prop-types";
import { Grid, FormControlLabel, Checkbox } from "@mui/material";
import { Box } from "@mui/system";

import MetricDisplay from "library/display/MetricDisplay";
import Badge from "library/display/OutPointBadge";
import Selector from "library/form/OutPointSelector";
import SubHeadLight from "library/text/headers/SubHeadLight";
import CSVDownloader from "library/buttons/CSVDownloader";
import { liftPageTemplateStyles as styles } from "./LiftPageTemplateStyles";

function LiftGraphSummaryBar(props) {
  const {
    liftType,
    summaryKeyValuePairs,
    supportedChannels = [],
    onSelectChannels,
    selectedChannels,
    chartView = 100,
    rawData,
    isIncludingBaselineLift,
    setIsIncludingBaselineLift,
  } = props;

  const channelSelectorPrefix = "Channels: ";

  const shouldShowBaselineLiftToggle = setIsIncludingBaselineLift != null;
  const baselineLiftToggle = shouldShowBaselineLiftToggle && (
    <FormControlLabel
      sx={styles.switch}
      control={
        <Checkbox
          checked={isIncludingBaselineLift}
          onChange={() => setIsIncludingBaselineLift(!isIncludingBaselineLift)}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="Include baseline lift"
    />
  );

  const metricDisplays = summaryKeyValuePairs.map(({ key, value }) => {
    let renderBadge = false;

    if (key.toLowerCase().includes("revenue") && Number(chartView) !== 100) {
      renderBadge = true;
    }

    return (
      <MetricDisplay
        key={key}
        style={{ margin: "40px 82px 0 0" }}
        keyText={key}
        valueText={value}
        keyAdornment={
          renderBadge ? (
            <Badge
              moreTextStyles={styles.badgeTextStyle}
              moreBoxStyles={styles.badgeBoxStyle}
              text={`${chartView}%`}
            />
          ) : undefined
        }
      />
    );
  });

  const channelsSelector = (
    <Selector
      multiple
      menuItems={supportedChannels}
      onChange={onSelectChannels}
      selectedValue={selectedChannels}
      leading={channelSelectorPrefix}
      renderValueOverride={() => {
        return (
          <Box
            sx={{
              ...styles.flexDisplay,
              ...styles.minWidthMaxContent,
            }}
          >
            <SubHeadLight>{channelSelectorPrefix}</SubHeadLight>
            <Badge text={selectedChannels.length} />
          </Box>
        );
      }}
    />
  );

  const csvDownloader = (
    <CSVDownloader
      label="export"
      data={rawData}
      filename={`${liftType}-lift-data`}
      ButtonProps={styles.csvDownloadButton}
    />
  );

  const graphControls = (
    <Grid
      item
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-start"
      sx={{ margin: "40px 82px 0 0" }}
    >
      <Grid item xs={5} container direction="column" alignItems="flex-start">
        <Grid item>{channelsSelector}</Grid>
        <Grid item>{baselineLiftToggle}</Grid>
      </Grid>
      <Grid item xs={2} sx={{ marginTop: "4px" }}>
        {csvDownloader}
      </Grid>
    </Grid>
  );

  return (
    <Grid container alignItems="flex-start" direction="row">
      <Grid item xs={12} md={6}>
        {metricDisplays}
      </Grid>
      <Grid item xs={12} md={6}>
        {graphControls}
      </Grid>
    </Grid>
  );
}

LiftGraphSummaryBar.propTypes = {
  liftType: PropTypes.string,
  summaryKeyValuePairs: PropTypes.arrayOf(PropTypes.object),
  supportedChannels: PropTypes.arrayOf(PropTypes.string),
  onSelectChannels: PropTypes.func,
  selectedChannels: PropTypes.arrayOf(PropTypes.string),
  chartView: PropTypes.number,
  rawData: PropTypes.array,
  isIncludingBaselineLift: PropTypes.bool,
  setIsIncludingBaselineLift: PropTypes.func,
};

export default LiftGraphSummaryBar;
