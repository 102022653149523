import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import SubHead from "library/text/headers/SubHead";
import { CalendarToday } from "@mui/icons-material";
import OutPointTooltip from "library/display/OutPointTooltip";
import LabelText from "library/text/body/LabelText";
import OutPointSelector from "library/form/OutPointSelector";
import SectionCollapseButton from "./SectionCollapseButton";
import {
  AGGREGATION_DAY_RANGES,
  MIX_RANGES as mixRanges,
} from "../../../utils/enums";

const styles = {
  subHeaderControls: { marginBottom: "16px" },
  headerOne: {
    fontWeight: 400,
  },
  subHeader: {
    fontWeight: 400,
    fontSize: "20px",
  },
  aggLabelText: {
    marginRight: "10px",
  },
  sectionHeaderContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "16px",
    marginBottom: "16px",
  },
  sectionHeaderTextContainer: {
    display: "flex",
    flexDirection: "flex-row",
    alignItems: "center",
  },
  aggregationRangeSelector: {
    minWidth: "140px",
    width: "auto",
    padding: "16px 16px",
  },
  tooltip: {
    paddingTop: "16px",
  },
};

const renderStartAdornment = (adornmentProps) => (
  <CalendarToday {...adornmentProps} />
);

function SectionTemplate(props) {
  const {
    sectionHeading = "Home",
    sectionHeadingControlComponents = [],
    sectionContent,
    chosenTimeRange = "daily",
    setChosenTimeRange = () => true,
    mixRange = "Last 3 months",
    setMixRange = () => true,
    shouldShowTimeRangeSelector = true,
  } = props;

  const [isOpen, setIsOpen] = useState(true);
  const infoText =
    "Select the time period over which the data will be considered.";

  const timeRangeSelector = (
    <Grid container item alignItems="center" justifyContent="flex-end">
      <Grid item>
        <LabelText sx={styles.aggLabelText}>Date range</LabelText>
      </Grid>
      <Grid item>
        <OutPointSelector
          sx={{ width: "auto" }}
          onChange={setMixRange}
          selectedValue={mixRange}
          menuItems={mixRanges}
          StartAdornment={renderStartAdornment}
        />
        <OutPointTooltip
          info={infoText}
          icon="info"
          sxToolTip={styles.tooltip}
        />
      </Grid>
    </Grid>
  );

  const aggregationRangeSelector = (
    <Grid container alignItems="center" justifyContent="flex-end">
      <Grid item>
        <LabelText sx={styles.aggLabelText}>Aggregation range</LabelText>
      </Grid>
      <Grid item>
        <OutPointSelector
          sx={styles.aggregationRangeSelector}
          selectedValue={chosenTimeRange}
          onChange={(timeRange) => setChosenTimeRange(timeRange)}
          menuItems={Object.keys(AGGREGATION_DAY_RANGES)}
        />
      </Grid>
    </Grid>
  );

  const controls = (
    <Grid
      container
      item
      md={12}
      xs={12}
      sx={styles.subHeaderControls}
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      {isOpen &&
        sectionHeadingControlComponents.map((c, idx) => (
          <Grid
            idx={idx}
            justifyContent="flex-end"
            sx={{ paddingLeft: "24px" }}
          >
            {c}
          </Grid>
        ))}
      {isOpen && shouldShowTimeRangeSelector && (
        <Grid sx={{ paddingLeft: "24px" }}>{timeRangeSelector}</Grid>
      )}
      {isOpen && (
        <Grid sx={{ paddingLeft: "24px" }}>{aggregationRangeSelector}</Grid>
      )}
    </Grid>
  );

  const sectionTextAndCollapseButton = (
    <div style={styles.sectionHeaderTextContainer}>
      <SubHead sx={styles.subHeader} data-cy="overview-header">
        {sectionHeading}
      </SubHead>
      <SectionCollapseButton isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );

  return (
    <Grid container sx={{ marginTop: "24px" }}>
      <Grid
        container
        item
        xs={12}
        sx={styles.sectionHeaderContainer}
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        {sectionTextAndCollapseButton}
      </Grid>
      {controls}
      <Grid container item xs={12}>
        {isOpen && sectionContent}
      </Grid>
    </Grid>
  );
}

SectionTemplate.propTypes = {
  sectionHeading: PropTypes.string,
  sectionHeadingControlComponents: PropTypes.arrayOf(PropTypes.node),
  sectionContent: PropTypes.node,
  chosenTimeRange: PropTypes.string,
  setChosenTimeRange: PropTypes.func,
  mixRange: PropTypes.string,
  setMixRange: PropTypes.func,
  shouldShowTimeRangeSelector: PropTypes.bool,
};

export default SectionTemplate;
