import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, Navigate } from "react-router-dom";

import { authenticateToken, getToken, tokenValid } from "utils/authentication";

function OnboardingRoute({ component: Component }) {
  const authStatus = useSelector((state) => state.login.authStatus);
  const [token, setToken] = useState(getToken());

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setToken(getToken());
  }, [authStatus]);

  useEffect(() => {
    const tokenStatus = tokenValid();
    if (!tokenStatus) {
      navigate("/login");
    }
  }, [navigate]);

  const authenticate = async () => {
    await authenticateToken(dispatch);
    setToken(getToken());
  };
  authenticate();

  return authStatus || token ? <Component /> : <Navigate to="/login" />;
}

export default OnboardingRoute;

OnboardingRoute.propTypes = {
  component: PropTypes.func,
};
