import React from "react";
import PropTypes from "prop-types";
import { Grid, Tooltip, TextField, Typography, Toolbar } from "@mui/material";

import {
  Search as SearchIcon,
  Delete as DeleteIcon,
  ModeEditRounded as EditIcon,
} from "@mui/icons-material";

import { campaignGroupTableStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

/**
 * Toolbar shown when there are selected row items.
 */
function CustomToolbar(props) {
  const {
    campaignGroupId,
    selectedRows,
    handleSearchInputChange,
    handleSelectedRowsDeletionClick,
    handleMoveSelectedRowsClick,
  } = props;

  const searchInputPlaceholder = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <SearchIcon
        sx={{
          color: "#2F2F33",
        }}
      />
      <Typography sx={styles.inputPlaceholderText}>Search Campaigns</Typography>
    </Grid>
  );

  const handleSearchBoxInput = (e) => {
    const inputVal = e?.target?.value;
    handleSearchInputChange(inputVal);
  };

  const canShowToolbarIcons = Boolean(selectedRows?.length);

  const deleteTooltipContent = `Remove ${selectedRows.length} selected ${
    selectedRows.length > 1 ? "campaigns" : "campaign"
  } from group`;
  const canShowRemover = canShowToolbarIcons && campaignGroupId !== "ungrouped";
  const remover = canShowRemover && (
    <Tooltip
      title={deleteTooltipContent}
      onClick={handleSelectedRowsDeletionClick}
    >
      <DeleteIcon fontSize="large" />
    </Tooltip>
  );

  const editTooltipContent = `Move ${selectedRows.length} selected ${
    selectedRows.length > 1 ? "campaigns" : "campaign"
  } into another group`;
  const canShowEditor = canShowToolbarIcons;
  const editor = canShowEditor && (
    <Tooltip title={editTooltipContent} onClick={handleMoveSelectedRowsClick}>
      <EditIcon fontSize="large" sx={{ marginRight: "30px" }} />
    </Tooltip>
  );

  return (
    <Toolbar disableGutters>
      <Grid
        container
        direction="row"
        sx={styles.toolbarComponentsContainer}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={8}>
          <TextField
            label={searchInputPlaceholder}
            InputProps={{
              sx: styles.toolbarSearchBoxTextField,
            }}
            sx={styles.toolbarSearchBoxTextField}
            onChange={handleSearchBoxInput}
          />
        </Grid>
        <Grid item>
          {editor}
          {remover}
        </Grid>
      </Grid>
    </Toolbar>
  );
}

CustomToolbar.propTypes = {
  campaignGroupId: PropTypes.string,
  selectedRows: PropTypes.array,
  handleSearchInputChange: PropTypes.func,
  handleSelectedRowsDeletionClick: PropTypes.func,
  handleMoveSelectedRowsClick: PropTypes.func,
};

export default CustomToolbar;
