import React from "react";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import PropTypes from "prop-types";
import BaseHeader from "./BaseHeader";

const styles = {
  header: {
    fontSize: "2.188rem",
    lineHeight: "2.75rem",
    fontWeight: "bold",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-0.02em",
  },
};

function Header1({ children, className, sx = {}, ...rest }) {
  const mergedStyle = { ...styles.header, ...sx };
  return (
    <BaseHeader sx={mergedStyle} className={className} {...rest}>
      {children}
    </BaseHeader>
  );
}

export default Header1;

Header1.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  sx: PropTypes.object,
};
