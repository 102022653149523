import React from "react";

import WantToKnow from "pages/ncr/components/WantToKnow";
import SubHead from "library/text/headers/SubHead";
import Title from "library/text/titles/Title";

export default function DataNotEnteredYetPage() {
  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Title
        text="Your recommendations are on the way"
        style={{
          marginBottom: "20px",
          marginTop: "25%",
          textAlign: "center",
        }}
      />
      <SubHead style={{ textAlign: "center" }}>
        Check your email for updates.
      </SubHead>
      <WantToKnow />
    </div>
  );
}
