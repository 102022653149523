import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { ArrowDropDown } from "@mui/icons-material";
import { Typography } from "@mui/material";
import PrimaryButton from "library/buttons/PrimaryButton";
import Spacer from "library/containers/Spacer";
import { OutPointBanner } from "library/display/OutPointBanner";
import { MAP_GOALS, RECOMMENDATION_SCENARIO_DETAILS } from "utils/enums";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OPMenu from "library/form/OPMenu";
import useToggle from "hooks/useToggle";
import { getOverview } from "redux/overviewSlice";
import { useDispatch } from "react-redux";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { OP_COLORS } from "../../../assets/palette";

function ScenarioBanner({ scenario }) {
  const { state: menuState, toggleOff, toggle } = useToggle();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  /* open/close the scenario menu, handle unintended state of menu safely */
  const handleScenarioToggle = (event = {}) => {
    toggle();
    setAnchorEl(event.currentTarget);
  };

  /* force close the scenario menu on scenario selection */
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    toggleOff();
  }, [anchorEl]);

  const { title = "", subtitle = "" } =
    RECOMMENDATION_SCENARIO_DETAILS[MAP_GOALS[scenario] || "revenue"] || {};

  const menuOptions = [
    {
      display: "Profit",
      onClick: async () => {
        await makeAuthenticatedPostRequest("/metadata", { goal: "efficiency" });
        dispatch(getOverview());

        handleMenuClose();
      },
    },
    {
      display: "Revenue",
      onClick: async () => {
        await makeAuthenticatedPostRequest("/metadata", { goal: "growth" });
        dispatch(getOverview());

        handleMenuClose();
      },
    },
  ];

  const renderBadgeIcon = (_) => <InfoOutlinedIcon {..._} />;
  return (
    <OutPointBanner
      displayText={title}
      subDisplayText={subtitle}
      level="neutral"
      moreBannerStyles={{
        borderRadius: "8px",
        height: "auto",
        borderColor: "primary.dark", // TODO: COLOUR_CHECK: the nectar version hat this set to "primary.main" and the main version was set to "blue.main"
        borderStyle: "solid",
        padding: "16px 6px 16px 6px",
      }}
      BadgeIcon={renderBadgeIcon}
    >
      <Spacer />
      <PrimaryButton
        style={{
          borderRadius: "8px",
          border: `1px solid ${OP_COLORS.DARK_GUNMETAL}`,
          height: "48px",
          padding: "12px, 16px, 12px, 16px",
          width: "auto",
        }}
        variant="outlined"
        onClick={handleScenarioToggle}
        endIcon={
          <ArrowDropDown
            sx={{
              color: "secondary.main",
            }}
          />
        }
      >
        <Typography
          style={{
            fontFamily: "IBM Plex Sans",
            fontSize: "16px",
            fontWeight: 400,
            letter: "1%",
            lineHeight: "24px",
            letterSpacing: "0.01em",
            textAlign: "left",
            color: OP_COLORS.DARK_GUNMETAL,
          }}
        >
          Change scenario
        </Typography>
      </PrimaryButton>
      <OPMenu
        menuState={menuState}
        handleMenuClose={handleMenuClose}
        menuOptions={menuOptions}
        anchorEl={anchorEl}
      />
    </OutPointBanner>
  );
}

export default ScenarioBanner;

ScenarioBanner.propTypes = {
  scenario: PropTypes.string,
};
