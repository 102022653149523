export const performanceInsightsStyles = {
  strategyToggleContainer: {
    margin: "10px 10px 10px 10px",
  },
  aggregationText: {
    marginRight: "10px",
  },
  toggleButtonLeft: {
    textTransform: "none",
    borderWidth: "1.5px",
    borderRadius: "8px 0px 0px 8px",
    "&.Mui-selected": {
      backgroundColor: "#f8f4fc",
      borderColor: "blue.main",
    },
  },
  toggleButtonRight: {
    textTransform: "none",
    borderWidth: "1.5px",
    borderRadius: "0px 8px 8px 0px",
    "&.Mui-selected": {
      backgroundColor: "#f8f4fc",
      borderColor: "blue.main",
    },
  },
};

export const insightsSummaryTableStyles = {
  moreTableContainerStyles: {},
};

export const PerformanceInsightsGraphStyles = {
  switch: {
    marginLeft: "12px",
  },
  cardSubtitleText: {
    fontSize: "14px",
    marginTop: "12px",
    alignItems: "center",
  },
};

export const summaryBarGraphStyles = {
  barGraphContainer: {
    marginTop: "24px",
    width: "100%",
    height: "100%",
  },
  containerCard: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  legendContainer: {
    width: "100%",
    marginBottom: "24px",
  },
  cardTitleText: {
    fontSize: "20px",
    fontWeight: 400,
  },
  subheaderText: {
    padding: "24px",
    width: "100%",
  },
};
