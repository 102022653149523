import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Box } from "@mui/material";

import PrimaryButton from "library/buttons/PrimaryButton";
import TextFieldSimple from "library/form/OutPointTextField";

import { isValidName } from "utils/data/validators";

import {
  registerNewCampaignGroup,
  selectObjectProperty,
} from "redux/campaignsSlice";

import { creatorStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import RadioButton from "library/buttons/RadioButton";
import LabelText from "library/text/body/LabelText";
import OPAutoComplete from "library/form/OutPointAutoComplete";

/**
 * Campaign Group Creator is a simple dialog that helps the
 * user create a new campaign group. The action button is only
 * enabled if a valid campaign group name is typed into the
 * input textfield.
 * */
function CampaignGroupCreator(props) {
  const { handleCloseDrawer } = props;
  const dispatch = useDispatch();

  const [input, setInput] = useState("");
  const [isBrand, setIsBrand] = useState(false);
  const [strategy, setStrategy] = useState("default");

  const currentGroupIds = useSelector(
    selectObjectProperty("campaign_groups", "id"),
  );
  const currentStrategyIds = useSelector(
    selectObjectProperty("strategies", "id"),
  );

  const handleGroupCreation = () => {
    dispatch(
      registerNewCampaignGroup({
        groupName: input,
        strategyId: strategy === "default" ? input : strategy,
        isBrand,
      }),
    );
    setInput("");
    setIsBrand(false);
    setStrategy();
    handleCloseDrawer();
  };

  const inputField = (
    <Grid
      container
      display="flex"
      direction="row"
      alignItems="flex-end"
      justifyContent="space-between"
    >
      <TextFieldSimple
        name="New campaign group name"
        sx={styles.inputField}
        onChange={(text) => setInput(text.trim())}
      />
    </Grid>
  );

  const brandPerformanceRadios = (
    <Box display="flex" direction="row" alignItems="center">
      <LabelText sx={styles.radioLabel}>Funnel type:</LabelText>
      <RadioButton
        label="Brand"
        checked={isBrand}
        setChecked={() => setIsBrand(true)}
        onClick={() => {}}
      />
      <RadioButton
        label="Performance"
        checked={!isBrand}
        setChecked={() => setIsBrand(false)}
        onClick={() => {}}
      />
    </Box>
  );

  const isValidInput = isValidName(input) && !currentGroupIds.includes(input);

  const cancelAddButton = (
    <PrimaryButton
      variant="outlined"
      onClick={handleCloseDrawer}
      sx={styles.button}
    >
      <Typography sx={styles.buttonLabel}>Cancel and go back</Typography>
    </PrimaryButton>
  );

  const confirmAddButton = (
    <PrimaryButton
      disabled={!isValidInput}
      onClick={handleGroupCreation}
      sx={styles.button}
    >
      <Typography sx={styles.buttonLabel}>Create New Group</Typography>
    </PrimaryButton>
  );

  const actionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancelAddButton}
      {confirmAddButton}
    </Grid>
  );

  const strategySelector = (
    <OPAutoComplete
      sx={styles.strategySelector}
      options={["default"].concat(
        currentStrategyIds.filter((id) => id !== "ungrouped"),
      )}
      value={strategy}
      name="Select a strategy"
      onChange={(e, selection) => setStrategy(selection)}
    />
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      sx={{
        maxWidth: "600px",
      }}
    >
      <Typography>
        Please choose a unique name that best represents the campaigns you want
        to group and select the strategy that this campaign group belongs to.
      </Typography>
      {inputField}
      {strategySelector}
      {brandPerformanceRadios}
      {actionButtons}
    </Grid>
  );
}

CampaignGroupCreator.propTypes = {
  handleCloseDrawer: PropTypes.func,
};

export default CampaignGroupCreator;
