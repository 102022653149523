import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";

import PrimaryButton from "library/buttons/PrimaryButton";
import TextFieldSimple from "library/form/OutPointTextField";

import { isValidName } from "utils/data/validators";

import {
  registerNewStrategy,
  selectObjectProperty,
} from "redux/campaignsSlice";

import { creatorStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

/**
 * Strategy Creator is a simple dialog that helps the
 * user create a new strategy. The action button is only
 * enabled if a valid strategy name is typed into the
 * input textfield.
 * */
function StrategyCreator(props) {
  const { handleCloseDrawer } = props;
  const dispatch = useDispatch();

  const [input, setInput] = useState("");

  const currentStrategyIds = useSelector(
    selectObjectProperty("strategies", "id"),
  );

  const handleStrategyCreation = () => {
    dispatch(
      registerNewStrategy({
        strategyName: input,
      }),
    );
    setInput("");
    handleCloseDrawer();
  };

  const inputField = (
    <TextFieldSimple
      name="New strategy name"
      sx={styles.strategyInputField}
      onChange={(text) => setInput(text.trim())}
    />
  );

  const isValidInput =
    isValidName(input) && !currentStrategyIds.includes(input);

  const cancelAddButton = (
    <PrimaryButton
      variant="outlined"
      onClick={handleCloseDrawer}
      sx={styles.button}
    >
      <Typography sx={styles.buttonLabel}>Cancel and go back</Typography>
    </PrimaryButton>
  );

  const confirmAddButton = (
    <PrimaryButton
      disabled={!isValidInput}
      onClick={handleStrategyCreation}
      sx={styles.button}
    >
      <Typography sw={styles.buttonLabel}>Create new strategy</Typography>
    </PrimaryButton>
  );

  const actionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancelAddButton}
      {confirmAddButton}
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      sx={{
        maxWidth: "600px",
      }}
    >
      <Typography>
        Please choose a unique name that best represents the strategy you want
        to add.
      </Typography>
      {inputField}
      {actionButtons}
    </Grid>
  );
}

StrategyCreator.propTypes = {
  handleCloseDrawer: PropTypes.func,
};

export default StrategyCreator;
