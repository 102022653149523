import React, { useState } from "react";

import { useDispatch } from "react-redux";

import BodyText from "library/text/body/BodyText";
import OutPointTextField from "library/form/OutPointTextField";
import { isValidNumber } from "utils/data/strings";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import PropTypes from "prop-types";
import { AGGREGATION_DAY_RANGES } from "utils/enums";
import OutPointSelector from "library/form/OutPointSelector";
import SeeResultsButton from "./SeeResultsButton";

function BudgetAdjustmentPage({
  setOptimization,
  setErrorText,
  chosenTimeRange,
  setChosenTimeRange,
  setDisplayTimeRange,
}) {
  const [budget, setBudget] = useState("");
  const [adjustment, setAdjustment] = useState("");
  const dispatch = useDispatch();

  return (
    <>
      <BodyText>
        Select a budget to optimize within, plus or minus an adjustment margin.
      </BodyText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          marginTop: "10px",
        }}
      >
        <OutPointTextField
          name="Budget"
          startAdornment="$"
          sx={{ width: "250px", marginRight: "20px" }}
          value={budget}
          onChange={(newValue) =>
            isValidNumber(newValue) && setBudget(newValue)
          }
        />
        <OutPointTextField
          name="Adjustment"
          sx={{ width: "250px" }}
          endAdornment="%"
          value={adjustment}
          onChange={(newValue) =>
            isValidNumber(newValue) && setAdjustment(newValue)
          }
        />
        <OutPointSelector
          sx={{
            minWidth: "200px",
            width: "auto",
            padding: "16px 16px",
            marginLeft: "16px",
          }}
          selectedValue={chosenTimeRange}
          onChange={(timeRange) => setChosenTimeRange(timeRange)}
          menuItems={["monthly", "weekly", "daily"]}
        />
      </div>
      <SeeResultsButton
        dispatch={dispatch}
        disabled={!budget || !adjustment}
        request={async () => {
          setOptimization({});
          setDisplayTimeRange(chosenTimeRange);
          return makeAuthenticatedGetRequest("/optimize_adjustment", {
            budget: budget / AGGREGATION_DAY_RANGES[chosenTimeRange],
            budgetAdjustment: adjustment,
          });
        }}
        setOptimization={setOptimization}
        setErrorText={setErrorText}
      />
    </>
  );
}

export default BudgetAdjustmentPage;

BudgetAdjustmentPage.propTypes = {
  setOptimization: PropTypes.func,
  setErrorText: PropTypes.func,
  chosenTimeRange: PropTypes.string,
  setChosenTimeRange: PropTypes.func,
  setDisplayTimeRange: PropTypes.func,
};
