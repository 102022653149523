import { getChannelColour } from "assets/colours";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { lightenDarkenColor } from "react-papaparse";
import { useSelector } from "react-redux";
import { normalizeChannelName, sentenceCase } from "utils/data/strings";
import { createCurves, handleExplicitlySetRange } from "utils/graphing/data";
import {
  daysToMilliSeconds,
  mixRangeToNumDays,
  trimDataSeries,
} from "utils/data/dates";

import {
  adjustDataPoints,
  aggregateDataSeries,
  makeRelatedCurvesContiguous,
} from "utils/graphing/series";

import { deepCopy } from "utils/data/objects";
import { MIX_RANGES as mixRanges } from "../../../utils/enums";
import ActualsTemplate from "./ActualsTemplate";
import StrategyChannelToggle from "./StrategyChannelToggle";

function SpendActualsCard(props) {
  const { timeRangeLabel = "daily", timeRangeDays = 1 } = props;
  const roasResponse = useSelector((state) => state.reportingData);
  const [isGroupedByStrategy, setIsGroupByStrategy] = useState(true);

  const [mixRange, setMixRange] = useState("All time");
  const [curves, setCurves] = useState([]);
  const [explicitRange, setExplicitRange] = useState([]);

  const updateData = () => {
    const relevantKey = isGroupedByStrategy
      ? "actualsGroupStrategy"
      : "actualsGroup"; // key name based on the grouping toggle
    const actualsData = deepCopy(roasResponse[relevantKey]?.data || {});
    const supportedCategories =
      roasResponse[relevantKey]?.ordered_categories || [];
    if (!actualsData) {
      return;
    }

    // picks and trims the data series based on the overall params selected:
    const endDate = Math.max(
      ...Object.values(actualsData).map((categoryData) =>
        Math.max(...categoryData.map((row) => row.timestamp)),
      ),
    );
    const startDate =
      mixRange === "All time"
        ? 0
        : endDate - daysToMilliSeconds(mixRangeToNumDays[mixRange]);
    const trimmedData = trimDataSeries(
      actualsData,
      startDate,
      endDate,
      "timestamp",
    );

    // creates normal curves for each category, modifying the trimmedData directly:
    supportedCategories.forEach((category) => {
      const normalCurves = createCurves(trimmedData[category], {
        prev_channel_spend: "z",
        timestamp: "x",
        accumulative_spend: "y",
        spend: "value",
        interpolated: "interpolated",
      });

      trimmedData[category] = normalCurves;
    });

    // gathers a map of last datapoints along with a pass that creates curves
    const categoryToLastDataPointMap = {};

    const updatedCurves = supportedCategories
      .map((category, index) => {
        // non-interpolated line points:
        const linePoints = trimmedData[category].filter(
          (row) => !row.interpolated,
        );

        if (!linePoints.length) return null;

        categoryToLastDataPointMap[category] =
          linePoints[linePoints.length - 1];

        return {
          linePoints,
          name: normalizeChannelName(category),
          /*
          Supported Channels is the source of truth for ordering
          This ensures, that order is preserved on all display periods
        */
          order: index + 1,
          color: getChannelColour(category),
          fill: {
            target: "origin",
          },
        };
      })
      .filter((e) => e);

    const curvesInterpolated = supportedCategories
      .map((category, index) => {
        const linePoints = trimmedData[category].filter(
          (row) => row.interpolated,
        );
        const spendExistsLinePoints = linePoints?.filter((row) => row.value);
        const isLastSeries = categoryToLastDataPointMap[category];

        if (!linePoints.length || !spendExistsLinePoints?.length) return null;

        if (isLastSeries) {
          linePoints.unshift(isLastSeries);
        }

        return {
          linePoints,
          name: `Aggregated ${normalizeChannelName(category)}`,
          order: index + 1,
          color: lightenDarkenColor(getChannelColour(category), 60),
          fill: {
            target: "origin",
          },
        };
      })
      .filter((e) => e);

    const allCurves = updatedCurves.concat(curvesInterpolated);
    const adjustedCurves = allCurves.map((curve) => {
      const newCurve = { ...curve };
      newCurve.linePoints = adjustDataPoints(newCurve.linePoints);

      return newCurve;
    });

    /// TODO: verify if I should be doing smooth then aggregate or aggregate then smooth. If it's aggregate then smooth, then the window size for smoothing has to vary based on what the timeRangeDays is!!
    const timeAggregatedCurves = makeRelatedCurvesContiguous(
      aggregateDataSeries(adjustedCurves, timeRangeDays, "x", "y"),
      timeRangeDays,
    );

    setExplicitRange(
      handleExplicitlySetRange({ curves: timeAggregatedCurves }),
    );
    setCurves(
      timeAggregatedCurves.filter((curve) => curve.linePoints?.length > 1),
    );
  };

  useEffect(updateData, [
    mixRange,
    roasResponse.status,
    isGroupedByStrategy,
    timeRangeDays,
    timeRangeLabel,
  ]);

  const toggleHeader = (
    <StrategyChannelToggle
      groupByStrategy={isGroupedByStrategy}
      setGroupByStrategy={setIsGroupByStrategy}
    />
  );

  const groupingStr = isGroupedByStrategy ? "Strategies" : "Channels";
  const headerText = `Historical ${sentenceCase(
    timeRangeLabel,
  )} Spend Across ${groupingStr}`;

  return (
    <div
      style={{
        padding: "32px 24px 8px 24px",
        height: "100%",
        position: "relative",
      }}
      data-cy={`actuals-card-${curves?.length > 0 ? "filled" : "empty"}`}
    >
      <ActualsTemplate
        aggregationControls={toggleHeader}
        headerText={headerText}
        groupByStrategy={isGroupedByStrategy}
        setGroupByStrategy={setIsGroupByStrategy}
        mixRange={mixRange}
        setMixRange={setMixRange}
        mixRanges={mixRanges}
        curves={curves}
        explicitRange={explicitRange}
        timeRangeLabel={timeRangeLabel}
      />
    </div>
  );
}

SpendActualsCard.propTypes = {
  timeRangeDays: PropTypes.number,
  timeRangeLabel: PropTypes.string,
};

export default SpendActualsCard;
