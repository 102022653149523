/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { formatDate, getDateRange } from "utils/data/dates";
import { STATUS } from "utils/enums";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { groupBy, isEmpty } from "lodash";

/**
 * Returns a merged array of spend and lift data which is a list of objects that represent the specific lift and associated
 * spend values for each date.
 * */
const mergeRawSpendAndLiftData = (rawSpendData, specificRawLiftData) => {
  const finalMergedRawData = [];
  const hasRevenueData = (arr) =>
    arr.some((constituentObj) => "revenue" in constituentObj);

  const joined = [...rawSpendData, ...specificRawLiftData];
  const groupedByDates = groupBy(joined, (elem) => elem.date);

  // ensures that both spend and revenue data exist for every date value in consideration:
  Object.entries(groupedByDates).forEach(([_, dateGrouping]) => {
    if (!hasRevenueData(dateGrouping)) {
      return;
    }
    const mergedDataPoint = {};
    dateGrouping.forEach((row) => {
      Object.entries(row).forEach(([elemKey, elem]) => {
        mergedDataPoint[elemKey] = elem;
      });
    });
    finalMergedRawData.push(mergedDataPoint);
  });

  return finalMergedRawData;
};

/**
 * Selects the unique lift types that the data from the backend provides.
 * e.g. organice, lift, retail...
 * */
export const selectLiftTypes = createSelector(
  (state) => state?.liftDataStore?.liftData || {},
  (liftData) => Object.keys(liftData),
);

export const selectLiftData = (liftType) => (state) => {
  const {
    liftData,
    rawLiftData, // this is a dict of arrays, with the keys being type of lift
    spendData,
    rawSpendData, // this is an array, like before
    supportedSpendChannels,
    supportedRevenueChannels,
    selectedChannels,
    startDate,
    endDate,
    staticBaselineCoefficients,
  } = state.liftDataStore;

  const liftCoordinatesInfo = liftData[liftType] || {};
  let mergedLiftData = {};
  if (!isEmpty(liftCoordinatesInfo) && !isEmpty(spendData)) {
    mergedLiftData = {
      ...spendData,
      revenue: { ...liftCoordinatesInfo },
    };
  }
  const rawData = mergeRawSpendAndLiftData(
    rawSpendData,
    rawLiftData[liftType] || [],
  );
  const staticBaselineLift = staticBaselineCoefficients?.[liftType] || 0;

  return {
    liftData: mergedLiftData,
    staticBaselineLift,
    rawData,
    supportedSpendChannels,
    supportedRevenueChannels,
    selectedChannels,
    startDate,
    endDate,
  };
};

export const getLiftData = createAsyncThunk(
  "asyncRedux/liftData",
  async (queryParams = {}) => {
    let { startDate, endDate } = queryParams;

    if (!startDate || !endDate)
      throw new Error(`Missing required parameters ${queryParams}`);

    startDate = formatDate({ date: startDate });
    endDate = formatDate({ date: endDate });

    if (startDate > endDate)
      return console.log("Start date > endDate", { startDate, endDate });

    return makeAuthenticatedGetRequest("lift-model-data", {
      startDate,
      endDate,
    });
  },
);

const [defaultStartDate, defaultEndDate] = getDateRange({
  options: { rangeUnit: 2 },
});

const initialState = {
  liftData: {},
  rawLiftData: {},
  spendData: {},
  staticBaselineCoefficients: {},
  rawSpendData: [],
  supportedSpendChannels: [],
  supportedRevenueChannels: [],
  selectedChannels: [],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  status: null,
  authError: null,
};

const liftDataSlice = createSlice({
  name: "liftDataSlice",
  initialState,
  reducers: {
    liftDataLogout: () => {
      return initialState;
    },
    setLiftData: (state, { payload }) => {
      state.liftData = payload;
    },
    setSelectedChannels: (state, { payload }) => {
      state.selectedChannels = payload;
    },
    setStartDate: (state, { payload }) => {
      state.startDate = payload;
    },
    setEndDate: (state, { payload }) => {
      state.endDate = payload;
    },
  },
  extraReducers: {
    [getLiftData.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getLiftData.fulfilled]: (state, { payload }) => {
      if (!payload) return;

      const { data = {} } = payload;

      const {
        all_lift_coordinates: allLiftCoordinates = {},
        all_lift_raw_data: allLiftRawData = {},
        all_spend_coordinates: allSpendCoordinates = {},
        all_spend_raw_data: allSpendRawData = {},
        all_static_baseline_coefficients: allStaticBaselineCoefficients = {},
        supported_spend_channels: supportedSpendChannels = [],
        supported_revenue_channels: supportedRevenueChannels = [],
      } = data;

      state.liftData = allLiftCoordinates;
      state.rawLiftData = allLiftRawData;
      state.spendData = allSpendCoordinates;
      state.rawSpendData = allSpendRawData;
      state.supportedSpendChannels = supportedSpendChannels;
      state.supportedRevenueChannels = supportedRevenueChannels;
      state.staticBaselineCoefficients = allStaticBaselineCoefficients;
      state.selectedChannels = supportedSpendChannels;
    },
    [getLiftData.rejected]: (state) => {
      state.liftData = {};
    },
  },
});

export const {
  liftDataLogout,
  setLiftData,
  setSelectedChannels,
  setEndDate,
  setStartDate,
} = liftDataSlice.actions;

export default liftDataSlice.reducer;
