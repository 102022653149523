import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import { cardData, cardGroups } from "pages/connections/data/connectionsData";
import PlatformCardTemplate from "./PlatformCardTemplate";

function PlatformCards(props) {
  const { group, whereFrom, justify, height } = props;

  let cardsToDisplay = cardGroups.all;
  if (cardGroups[group]) cardsToDisplay = cardGroups[group];
  cardsToDisplay.sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
  });

  return (
    <Grid
      container
      justifyContent={justify || "left"}
      spacing={3}
      data-cy="platform-cards"
    >
      {cardsToDisplay.map((channel) => (
        <Grid key={`platformCard-${channel}`} item xs={6} md={4}>
          <PlatformCardTemplate
            channel={channel}
            height={height}
            whereFrom={whereFrom}
            {...(cardData[channel] || {})}
          />
        </Grid>
      ))}
    </Grid>
  );
}
PlatformCards.propTypes = {
  group: PropTypes.string,
  whereFrom: PropTypes.string,
  justify: PropTypes.string,
  height: PropTypes.string,
};

export default PlatformCards;
