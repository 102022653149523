import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Grid, Button } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import Header3 from "library/text/headers/Header3";
import Header4 from "library/text/headers/Header4";
import BodyText from "library/text/body/BodyText";
import Label from "library/text/body/LabelText";
import TextFieldSimple from "library/form/OutPointTextField";
import OPAutoComplete from "library/form/OutPointAutoComplete";
import ChipButtonGroup from "library/buttons/ChipButtonGroup";
import PrimaryButton from "library/buttons/PrimaryButton";
import { makeNcrPutRequest } from "utils/backend-api";
import { newChannelSubmit } from "redux/newChannelRecommendationsSlice";
import RadioButtons from "library/form/RadioButtons";
import ConfirmCloseModal from "pages/ncr/components/ConfirmCloseModal";
import OutPointModal from "library/surface/ModalCentered";

const GRID_SPACING = 2;

const styles = {
  card: {
    // top: '5%',
  },
  header4: {
    color: "secondary.main",
    marginTop: "30px",
    marginBottom: "10px",
  },
  submitButtons: {
    width: "200px",
  },
};

const INDUSTRIES = [
  "Apparel and fashion",
  "Alcohol",
  "Automotive",
  "Books and literature",
  "Charity",
  "E-commerce",
  "Education",
  "Electronics",
  "Entertainment",
  "Financial services",
  "Fitness",
  "Food and drink",
  "Games",
  "Government",
  "Health & beauty",
  "Household goods",
  "Healthcare",
  "Pets",
  "Personal care",
  "Retail",
  "Telecom",
];

const ONLY_NUMBERS_REGEX = /^\d*$/;

const FIELD_IDS = Object.freeze({
  BUDGET: "adBudget",
  INDUSTRY: "industry",
  CAC: "targetCAC",
  ROAS: "targetROAS",
  AGE: "ages",
  SEX: "sex",
  MARKET: "market",
  CAMPAIGN_TYPE: "campaignType",
  TIMELINE: "timeline",
});

const OPTIONAL_FIELDS = ["CAMPAIGN_TYPE"];

function IndustryField({ name, ...props }) {
  return (
    <>
      <Label style={{ marginBottom: "3px" }}>{name}</Label>
      <OPAutoComplete {...props} />
    </>
  );
}

IndustryField.propTypes = {
  name: PropTypes.string,
};

export default function NewChannelRecommendationsFormModal(props) {
  const { modalOpen, setModalOpen } = props;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [formInformation, setFormInformation] = useState({});

  const dispatch = useDispatch();

  const channelsList = useSelector(
    (state) => state.recommendationsData,
  )?.baseLineScenarios;
  const goal = useSelector((state) => state.overviewData)?.data?.goal;
  const LTV = useSelector((state) => state.overviewData)?.data?.ltv;

  const canSubmit = Object.keys(FIELD_IDS).reduce((prev, fieldId) => {
    if (OPTIONAL_FIELDS.includes(fieldId)) {
      return prev;
    }

    const fieldInfo = formInformation[FIELD_IDS[fieldId]];
    if (typeof fieldInfo === "object") {
      return (
        prev &&
        Object.values(fieldInfo).reduce(
          (prevField, currField) => prevField || currField,
          false,
        )
      );
    }

    return prev && formInformation[FIELD_IDS[fieldId]];
  }, true);

  const onInputChange = (id, value) => {
    setFormInformation((prev) => {
      const newFormInformation = { ...prev };
      newFormInformation[id] = value;
      return newFormInformation;
    });
  };

  const onInputChangeIfNumber = (id, value) => {
    if (ONLY_NUMBERS_REGEX.test(value)) {
      onInputChange(id, value);
    }
  };

  const onSubmit = async () => {
    if (canSubmit) {
      const infoToSubmit = formInformation;
      if (!infoToSubmit[FIELD_IDS.CAMPAIGN_TYPE]) {
        infoToSubmit[FIELD_IDS.CAMPAIGN_TYPE] =
          goal === "growth" ? "brand" : "target";
      }
      infoToSubmit[FIELD_IDS.TIMELINE] =
        parseInt(infoToSubmit[FIELD_IDS.TIMELINE], 10) * 7;
      infoToSubmit.channels = channelsList;
      infoToSubmit.LTV = LTV;

      try {
        const response = await makeNcrPutRequest("metadata", {
          metadata: infoToSubmit,
        });

        if (response.success) {
          setModalOpen(false);
          dispatch(newChannelSubmit());
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("PUT Request for NCR metadata failed with error, ", e);
      }
    }
  };

  const tryModalClose = () => {
    setConfirmModalOpen(true);
  };

  const handleModalClose = () => {
    setFormInformation({});
    setConfirmModalOpen(false);
    setModalOpen(false);
  };

  const BASIC_INFO_FIELDS = [
    {
      name: "Campaign Type (Optional)",
      id: FIELD_IDS.CAMPAIGN_TYPE,
      size: 12,
      Component: RadioButtons,
      onChange: () => undefined,
      labels: ["brand", "target"],
      value: formInformation[FIELD_IDS.CAMPAIGN_TYPE],
      setValue: (value) => {
        if (formInformation[FIELD_IDS.CAMPAIGN_TYPE] === value) {
          onInputChange(FIELD_IDS.CAMPAIGN_TYPE, undefined);
        } else {
          onInputChange(FIELD_IDS.CAMPAIGN_TYPE, value);
        }
      },
    },
    {
      name: "Industry",
      id: FIELD_IDS.INDUSTRY,
      size: 12,
      Component: IndustryField,
      options: INDUSTRIES,
      onChange: (id) => (e, value) => onInputChange(id, value),
    },
    {
      name: "Estimated Ad Budget",
      id: FIELD_IDS.BUDGET,
      size: 12,
      startAdornment: "$",
      Component: TextFieldSimple,
      onChange: (id) => (value) => onInputChangeIfNumber(id, value),
    },
    {
      name: "Target CAC",
      id: FIELD_IDS.CAC,
      size: 6,
      startAdornment: "$",
      Component: TextFieldSimple,
      onChange: (id) => (value) => onInputChangeIfNumber(id, value),
    },
    {
      name: "Target ROAS",
      id: FIELD_IDS.ROAS,
      size: 6,
      startAdornment: "$",
      Component: TextFieldSimple,
      onChange: (id) => (value) => onInputChangeIfNumber(id, value),
    },
  ];

  const BUTTON_GROUPS = [
    {
      label: "Ages",
      id: FIELD_IDS.AGE,
      size: 8,
      options: [
        {
          label: "2+",
          id: "2+",
        },
        {
          label: "18-49",
          id: "18-49",
        },
        {
          label: "25-54",
          id: "25-54",
        },
        {
          label: "35+",
          id: "35+",
        },
        {
          label: "50+",
          id: "50+",
        },
      ],
    },
    {
      label: "Sex",
      id: FIELD_IDS.SEX,
      size: 4,
      options: [
        {
          label: "Male",
          id: "male",
        },
        {
          label: "Female",
          id: "female",
        },
      ],
    },
    {
      label: "Market",
      id: FIELD_IDS.MARKET,
      size: 6,
      options: [
        {
          label: "Canada",
          id: "canada",
        },
        {
          label: "USA",
          id: "us",
        },
        {
          label: "Other",
          id: "other",
        },
      ],
    },
  ];

  useEffect(() => {
    BUTTON_GROUPS.map(({ id }) => onInputChange(id, {}));
  }, [modalOpen]);

  return (
    <>
      <ConfirmCloseModal
        modalOpen={confirmModalOpen}
        setModalOpen={setConfirmModalOpen}
        onClose={handleModalClose}
      />

      <OutPointModal
        modalOpen={modalOpen}
        setModalOpen={tryModalClose}
        sx={styles.card}
      >
        <Header3>Enter your campaign details</Header3>
        <BodyText color="secondary">
          We’ll use this to understand your objectives and narrow down possible
          channels.
        </BodyText>

        <Header4 sx={styles.header4}>Basic information</Header4>
        <Grid container spacing={GRID_SPACING}>
          {BASIC_INFO_FIELDS.map(
            ({ Component, onChange, size, id, ...rest }) => (
              <Grid item xs={size}>
                <Component
                  onChange={onChange(id)}
                  value={formInformation[id] || ""}
                  {...rest}
                />
              </Grid>
            ),
          )}
        </Grid>

        <Header4 sx={styles.header4}>Timeline</Header4>
        <Grid spacing={GRID_SPACING} container>
          <Grid item xs={6}>
            <TextFieldSimple
              name="Campaign Length"
              endAdornment="weeks"
              value={formInformation[FIELD_IDS.TIMELINE] || ""}
              onChange={(value) =>
                onInputChangeIfNumber(FIELD_IDS.TIMELINE, value)
              }
            />
          </Grid>
        </Grid>

        <Header4 sx={styles.header4}>Target demographic</Header4>
        <BodyText color="secondary" style={{ marginBottom: "10px" }}>
          Specify the audience you’d like to reach.
        </BodyText>
        <Grid container>
          {BUTTON_GROUPS.map(
            ({ label, size, id, options, ...rest }) =>
              formInformation[id] != null && (
                <Grid item xs={size} style={{ marginBottom: "7px" }}>
                  <Label color="secondary" style={{ marginBottom: "5px" }}>
                    {label}
                  </Label>
                  <ChipButtonGroup
                    selections={formInformation[id]}
                    setSelections={(setSelections) =>
                      onInputChange(id, setSelections(formInformation[id]))
                    }
                    options={options}
                    {...rest}
                  />
                  {formInformation[id].other && (
                    <TextFieldSimple
                      name="Please specify"
                      onChange={(value) => {
                        const buttonGroupInfo = { ...formInformation[id] };
                        buttonGroupInfo.other = value === "" ? true : value;
                        onInputChange(id, buttonGroupInfo);
                      }}
                    />
                  )}
                </Grid>
              ),
          )}
        </Grid>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 30px 0 30px",
            width: "100%",
            marginTop: "50px",
          }}
        >
          <Button
            sx={styles.submitButtons}
            variant="text"
            onClick={tryModalClose}
          >
            Cancel
          </Button>
          <PrimaryButton
            disabled={!canSubmit}
            sx={styles.submitButtons}
            onClick={onSubmit}
          >
            Confirm
          </PrimaryButton>
        </div>
      </OutPointModal>
    </>
  );
}

NewChannelRecommendationsFormModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
};
