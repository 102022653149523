import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

import { Doughnut } from "react-chartjs-2";
import { deepCopy } from "utils/data/objects";
import { LIGHT_MEDIUM_GREY } from "assets/palette";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
  annotationPlugin,
  Filler,
);

// export this and get started in your own graph implementation
const EASILY_EXTENDABLE_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  tooltipPluginOptions: {},
  titlePluginOptions: {
    display: false,
    text: "",
  },
};

export const getEasilyExtendableOptions = () =>
  deepCopy(EASILY_EXTENDABLE_OPTIONS);

function PieChart(props) {
  const {
    data = {},
    dataLabels = [],
    moreOptions = {},
    graphStyle = {},
    chartRef = null,
  } = props;

  // extract options
  const {
    responsive = true,
    cutout,
    tooltipPluginOptions = {},
    onHover = () => {},
    titlePluginOptions = {},
    annotationsPluginOptions = [],
  } = moreOptions;

  const defaultData = {
    labels: [],
    datasets: [],
  };

  const annotations = annotationsPluginOptions.map((annotation) => {
    const { labelSettings = {}, annotationSettings = {} } = annotation;

    return {
      drawTime: "afterDatasetsDraw",
      type: "line",
      mode: "vertical",
      borderWidth: 1,
      borderColor: annotationSettings?.borderColor || "#50C878",
      borderDash: [5, 5],
      ...annotationSettings,
      label: {
        content: labelSettings?.content,
        enabled: true,
        position: "start",
        backgroundColor: labelSettings?.backgroundColor || "#50C878",
        ...labelSettings,
      },
    };
  });

  // common styles amongst tool tip components
  const toolTipCommonStyles = {
    font: {
      size: 16,
      fontFamily: "IBM Plex Sans",
      lineHeight: "24px",
    },
    color: "black",
  };

  const options = {
    cutout: cutout ?? "0%",
    responsive,
    plugins: {
      annotation: {
        annotations,
      },
      title: {
        display: false,
        text: "",
        ...titlePluginOptions,
      },
      tooltip: {
        mode: "nearest",
        intersect: false,
        callbacks: {
          title: (context) => {
            if (!context.length) return context;

            const { x = 0 } = context[0]?.raw || {};

            if (tooltipPluginOptions.formatTitle)
              return tooltipPluginOptions.formatTitle(context[x]);

            return context;
          },
          label: (context) => {
            // NOTE: This should be changed to work similar to title
            return `${context.dataset.label}: $${context.formattedValue}`;
          },
          ...(tooltipPluginOptions.label && {
            label: tooltipPluginOptions.label,
          }),
        },
        backgroundColor: "white",
        borderColor: LIGHT_MEDIUM_GREY,
        borderWidth: 1,
        titleColor: toolTipCommonStyles.color,
        titleFont: toolTipCommonStyles.font,
        bodyColor: toolTipCommonStyles.color,
        bodyFont: toolTipCommonStyles.font,
        padding: 24,
        cornerRadius: 8,
        usePointStyle: true,
        boxWidth: 12,
      },
    },
    // for most intents and purposes, you likely want to keep the hover setting to nearest without intersecting
    // nearest finds the nearest element to the current point
    // intersect allows us to hover and display information without being exactly on the line series
    hover: {
      mode: "nearest",
      intersect: false,
    },
    onHover,
  };

  defaultData.datasets.push({
    label: data.name,
    data: data.values,
    backgroundColor: data.colors,
    hoverOffset: data.hoverOffset ?? 4,
  });
  defaultData.labels = dataLabels;

  return (
    <Doughnut
      ref={chartRef}
      style={{
        maxHeight: "300px",
        marginTop: "30px",
        marginBottom: "30px",
        width: "100%",
        ...graphStyle,
      }}
      options={options}
      data={defaultData}
    />
  );
}

export default React.memo(PieChart);

PieChart.propTypes = {
  data: PropTypes.object,
  moreOptions: PropTypes.object,
  dataLabels: PropTypes.arrayOf(PropTypes.string),
  graphStyle: PropTypes.object,
  chartRef: PropTypes.oneOf([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};
