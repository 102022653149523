import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import Header1 from "library/text/headers/Header1";
import OutPointSelector from "library/form/OutPointSelector";
import BodyText from "library/text/body/BodyText";
import RecommendationsSummaryTable from "pages/recommendations/components/RecommendationsSummaryTable";
import graphic from "assets/signin_graphic.png";

import OutPointCard from "library/surface/OutPointCard";
import { FEEDBACK_OR_ERROR_100, FEEDBACK_OR_ERROR_900 } from "assets/palette";
import IconText from "library/containers/IconText";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import RecommendationsBarGraph from "pages/recommendations/components/RecommendationsBarGraph";
import { AGGREGATION_DAY_RANGES } from "utils/enums";
import BudgetAdjustmentPage from "./components/BudgetAdjustmentPage";
import FixedChannelsPage from "./components/FixedChannelsPage";
import FixedBudgetPage from "./components/FixedBudgetPage";
import MediaPlanPage from "./components/MediaPlanPage";

const SCENARIO_OPTIONS = [
  "media plan",
  "fixed budget",
  "fixed channels",
  "budget adjustment",
];

const PAGES = {
  "media plan": MediaPlanPage,
  "fixed budget": FixedBudgetPage,
  "fixed channels": FixedChannelsPage,
  "budget adjustment": BudgetAdjustmentPage,
};

export default function OptimizationPage() {
  const [scenario, setScenario] = useState("media plan");
  const [optimization, setOptimization] = useState({});
  const [displayTimeRange, setDisplayTimeRange] = useState("monthly");
  const [chosenTimeRange, setChosenTimeRange] = useState("monthly");
  const [errorText, setErrorText] = useState("");

  const Form = PAGES[scenario];

  const recommendationsData = useSelector((state) => state.recommendationsData);
  const baselineData = recommendationsData?.data?.baseline;

  useEffect(() => {
    setOptimization({});
  }, [scenario]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Header1 style={{ marginBottom: "5px" }}>Planner</Header1>
        <OutPointSelector
          sx={{ marginBottom: "20px" }}
          onChange={(text) => setScenario(text)}
          menuItems={SCENARIO_OPTIONS}
          defaultText="Choose scenario"
          selectedValue={scenario}
        />
      </div>
      {Form ? (
        <Form
          setOptimization={setOptimization}
          setErrorText={setErrorText}
          chosenTimeRange={chosenTimeRange}
          setChosenTimeRange={setChosenTimeRange}
          setDisplayTimeRange={setDisplayTimeRange}
        />
      ) : (
        <div>
          <BodyText>Choose a scenario to begin planning</BodyText>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <img
              alt="Person looking at chart"
              style={{ height: "300px", marginTop: "15%" }}
              src={graphic}
            />
          </div>
        </div>
      )}
      {Object.keys(optimization).length > 0 ? (
        <>
          {errorText ? (
            <OutPointCard
              style={{
                padding: "20px",
                backgroundColor: FEEDBACK_OR_ERROR_100,
                height: "auto",
                marginTop: "40px",
              }}
            >
              <IconText>
                <WarningRoundedIcon
                  style={{ color: FEEDBACK_OR_ERROR_900, marginRight: "20px" }}
                />
                <BodyText>{errorText}</BodyText>
              </IconText>
            </OutPointCard>
          ) : (
            <div />
          )}
          <RecommendationsSummaryTable
            scenarioData={optimization}
            baselineData={baselineData}
            timeRange={displayTimeRange}
            timeRangeDays={AGGREGATION_DAY_RANGES[displayTimeRange]}
            reverseTotalsRow
          />
          <div style={{ marginTop: "20px" }}>
            <RecommendationsBarGraph
              scenarioData={optimization}
              baselineData={baselineData}
              timeRangeLabel={displayTimeRange}
              timeRangeDays={AGGREGATION_DAY_RANGES[displayTimeRange]}
            />
          </div>
        </>
      ) : (
        <div />
      )}
    </>
  );
}
