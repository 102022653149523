import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function Spacer({ sx = {}, props }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 0.9,
        height: "1px",
        ...sx,
      }}
      {...props}
    />
  );
}

export default Spacer;

Spacer.propTypes = {
  sx: PropTypes.object,
  props: PropTypes.object,
};
