import React from "react";
import { IconButton } from "@mui/material";
import { ExpandLessRounded, ExpandMoreRounded } from "@mui/icons-material";
import PropTypes from "prop-types";

function SectionCollapseButton({ isOpen, setIsOpen, sx = {} }) {
  const Icon = isOpen ? ExpandLessRounded : ExpandMoreRounded;
  return (
    <IconButton sx={{ ...sx }} onClick={() => setIsOpen(!isOpen)} disableRipple>
      <Icon />
    </IconButton>
  );
}
SectionCollapseButton.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  sx: PropTypes.object,
};

export default SectionCollapseButton;
