//
// Methods in this file should reliably work with data from the recommendationsData dataset.
//

/* Given a filter function and baseline channel data, return those channels which pass the filterFunc */
export function filterChannelsByRoas(
  baselineChannelData,
  curRoasType,
  filterFunc,
) {
  const channels = Object.keys(baselineChannelData);
  const filteredChannels = channels.filter((channel) =>
    filterFunc(baselineChannelData[channel][`${curRoasType}_roas`]),
  );
  const spendSortedChannels = filteredChannels.sort(
    (channel1, channel2) =>
      baselineChannelData[channel2].spend - baselineChannelData[channel1].spend,
  );
  return spendSortedChannels;
}

/* Given baseline and spend channelData, return recommendation percentages */
export function sortOptimalMediaMix(
  baselineChannelData,
  scenarioChannelData,
  keyword,
) {
  const channels = Object.keys(baselineChannelData);
  const groupedChannelData = { increase: [], decrease: [], turnBackOn: [] };

  channels.forEach((channel) => {
    const baselineVal = baselineChannelData[channel][keyword];
    const scenarioVal = scenarioChannelData[channel][keyword];

    if (baselineVal === 0 && scenarioVal > 0) {
      groupedChannelData.turnBackOn.push(channel);
      return;
    }

    if (baselineVal < scenarioVal) {
      groupedChannelData.increase.push(channel);
      return;
    }

    if (baselineVal > scenarioVal) {
      groupedChannelData.decrease.push(channel);
    }
  });

  return groupedChannelData;
}
