//
// Methods in this file should generate series for graphs and plots.
//
import { groupBy, slice, meanBy, get, uniq } from "lodash";
import { deepCopy } from "utils/data/objects";
import { isSameDay } from "date-fns";
import {
  aggregateDataSeriesOverTimeWindow,
  isSameWindowFunctions,
} from "../data/dates";

export function computeAverage(marginals) {
  const marginalY = marginals.map((marginal) => parseFloat(marginal.y));
  return marginalY.reduce((a, b) => a + b) / marginals.length;
}

/**
 * Combines coordinates across multiple coordinates for a common date.
 *
 * Preconditions:
 * - coordinates are objects that must already have x and y attributes, i.e. be visualizeable
 * */
export const aggregateCoordinatesAlongXAxis = (coordinates) => {
  const coordinatesGroupedByDate = groupBy(coordinates, "x");

  const cumulativeCoordinates = Object.entries(coordinatesGroupedByDate) // sorted by channel, and grouped x (date)
    .map(([date, datapoints]) => {
      // datapoints will be same channel but have diff campaigns
      const accumulatedValue = datapoints.reduce(
        (sum, currCampaign) => sum + currCampaign.y || 0,
        0,
      );
      // above reduces on "currCampaign", but really its the y value for each entry (aka diff
      // campaigns too
      return {
        x: Number(date),
        y: accumulatedValue,
      };
    });

  return cumulativeCoordinates;
};

/**
 * Calculates a simple moving average of points (as defined by a sliding window of windowSize) so as to smoothen a curve.
 * */
export const smoothCurve = (
  dataPoints = [],
  windowSize = 14,
  yAccessKey = "y",
) => {
  const halfWindowSize = Math.floor(windowSize / 2);
  const smoothenedPoints = dataPoints.map((point, idx) => {
    const windowStartIdx = Math.max(0, idx - halfWindowSize);
    const windowEndIdx = Math.min(dataPoints.length, idx + halfWindowSize);
    const window = slice(dataPoints, windowStartIdx, windowEndIdx);
    const windowMean = meanBy(window, yAccessKey);
    const newPoint = { ...point };
    newPoint[yAccessKey] = windowMean;

    return newPoint;
  });

  return smoothenedPoints;
};

/**
 * In our implementation thus far, the aggregated (this probably means predicted) curves
 * that pertain their specific historic curve are intended to be contiguous.
 * e.g. "Aggregated digital" curve is to come after all the "digital" data points have been drawn.
 *
 * To ensure that they are contiguous, the last datapoint from the historic curve can just be copied over to
 * become the first datapoint in the aggregated version.
 * This happens only if these two points have different x values.
 *
 * NOTE: this is an impure function that modifies the curves.
 * Assumptions:
 * - the logic in this helper function is reliant on the fact that the aggregated version of the graph is named
 *   a particular way (i.e. if the historcal curve is called "XXX" then the aggregated version is called "Aggregated XXX")
 * */
export function makeRelatedCurvesContiguous(curves, timeRangeDays = 1) {
  const isSameTimeWindowFn = isSameWindowFunctions[timeRangeDays] || isSameDay;
  const aggPrefix = "Aggregated ";
  const findHistoricVersionsOfCurve = (aggCurveName) => {
    const expectedName = aggCurveName.replace(aggPrefix, "");
    return curves.filter((otherCurve) => {
      const { name: otherName } = otherCurve;

      return expectedName === otherName;
    });
  };
  curves.forEach((curveObj) => {
    const { name: curveName } = curveObj;
    const historicVersions = findHistoricVersionsOfCurve(curveName);
    const hasHistoricVersion = historicVersions.length > 0;
    const isAggregatedVersion =
      curveName.startsWith(aggPrefix) && hasHistoricVersion;
    if (!isAggregatedVersion) {
      return;
    }
    // eslint-disable-next-line no-console -- it's an assertion
    console.assert(
      historicVersions.length === 1,
      "Based on naming convention, there should only be one corresponding historic curve for every aggregated curve.",
    );

    const historicCurve = historicVersions[0];
    const lastLinePointInHistoricCurve =
      historicCurve.linePoints[historicCurve.linePoints.length - 1];
    const firstLinePointInAggCurve = curveObj.linePoints[0];
    const isAlreadyContiguous = isSameTimeWindowFn(
      firstLinePointInAggCurve.x,
      lastLinePointInHistoricCurve.x,
    );

    if (isAlreadyContiguous) {
      // ensure that x values are equal:
      curveObj.linePoints[0].x = lastLinePointInHistoricCurve.x;
    } else {
      // unshift a new point:
      const newLinePoint = {
        ...firstLinePointInAggCurve,
        x: lastLinePointInHistoricCurve.x,
        y: lastLinePointInHistoricCurve.y,
      };

      curveObj.linePoints.unshift(newLinePoint); // side-effect
    }
  });

  return curves;
}

export function aggregateDataSeries(
  curves = [],
  windowSize = 1,
  dateAccessKey = "x",
  valAccessKey = "y",
  shouldAverage = false,
) {
  // FIXME: some typing issue comes up here and curves.map doesn't work without this guard clause
  if (!curves || !Array.isArray(curves)) {
    return curves;
  }

  const timeAggregatedCurves = curves.map((curve) => {
    const aggregatedCurve = deepCopy(curve);
    aggregatedCurve.linePoints = aggregateDataSeriesOverTimeWindow(
      curve.linePoints,
      windowSize,
      dateAccessKey,
      valAccessKey,
      shouldAverage,
    );

    return aggregatedCurve;
  });

  return timeAggregatedCurves;
}

/**
 * Based on the global mean of all the datapoints, keeps only those that are deemed as not outliers.
 *
 * NOTE: this is rudimentary for now -- we consider a point as an outlier if it's 200x (thresholdMultiple) the value of the global mean.
 *
 * */
export const filterGlobalOutliers = (
  dataPoints = [],
  yAccessKey = "y",
  thresholdMultiple = 200,
) => {
  const globalMean = meanBy(dataPoints, yAccessKey);
  const filteredDataPoints = dataPoints.filter((point) => {
    const deviation = Math.abs(get(point, yAccessKey) - globalMean);
    const deviationMultiple = deviation / globalMean;
    const isOutlier = deviationMultiple >= thresholdMultiple;

    return !isOutlier;
  });

  return filteredDataPoints;
};

export const adjustDataPoints = (dataPoints = []) => {
  const dataPointsWithoutOutliers = filterGlobalOutliers(dataPoints);
  const smoothenedDataPoints = smoothCurve(dataPointsWithoutOutliers);

  return smoothenedDataPoints;
};

/**
 * Based on a sliding window that defines a context, removes points if they can be deemed as outliers.
 *
 * NOTE: this is rudimentary for now -- we consider a point as an outlier if it's 200x (thresholdMultiple) the value of the mean
 * within the context window.
 *
 * */
export const filterContextualOutliers = (
  dataPoints = [],
  windowSize = 14,
  yAccessKey = "y",
  thresholdMultiple = 200,
) => {
  const filteredDataPoints = [];
  const halfWindowSize = Math.floor(windowSize / 2);

  const indicesOfDataPointsToRemove = []; // tolerates duplicates which get removed later
  dataPoints.forEach((_, dataPointIdx) => {
    const windowStartIdx = Math.max(0, dataPointIdx - halfWindowSize);
    const windowEndIdx = Math.min(
      dataPoints.length,
      dataPointIdx + halfWindowSize,
    );
    const window = slice(dataPoints, windowStartIdx, windowEndIdx);
    const windowMean = meanBy(window, yAccessKey);

    // gathers indices of local outliers:
    const localIndicesOfOutliers = [];
    window.forEach((windowPoint, windowIdx) => {
      const absDeviation = Math.abs(get(windowPoint, yAccessKey) - windowMean);
      const deviationMultiple = absDeviation / windowMean;
      const isOutlier = deviationMultiple > thresholdMultiple;

      if (isOutlier) {
        localIndicesOfOutliers.push(windowIdx);
      }
    });

    const globalIndicesOfOutliers = localIndicesOfOutliers.map(
      (localIdx) => windowStartIdx + localIdx,
    );
    indicesOfDataPointsToRemove.push(...globalIndicesOfOutliers);
  });

  const uniqueDataPointIndicesToRemove = uniq(indicesOfDataPointsToRemove);
  dataPoints.forEach((p, idx) => {
    if (idx in uniqueDataPointIndicesToRemove) {
      return;
    }

    filteredDataPoints.push(p);
  });

  return filteredDataPoints;
};
