import React from "react";
import { ExpandMore } from "@mui/icons-material";
import { Button, Grid, TextField } from "@mui/material";
import { OP_COLORS, PRIMARY_COLOR, PRIMARY_DARK } from "assets/palette";
import OutPointAutoComplete from "library/form/OutPointAutoComplete";
import SubHeadLight from "library/text/headers/SubHeadLight";
import PropTypes from "prop-types";

export default function CustomScenarioPicker({
  isVisible = false,
  spendList = {},
  scenarioData = {},
  calculatePlatformSelectionData,
  disableSeeResultsButton,
  handlePlatformSelectionUpdate,
  clearUserSelections,
}) {
  return (
    isVisible && (
      <Grid container sx={{ display: "flex", marginBottom: 4 }}>
        {Object.keys(spendList).map((channel) => {
          let value = null;

          if (scenarioData?.[channel]?.spend) {
            value = Number(scenarioData[channel].spend).toFixed(2);
          }

          return (
            <OutPointAutoComplete
              name={channel}
              key={channel}
              options={spendList[channel]}
              startAdornment="$ "
              value={value}
              popupIcon={<ExpandMore sx={{ color: "black", width: "80%" }} />}
              renderInput={(params) => {
                params.InputProps.startAdornment = "$ ";
                return (
                  <>
                    <SubHeadLight
                      style={{
                        color: OP_COLORS.OUTER_SPACE_CRAYOLA,
                        fontWeight: 500,
                        fontSize: "14px",
                        marginBottom: "4px",
                        marginTop: "24px",
                      }}
                    >
                      {channel}
                    </SubHeadLight>
                    <TextField
                      {...params}
                      label=""
                      style={{ backgroundColor: "white" }}
                    />
                  </>
                );
              }}
              sx={{ width: "160px", marginRight: "16px" }}
              onChange={(_, newValue) => {
                handlePlatformSelectionUpdate(channel, newValue);
              }}
            />
          );
        })}
        <Grid item xs={12} mt="24px">
          <Button
            variant="contained"
            style={{
              width: "176px",
              color: "white",
              backgroundColor: disableSeeResultsButton
                ? PRIMARY_COLOR
                : PRIMARY_DARK,
            }}
            disabled={disableSeeResultsButton}
            onClick={() => {
              calculatePlatformSelectionData();
            }}
          >
            See results
          </Button>
          <Button
            variant="text"
            sx={{
              color: PRIMARY_DARK,
              width: "34px",
              "&.MuiButtonBase-root:hover": {
                backgroundColor: "transparent",
              },
            }}
            onClick={() => {
              clearUserSelections();
            }}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    )
  );
}

CustomScenarioPicker.propTypes = {
  isVisible: PropTypes.bool,
  spendList: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  scenarioData: PropTypes.object,
  calculatePlatformSelectionData: PropTypes.func,
  disableSeeResultsButton: PropTypes.bool,
  handlePlatformSelectionUpdate: PropTypes.func,
  clearUserSelections: PropTypes.func,
};
