/**
 * Returns true if data is an array of items, with each item being an object where columnName is mapped to columnValue.
 * The library actually just relies on the first object's keys to determine the column names to use, but for extra checks,
 * this function checks that the keys within every object equals the keys in the first object.
 * Example:
      // [
      //   {
      //     "Column 1": "foo",
      //     "Column 2": "bar"
      //   },
      //   {
      //     "Column 1": "abc",
      //     "Column 2": "def"
      //   }
      // ]
 */
export const isValidJsonArray = (data = []) => {
  const isArray = Array.isArray(data);

  if (!isArray || !data.length) {
    return false;
  }

  const fields = Object.keys(data[0]);

  if (!fields.length) return false;

  return data.every((row) => {
    const rowFields = Object.keys(row);

    if (rowFields.length !== fields.length) return false;

    return rowFields.every((field, index) => field === fields[index]);
  });
};

/**
       * Returns true if the data passed in is a non-empty object which has the keys ('fields' and 'data').
       * Additionally, both 'fields' and 'data' should be arrays. The number of elements in each sub-array within
       * the data array (i.e. each row-item) should be the same as the number of sub-elements in the fields array (i.e. the number of columns).
       * Example:
          // {
          //   "fields": ["Column 1", "Column 2"],
          //   "data": [
          //     ["foo", "bar"],
          //     ["abc", "def"]
          //   ]
          // })
        Trade off - performance hit 0(2n)
      */
export const hasFieldsAndDataAttributesFormat = (message = {}) => {
  const { fields, data } = message;

  if (!fields || !data) return false;
  // required fields must be arrays
  if (!Array.isArray(fields) || !Array.isArray(data)) return false;

  const numOfColumns = fields.length;
  const dataHasCorrectNumOfColumns = data.every(
    (dataItem) => dataItem.length === numOfColumns,
  );

  return dataHasCorrectNumOfColumns;
};

export const addDateColumnToDataset = (data) => {
  const doAllDataContainUnixTimeStamp = data.every(
    (datum) => "timestamp" in datum,
  );
  if (!doAllDataContainUnixTimeStamp) {
    return data;
  }

  const updatedData = [];
  data.forEach((datum) => {
    const date = new Date(datum.timestamp).toDateString();
    const updatedDatum = {
      ...datum,
      date,
    };
    delete updatedDatum.timestamp;
    updatedData.push(updatedDatum);
  });

  return updatedData;
};

/**
       * Returns true if the csv data is an array of arrays, where each inner array represents a row item
       * and all of the inner arrays have the same number of (columns) elements.
       * Example:
          // [
          //   ["1-1", "1-2", "1-3"],
          //   ["2-1", "2-2", "2-3"]
          // ]
      
          Trade off - performance hit 0(2n)
       * */
export const isMultiDimensional = (data) => {
  const isArray = Array.isArray(data);

  if (!isArray) {
    return false;
  }

  const columnCount = Array.isArray(data[0]) ? data[0].length : 0;

  if (!columnCount) return false; // no columns

  const everyRowHasSameNumOfColumns = data.every(
    (row) => Array.isArray(row) && row.length === columnCount,
  );

  return everyRowHasSameNumOfColumns;
};

/**
 * Returns true if the data format is un-parseable by the react-papaparse library and
 * hence Downloadable. There are 3 distinct formats that are supported by the library.
 * */
export const isDownloadableData = (data, config = {}) => {
  if (!data) {
    return false;
  }

  let isDownloadable = false;

  if (config.format === "field-data") {
    isDownloadable = hasFieldsAndDataAttributesFormat(data);

    if (!isDownloadable) return false;
  }

  if (config.checkMultiDimensionalFields) {
    isDownloadable = isMultiDimensional(data);

    if (!isDownloadable) return false;
  } else {
    return isValidJsonArray(data);
  }

  return false;
};
