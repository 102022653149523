// eslint-disable-next-line import/prefer-default-export
export const manuallyShowTooltip = (chartRef, dataIndex) => {
  const currentActiveElements = chartRef?.current?.getActiveElements();
  const currentActiveTooltips = chartRef?.current?.tooltip?.getActiveElements();
  chartRef?.current?.setActiveElements([
    ...currentActiveElements,
    {
      datasetIndex: 0,
      index: dataIndex,
    },
  ]);
  chartRef?.current?.tooltip?.setActiveElements([
    ...currentActiveTooltips,
    {
      datasetIndex: 0,
      index: dataIndex,
    },
  ]);
  chartRef?.current?.update();
};

export const manuallyHideToolTip = (chartRef, dataIndex) => {
  const currentActiveElements = chartRef?.current?.getActiveElements();
  const selectedElement = currentActiveElements.findIndex(
    (element) => element.index === dataIndex,
  );
  const newActiveElements = [...currentActiveElements];
  newActiveElements.splice(selectedElement, 1);

  const currentActiveTooltips = chartRef?.current?.tooltip?.getActiveElements();
  const selectedTooltip = currentActiveTooltips.findIndex(
    (element) => element.index === dataIndex,
  );
  const newActiveTooltips = [...currentActiveTooltips];
  newActiveTooltips.splice(selectedTooltip, 1);

  chartRef?.current?.setActiveElements(newActiveElements);
  chartRef?.current?.tooltip?.setActiveElements(newActiveTooltips);
  chartRef?.current?.update();
};
