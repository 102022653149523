import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import BodyText from "library/text/body/BodyText";
import PrimaryButton from "library/buttons/PrimaryButton";
import ContactUsModal from "pages/onboarding/components/ContactUsModal";
import Header2 from "library/text/headers/Header2";

function CelebrateEmoji() {
  return (
    <span
      style={{ fontSize: "35px", textAlign: "left" }}
      role="img"
      aria-label="Celebrate"
      aria-hidden="false"
    >
      🎉
    </span>
  );
}

const styles = {
  text: {
    marginBottom: "20px",
  },
  img: {
    margin: "30px",
    width: "75px",
  },
  hyperlink: {
    color: "primary.main",
    fontWeight: 600,
  },
  button: {
    marginTop: "30px",
    width: "500px",
  },
};

function SuccessPage() {
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <ContactUsModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <BodyText style={{ marginBottom: "20px" }}>
        <CelebrateEmoji />
      </BodyText>
      <Header2 sx={styles.text}>Your first delivery is on the way!</Header2>
      <BodyText sx={styles.text}>
        We’ll contact you through email when your delivery is ready. This
        usually takes about 5-7 business days.
      </BodyText>
      <PrimaryButton sx={styles.button} onClick={() => navigate("/")}>
        Done
      </PrimaryButton>
    </>
  );
}

export default SuccessPage;
