import { createTheme } from "@mui/material";
import {
  PRIMARY_COLOR,
  PRIMARY_LIGHT,
  PRIMARY_DARK,
  PRIMARY_BACKGROUND,
  BRAND_GRAY_100,
  SONIC_SILVER,
  OP_COLORS,
  BLACK_60_PERCENT,
} from "../assets/palette";

const POPPINS = {
  fontFamily: "Poppins",
  fontWeight: "600",
  letterSpacing: "0.342857px",
};

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      dark: PRIMARY_DARK,
      light: PRIMARY_LIGHT,
      background: PRIMARY_BACKGROUND,
    },
    secondary: {
      main: BLACK_60_PERCENT,
    },
    inverseSecondary: {
      main: OP_COLORS.WHITE_TRANSLUCENT,
    },
    base: {
      main: "white",
    },
    grey: {
      light: BRAND_GRAY_100,
      main: SONIC_SILVER,
    },
    blue: {
      main: OP_COLORS.BLUE_PALATINATE_BLUE,
    },
  },
  typography: {
    h1: POPPINS,
    h2: POPPINS,
    h3: POPPINS,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
