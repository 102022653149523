import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";
import BodyText from "library/text/body/BodyText";
import OutPointTextField from "library/form/OutPointTextField";
import OutPointToggle from "library/buttons/OutPointToggle";

import { capitalize } from "lodash";
import { addMessage } from "redux/snackbarSlice";
import { isValidNumber } from "utils/data/strings";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import PropTypes from "prop-types";
import OutPointSelector from "library/form/OutPointSelector";
import { AGGREGATION_DAY_RANGES } from "utils/enums";
import GridItem from "./GridItem";
import SeeResultsButton from "./SeeResultsButton";

function FixedChannelsPage({
  setOptimization,
  setErrorText,
  chosenTimeRange,
  setChosenTimeRange,
  setDisplayTimeRange,
}) {
  const [budget, setBudget] = useState("");
  const [channelSpends, setChannelSpends] = useState({});
  const [shouldUseChannels, setShouldUseChannels] = useState({});
  const dispatch = useDispatch();

  const channels = useSelector((state) =>
    Object.keys(state.predictionsStore?.predictionsTableData || {}),
  );

  return (
    <div>
      <BodyText>
        Choose a budget to optimize within, and specify spend for some channels.
      </BodyText>
      <div
        style={{
          marginBottom: "30px",
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          marginTop: "10px",
        }}
      >
        <OutPointTextField
          name="Budget"
          sx={{ width: "250px" }}
          startAdornment="$"
          value={budget}
          onChange={(newValue) =>
            isValidNumber(newValue) && setBudget(newValue)
          }
        />
        <OutPointSelector
          sx={{
            minWidth: "200px",
            width: "auto",
            padding: "16px 16px",
            marginLeft: "16px",
          }}
          selectedValue={chosenTimeRange}
          onChange={(timeRange) => setChosenTimeRange(timeRange)}
          menuItems={["monthly", "weekly", "daily"]}
        />
      </div>
      <Grid container rowGap={2} columnSpacing={2}>
        {channels.map((channel) => (
          <GridItem key={channel}>
            <OutPointTextField
              name={capitalize(channel)}
              startAdornment="$"
              value={channelSpends[channel] || ""}
              onChange={(newValue) =>
                isValidNumber(newValue) &&
                setChannelSpends((oldSpends) => {
                  const newSpends = { ...oldSpends };
                  if (newValue === "") {
                    delete newSpends[channel];
                  } else {
                    newSpends[channel] = newValue;
                  }
                  return newSpends;
                })
              }
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <BodyText color="secondary" style={{ fontSize: "12px" }}>
                Set as fixed
              </BodyText>
              <OutPointToggle
                checked={!!shouldUseChannels[channel]}
                onChange={() =>
                  setShouldUseChannels((oldChannels) => {
                    const newChannels = { ...oldChannels };
                    newChannels[channel] = !newChannels[channel];
                    return newChannels;
                  })
                }
              />
            </div>
          </GridItem>
        ))}
      </Grid>
      <SeeResultsButton
        dispatch={dispatch}
        disabled={
          !budget ||
          !Object.keys(shouldUseChannels).reduce(
            (acc, channel) =>
              acc &&
              (shouldUseChannels[channel] ? !!channelSpends[channel] : true),
            true,
          ) ||
          !Object.keys(shouldUseChannels).reduce(
            (acc, channel) => acc || shouldUseChannels[channel],
            false,
          )
        }
        request={async () => {
          const fixedChannelSpends = {};
          let totalSpend = 0;
          Object.keys(channelSpends).forEach((channel) => {
            if (shouldUseChannels[channel]) {
              const newChannelName = channel.replaceAll(" ", "_");
              fixedChannelSpends[newChannelName] =
                channelSpends[channel] /
                AGGREGATION_DAY_RANGES[chosenTimeRange];
              totalSpend += parseInt(fixedChannelSpends[newChannelName], 10);
            }
          });

          if (totalSpend >= budget) {
            dispatch(
              addMessage({
                message: `Fixed channel spend cannot be over budget. Total channel spend is $${totalSpend}`,
              }),
            );
            return {
              success: false,
            };
          }

          setOptimization({});
          setDisplayTimeRange(chosenTimeRange);

          return makeAuthenticatedPostRequest("optimize_fixed_channels", {
            budget: budget / AGGREGATION_DAY_RANGES[chosenTimeRange],
            channels: fixedChannelSpends,
          });
        }}
        setOptimization={setOptimization}
        setErrorText={setErrorText}
      />
    </div>
  );
}

export default FixedChannelsPage;

FixedChannelsPage.propTypes = {
  setOptimization: PropTypes.func,
  setErrorText: PropTypes.func,
  chosenTimeRange: PropTypes.string,
  setChosenTimeRange: PropTypes.func,
  setDisplayTimeRange: PropTypes.func,
};
