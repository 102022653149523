import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box, ClickAwayListener } from "@mui/material";
import { format, parseISO } from "date-fns";
import { CALENDAR_DATE_FORMAT } from "utils/enums";
import { formatDate } from "utils/data/dates";
import PropTypes from "prop-types";

const borderRadius = "100px";
const maxWidth = "160px";
const height = "48px";

/**
 * Returns a date object after formating the datestring into a suitable format first.
 *
 * TODO: ensure that onChange for the dates don't trigger too early and break things because of invalid time inputs (or half-inputs)
 * */
function safeCreateDate(date) {
  const formatted = parseISO(formatDate({ date }));

  return formatted;
}

const styles = {
  getDateInputAdornmentProps: (handler) => ({
    position: "start",
    onClick: handler,
  }),
  containerBoxStyle: {
    display: "flex",
  },
  startDateInputStyle: {
    borderRadius,
    maxWidth,
    height,
  },
  endDateInputStyle: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderLeftColor: "transparent",
    },
    borderTopRightRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
    maxWidth,
    height,
  },
  doubleCalendarModeStyle: {
    borderTopLeftRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  startDatePopperProps: {
    popperOptions: {
      placement: "bottom-end",
      modifiers: [{ name: "start date popper" }],
    },
    style: {
      paddingRight: "10px",
    },
  },
  endDatePopperProps: {
    popperOptions: {
      placement: "bottom-start",
    },
    style: {
      boxShadow: "none",
    },
  },
};

function OPDatePicker(props) {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    inputFormat = CALENDAR_DATE_FORMAT,
  } = props;

  const [openCalendar, setOpenCalendar] = useState(false);
  const [openCalendarEndDate, setOpenCalendarEndDate] = useState(false);
  const [error, setError] = useState(false);
  const [errorCalendarTwo, setErrorCalendarTwo] = useState(false);

  const isRenderCalendarTwo = endDate && setEndDate;

  const handleClickAway = () => {
    if (openCalendar) {
      setOpenCalendar(false);
    }
    if (openCalendarEndDate) {
      setOpenCalendarEndDate(false);
    }
  };

  const handleAdornmentClick = (e) => {
    if (openCalendar || openCalendarEndDate) {
      setOpenCalendar(false);
      setOpenCalendarEndDate(false);

      return;
    }

    setOpenCalendar(true);
    setOpenCalendarEndDate(true);

    e.stopPropagation();
  };

  const handleStartDatePick = (tValue) => {
    setError(false);
    setOpenCalendarEndDate(false);

    try {
      const tValueDate = formatDate({
        date: format(tValue, inputFormat),
      });

      if (tValueDate > formatDate({ date: endDate })) {
        throw new Error("Invalid date selection");
      }
    } catch (e) {
      setError(true);
      setStartDate(format(new Date(), inputFormat));
    }

    setErrorCalendarTwo(false);
    setStartDate(format(tValue, inputFormat));
  };

  const handleEndDatePick = (tValue) => {
    setErrorCalendarTwo(false);
    setOpenCalendar(false);

    // evaluate tValue
    try {
      const tValueDate = formatDate({
        date: format(tValue, inputFormat),
      });

      if (tValueDate < formatDate({ date: startDate })) {
        throw new Error("Invalid date selection");
      }
    } catch (e) {
      setErrorCalendarTwo(true);
      return;
    }

    setError(false);
    setEndDate(format(tValue, inputFormat));
  };

  const startDateInputTextFieldProps = {
    onClick: () => {
      setOpenCalendar(!openCalendar);
      setOpenCalendarEndDate(false);
    },
    error,
    InputProps: {
      sx: {
        ...styles.startDateInputStyle,
        ...(isRenderCalendarTwo ? styles.doubleCalendarModeStyle : {}),
      },
    },
  };

  const endDateTextFieldProps = {
    onClick: () => {
      setOpenCalendarEndDate(!openCalendarEndDate);
      setOpenCalendar(false);
    },
    error: errorCalendarTwo,
    InputProps: {
      sx: styles.endDateInputStyle,
    },
  };

  const startDatePicker = (
    <DesktopDatePicker
      open={openCalendar}
      format={inputFormat}
      /* disableMaskedInput // TODO: verify if this is necessary Open issue that needs to be fixed so we can apply masks https://github.com/mui/mui-x/issues/4487 */
      value={safeCreateDate(startDate)}
      maxDate={safeCreateDate(endDate)}
      onChange={(val) => handleStartDatePick(val)}
      InputAdornmentProps={styles.getDateInputAdornmentProps(
        handleAdornmentClick,
      )}
      slotProps={{
        textField: startDateInputTextFieldProps, // this is supposed to be for the
        popper: styles.startDatePopperProps,
        inputAdornment: styles.getDateInputAdornmentProps(handleAdornmentClick),
      }}
    />
  );

  const endDatePicker = (
    <DesktopDatePicker
      open={openCalendarEndDate}
      format={inputFormat}
      disableMaskedInput
      value={safeCreateDate(endDate)}
      minDate={safeCreateDate(startDate)}
      onChange={(val) => handleEndDatePick(val)}
      /* disableOpenPicker */
      slotProps={{
        textField: endDateTextFieldProps,
        popper: styles.endDatePopperProps,
      }}
    />
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={styles.containerBoxStyle}>
            {startDatePicker}
            {isRenderCalendarTwo ? endDatePicker : null}
          </Box>
        </LocalizationProvider>
      </Box>
    </ClickAwayListener>
  );
}

export default OPDatePicker;

OPDatePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
  inputFormat: PropTypes.string,
};
