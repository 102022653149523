import React, { useRef } from "react";
import PropTypes from "prop-types";
import RectangleTwoToneIcon from "@mui/icons-material/RectangleTwoTone";
import { Grid } from "@mui/material";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";

import { POSITIVE_COLOURS, NEGATIVE_COLOURS } from "assets/colours";

import LegendItem from "library/graphing/LegendItem";
import OutPointCard from "library/surface/OutPointCard";
import { summaryBarGraphStyles } from "pages/recommendations/styles/recommendationsComponentsStyles";
import { performanceInsightsStyles } from "pages/overview/components/styles/overviewComponentsStyles";
import { useSelector } from "react-redux";
import { calculateCac } from "utils/data/metrics";
import { capitalize, kDigitConversion } from "utils/data/strings";
import {
  registerChartJsComponents,
  createDatasetViaNumArray,
  createDataObjViaNumArrays,
  createOptionsObj,
  createScaleOptions,
  getDefaultTooltipOptions,
} from "library/graphing/ChartJsHelpers";

import { Bar } from "react-chartjs-2";
import StrategyChannelToggle from "pages/overview/components/StrategyChannelToggle";
import DownloadButtons from "library/graphing/DownloadButtons";
import SubHeadLight from "library/text/headers/SubHeadLight";
import {
  PRIMARY_LIGHT,
  PRIMARY_COLOR,
  PRIMARY_DARK,
} from "../../../assets/palette";

registerChartJsComponents([
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
]);

const styles = {
  ...performanceInsightsStyles,
  ...summaryBarGraphStyles,
};

function RecommendationsBarGraph(props) {
  const {
    scenarioData,
    baselineData,
    groupByStrategy,
    setGroupByStrategy,
    timeRangeLabel = "daily",
    timeRangeDays = 1,
  } = props;

  const chartRef = useRef(null);
  const ltv = useSelector((state) => state.overviewData.data?.ltv);

  const scenarioChannelData = scenarioData?.category_data;
  const baselineChannelData = baselineData?.category_data;

  const isReadyToDisplay =
    scenarioChannelData && Object.keys(scenarioChannelData)?.length;
  if (!isReadyToDisplay) {
    return null;
  }

  const platforms = Object.keys(baselineChannelData);
  const calculatePlatformDetails = (platform) => {
    const currentDailySpend =
      baselineChannelData[platform].spend * timeRangeDays;
    const optimalDailySpend =
      scenarioChannelData[platform].spend * timeRangeDays;
    const spendChange = optimalDailySpend - currentDailySpend;

    const originalReturn =
      baselineChannelData[platform].total_revenue * timeRangeDays;
    const predictedReturn =
      scenarioChannelData[platform].total_revenue * timeRangeDays;
    const returnChange = predictedReturn - originalReturn;

    const originalRoas = baselineChannelData[platform].average_roas;
    const predictedRoas = scenarioChannelData[platform].average_roas;
    const roasChange = predictedRoas - originalRoas;

    const originalCac = calculateCac(ltv, currentDailySpend, originalReturn);
    const predictedCac = calculateCac(ltv, optimalDailySpend, predictedReturn);
    const cacChange = predictedCac - originalCac;

    return {
      currentDailySpend,
      optimalDailySpend,
      spendChange,
      originalReturn,
      predictedReturn,
      returnChange,
      originalRoas,
      predictedRoas,
      roasChange,
      originalCac,
      predictedCac,
      cacChange,
    };
  };

  const labels = [];
  const neutralDataPoints = [];
  const decreaseDataPoints = [];
  const increaseDataPoints = [];
  /// calculates data points and adds to its respective arrays:
  platforms.forEach((platform) => {
    labels.push(capitalize(platform));

    const { currentDailySpend, optimalDailySpend, spendChange } =
      calculatePlatformDetails(platform);

    let neutralVal = 0;
    let increaseVal = 0;
    let decreaseVal = 0;

    if (spendChange === 0) {
      neutralVal = optimalDailySpend;
    } else if (spendChange < 0) {
      neutralVal = optimalDailySpend;
      decreaseVal = Math.abs(spendChange);
    } else {
      neutralVal = currentDailySpend;
      increaseVal = spendChange;
    }

    neutralDataPoints.push(neutralVal);
    decreaseDataPoints.push(decreaseVal);
    increaseDataPoints.push(increaseVal);
  });

  const commonDataSetProps = {
    axis: "y",
    fill: false,
    maxBarThickness: 75,
  };

  const dataSets = [
    createDatasetViaNumArray(neutralDataPoints, {
      label: "constant",
      backgroundColor: PRIMARY_LIGHT || PRIMARY_COLOR,
      borderColor: PRIMARY_DARK,
      ...commonDataSetProps,
    }),
    createDatasetViaNumArray(increaseDataPoints, {
      label: "increase",
      backgroundColor: POSITIVE_COLOURS?.light || "green",
      borderColor: POSITIVE_COLOURS?.dark || "green",
      ...commonDataSetProps,
    }),
    createDatasetViaNumArray(decreaseDataPoints, {
      label: "decrease",
      backgroundColor: NEGATIVE_COLOURS?.light || "red",
      borderColor: NEGATIVE_COLOURS?.dark || "red",
      ...commonDataSetProps,
    }),
  ];

  const data = createDataObjViaNumArrays(labels, dataSets);

  const formatSpendTicks = (x) => `${kDigitConversion(x)}`;

  const xAxisScaleOptions = createScaleOptions(
    "x",
    {
      // x-axis' title props:
      display: true,
      align: "center",
      text: "Spend ($)",
    },
    {
      // x-axis' tick props
      callback: formatSpendTicks,
      autoSkip: false,
    },
    {
      // other x axis props:
      stacked: true,
    },
  );

  const yAxisScaleOptions = createScaleOptions(
    "y",
    {
      // y-axis' title props:
      display: false,
      align: "center",
      text: "Channels",
    },
    {
      /* y-axis' tick props */ autoSkip: false,
    },
    {
      // other y axis props:
      stacked: true,
      grid: {
        display: false,
      },
    },
  );

  const scaleOptions = {
    x: xAxisScaleOptions,
    y: yAxisScaleOptions,
  };

  const isStackedBarGroupOnlyContainsNeutralBar = (context) => {
    const datasetLabel = context.dataset.label;
    // eslint-disable-next-line no-underscore-dangle
    const stacks = context.parsed._stacks.x;
    const {
      0: neutralVal = 0,
      1: increaseVal = 0,
      2: decreaseVal = 0,
    } = stacks;

    const isSolelyNeutralBarInStack =
      neutralVal !== 0 && increaseVal === 0 && decreaseVal === 0;

    return datasetLabel === "constant" && isSolelyNeutralBarInStack;
  };

  const isStackEmpty = (context) => {
    // eslint-disable-next-line no-underscore-dangle
    const stacks = context.parsed._stacks.x;
    const {
      0: neutralVal = 0,
      1: increaseVal = 0,
      2: decreaseVal = 0,
    } = stacks;
    const isEmpty = [neutralVal, increaseVal, decreaseVal].every(
      (x) => x === 0,
    );

    return isEmpty;
  };

  const options = createOptionsObj(
    scaleOptions,
    {
      // plugin options:
      tooltip: {
        ...getDefaultTooltipOptions(),
        callbacks: {
          // changes rendering:
          label: (context) => {
            if (isStackEmpty(context)) {
              return "";
            }

            if (
              isStackedBarGroupOnlyContainsNeutralBar(context) ||
              isStackEmpty(context)
            ) {
              return `Keep constant at $${context.formattedValue}`;
            }

            const datasetLabel = context.dataset.label;
            let changePrefix = "";

            if (datasetLabel === "increase") {
              changePrefix = "Increase spend by";
            }
            if (datasetLabel === "decrease") {
              changePrefix = "Decrease spend by";
            }

            return `${changePrefix} $${context.formattedValue}`;
          },
        },
      },
    },
    {
      // other options:
      indexAxis: "y", // makes the graph horizontal:
      elements: {
        bar: {
          borderColor: "black",
          borderWidth: 1,
        },
      },
      layout: {
        padding: 8,
      },
    },
  );

  const positiveLegend = (
    <LegendItem title="Increase" key="1">
      <RectangleTwoToneIcon
        sx={{
          color: POSITIVE_COLOURS?.dark || "green",
          backgroundColor: POSITIVE_COLOURS?.light || "green",
          borderRadius: "3px",
        }}
      />
    </LegendItem>
  );

  const constantLegend = (
    <LegendItem title="Constant" key="2">
      <RectangleTwoToneIcon
        sx={{
          color: "primary.dark",
          backgroundColor: "primary.light",
          borderRadius: "3px",
        }}
      />
    </LegendItem>
  );

  const negativeLegend = (
    <LegendItem title="Decrease" key="3">
      <RectangleTwoToneIcon
        sx={{
          color: NEGATIVE_COLOURS?.dark || "red",
          backgroundColor: NEGATIVE_COLOURS?.light || "red",
          borderRadius: "3px",
        }}
      />
    </LegendItem>
  );

  const legend = (
    <Grid
      container
      sx={styles.legendContainer}
      direction="row"
      alignItems="flex-start"
      justifyContent="center"
    >
      {[positiveLegend, constantLegend, negativeLegend].map((x) => x)}
    </Grid>
  );

  const bars = (
    <Bar
      style={{
        maxHeight: null,
        maxWidth: null,
        padding: "0px 24px 24px 24px",
      }}
      options={options}
      data={data}
    />
  );

  const showStrategy = !!setGroupByStrategy;
  const strategyToggle = showStrategy ? (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="flex-end"
      sx={styles.strategyToggleContainer}
    >
      <StrategyChannelToggle
        groupByStrategy={groupByStrategy}
        setGroupByStrategy={setGroupByStrategy}
      />
    </Grid>
  ) : (
    <div />
  );

  return (
    <Grid
      container
      sx={styles.barGraphContainer}
      style={{ position: "relative" }}
      direction="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <DownloadButtons
        style={{ position: "absolute", right: "20px", top: "20px" }}
        filename={`Recommended ${timeRangeLabel} spend changes across all ${
          groupByStrategy ? "strategies" : "channels"
        }`}
        componentRef={chartRef}
      />
      <OutPointCard style={summaryBarGraphStyles.containerCard}>
        <div ref={chartRef}>
          <Grid container item alignItems="center" sx={styles.subheaderText}>
            <Grid item xs={8}>
              <SubHeadLight isSentenceCase={false}>
                {`Recommended ${timeRangeLabel} spend changes across all ${
                  groupByStrategy ? "strategies" : "channels"
                }`}
              </SubHeadLight>
            </Grid>
            <Grid item xs={4}>
              {strategyToggle}
            </Grid>
          </Grid>
          {bars}
          {legend}
        </div>
      </OutPointCard>
    </Grid>
  );
}

export default React.memo(RecommendationsBarGraph);

RecommendationsBarGraph.propTypes = {
  scenarioData: PropTypes.object,
  baselineData: PropTypes.object,
  groupByStrategy: PropTypes.bool,
  setGroupByStrategy: PropTypes.func,
  timeRangeDays: PropTypes.number,
  timeRangeLabel: PropTypes.string,
};
