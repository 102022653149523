import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { usePapaParse } from "react-papaparse";

import OutPointTooltip from "library/display/OutPointTooltip";
import Title from "library/text/titles/Title";
import Header1 from "library/text/headers/Header1";
import BodyText from "library/text/body/BodyText";
import Hyperlink from "library/text/body/Hyperlink";
import {
  createCoordinateHeaderTransformer,
  createOnParseHandler,
  transformCsvCell,
  parseCsv,
  canVisualizeCoordinates,
} from "utils/graphing/data";
import { isValidCustomChannel } from "utils/data/strings";
import { aggregateCoordinatesAlongXAxis } from "utils/graphing/series";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import UploadStepper from "pages/manual_upload/components/UploadStepper";
import CompletionBanner from "pages/manual_upload/components/CompletionBanner";
import UploadPreview from "pages/manual_upload/components/UploadPreview";
import {
  StepperButton,
  UploadConfirmationButton,
} from "pages/manual_upload/components/UploadButtons";
import UploadMessageBoard from "pages/manual_upload/components/UploadMessageBoard";
import FileUpload from "pages/manual_upload/components/FileUpload";
import OPAutoCompleteDynamic from "library/form/AutoCompleteDynamic";
import { UPLOAD_VALIDITY_STATUS } from "utils/enums";
import { getOverview } from "redux/overviewSlice";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  FEEDBACK_OR_ERROR_900,
  FEEDBACK_SUCCESS,
  PRIMARY_DARK,
} from "assets/palette";

const NETWORK_ERROR_FILE_ID = -1;
const DEFAULT_FILE_ID = 0;
const VALIDATION_ENDPOINT = "validate";
const UPLOAD_ENDPOINT = "upload";
const X_AXIS_VARIABLE = "date";
const MAX_ALLOWED_FILE_SIZE = 900000; // bytes NOTE: elb default nginx http server will have max_body_size set to 1MB, we set this filesize to 0.9Mb just to be safe

const createUploadMsg = ({
  msgText,
  msgType = "info",
  row = null,
  column = null,
  reason = null,
}) => {
  // NOTE: follows the same snake_case as backend keys for consistency
  return {
    msg_type: msgType,
    msg_text: msgText,
    row,
    column,
    reason,
  };
};

const styles = {
  smallHeader: {
    fontSize: 14,
    marginBottom: "24px",
  },
  title: {
    fontSize: 28,
  },
  pageDiv: {
    margin: " 0 auto",
  },
  toolTip: {
    color: "blue",
    marginTop: "10px",
    fontSize: "1rem",
  },
  correctIcon: {
    color: FEEDBACK_SUCCESS,
    paddingRight: "5px",
    marginBottom: "-5px",
  },
  incorrectIcon: {
    color: FEEDBACK_OR_ERROR_900,
    paddingRight: "5px",
    marginBottom: "-5px",
  },
};

/**
 * This template component intends to support multiple file uploadTypes.
 *
 * The config object adds a layer of indirection so that we pass in custom
 * endpoints, reducer actions and selectors to this generic template.
 * It MUST have the following:
 *   1. uploadActions
 *      contains redux actions that are dispatched upon the following events
 *      - selectChannel
 *      - addCoordinates
 *      - updateDroppedFileState
 *      - resetState
 *   2. selectors
 *      state selector functions
 *      - channelSelector
 * */
export default function UploadPageTemplate(props) {
  const { titleText, headerText, uploadType, config } = props;
  const dispatch = useDispatch();
  const { readString } = usePapaParse();

  const [activeStep, setActiveStep] = useState(0);
  const selectedChannel = useSelector(config.selectors.channelSelector);
  const channelOptions = useSelector(
    (state) => state.overviewData?.data?.valid_channels || [],
  );
  const isCustomChannel = useSelector(config.selectors.isCustomChannelSelector);
  const [coordinates, setCoordinates] = useState([]);
  const [hasParsedSuccessfully, setHasParsedSuccessfully] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [hasUploadedSuccessfully, setHasUploadedSuccessfully] = useState(false);
  const [backendMsgLog, setBackendMsgLog] = useState([]); // display messages from backend
  const [droppedFile, setDroppedFile] = useState({
    fileId: DEFAULT_FILE_ID,
    fileObj: undefined,
    validationStatus: UPLOAD_VALIDITY_STATUS.PENDING,
  });

  // Stepper handlers:
  const handleStepNext = () => {
    setActiveStep((prevActiveStep) =>
      // Disabling eslint as this function is used in a circular import, but tested to work
      // eslint-disable-next-line no-use-before-define
      Math.min(prevActiveStep + 1, steps.length - 1),
    );
  };

  const handleStepBack = () => {
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const resetState = () => {
    setActiveStep(0);
    setCoordinates([]);
    setHasParsedSuccessfully(false);
    setCompleted(false);
    setIsUploading(false);
    setBackendMsgLog([]);
    setDroppedFile({
      fileId: "",
      fileObj: undefined,
      validationStatus: UPLOAD_VALIDITY_STATUS.PENDING,
    });
    dispatch(config.uploadActions.resetState());
  };

  const handleChannelSelection = (selectionChannel) => {
    const formattedChannel = `${selectionChannel}`.trim().toLowerCase();
    const options = [];
    channelOptions.forEach((optionChannel) => {
      options.push(optionChannel.toLowerCase());
    });
    const selectionIsCustomChannel = !options.includes(formattedChannel);

    dispatch(
      config.uploadActions.updateIsCustomChannel(selectionIsCustomChannel),
    );
    dispatch(config.uploadActions.selectChannel(selectionChannel));
    handleStepNext();
  };

  /**
   * Returns a tuple containing the validity status enum and a descriptive log from the backend (to be used for error descriptions)
   * */
  const verifyWithBackend = async (file, fileId, channel) => {
    const formData = new FormData();

    formData.append("uploadedData", file);
    formData.append("uploadType", uploadType);
    formData.append("fileId", fileId);
    formData.append("channel", channel);
    formData.append("isCustomChannel", isCustomChannel);

    let status;
    let backendLog;
    let echoedFileId;

    try {
      const response = await makeAuthenticatedPostRequest(
        VALIDATION_ENDPOINT,
        formData,
      );

      const {
        file_id: currFileId,
        validation_status: validationStatus,
        response_msgs: responseMsgs,
      } = response.data;

      echoedFileId = currFileId;
      status = validationStatus;
      backendLog = responseMsgs;
    } catch (e) {
      const msgText = "There was a network error when verifying your CSV.";
      // eslint-disable-next-line no-console
      console.error(msgText, e);
      const msg = createUploadMsg({
        msgText,
        msgType: "error",
        reason: "Please try again, or contact us if the problem persists.",
      });

      echoedFileId = NETWORK_ERROR_FILE_ID;
      status = UPLOAD_VALIDITY_STATUS.NETWORK_ERROR;
      backendLog = [msg];
    }

    setBackendMsgLog(backendLog);

    return [status, echoedFileId];
  };

  const uploadParserConfig = {
    header: true,
    skipEmptyLines: true,
    transformHeader: createCoordinateHeaderTransformer(
      X_AXIS_VARIABLE,
      uploadType,
    ),
    transform: transformCsvCell,
    complete: createOnParseHandler((currCoordinates) => {
      const canVisualize = canVisualizeCoordinates(currCoordinates);
      const newCoords = canVisualize
        ? aggregateCoordinatesAlongXAxis(currCoordinates)
        : [];

      setHasParsedSuccessfully(canVisualize);
      setCoordinates(newCoords);
      dispatch(config.uploadActions.addCoordinates(newCoords));

      if (!canVisualize) {
        const msgText = "Your data couldn't be visualized.";
        const reason = `Please ensure that your CSV file contains the columns "${uploadType}" and "date".`;
        const msg = createUploadMsg({
          msgText,
          msgType: "error",
          reason,
        });
        // eslint-disable-next-line no-console
        console.error(msgText);
        setBackendMsgLog([msg]);
      }
    }),
  };

  /**
   * Triggers backend verification if the supplied csv file can be parsed into coordinates.
   * */
  useEffect(() => {
    if (!hasParsedSuccessfully) {
      return;
    }

    const checkWithBackend = async () => {
      let [status, echoedFileId] = await verifyWithBackend(
        droppedFile.fileObj,
        droppedFile.fileId,
        selectedChannel,
      );

      if (droppedFile.fileId !== echoedFileId) {
        status = UPLOAD_VALIDITY_STATUS.NETWORK_ERROR;
        echoedFileId = droppedFile.fileId;

        const msgText = "There has been a mismatch with the files!";
        const msg = createUploadMsg({
          msgText,
          msgType: "error",
          reason:
            "Please refresh and try again, or contact us if the problem persists.",
        });
        setBackendMsgLog([msg]);
        // eslint-disable-next-line no-console
        console.error(msgText);
      }

      setCompleted(false);
      setDroppedFile({
        ...droppedFile,
        fileId: echoedFileId,
        validationStatus: status,
      });
      dispatch(
        config.uploadActions.updateDroppedFileState({
          fileId: droppedFile.fileId,
          validationStatus: status,
        }),
      );
    };

    checkWithBackend();
  }, [hasParsedSuccessfully, selectedChannel]);

  /**
   * Upon a file drop, parses the contents of the csv into coordinates.
   * Via useEffect, successful parsing triggers the backend validation as well.
   * */
  const handleFileDrop = async (event) => {
    const [file] = event.target.files; // input prop set to single file

    if (!file) {
      // NB: null-check especially important for chromium browsers
      return;
    }

    if (file.size > MAX_ALLOWED_FILE_SIZE) {
      const msgText = "Unfortunately, the file you uploaded is too large.";
      const reason = "Please re-upload a file that is at most 900kb.";
      // eslint-disable-next-line no-console
      console.info(msgText);
      setDroppedFile({
        fileObj: null,
        fileId: null,
        validationStatus: UPLOAD_VALIDITY_STATUS.INVALID,
      });
      const msg = createUploadMsg({
        msgText,
        msgType: "error",
        reason,
      });

      setBackendMsgLog([msg]);
      // eslint-disable-next-line no-console
      console.error(msgText);
      return;
    }

    setHasParsedSuccessfully(false);

    await parseCsv(file, (csvString) =>
      readString(csvString, uploadParserConfig),
    );

    setDroppedFile({
      fileObj: file,
      fileId: uuid(),
      validationStatus: UPLOAD_VALIDITY_STATUS.PARSED,
    });
  };

  const handleUploadConfirmation = async () => {
    if (droppedFile.validationStatus !== UPLOAD_VALIDITY_STATUS.VALID) {
      const msgText =
        "The file you're trying to upload has not been validated.";
      const msg = createUploadMsg({
        msgText,
        msgType: "error",
        reason:
          "Please refresh and try again, or contact us if the problem persists.",
      });
      setBackendMsgLog([msg]);
      return;
    }

    setIsUploading(true);

    const formData = new FormData();

    formData.append("uploadType", uploadType);
    formData.append("uploadedData", droppedFile.fileObj);
    formData.append("channel", selectedChannel);
    formData.append("isCustomChannel", isCustomChannel);

    let responseMsgs = null;
    try {
      const response = await makeAuthenticatedPostRequest(
        UPLOAD_ENDPOINT,
        formData,
      );
      responseMsgs = response.data.response_msgs;
      setBackendMsgLog(responseMsgs);
      setHasUploadedSuccessfully(true);
    } catch (e) {
      const msg = createUploadMsg({
        msgText: "Your upload was not successful.",
        msgType: "error",
        reason:
          "Please refresh and try again or contact us if the problem persists.",
      });

      setBackendMsgLog([msg]);
      setHasUploadedSuccessfully(false);
    } finally {
      setCompleted(true);
      setIsUploading(false);
      dispatch(getOverview());
    }
  };

  const uploadRulesDescription = (
    <>
      <Header1 sx={{ paddingTop: "10px" }} isSentenceCase={false}>
        CSV rules:
      </Header1>
      <BodyText sx={{ paddingTop: "10px" }}>
        The csv is valid if the columns are labelled as: <br />
        <b>'channel'</b>, <b>'date'</b>, <b>'campaign'</b>,{" "}
        <b>'{uploadType}'</b>.<br />
        <br />
        Please ensure that: <br />
        <ul>
          <li>
            The dates are in{" "}
            <Hyperlink
              href="https://www.w3.org/QA/Tips/iso-date"
              target="_blank"
            >
              ISO date format
            </Hyperlink>{" "}
            <br />
            i.e. <code>YYYY-MM-DD</code> with <code>'-'</code> as separators.
            examples: <br />
            <ul>
              <li>
                <CheckCircleOutlineIcon sx={styles.correctIcon} />
                <code>2022-12-02</code>
              </li>
              <li>
                <HighlightOffIcon sx={styles.incorrectIcon} />
                <code>2022/12/02</code>
              </li>
              <li>
                <HighlightOffIcon sx={styles.incorrectIcon} />
                <code>02/12/2022 00:00</code>
              </li>
              <li>
                <HighlightOffIcon sx={styles.incorrectIcon} />
                <code>2 December 2022</code>
              </li>
            </ul>
          </li>
          <li>
            The channel that you have selected in Step 1 matches the channel
            indicated in the csv
          </li>
          <li>There are no empty cells in any row</li>
        </ul>
      </BodyText>
    </>
  );

  // represents the steps that the user follows for making an upload
  const steps = [
    {
      label: selectedChannel
        ? `Selected Channel: ${selectedChannel}`
        : "Select desired channel",
      description: "",
      component: channelOptions && (
        <OPAutoCompleteDynamic
          name="UploadAutoComplete"
          handleSelection={handleChannelSelection}
          options={channelOptions}
          textFieldLabel="Channel"
          dialogTitle="Add a new channel"
          dialogContentText="Do you want to add a custom channel?"
          isValidInput={isValidCustomChannel}
        />
      ),
    },
    {
      label: `Upload ${uploadType} file`,
      description: "Click the button to browse for your file",
      component: (
        <>
          <FileUpload
            droppedFile={droppedFile}
            handleFileDrop={handleFileDrop}
            handleStepNext={handleStepNext}
            handleStepBack={handleStepBack}
            completed={completed}
          />
          {(droppedFile.validationStatus === UPLOAD_VALIDITY_STATUS.INVALID ||
            droppedFile.validationStatus ===
              UPLOAD_VALIDITY_STATUS.NETWORK_ERROR) && (
            <UploadMessageBoard
              isUploading={isUploading}
              droppedFile={droppedFile}
              backendMsgLog={backendMsgLog}
            />
          )}
        </>
      ),
      tooltip: (
        <OutPointTooltip
          info={uploadRulesDescription}
          placement="right"
          sxToolTip={styles.toolTip}
        />
      ),
    },
    {
      label: "Preview File & Confirm",
      description: "Preview your file just to make sure",
      component: (
        <>
          <UploadPreview coordinates={coordinates} />
          {!completed &&
            droppedFile.validationStatus === UPLOAD_VALIDITY_STATUS.VALID && (
              <UploadConfirmationButton
                handleUploadConfirmation={handleUploadConfirmation}
                text="Confirm Upload"
              />
            )}
          {!completed && (
            <StepperButton
              handleStep={handleStepBack}
              text="Back"
              color={PRIMARY_DARK}
            />
          )}
          <UploadMessageBoard
            isUploading={isUploading}
            droppedFile={droppedFile}
            backendMsgLog={backendMsgLog}
          />
        </>
      ),
    },
  ];

  // TODO: [Lower priority] prettify the completion banner, similar to the errorMsgsBanner
  return (
    <div>
      <div style={styles.pageDiv}>
        <Title text={titleText} sx={styles.title} data-cy="spend-title" />
        <Header1 sx={styles.smallHeader} color="secondary">
          {headerText}
        </Header1>
        <UploadStepper activeStep={activeStep} steps={steps} />

        {activeStep === steps.length - 1 &&
          completed &&
          droppedFile.validationStatus === UPLOAD_VALIDITY_STATUS.VALID && (
            <CompletionBanner
              handleStepReset={resetState}
              text={
                hasUploadedSuccessfully
                  ? "Your upload was successful! - thank you"
                  : ""
              }
              resetText={
                hasUploadedSuccessfully
                  ? "Upload for another channel"
                  : "Retry or Upload for another channel"
              }
            />
          )}
      </div>
    </div>
  );
}
UploadPageTemplate.propTypes = {
  titleText: PropTypes.string,
  headerText: PropTypes.string,
  uploadType: PropTypes.string,
  config: PropTypes.object,
};
