import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ChangeChip from "library/display/ChangeChip";
import OutPointCard from "library/surface/OutPointCard";
import { summaryTableStyles as styles } from "pages/recommendations/styles/recommendationsComponentsStyles";
import React from "react";
import { useSelector } from "react-redux";
import { computePercentageChange } from "utils/data/math";
import { calculateCac, formatMetric } from "utils/data/metrics";
import { capitalize } from "utils/data/strings";
import PropTypes from "prop-types";

/**
 * Displays recommendations for all channels / platforms in a single table.
 * */
function SummaryTable(props) {
  const {
    scenarioData,
    baselineData,
    reverseTotalsRow = false,
    timeRange = "daily",
    timeRangeDays = 1,
  } = props;

  const ltv = useSelector((state) => state.overviewData.data?.ltv);
  const scenarioChannelData = scenarioData.category_data;
  const baselineChannelData = baselineData.category_data;

  if (!Object.keys(scenarioChannelData)?.length) return null;

  const platforms = Object.keys(baselineChannelData);

  const createTextTableCell = (content, isBold = false) => {
    return (
      <TableCell key={content}>
        <Grid container>
          <Typography
            sx={isBold ? styles.boldedTableCellText : styles.tableCellText}
          >
            {content}
          </Typography>
        </Grid>
      </TableCell>
    );
  };

  const createTableCell = (
    content,
    changeVal = null,
    prefix = "",
    isBold = false,
    invertColour = false,
    variableDecimals = true,
  ) => {
    return (
      <TableCell>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          sx={styles.tableCellContainer}
        >
          <Grid item sx={styles.tableCellTextContainer}>
            <Typography
              sx={isBold ? styles.boldedTableCellText : styles.tableCellText}
            >
              {`${prefix}${formatMetric(
                content,
                false,
                true,
                variableDecimals,
              )}`}
            </Typography>
          </Grid>
          {(changeVal || changeVal === 0) && (
            <ChangeChip
              change={changeVal}
              isPercentage
              useIcon
              inverted={invertColour}
              variableDecimals
            />
          )}
        </Grid>
      </TableCell>
    );
  };

  const createTotalsRow = () => {
    const currentSpend = baselineData.spend * timeRangeDays;
    const optimalSpend = scenarioData.spend * timeRangeDays;
    const spendChange = computePercentageChange(optimalSpend, currentSpend);

    const originalReturn = baselineData.total_revenue * timeRangeDays;
    const predictedReturn = scenarioData.total_revenue * timeRangeDays;
    const returnChange = computePercentageChange(
      predictedReturn,
      originalReturn,
    );

    const originalRoas = baselineData.roas;
    const predictedRoas = scenarioData.roas;
    const roasChange = computePercentageChange(predictedRoas, originalRoas);

    const originalCac = calculateCac(ltv, currentSpend, originalReturn);
    const predictedCac = calculateCac(ltv, optimalSpend, predictedReturn);
    const cacChange = computePercentageChange(predictedCac, originalCac);

    return (
      <TableRow sx={styles.totalRowStyles}>
        {createTextTableCell("Total", true)}
        {createTableCell(currentSpend, null, "$", true, false, true)}
        {createTableCell(optimalSpend, spendChange, "$", true, false, true)}
        {createTableCell(predictedReturn, returnChange, "$", true, false, true)}
        {createTableCell(predictedRoas, roasChange, "", true, false, false)}
        {createTableCell(predictedCac, cacChange, "$", true, true, true)}
      </TableRow>
    );
  };

  const createRowForPlatform = (platform) => {
    const currentSpend = baselineChannelData[platform].spend * timeRangeDays;
    const optimalSpend = scenarioChannelData[platform].spend * timeRangeDays;
    const spendChange = computePercentageChange(optimalSpend, currentSpend);

    const originalReturn =
      baselineChannelData[platform].total_revenue * timeRangeDays;
    const predictedReturn =
      scenarioChannelData[platform].total_revenue * timeRangeDays;
    const returnChange = computePercentageChange(
      predictedReturn,
      originalReturn,
    );

    const originalRoas = baselineChannelData[platform].average_roas;
    const predictedRoas = scenarioChannelData[platform].average_roas;
    const roasChange = computePercentageChange(predictedRoas, originalRoas);

    const originalCac = calculateCac(ltv, currentSpend, originalReturn);
    const predictedCac = calculateCac(ltv, optimalSpend, predictedReturn);
    const cacChange = computePercentageChange(predictedCac, originalCac);

    return (
      <TableRow key={platform}>
        {createTextTableCell(capitalize(platform))}
        {createTableCell(currentSpend, null, "$", false, false, true)}
        {createTableCell(optimalSpend, spendChange, "$", false, false, true)}
        {createTableCell(
          predictedReturn,
          returnChange,
          "$",
          false,
          false,
          true,
        )}
        {createTableCell(predictedRoas, roasChange, "", false, false, false)}
        {createTableCell(predictedCac, cacChange, "$", false, true, true)}
      </TableRow>
    );
  };

  const createTableHeaderRow = () => {
    const headers = [
      "Channel",
      `Current ${timeRange} spend`,
      `Recommended ${timeRange} spend`,
      "Predicted return",
      "Predicted ROAS",
      "Predicted CAC",
    ];

    return (
      <TableRow>{headers.map((h) => createTextTableCell(h, true))}</TableRow>
    );
  };

  return (
    <Grid container>
      <OutPointCard
        style={{
          marginTop: "24px",
        }}
      >
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead>{createTableHeaderRow()}</TableHead>
            <TableBody>
              {reverseTotalsRow && createTotalsRow()}
              {platforms.map(createRowForPlatform)}
              {!reverseTotalsRow && createTotalsRow()}
            </TableBody>
          </Table>
        </TableContainer>
      </OutPointCard>
    </Grid>
  );
}

export default SummaryTable;

SummaryTable.propTypes = {
  scenarioData: PropTypes.object,
  baselineData: PropTypes.object,
  reverseTotalsRow: PropTypes.bool,
  timeRange: PropTypes.string,
  timeRangeDays: PropTypes.number,
};
