import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Badge from "library/display/OutPointBadge";
import HorizontalTabsWithPanels from "library/display/HorizontalTabsWithPanels";
import OutPointSelector from "library/form/OutPointSelector";
import { createCurves } from "utils/graphing/data";
import { addCommasToNumber, kDigitConversion } from "utils/data/strings";
import { capitalize } from "lodash";
import { getChannelColour } from "assets/colours";
import LegendBox from "library/graphing/LegendBox";
import LegendItem from "library/graphing/LegendItem";
import { dateSecondsToString } from "utils/data/dates";
import { GRAPH_LONG_MONTH_FORMAT } from "utils/enums";
import { convertResponseToScenario } from "utils/dataset/predictions";
import OutPointToggle from "library/buttons/OutPointToggle";
import LabelText from "library/text/body/LabelText";
import SubHeadLight from "library/text/headers/SubHeadLight";
import { Box } from "@mui/system";
import {
  createOptionsObj,
  createScaleOptions,
  getDefaultTooltipOptions,
  registerChartJsComponents,
} from "library/graphing/ChartJsHelpers";
import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import MonthlyDetailsTable from "./MonthlyDetailsTable";

const MONTH_YEAR_SUFFIX = " Budget";
const FORMATTED_DATE_KEY = "formatted_date";

const styles = {
  container: {
    paddingTop: "24px",
  },
  selector: {
    minWidth: "max-content",
    display: "flex",
  },
  legend: {
    marginTop: "36px",
  },
  chartControls: { paddingRight: "12px", paddingBottom: "12px" },
};

registerChartJsComponents([
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
]);

function MediaPlanResults({ predictions, channels }) {
  const months = Object.keys(predictions)
    .map((key) => key.replaceAll(MONTH_YEAR_SUFFIX, ""))
    .sort((a, b) => new Date(a) - new Date(b));
  const [activeMonth, setActiveMonth] = useState(months[0]);
  const [activeMonthScenario, setActiveMonthScenario] = useState(
    convertResponseToScenario(
      predictions[`${activeMonth}${MONTH_YEAR_SUFFIX}`],
    ),
  );
  const [chartCurves, setChartCurves] = useState([]);
  const [showSpendCurves, setShowSpendCurves] = useState(true);
  const [selectedChannels, setSelectedChannels] = useState(channels);

  useEffect(() => {
    const revenueCurves = selectedChannels.map((channel) => {
      const transposedPredictions = Object.keys(predictions)
        .map((monthYear) => {
          return {
            ...predictions[monthYear][channel],
            label: Date.parse(
              predictions[monthYear][channel][FORMATTED_DATE_KEY],
            ),
          };
        })
        .sort((a, b) => a.label - b.label);
      const linePoints = createCurves(transposedPredictions, {
        predicted_revenue: "y",
        label: "x",
      });
      return {
        pointRadius: 0,
        data: linePoints,
        linePoints,
        legendType: "return",
        borderColor: getChannelColour(channel),
        backgroundColor: getChannelColour(channel),
        label: `${capitalize(channel)}`,
        yAxisID: "yReturn",
      };
    });

    const spendCurves = selectedChannels.map((channel) => {
      const transposedPredictions = Object.keys(predictions)
        .map((monthYear) => ({
          ...predictions[monthYear][channel],
          label: Date.parse(
            predictions[monthYear][channel][FORMATTED_DATE_KEY],
          ),
        }))
        .sort((a, b) => a.label - b.label);
      const linePoints = createCurves(transposedPredictions, {
        spend: "y",
        label: "x",
      });
      return {
        pointRadius: 0,
        data: linePoints,
        borderDash: [5, 5],
        linePoints,
        yAxisID: "ySpend",
        legendType: "spend",
        applyCustomStyling: true,
        borderColor: getChannelColour(channel),
        backgroundColor: getChannelColour(channel),
        label: `${capitalize(channel)}`,
      };
    });

    const activeCurves = showSpendCurves
      ? revenueCurves.concat(spendCurves)
      : revenueCurves;
    setChartCurves(activeCurves);
  }, [showSpendCurves, selectedChannels]);

  const xAxisScaleOptions = createScaleOptions(
    "x",
    {},
    {
      // x-axis' tick props:
      callback: function xTicksNamedCallback(value) {
        return dateSecondsToString(
          // eslint-disable-next-line react/no-this-in-sfc -- can't find another way to get the default function within chartjs
          this.getLabelForValue(value),
          GRAPH_LONG_MONTH_FORMAT,
        );
      },
      autoSkip: true,
      maxTicksLimit: 8,
    },
    {
      // other x axis props:
      grid: {
        display: false,
      },
    },
  );

  const ySpendAxisScaleOptions = createScaleOptions(
    "ySpend",
    {
      // y-axis' title props:
      display: true,
      align: "center",
      text: showSpendCurves ? "Budget ($)" : "",
    },
    {
      /* y-axis' tick props */
      autoSkip: false,
      callback: function namedCallback(value) {
        return showSpendCurves ? kDigitConversion(value) : "";
      },
    },
    {
      // other y axis props:
      type: "linear",
      position: "right",
      axis: "y",
      grid: {
        drawOnChartArea: false,
      },
    },
    // isCustomAxis?
    true,
  );

  const yReturnAxisScaleOptions = createScaleOptions(
    "yReturn",
    {
      // y-axis' title props:
      display: true,
      align: "center",
      text: "Return ($)",
    },
    {
      /* y-axis' tick props */
      autoSkip: false,
      callback: function namedCallback(value) {
        return kDigitConversion(value);
      },
    },
    {
      // other y axis props:
      type: "linear",
      axis: "y",
      position: "left",
    },
    // isCustomAxis?
    true,
  );

  const scaleOptions = {
    x: xAxisScaleOptions,
    yReturn: yReturnAxisScaleOptions,
    ySpend: ySpendAxisScaleOptions,
  };

  const pluginOptions = {
    tooltip: {
      ...getDefaultTooltipOptions(),
      callbacks: {
        label: (context) => {
          const { dataset, dataIndex } = context || {};
          const { y } = dataset.data[dataIndex];

          if (!(Number(y) && dataset)) return null;

          return `${context.dataset.label} ${
            context.dataset.legendType
          }: $${addCommasToNumber(Number(y).toFixed(2))}`;
        },
        title: (context) => {
          const { label: dateString } = context[0];

          return dateSecondsToString(
            parseInt(dateString, 10),
            GRAPH_LONG_MONTH_FORMAT,
          );
        },
      },
    },
  };

  const graphOptions = createOptionsObj(scaleOptions, pluginOptions, {
    /// other options
    responsive: true,
    interaction: {
      mode: "nearest",
      intersect: false,
    },
  });

  const handleToggleShowSpend = () => {
    setShowSpendCurves(!showSpendCurves);
  };

  const handleSelectChannel = (selection) => {
    setSelectedChannels(selection);
  };

  const channelSelector = (
    <OutPointSelector
      multiple
      menuItems={channels}
      onChange={handleSelectChannel}
      selectedValue={selectedChannels}
      sx={{
        marginRight: "24px",
      }}
      renderValueOverride={() => {
        return (
          <Box sx={styles.selector}>
            <SubHeadLight>Channels: </SubHeadLight>
            <Badge text={selectedChannels.length} />
          </Box>
        );
      }}
    />
  );

  const xLabels = chartCurves?.[0]?.linePoints.map((point) => point.x);
  const data = {
    labels: xLabels,
    datasets: chartCurves,
  };

  const tabContents = [
    {
      label: "Overview",
      component: (
        <>
          <Grid
            container
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={styles.chartControls}
          >
            {channelSelector}
            <LabelText variant="caption">Show monthly budget</LabelText>
            <OutPointToggle
              checked={showSpendCurves}
              onChange={handleToggleShowSpend}
            />
          </Grid>
          <Line data={data} options={graphOptions} />
          <Grid container sx={styles.legend}>
            <Grid item xs={12}>
              <LegendBox title="MONTHLY RETURN">
                {chartCurves
                  .filter((curve) => curve.legendType === "return")
                  .map(({ borderColor, label }) => {
                    return (
                      <LegendItem
                        title={label}
                        key={label + borderColor}
                        iconColor={borderColor}
                      />
                    );
                  })}
              </LegendBox>
            </Grid>
            {showSpendCurves && (
              <Grid item xs={12}>
                <LegendBox title="MONTHLY BUDGET">
                  {chartCurves
                    .filter((curve) => curve.legendType === "spend")
                    .map(({ borderColor, label }) => {
                      return (
                        <LegendItem
                          title={label}
                          key={label + borderColor}
                          iconColor={borderColor}
                          icon="dashed"
                        />
                      );
                    })}
                </LegendBox>
              </Grid>
            )}
          </Grid>
        </>
      ),
    },
    {
      label: "Monthly Details",
      component: (
        <>
          <OutPointSelector
            menuItems={months}
            selectedValue={activeMonth}
            onChange={(month) => {
              const activeScenario = convertResponseToScenario(
                predictions[`${month}${MONTH_YEAR_SUFFIX}`],
              );
              setActiveMonthScenario(activeScenario);
              setActiveMonth(month);
            }}
          />
          <MonthlyDetailsTable
            scenarioData={activeMonthScenario}
            reverseTotalsRow
          />
        </>
      ),
    },
  ];

  return (
    <Grid container flexDirection="column" sx={styles.container}>
      <HorizontalTabsWithPanels contents={tabContents} />
    </Grid>
  );
}

export default MediaPlanResults;

MediaPlanResults.propTypes = {
  predictions: PropTypes.object,
  channels: PropTypes.array,
};
