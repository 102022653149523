import { Box, Grid } from "@mui/material";
import useHover from "hooks/useHover";
import React from "react";
import PropTypes from "prop-types";

export const TABLE = {
  HEADER: {
    backgroundColor: "#FAFAFC",
  },
  border: "1px solid rgb(246,244,253)",
};

export default function TableRow({
  isHeaderRow = false,
  titleComponent,
  renderColumn,
  columns,
  columnsAll = [],
  moreContainerStyles = {},
  moreTitleProps = {},
  hoverStyle = null,
}) {
  const { hoverOn, hoverOff, state: isHover } = useHover();

  const boxStyle = {
    backgroundColor: isHeaderRow ? TABLE.HEADER.backgroundColor : "white",
    borderBottom: TABLE.border,
    ...moreContainerStyles,
  };

  if (isHover && hoverStyle && !isHeaderRow) {
    Object.assign(boxStyle, hoverStyle);
  }

  const onMouseEnterProps = hoverStyle
    ? {
        onMouseEnter: () => hoverOn(),
        onMouseLeave: () => hoverOff(),
      }
    : {};

  return (
    <Box style={boxStyle} {...onMouseEnterProps}>
      <Grid container columns={columns} gap="12px">
        <Grid item xs={2} {...moreTitleProps}>
          {titleComponent}
        </Grid>
        {columnsAll.map((column, i) => (
          // Due to not knowing the shape of the data passed in to this, using index as key.
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={`column-${i}`} item xs={1} alignSelf="center">
            {renderColumn(column)}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

TableRow.propTypes = {
  isHeaderRow: PropTypes.bool,
  titleComponent: PropTypes.element,
  renderColumn: PropTypes.func,
  columns: PropTypes.number,
  columnsAll: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
  moreContainerStyles: PropTypes.object,
  moreTitleProps: PropTypes.object,
  hoverStyle: PropTypes.object,
};
