import React, { useState } from "react";
import PropTypes from "prop-types";

import { CardActionArea, IconButton } from "@mui/material";
import { InfoOutlined, DeleteForever } from "@mui/icons-material";

import BodyText from "library/text/body/BodyText";
import IconText from "library/containers/IconText";
import {
  FEEDBACK_SUCCESS,
  FEEDBACK_WARNING_WARNING,
  FEEDBACK_ERROR_ERROR,
  BRAND_GRAY_700,
  BORDER_GREY,
} from "assets/palette";
import OutPointCard from "library/surface/OutPointCard";
import PlatformCardModal from "./PlatformCardModal";
import LogoChip from "./LogoChip";

const styles = {
  cardActionArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px",
  },
  logoChip: {
    marginRight: "10px",
  },
  listItemCard: {
    boxShadow: "none",
    border: `1px solid ${BORDER_GREY}`,
    marginBottom: "10px",
  },
  seeDetails: {
    marginLeft: "5px",
    textDecoration: "underline",
  },
};

function PlatformListItem(props) {
  const {
    credentials,
    whereFrom,
    channel,
    logo,
    setDeleteModalState,
    setInfoModalState,
    setNeedsAttentionModalState,
    setConnectionPendingModalOpen,
  } = props;
  const name = credentials?.name;
  const hasCredentials = credentials?.credentials;
  const hasMetadata = credentials?.metadata;
  const isValid = credentials?.valid;

  const [modalOpen, setModalOpen] = useState(false);

  let status = "";
  let statusColor = "white";
  if (!hasCredentials || !hasMetadata) {
    status = "Unfinished";
    statusColor = BRAND_GRAY_700;
  } else if (isValid) {
    status = "Connected";
    statusColor = FEEDBACK_SUCCESS;
  } else if (isValid === false) {
    status = "Needs attention";
    statusColor = FEEDBACK_ERROR_ERROR;
  } else {
    status = "Pending";
    statusColor = FEEDBACK_WARNING_WARNING;
  }

  return (
    <>
      <PlatformCardModal
        whereFrom={whereFrom}
        channel={channel}
        name={name}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />

      <OutPointCard sx={styles.listItemCard}>
        <CardActionArea
          onClick={() => setModalOpen(true)}
          sx={styles.cardActionArea}
        >
          <IconText>
            <LogoChip
              style={styles.logoChip}
              logo={logo}
              channel={channel}
              size="15px"
            />
            <BodyText color="secondary">
              {name} •{" "}
              <span style={{ color: statusColor, fontWeight: 700 }}>
                {status}
              </span>
            </BodyText>
            {status === "Pending" && (
              <BodyText
                sx={styles.seeDetails}
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  setConnectionPendingModalOpen(true);
                }}
              >
                See details
              </BodyText>
            )}
            {status === "Needs attention" && (
              <BodyText
                sx={styles.seeDetails}
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  setNeedsAttentionModalState({ name, channel });
                }}
              >
                See details
              </BodyText>
            )}
          </IconText>

          <div>
            <IconButton>
              <InfoOutlined
                sx={styles.icon}
                onClick={(event) => {
                  event.stopPropagation();
                  setInfoModalState({
                    logo,
                    name,
                    channel,
                    metadata: hasMetadata,
                    status,
                    statusColor,
                  });
                }}
              />
            </IconButton>

            <IconButton>
              <DeleteForever
                sx={styles.icon}
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteModalState({
                    name,
                    channel,
                  });
                }}
              />
            </IconButton>
          </div>
        </CardActionArea>
      </OutPointCard>
    </>
  );
}

PlatformListItem.propTypes = {
  credentials: PropTypes.object,
  whereFrom: PropTypes.string,
  channel: PropTypes.string,
  logo: PropTypes.string,
  setDeleteModalState: PropTypes.func,
  setInfoModalState: PropTypes.func,
  setNeedsAttentionModalState: PropTypes.func,
  setConnectionPendingModalOpen: PropTypes.func,
};

export default PlatformListItem;
