import React from "react";

import {
  ArrowUpwardRounded as UpIcon,
  ArrowDownwardRounded as DownIcon,
  DehazeRounded as NeutralIcon,
} from "@mui/icons-material";

import { getChangeColours } from "assets/colours";
import { formatMetric } from "utils/data/metrics";

import LabelChip from "library/display/LabelChip";
import PropTypes from "prop-types";

const styles = {
  chip: {
    borderRadius: "16px",
  },
  getChipTextStyles: (textColour, backgroundColour) => ({
    color: textColour,
    backgroundColor: backgroundColour,
    fontFamily: "IBM Plex Sans",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.01em",
    textAlign: "center",
  }),
  getIconStyle: (textColour) => ({ color: textColour }),
};

function ChangeChip({
  change,
  isPercentage = false,
  inverted = false,
  useIcon = false,
  variableDecimals = false,
  ...rest
}) {
  const { textColour, backgroundColour } = getChangeColours(
    parseFloat(inverted ? -change : change),
  );

  const metricText = `${change > 0 && !useIcon ? "+" : ""}${formatMetric(
    change,
    isPercentage,
    !useIcon,
    variableDecimals,
  )}`;

  const noChange = change === 0 ? NeutralIcon : DownIcon;
  const Icon = change > 0 ? UpIcon : noChange;

  return (
    <LabelChip
      text={metricText}
      icon={
        useIcon ? (
          <Icon size="small" style={styles.getIconStyle(textColour)} />
        ) : (
          <div />
        )
      }
      chipStyles={styles.chip}
      chipTextStyles={styles.getChipTextStyles(textColour, backgroundColour)}
      {...rest}
    />
  );
}

export default ChangeChip;

ChangeChip.propTypes = {
  change: PropTypes.number,
  isPercentage: PropTypes.bool,
  inverted: PropTypes.bool,
  useIcon: PropTypes.bool,
  variableDecimals: PropTypes.bool,
};
