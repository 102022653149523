import React from "react";
import PlatformCards from "pages/connections/components/PlatformCards";
import PlatformList from "pages/connections/components/PlatformList";
import PropTypes from "prop-types";

const styles = {
  cardContainer: {
    marginTop: "20px",
    marginBottom: "45px",
  },
};

function ChoosePlatformTemplate(props) {
  const { platforms, body } = props;

  return (
    <>
      {body}
      <div style={styles.cardContainer}>
        <PlatformList group={platforms} whereFrom="connections" />
        <PlatformCards
          group={platforms}
          justify="center"
          whereFrom="onboarding"
        />
      </div>
    </>
  );
}

ChoosePlatformTemplate.propTypes = {
  platforms: PropTypes.array,
  body: PropTypes.element,
};

export default ChoosePlatformTemplate;
