import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Predicate, CONDITION_TYPES } from "classes/Predicate";
import { Grid, Checkbox, TextField, Typography } from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";

import { campaignsFilterStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import { CAMPAIGN_ATTRIBUTES } from "utils/enums";

import OPAutoComplete from "library/form/OutPointAutoComplete";

import {
  selectObjectProperty,
  createUniqueCampaignsAttributeSelector,
} from "redux/campaignsSlice";

const condsWithSelectionInputTypes = [
  CONDITION_TYPES.EQUALS_TO,
  CONDITION_TYPES.NOT_EQUALS_TO,
];
const condsWithTextInputTypes = [
  CONDITION_TYPES.CONTAINS,
  CONDITION_TYPES.DOES_NOT_CONTAIN,
];

const createRenderInput = (label) => {
  const renderInput = (params) => {
    return (
      <TextField
        {...params}
        label={label}
        InputProps={{
          ...params.InputProps,
          sx: styles.searchInputProps,
        }}
      />
    );
  };
  return renderInput;
};

/**
 * Creates a filter to be applied when finding campaigns.
 * */
function CampaignsFilter(props) {
  const { predicate, updateCompositeFilter } = props;

  const [reload, setReload] = useState(0);

  useEffect(() => {
    if (reload > 0) {
      updateCompositeFilter();
    }
  }, [reload]);

  const campaignAttributes = { ...CAMPAIGN_ATTRIBUTES };
  campaignAttributes.Medium.options = useSelector(
    createUniqueCampaignsAttributeSelector("medium"),
  );
  campaignAttributes.Channel.options = useSelector(
    createUniqueCampaignsAttributeSelector("channel"),
  );
  campaignAttributes.Source.options = useSelector(
    createUniqueCampaignsAttributeSelector("source"),
  );
  campaignAttributes.Strategy.options = useSelector(
    selectObjectProperty("strategies", "id"),
  );
  campaignAttributes["Campaign Group"].options = useSelector(
    selectObjectProperty("campaign_groups", "id"),
  );
  campaignAttributes["Campaign Name"].options = useSelector(
    selectObjectProperty("campaigns", "campaign"),
  );

  /// Triggers a state change to reload the filter component:
  const reloadComponent = useCallback(() => {
    setReload((x) => x + 1);
  }, []);

  /**
     Minor future TODO:
      In response to this review comment:
      https://github.com/OutPoint-app/outpoint-frontend/pull/265#discussion_r976501827

      Can't seem to change this toggleFilter apply...
      because it's being passed to the checkbox's onChange handler prop but
      then when I do control flow logic for my own handler, the
      e.target.checked doesn't change properly and I think it's because of a mix of react state and Predicate object state changes not syncing up
      well. I will just leave this as a comment in that area of the code.
   * */
  const toggleFilterApply = () => {
    const currentState = predicate.isApplied;
    predicate.setIsApplied(!currentState);
    reloadComponent();
  };

  const handleAttributeNameSelection = (e, attribute) => {
    predicate.setAttributeName(attribute);
    reloadComponent();
  };

  /**
   * NOTE: if the previous condition required a selection and the
   * current condition would require a text input (or vice-versa), then
   * the predicate's value attribute shall be reset to null.
   * */
  const handleConditionTypeSelection = (e, conditionType) => {
    // NOTE: input should only be cleared if it goes from text input --> selection input type
    const requiresInputTypeChange =
      predicate.condition &&
      condsWithSelectionInputTypes.includes(conditionType) &&
      condsWithTextInputTypes.includes(predicate.condition);

    if (requiresInputTypeChange) {
      predicate.value = null;
    }

    predicate.setCondition(conditionType);
    reloadComponent();
  };

  const handleValueSelection = (e, value) => {
    predicate.setValue(value);
    reloadComponent();
  };

  const handleValueInput = (e) => {
    const newVal = e?.target?.value;
    predicate.setValue(newVal);
    reloadComponent();
  };

  const createInputPlaceholder = (text) => (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography sx={styles.inputPlaceholderText}>{text}</Typography>
    </Grid>
  );

  const attributeSelector = (
    <OPAutoComplete
      name="Attribute Name"
      disableClearable
      sx={styles.filterCreatorDropdown}
      options={Object.values(campaignAttributes).map((attr) => attr.label)}
      onChange={handleAttributeNameSelection}
      value={predicate.attributeName}
      renderInput={createRenderInput(createInputPlaceholder("Attribute"))}
      popupIcon=""
    />
  );

  const conditionTypeSelector = (
    <OPAutoComplete
      name="Condition"
      disableClearable
      disabled={!predicate.attributeName}
      sx={styles.filterCreatorDropdown}
      options={Object.values(CONDITION_TYPES)}
      onChange={handleConditionTypeSelection}
      value={predicate.condition}
      renderInput={createRenderInput(createInputPlaceholder("Condition"))}
      popupIcon=""
    />
  );

  const valueSelector = (
    <OPAutoComplete
      disabled={!predicate.attributeName}
      disableClearable
      name="Value"
      sx={styles.filterCreatorDropdown}
      options={campaignAttributes[predicate.attributeName]?.options || []}
      onChange={handleValueSelection}
      value={predicate.value}
      renderInput={createRenderInput(createInputPlaceholder("Value"))}
      popupIcon=""
    />
  );

  const valueTextField = (
    <TextField
      disabled={!predicate.attributeName}
      label="Value"
      autoFocus
      value={predicate?.value || ""}
      InputProps={{
        sx: styles.valueTextFieldInputProps,
      }}
      sx={styles.filterTextField}
      onChange={handleValueInput}
    />
  );

  let valueInput = valueSelector;
  if (condsWithTextInputTypes.includes(predicate?.condition)) {
    valueInput = valueTextField;
  } else if (condsWithSelectionInputTypes.includes(predicate?.condition)) {
    valueInput = valueSelector;
  }

  const checkBox = (
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
      checkedIcon={<CheckBoxIcon fontSize="medium" />}
      indeterminate={!predicate.isComplete}
      checked={predicate.isApplied}
      sx={styles.filterCheckbox}
      onChange={toggleFilterApply}
    />
  );

  // TODO: style the inputs later -- there's some custom styling that needs to be done
  // and the OPAutoComplete needs to be changed
  return (
    <Grid container sx={styles.campaignsFilterContainer}>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={styles.campaignsFilter}
      >
        <Grid item xs={3}>
          {attributeSelector}
        </Grid>
        <Grid item xs={3}>
          {conditionTypeSelector}
        </Grid>
        <Grid item xs={4}>
          {valueInput}
        </Grid>
        <Grid item xs={1}>
          {checkBox}
        </Grid>
      </Grid>
    </Grid>
  );
}
CampaignsFilter.propTypes = {
  updateCompositeFilter: PropTypes.func,
  predicate: PropTypes.instanceOf(Predicate),
};

export default CampaignsFilter;
