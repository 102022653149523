import React from "react";
import PropTypes from "prop-types";
import { Button, Input, InputLabel } from "@mui/material";

import UploadIcon from "@mui/icons-material/Upload";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import BodyText from "library/text/body/BodyText";
import FileStepperButton from "./UploadButtons";

const styles = {
  fileInput: {
    opacity: 0,
    fontSize: 100,
    position: "absolute",
    left: 0,
    zIndex: "tooltip",
    color: "primary",
  },
  largeIconButton: {
    fontSize: 100,
  },
};

export default function FileUpload(props) {
  const {
    uploadedFileData,
    uploadedFileName,
    handleFileUpload,
    handleStepNext,
    handleStepBack,
  } = props;

  return (
    <>
      <InputLabel
        color="primary"
        htmlFor="import-button"
        sx={{ width: "120px" }}
      >
        <Button sx={styles.largeIconButton}>
          {uploadedFileData ? (
            <DownloadDoneIcon sx={styles.largeIconButton} />
          ) : (
            <UploadIcon sx={styles.largeIconButton} />
          )}
          <Input
            id="import-button"
            single="true"
            inputProps={{
              accept:
                ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
            }}
            onChange={handleFileUpload}
            onClick={(event) => {
              event.target.value = null;
            }}
            type="file"
            sx={styles.fileInput}
          />
        </Button>
      </InputLabel>
      {uploadedFileData && (
        <BodyText>You've uploaded: {uploadedFileName}</BodyText>
      )}
      <FileStepperButton
        uploadedFileData={uploadedFileData}
        handleStepNext={handleStepNext}
        handleStepBack={handleStepBack}
        frontStepText="Continue"
        backStepText="Back"
      />
    </>
  );
}
FileUpload.propTypes = {
  uploadedFileData: PropTypes.array,
  uploadedFileName: PropTypes.string,
  handleFileUpload: PropTypes.func,
  handleStepNext: PropTypes.func,
  handleStepBack: PropTypes.func,
};
