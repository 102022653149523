import { BRAND_GRAY_700 } from "assets/palette";
import { performanceInsightsStyles } from "pages/overview/components/styles/overviewComponentsStyles";

const styles = {
  ...performanceInsightsStyles,
  captionText: {
    // styleName: Subhead (Sm)/Caps;
    fontFamily: "IBM Plex Sans",
    color: BRAND_GRAY_700,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0.08em",
    textAlign: "left",
    marginBottom: "8px",
  },
  titleText: {
    marginBottom: "8px",
  },
  scenarioToggleButtonText: {
    // styleName: Subhead/Med;
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: 400,
    letter: "1%",
    lineHeight: "24px",
    letterSpacing: "0.01em",
    textAlign: "left",
    color: "#24232F",
  },
  scenarioToggleButton: {
    borderRadius: "8px",
    border: "1px solid #24232F",
    height: "48px",
    padding: "12px, 16px, 12px, 16px",
    width: "auto",
  },
  marginZero: {
    margin: "0 auto",
  },
  recommendationsPageComponentsContainer: {
    marginTop: "35px",
  },
  recommendationAndTableContainer: {},
  headerMain: {
    marginBottom: "8px",
  },
  headerStyle: {
    margin: "24px",
  },
  btnGroup: {
    width: "100%",
  },
  btn: {
    width: "34%",
  },
  smallHeader: {
    marginBottom: "8px",
  },
  horizontalLine: {
    width: "100%",
    border: "none",
    height: "1px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    marginBottom: "25px",
  },
  dateUpdated: {
    marginTop: "50px",
    marginBottom: "0",
  },
  linkStyle: {
    color: "#2A5DB0",
    marginLeft: "5px",
  },
  toolTipStyle: {
    color: "blue",
    marginTop: "3px",
    fontSize: "1rem",
  },
  cardStyle: {
    paddingLeft: "15px",
    paddingRight: "15px",
    borderRadius: "6px",
    height: "120px",
  },
};

export default styles;
