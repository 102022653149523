import React from "react";
import { Typography } from "@mui/material";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import PropTypes from "prop-types";

const styles = {
  text: {
    fontFamily: "IBM Plex Sans",
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: "1.25rem",
  },
};

function LabelText({ children, className, sx = {}, ...rest }) {
  const mergedStyles = {
    ...styles.text,
    ...sx,
  };

  return (
    <Typography sx={mergedStyles} className={className} {...rest}>
      {children}
    </Typography>
  );
}

export default LabelText;

LabelText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  sx: PropTypes.object,
};
