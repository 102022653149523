import { ArrowDropDown } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import useToggle from "hooks/useToggle";
import PrimaryButton from "library/buttons/PrimaryButton";
import Spacer from "library/containers/Spacer";
import OPBanner from "library/display/OutPointBanner";
import OPMenu from "library/form/OPMenu";
import Header4 from "library/text/headers/Header4";
import CustomScenarioPicker from "pages/recommendations/components/CustomScenarioPicker";
import Recommendation from "pages/recommendations/components/Recommendation";
import RecommendationsSummaryTable from "pages/recommendations/components/RecommendationsSummaryTable";
import RecommendationsBarGraph from "pages/recommendations/components/RecommendationsBarGraph";
import SubHeadLight from "library/text/headers/SubHeadLight";
import BodyText from "library/text/body/BodyText";
import LayersIcon from "@mui/icons-material/Layers";

import styles from "pages/recommendations/styles/recommendationPageStyles";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  recalculatePredictionChannelData,
  resetPredictionChannelData,
} from "redux/predictionsSlice";
import { useImmer } from "use-immer";
import {
  MAP_GOALS,
  RECOMMENDATION_SCENARIO_DETAILS as scenarioDetails,
  STATUS,
} from "utils/enums";
import { summaryBarGraphStyles } from "pages/recommendations/styles/recommendationsComponentsStyles";
import StrategyChannelToggle from "pages/overview/components/StrategyChannelToggle";

function RecommendationsPage() {
  const { state: menuState, toggleOff, toggle } = useToggle();

  const [anchorEl, setAnchorEl] = useState(null);
  const [showGraphicalView, setShowGraphicalView] = useState(false);
  const [isGroupedByStrategy, setIsGroupedByStrategy] = useState(false);

  const dispatch = useDispatch();

  const { recommendationsData, predictionsStore, overviewData } = useSelector(
    (state) => state,
  );

  const { goal = "efficiency" } = overviewData?.data || {};
  const [scenario, setScenario] = useState(MAP_GOALS[goal]);
  const [hideResults, setHideResults] = useState(true); // custom scenario
  const [disableSeeResultsButton, setDisableSeeResultsButton] = useState(true);

  const platformInitState = {
    baseline: recommendationsData?.baseLineScenarios || [],
    category_data: {},
  };

  const [platformSelectionData, updatePlatformSelection] =
    useImmer(platformInitState);
  const isCustomScenario = scenario === "custom";

  const clearUserSelections = () => {
    // Reset user selected custom scenario spends
    updatePlatformSelection(platformInitState);
    // Reset calculated scenario data
    dispatch(resetPredictionChannelData());
    // force hidden results to avoid any null programmer errors
    setHideResults(true);
  };

  const calculatePlatformSelectionData = useCallback(() => {
    dispatch(
      recalculatePredictionChannelData({
        platformSpendChannelData: Object.values(
          platformSelectionData.category_data,
        ),
      }),
    );
  }, [platformSelectionData.category_data]);

  const platformSelectionIsValid = (
    userSelectedInput = {},
    baselinePlatforms = [],
  ) => {
    if (!baselinePlatforms?.length) return false;

    const predictionPlatforms = Object.keys(userSelectedInput);

    const isValid = baselinePlatforms.every((platform) =>
      predictionPlatforms.includes(platform),
    );

    return isValid;
  };

  useEffect(() => {
    if (!isCustomScenario) return;

    updatePlatformSelection((prev) => {
      prev.baseline = recommendationsData?.baseLineScenarios;
    });

    const isValid = platformSelectionIsValid(
      predictionsStore.predictionScenario?.category_data,
      recommendationsData?.baseLineScenarios,
    );

    // if custom scenario, the results will be hidden if the user selected scenarios are incomplete or invalid
    setHideResults(!isValid);
  }, [
    predictionsStore.predictionScenario,
    recommendationsData?.baseLineScenarios,
    scenario,
  ]);

  useEffect(() => {
    const shouldDisable = !platformSelectionIsValid(
      platformSelectionData.category_data,
      recommendationsData?.baseLineScenarios,
    );

    // if custom scenario, the user will not be able to see results, if scenario selection is invalid or incomplete
    setDisableSeeResultsButton(shouldDisable);
  }, [platformSelectionData.category_data]);

  /**
   * Deletes channel data if the selection is deleted by the user
   * or updates the state with the new selection. 0 is a valid value
   *
   * @param {string} channel
   * @param {number} value
   * @returns undefined
   */
  const handlePlatformSelectionUpdate = (channel, value) => {
    if (value === null) {
      updatePlatformSelection((prevState) => {
        delete prevState.category_data[channel];
      });
      return;
    }

    updatePlatformSelection((prevState) => {
      prevState.category_data[channel] = {
        spend: value,
        channel,
      };
    });
  };

  /* open/close the scenario menu, handle unintended state of menu safely */
  const handleScenarioToggle = (event = {}) => {
    toggle();
    setAnchorEl(event.currentTarget);
  };

  /* force close the scenario menu on scenario selection */
  const handleMenuClose = React.useCallback(() => {
    setAnchorEl(null);
    toggleOff();
  }, [anchorEl]);

  /* change view, force close menu */
  const handleScenarioChange = (toScenario = "") => {
    setScenario(toScenario);

    return handleMenuClose();
  };

  /* calculate which data should be returned for scenario (custom or profit/revenu) */
  // TODO: verify if this custom stuff needs a logic change because of the new level of aggregation in the form of strategies
  const getScenarioData = (selectedScenario = "") => {
    if (isCustomScenario) {
      return predictionsStore.predictionScenario;
    }

    return recommendationsData?.data?.[selectedScenario];
  };

  const menuOptions = [
    {
      display: "Profit",
      onClick: () => {
        handleScenarioChange("profit");
      },
    },
    {
      display: "Revenue",
      onClick: () => {
        handleScenarioChange("revenue");
      },
    },
    {
      display: "Custom",
      onClick: () => {
        handleScenarioChange("custom");
      },
    },
  ];

  const scenarioToggleButton = (
    <PrimaryButton
      sx={styles.scenarioToggleButton}
      variant="outlined"
      onClick={handleScenarioToggle}
      endIcon={
        <ArrowDropDown
          sx={{
            color: "rgba(0, 0, 0, 0.8)",
          }}
        />
      }
    >
      <Typography sx={styles.scenarioToggleButtonText}>
        Change scenario
      </Typography>
    </PrimaryButton>
  );

  const recommLoaded = recommendationsData?.status === STATUS.SUCCESS;
  const baselineGroup = isGroupedByStrategy
    ? recommendationsData?.dataByStrategy?.baseline
    : recommendationsData?.data?.baseline;
  const baselineData = recommLoaded ? baselineGroup : null;
  const scenarioGroup = isGroupedByStrategy
    ? recommendationsData?.dataByStrategy?.[scenario]
    : getScenarioData(scenario);
  const scenarioData = recommLoaded ? scenarioGroup : null;

  if (!recommLoaded) return null;

  const { title, subtitle } = scenarioDetails[scenario] || {};
  const renderBadgeIcon = (_) => <InfoOutlinedIcon {..._} />;

  const banner = (
    <Grid container>
      <Grid container item xs={12}>
        <Header4
          style={{
            fontSize: "24px",
          }}
        >
          RECOMMENDATIONS
        </Header4>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={{
          marginBottom: "32px",
          marginTop: "24px",
        }}
      >
        <OPBanner
          displayText={title}
          subDisplayText={subtitle}
          level="neutral"
          moreBannerStyles={{
            borderRadius: "8px",
            borderColor: "primary.dark",
            borderStyle: "solid",
          }}
          BadgeIcon={renderBadgeIcon}
        >
          <Spacer />
          {scenarioToggleButton}
          <OPMenu
            menuState={menuState}
            handleMenuClose={handleMenuClose}
            menuOptions={menuOptions}
            anchorEl={anchorEl}
          />
        </OPBanner>
      </Grid>
    </Grid>
  );

  const strategyToggle = (
    <Grid container direction="row" alignItems="center">
      <StrategyChannelToggle
        groupByStrategy={isGroupedByStrategy}
        setGroupByStrategy={setIsGroupedByStrategy}
      />
    </Grid>
  );

  const summarySectionHeader = (
    <Grid
      container
      item
      mt="48px"
      direction="row"
      alignItems="center"
      sx={{ paddingBottom: "24px" }}
    >
      <Grid item xs={6}>
        <Box>
          <SubHeadLight style={summaryBarGraphStyles.cardTitleText}>
            Overview
          </SubHeadLight>
          <BodyText style={summaryBarGraphStyles.cardSubtitleText}>
            <LayersIcon sx={{ height: "14px", width: "13px", mr: "5.65px" }} />
            {`All ${isGroupedByStrategy ? "strategies" : "channels"}${
              showGraphicalView ? " - spend changes" : " "
            }`}
          </BodyText>
        </Box>
      </Grid>
      <Grid
        item
        container
        xs={6}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Grid item>{strategyToggle}</Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={showGraphicalView}
                onChange={() => setShowGraphicalView(!showGraphicalView)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={<BodyText>Show graphical view</BodyText>}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const summarySection = (
    <Grid container>
      {summarySectionHeader}
      {showGraphicalView ? (
        <RecommendationsBarGraph
          scenarioData={scenarioData}
          baselineData={baselineData}
          isGroupedByStrategy={isGroupedByStrategy}
        />
      ) : (
        <RecommendationsSummaryTable
          scenarioData={scenarioData}
          baselineData={baselineData}
          isGroupedByStrategy={isGroupedByStrategy}
        />
      )}
    </Grid>
  );

  return (
    <Box
      sx={{
        maxWidth: "1020px",
        backgroundColor: "transparent",
        flexGrow: 1,
        height: "100%",
      }}
    >
      <Grid
        container
        direction="column"
        sx={styles.recommendationsPageComponentsContainer}
      >
        {banner}
        <Grid
          item
          container
          direction="column"
          sx={styles.recommendationAndTableContainer}
        >
          <CustomScenarioPicker
            isVisible={isCustomScenario}
            scenarioData={platformSelectionData.category_data}
            spendList={predictionsStore.spendList}
            disableSeeResultsButton={disableSeeResultsButton}
            handlePlatformSelectionUpdate={handlePlatformSelectionUpdate}
            calculatePlatformSelectionData={calculatePlatformSelectionData}
            clearUserSelections={clearUserSelections}
          />
          {(!hideResults || !isCustomScenario) && (
            <>
              <Recommendation scenario={scenario} />
              {summarySection}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecommendationsPage;
