import React, { useState } from "react";
import { Typography, CircularProgress, Backdrop, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";

import OPDrawer from "library/surface/Drawer";
import OPDialog from "library/surface/OutPointDialog";
import CampaignsBanner from "pages/campaigns/components/CampaignsBanner";
import CampaignActionsButtons from "pages/campaigns/components/CampaignActionsButtons";
import GroupsDisplay from "pages/campaigns/components/GroupsDisplay";
import CampaignsFinder from "pages/campaigns/components/CampaignsFinder";
import CampaignGroupCreator from "pages/campaigns/components/CampaignGroupCreator";
import CampaignGroupDeletor from "pages/campaigns/components/CampaignGroupDeletor";
import { campaignsPageStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import CampaignGroupEditor from "./components/CampaignGroupEditor";
import StrategyCreator from "./components/StrategyCreator";
import StrategyDeletor from "./components/StrategyDeletor";
import StrategyEditor from "./components/StrategyEditor";

const groupMenuOptions = [
  {
    command: "addGroup",
    label: "Add group",
  },
  {
    command: "deleteGroup",
    label: "Delete group",
  },
  {
    command: "editGroups",
    label: "Edit groups",
  },
];
const strategyMenuOptions = [
  {
    command: "addStrategy",
    label: "Add strategy",
  },
  {
    command: "deleteStrategy",
    label: "Delete strategy",
  },
  {
    command: "editStrategy",
    label: "Edit strategies",
  },
];

function CampaignsPage() {
  const theme = useTheme();
  const isDoneProcessing = useSelector(
    (state) => state.campaignsData.status !== "loading",
  );
  const isSubmitting = useSelector((state) => state.campaignsData.isSubmitting);

  const [activeActions, setActiveActions] = useState([]);

  const handleCloseActionComponent = (targetAction) => {
    setActiveActions(activeActions.filter((action) => action !== targetAction));
  };

  const handleActionSelection = (action) => {
    setActiveActions([...activeActions, action]);
  };

  /**
   * Keyed by the action / type that is selected, returns the props
   * that are to be passed to OPDrawer to render the correct component in the drawer.
   * */
  const actionComponents = {
    find: {
      component: (
        <OPDrawer
          key="find"
          isOpen={activeActions.includes("find")}
          handleClose={() => handleCloseActionComponent("find")}
          anchor="right"
        >
          <CampaignsFinder
            handleActionSelection={handleActionSelection}
            handleCloseDrawer={() => {
              handleCloseActionComponent("addGroup");
              handleCloseActionComponent("find");
            }}
          />
        </OPDrawer>
      ),
    },
    addGroup: {
      component: (
        <OPDialog
          key="addGroup"
          isOpen={activeActions.includes("addGroup")}
          handleClose={() => handleCloseActionComponent("addGroup")}
          maxWidth="md"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>
              Add a new campaign group
            </Typography>
          }
        >
          <CampaignGroupCreator
            handleCloseDrawer={() => handleCloseActionComponent("addGroup")}
          />
        </OPDialog>
      ),
    },
    deleteGroup: {
      component: (
        <OPDialog
          key="deleteGroup"
          isOpen={activeActions.includes("deleteGroup")}
          handleClose={() => handleCloseActionComponent("deleteGroup")}
          scroll="body"
          maxWidth="lg"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>
              Delete a campaign group
            </Typography>
          }
        >
          <CampaignGroupDeletor
            handleCloseDrawer={() => handleCloseActionComponent("deleteGroup")}
          />
        </OPDialog>
      ),
    },
    editGroups: {
      component: (
        <OPDialog
          key="editGroups"
          isOpen={activeActions.includes("editGroups")}
          handleClose={() => handleCloseActionComponent("editGroups")}
          scroll="body"
          maxWidth="lg"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>Edit campaign groups</Typography>
          }
        >
          <CampaignGroupEditor
            handleCloseDrawer={() => handleCloseActionComponent("editGroups")}
          />
        </OPDialog>
      ),
    },
    addStrategy: {
      component: (
        <OPDialog
          key="addStrategy"
          isOpen={activeActions.includes("addStrategy")}
          handleClose={() => handleCloseActionComponent("addStrategy")}
          maxWidth="md"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>Add a new strategy</Typography>
          }
        >
          <StrategyCreator
            handleCloseDrawer={() => handleCloseActionComponent("addStrategy")}
          />
        </OPDialog>
      ),
    },
    deleteStrategy: {
      component: (
        <OPDialog
          key="deleteStrategy"
          isOpen={activeActions.includes("deleteStrategy")}
          handleClose={() => handleCloseActionComponent("deleteStrategy")}
          scroll="body"
          maxWidth="lg"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>Delete a strategy</Typography>
          }
        >
          <StrategyDeletor
            handleCloseDrawer={() =>
              handleCloseActionComponent("deleteStrategy")
            }
          />
        </OPDialog>
      ),
    },
    editStrategy: {
      component: (
        <OPDialog
          key="editStrategy"
          isOpen={activeActions.includes("editStrategy")}
          handleClose={() => handleCloseActionComponent("editStrategy")}
          scroll="body"
          maxWidth="lg"
          dialogTitleChildren={
            <Typography sx={styles.titleText}>Edit strategies</Typography>
          }
        >
          <StrategyEditor
            handleCloseDrawer={() => handleCloseActionComponent("editStrategy")}
          />
        </OPDialog>
      ),
    },
  };

  if (isDoneProcessing) {
    return (
      <>
        <Backdrop sx={styles.getBackdropStyle(theme)} open={isSubmitting}>
          <CircularProgress sx={styles.progressCircle} />
        </Backdrop>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          style={{ marginBottom: "48px" }}
        >
          <CampaignsBanner />
          <CampaignActionsButtons
            groupMenuOptions={groupMenuOptions}
            strategyMenuOptions={strategyMenuOptions}
            handleActionSelection={handleActionSelection}
          />
        </Grid>
        <GroupsDisplay />
        {activeActions.map((action) => actionComponents[action]?.component)}
      </>
    );
  }

  return (
    <Backdrop sx={styles.getBackdropStyle(theme)} open={isSubmitting}>
      <CircularProgress sx={styles.progressCircle} />
    </Backdrop>
  );
}

export default CampaignsPage;
