import React from "react";
import { Typography } from "@mui/material";
import { sentenceCase } from "utils/data/strings";
import PropTypes from "prop-types";

function BaseHeader({ children, className, isSentenceCase = true, ...rest }) {
  let newChildren;

  if (typeof children === "string") {
    newChildren = sentenceCase(children);
  } else if (Array.isArray(children)) {
    let changedFirstLetter = false;

    newChildren = children.map((child) => {
      if (typeof child === "string") {
        if (!changedFirstLetter) {
          changedFirstLetter = true;
          return sentenceCase(child);
        }
        return child.toLowerCase();
      }
      return child;
    });
  } else {
    newChildren = children;
  }
  return (
    <Typography className={className} {...rest}>
      {isSentenceCase ? newChildren : children}
    </Typography>
  );
}

export default BaseHeader;

BaseHeader.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isSentenceCase: PropTypes.bool,
  sx: PropTypes.object,
};
