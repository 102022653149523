import React from "react";
import SubHead from "library/text/headers/SubHead";
import { /* Box, */ Grid } from "@mui/material";
import { BRAND_GRAY_700, DARK_GREY } from "assets/palette";
import PropTypes from "prop-types";

const styles = {
  lightHeading: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: BRAND_GRAY_700,
    fontFamily: "IBM Plex Sans",
    paddingRight: "6px",
  },
  flexDisplay: {
    alignItems: { xs: "flex-start", lg: "flex-end" },
    flexDirection: { xs: "column", lg: "row" },
    display: "flex",
  },
  headingBig: {
    fontWeight: 400,
    fontSize: "29px",
    lineHeight: "32px",
    color: DARK_GREY,
    fontFamily: "IBM Plex Sans",
    marginBottom: "8px",
  },
  unitText: {
    fontSize: "20px",
    fontWeight: 700,
    marginLeft: "10px",
  },
  displayContainer: {},
};

function MetricDisplayRow(props) {
  const {
    keyText,
    valueText,
    keyAdornment = <div />,
    valueAdornment = <div />,
    sx = {},
    className = "",
    unit = null,
    ...rest
  } = props;
  const { lightHeading, headingBig, unitText, displayContainer } = styles;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      item
      sx={{ ...displayContainer, ...sx }}
      key={`${keyText}-${valueText}`}
      className={className}
      {...rest}
    >
      <Grid item xs={12} md={5}>
        <SubHead isSentenceCase={false} sx={lightHeading}>
          {keyText}
        </SubHead>
        {keyAdornment}
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        container
        justifyContent="flex-start"
        alignItems="flex-end"
        sx={{
          borderLeft: "2px solid lightgrey",
          paddingLeft: "8px",
        }}
      >
        <Grid item>
          <SubHead sx={headingBig}>
            {valueText}
            {unit && <span style={unitText}>/{unit}</span>}
          </SubHead>
        </Grid>
        <Grid item>{valueAdornment}</Grid>
      </Grid>
    </Grid>
  );
}

export default MetricDisplayRow;

MetricDisplayRow.propTypes = {
  keyText: PropTypes.string,
  valueText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  keyAdornment: PropTypes.element,
  valueAdornment: PropTypes.element,
  sx: PropTypes.object,
  className: PropTypes.string,
  unit: PropTypes.string,
};
