/**
 * Contains all the styling used by the components in the campaigns page (and the campaigns page also).
 *
 * TODO: prevent duplicates -- extract out common styles
 * TODO: extract colours out into the palette file
 * */

import { PRIMARY_COLOR } from "../../../assets/palette";

export const finderStyles = {
  campaignsFinderContainer: {
    height: "100%",
    position: "relative",
  },
  labelContainer: {
    height: "Hug(24px)",
    width: "Hug(442px)",
  },
  titleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "29px",
    fontWeight: 400,
  },
  inputPlaceholderText: {
    color: "#2F2F33",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    width: "100%",
    fontWeight: 400,
    marginLeft: "10px",
  },
  subTitleText: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  searchBox: {
    marginBottom: "8px",
    width: "100%",
  },
  searchInputProps: {
    borderRadius: "8px",
  },
  campaignsSearchContainer: {
    marginTop: "20px",
  },
  actionButton: {
    height: "Hug(48px)",
    borderRadius: "6px",
    padding: "12px, 16px, 12px, 16px",
    gap: "8px",
    color: PRIMARY_COLOR,
  },
  cancelDrawerButton: {
    width: "176px",
    border: `2px solid ${PRIMARY_COLOR}`,
  },
  contentContainer: {
    padding: "40px 48px",
    height: "87%",
    display: "block",
    overflow: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      // for safari & chrome
      width: 0,
      height: 0,
    },
  },
  actionArea: {
    position: "relative",
    bottom: "0px",
    color: "white",
    boxShadow:
      "0px 0px 4px rgba(16, 5, 56, 0.1), 0px 8px 40px rgba(22, 8, 79, 0.2)",
    height: "13%",
    width: "100%",
  },
  actionButtonText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.01em",
    textAlign: "left",
    height: "Hug(24px)",
    width: "Hug(51px)",
  },
  forwardActionButton: {
    width: "224px",
    // TODO: extract the colour out to the palette:
    color: PRIMARY_COLOR,
    padding: "12px, 18px, 12px, 18px",
  },
  forwardActionButtonText: {
    color: "white",
  },
  campaignsSelectorContainer: {
    width: "100%",
    marginBottom: "16px",
  },
  groupSelectorContainer: {
    width: "100%",
    marginBottom: "40px",
    marginTop: "40px",
  },
  groupCreatorText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
    letterSpacing: "0.01em",
    textAlign: "center",
    color: PRIMARY_COLOR,
    marginTop: "12px",
  },
  groupSelector: {
    width: "100%",
    m: 1,
    marginTop: "16px",
  },
  filterFormContainer: {
    width: "100%",
    marginBottom: "32px",
  },
  campaignsSelectionIndicatorContainer: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  campaignsSelectionIndicatorText: {
    color: "#606066",
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
    marginRight: "8px",
  },
};

export const campaignsFilterFormStyles = {
  bulkSelectorContainer: {
    // marginTop: '16px',
  },
  addFilterButtonContainer: {
    marginTop: "16px",
  },
  addFilterButton: {
    borderColor: PRIMARY_COLOR,
    width: "Hug(104px)",
    height: "Hug(32px)",
    borderRadius: "8px",
    padding: "8px 16px 8px 16px",
  },
  addFilterButtonText: {
    color: PRIMARY_COLOR,
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
  },
  filterDeletionChipContainer: {
    width: "100%",
    margin: "6px",
  },
  filterDeletionChip: {
    backgroundColor: "#F3F2F7",
    height: "30px",
    width: "Hug(156px)",
    borderRadius: "20px",
    padding: "6px",
  },
  filterDeletionLabelText: {
    color: "#2F2F33",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
  },
  filterSearchLabelText: {
    color: "#1C1C1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  muiAccordionRoot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  filtersIndicatorContainer: {
    marginBottom: "8px",
  },
  filtersIndicatorText: {
    color: "#606066",
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
  },
};

export const filterDeletionStyles = {
  filterDeletionChip: {
    backgroundColor: "#F3F2F7",
    height: "30px",
    width: "Hug(156px)",
    maxWidth: "100%",
    borderRadius: "20px",
    padding: "6px",
  },
  filterDeletionLabelText: {
    color: "#2F2F33",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
    overflowWrap: "break-word",
  },
};

export const campaignsFilterStyles = {
  campaignsFilterContainer: {
    borderBottom: "1px solid #E6E6ED",
  },
  filterCreatorDropdown: {
    width: "auto",
  },
  filterTextField: {
    width: "auto",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  campaignsFilter: {
    marginTop: "24px",
    marginBottom: "24px",
  },
  searchInputProps: {
    borderRadius: "8px",
  },
  valueTextFieldInputProps: {
    borderRadius: "8px",
    height: "40px",
  },
  inputPlaceholderText: {
    color: "#2F2F33",
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontWeight: 400,
  },
};

export const deletorStyles = {
  groupSelector: {
    width: "100%",
    marginTop: "16px",
  },
  muiAccordionRoot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  contentsDisplayContainer: {
    width: "100%",
  },
  deleteButton: {
    margin: "16px",
    height: "Hug(52px)",
    width: "231px",
    padding: "14px 18px 14px 18px",
    borderRadius: "8px",
    left: "18px",
    top: "14px",
  },
  buttonLabel: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  titleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "24px",
    fontWeight: 400,
  },
  deletorComponentsContainer: {
    overflow: "scroll",
    maxWidth: "600px",
  },
  deletorContentsDropdownContainer: {
    width: "fit-content",
    height: "50%",
  },
};

export const creatorStyles = {
  inputField: {
    height: "Hug(52px)",
    width: "100%",
    marginBottom: "10px",
    marginTop: "20px",
  },
  strategyInputField: {
    height: "Hug(52px)",
    width: "auto",
    marginBottom: "10px",
    marginTop: "20px",
  },
  button: {
    marginBottom: "16px",
    height: "Hug(52px)",
    width: "231px",
    padding: "14px 18px 14px 18px",
    borderRadius: "8px",
    top: "14px",
  },
  buttonLabel: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  titleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "24px",
    fontWeight: 400,
  },
  checkBox: {
    marginBottom: "10px",
  },
  strategySelector: {
    width: "auto",
    minWidth: "300px",
    height: "40px",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "12px",
    marginBottom: "10px",
  },
  radioLabel: {
    marginRight: "20px",
  },
};

export const editorStyles = {
  inputField: {
    height: "Hug(52px)",
    width: "200px",
    marginBottom: "10px",
  },
  strategySelector: {
    width: "205px",
    height: "40px",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "12px",
    marginBottom: "10px",
  },
  brandRadio: {
    marginBottom: "10px",
    width: "120px",
  },
  button: {
    marginBottom: "16px",
    height: "Hug(52px)",
    width: "231px",
    padding: "14px 18px 14px 18px",
    borderRadius: "8px",
    top: "14px",
  },
  buttonLabel: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  titleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "24px",
    fontWeight: 400,
  },
  strategyInputField: {
    width: "100%",
    height: "Hug(52px)",
    marginBottom: "10px",
  },
};

export const clearButtonStyles = {
  clearButton: {
    boxShadow: "none",
    backgroundColor: PRIMARY_COLOR,
    height: "Hug(24px)",
    width: "Hug(46px)",
    borderRadius: "20px",
    padding: "4px 6px",
    gap: "4px",
  },
  clearButtonText: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 500,
  },
};

export const campaignGroupTableStyles = {
  tableHeaderText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
  },
  inputPlaceholderText: {
    color: "#2F2F33",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    // lineHeight: '16px',
    fontWeight: 400,
    marginLeft: "10px",
  },
  searchBox: {
    marginBottom: "8px",
    // marginTop: '8px',
  },
  toolbarComponentsContainer: {
    padding: "16px 24px",
  },
  toolbarSearchBoxTextField: {
    height: "Hug(44px)",
    maxWidth: "500px",
    width: "100%",
    borderRadius: "8px",
  },
  toolbarSearchBoxInputLabel: {
    borderRadius: "8px",
    border: "1px solid #B8B8BF",
    // height: 'Hug(44px)',
    // width: 'Fill(500px)',
    // width: '500px',
    padding: "12px",
  },
  tableContainer: {
    maxHeight: "550px",
  },
  table: {
    minWidth: "50%",
  },
  deleteButton: {
    margin: "16px",
    height: "Hug(52px)",
    width: "231px",
    padding: "14px 18px 14px 18px",
    borderRadius: "8px",
    left: "18px",
  },
  buttonLabel: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  titleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "24px",
    fontWeight: 400,
  },
  emptyGroupText: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.01em",
    fontWeight: 400,
  },
  destinationGroupSelector: {
    marginTop: "16px",
    marginBottom: "16px",
  },
};

export const campaignsPageStyles = {
  getBackdropStyle: (theme) => ({
    color: "white",
    zIndex: theme.zIndex.drawer + 1,
  }),
  progressCircle: {
    left: 0,
    top: 0,
  },
  titleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "24px",
    fontWeight: 400,
  },
  actionButton: {
    height: "Hug(48px)",
    borderRadius: "6px",
    padding: "12px, 16px, 12px, 16px",
    gap: "8px",
    color: PRIMARY_COLOR,
  },
  cancelDrawerButton: {
    width: "176px",
    border: `2px solid ${PRIMARY_COLOR}`,
  },
  actionButtonText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: 500,
    letterSpacing: "0.01em",
    textAlign: "left",
    height: "Hug(24px)",
    width: "Hug(51px)",
  },
  forwardActionButton: {
    width: "224px",
    color: PRIMARY_COLOR,
    padding: "12px, 18px, 12px, 18px",
  },
  forwardActionButtonText: {
    color: "white",
  },
};

export const campaignsBannerStyles = {
  bannerContainer: {
    marginBottom: "20px",
  },
  bannerTitleText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "29px",
    fontWeight: 400,
  },
  bannerSubTitleText: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.01em",
    fontWeight: 400,
  },
};

export const campaignsActionsButtonsStyles = {
  buttonsContainer: {
    gap: "8px",
  },
  buttonLabel: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  finderButton: {
    width: "auto",
    height: "auto",
    padding: "14px 18px 14px 18px",
    borderRadius: "8px",
    top: "14px",
  },
  groupDropdownButton: {
    width: "181px",
    height: "52px",
    border: `1px solid ${PRIMARY_COLOR}`,
    borderRadius: "8px",
    top: "14px",
  },
  strategyDropdownButton: {
    width: "210px",
    height: "52px",
    border: `1px solid ${PRIMARY_COLOR}`,
    borderRadius: "8px",
    top: "14px",
  },
  actionsMenu: {
    borderRadius: "8px",
    gap: "60px",
  },
};
