import React, { useState, useEffect } from "react";

import { Grid } from "@mui/material";
import { PieChart, Park } from "@mui/icons-material";

import BodyText from "library/text/body/BodyText";
import SelectionCard from "library/surface/SelectionCard";
import SubHead from "library/text/headers/SubHead";
import RadioButton from "library/buttons/RadioButton";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { updateMetadata } from "redux/overviewSlice";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
  },
  circleContainer: {
    width: "56px",
    height: "56px",
    borderRadius: "50%",
    backgroundColor: "#EDE9FD",
    display: "inline-block",
    marginLeft: "24px",
    marginBottom: "5px",
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: "50%",
    bottom: "50%",
    fontSize: "26px",
    transform: "translate(-13px, -13px)",
    color: "primary.main",
  },
};

const cardText = [
  {
    id: "growth",
    title: "Growth",
    body: "I want to see higher spend recommendations that will help maximize revenue.",
    Icon: Park,
  },
  {
    id: "efficiency",
    title: "Efficiency",
    body: "I want to access more conservative spend recommendations that will help maximize profit.",
    Icon: PieChart,
  },
];

const sendData = async (data, dispatch) => {
  try {
    dispatch(updateMetadata(data));
    await makeAuthenticatedPostRequest("metadata", data);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn("unable to update goal in the backend", err);
  }
};

function GoalsPage({ setNextButtonDisabled }) {
  const dispatch = useDispatch();

  const [goal, setGoal] = useState();
  const [addLaterRadio, setAddLaterRadio] = useState(false);
  const overviewData = useSelector((state) => state.overviewData)?.data;

  useEffect(() => {
    const savedGoal = overviewData?.goal;
    const savedAddLater = overviewData?.add_goal_later;

    if (savedGoal) {
      setGoal(savedGoal);
    }

    if (savedAddLater) {
      setAddLaterRadio(true);
    }

    setNextButtonDisabled(Boolean(!savedGoal && !savedAddLater));
  }, [overviewData]);

  const isValidGoal = (currGoal) => cardText.some(({ id }) => id === currGoal);
  const handleClick = async (id) => {
    setGoal(id);
    setAddLaterRadio(false);

    if (!isValidGoal(id)) {
      setNextButtonDisabled(true);
      return;
    }

    sendData(
      {
        goal: id,
        add_goal_later: false,
      },
      dispatch,
    );
    setNextButtonDisabled(false);
  };

  return (
    <div style={styles.container}>
      <Grid container spacing={4} style={{ marginBottom: "20px" }}>
        {cardText.map(({ id, title, body, Icon }) => (
          <Grid key={title} item xs={6}>
            <SelectionCard
              checked={goal === id}
              handleClick={() => handleClick(id)}
              height="222px"
            >
              <div style={styles.cardContent}>
                <div style={styles.circleContainer}>
                  <Icon sx={styles.icon} />
                </div>
                <div style={{ padding: "24px" }}>
                  <SubHead style={{ marginBottom: "5px" }}>{title}</SubHead>
                  <BodyText color="secondary">{body}</BodyText>
                </div>
              </div>
            </SelectionCard>
          </Grid>
        ))}
      </Grid>
      <RadioButton
        label={"I'm not sure"}
        checked={addLaterRadio}
        setChecked={setAddLaterRadio}
        canUncheckSelf={false}
        onClick={() => {
          if (!addLaterRadio) {
            setNextButtonDisabled(false);
            setGoal(null);
            sendData(
              {
                add_goal_later: true,
                goal: null,
              },
              dispatch,
            );
          }
        }}
      />
    </div>
  );
}

GoalsPage.propTypes = {
  setNextButtonDisabled: PropTypes.func,
};

export default GoalsPage;
