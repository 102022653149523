import React from "react";
import PropTypes from "prop-types";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
  Chip,
} from "@mui/material";
import ExpandMoreRounded from "@mui/icons-material/ExpandMoreRounded";
import { CheckCircle, Cancel } from "@mui/icons-material";

import BodyText from "library/text/body/BodyText";
import Header3 from "library/text/headers/Header3";
import IconText from "library/containers/IconText";
import CardDivider from "pages/ncr/components/CardDivider";
import SubHead from "library/text/headers/SubHead";
import { LIGHT_GREY, GREEN, RED } from "assets/palette";
import SubHead2 from "library/text/headers/SubHead2";
import Caption1 from "library/text/body/Caption";
import {
  capitalize,
  addCommasToNumber,
  stringToTitleCase,
} from "utils/data/strings";

const styles = {
  summaryContainer: {
    display: "flex",
    direction: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0px 15px 15px",
  },
  chip: {
    marginLeft: "8px",
    color: "primary.main",
    backgroundColor: "#ECE9F5",
  },
  progressCircle: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  matchAndMiss: {
    textTransform: "uppercase",
    color: "secondary.main",
  },
  isFavorableIcon: {
    marginRight: "5px",
    fontSize: "12px",
  },
};

const METRICS_PER_COLUMN = 2;
const MAX_METRICS_PER_SECTION = 6;
const SCORE_MULTIPLIER = 65;

function getGradientColor(score) {
  const CEILING = 100;
  const MEDIAN_SCORE = 65;
  const FLOOR = 50;

  const RED_GRAD = [244, 29, 67];
  const YELLOW_GRAD = [255, 232, 30];
  const GREEN_GRAD = [0, 159, 102];

  let weight;
  if (score > CEILING) weight = 1;
  else if (score < FLOOR) weight = 0;
  else
    weight =
      (score > MEDIAN_SCORE ? score - MEDIAN_SCORE : score - FLOOR) /
      (score > MEDIAN_SCORE ? CEILING - MEDIAN_SCORE : MEDIAN_SCORE - FLOOR);

  const startColor = score > MEDIAN_SCORE ? YELLOW_GRAD : RED_GRAD;
  const endColor = score > MEDIAN_SCORE ? GREEN_GRAD : YELLOW_GRAD;

  const colorArray = startColor.map((color, idx) =>
    Math.round(color + weight * (endColor[idx] - color)),
  );
  const colorString = `${colorArray
    .reduce((acc, color) => `${acc} ${color.toString()},`, "rgb(")
    .slice(0, -1)})`;
  return colorString;
}

function MetricText({ name, isFavorable }) {
  return (
    <IconText style={{ marginBottom: "6px" }}>
      {isFavorable ? (
        <CheckCircle sx={styles.isFavorableIcon} style={{ color: GREEN }} />
      ) : (
        <Cancel sx={styles.isFavorableIcon} style={{ color: RED }} />
      )}
      <Caption1>{name}</Caption1>
    </IconText>
  );
}
MetricText.propTypes = {
  name: PropTypes.string,
  isFavorable: PropTypes.bool,
};

function MetricsSection({ filteredMetrics }) {
  const metricSectionsNum = Math.ceil(filteredMetrics.length);

  const columns = [];
  for (
    let i = 0;
    i < Math.ceil(metricSectionsNum / METRICS_PER_COLUMN);
    i += 1
  ) {
    const columnMetrics = [];
    for (let j = 0; j < METRICS_PER_COLUMN; j += 1) {
      if (i * METRICS_PER_COLUMN + j < filteredMetrics.length) {
        columnMetrics.push(
          <MetricText
            key={`${i}-${j}`}
            {...filteredMetrics[i * METRICS_PER_COLUMN + j]}
          />,
        );
      }
    }
    columns.push(
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        item
        xs={4}
      >
        {columnMetrics}
      </Grid>,
    );
  }
  return (
    <Grid item container>
      {columns}
    </Grid>
  );
}
MetricsSection.propTypes = {
  filteredMetrics: PropTypes.array,
};

function TitleSection({ chipText, channelTitleCase, channelText }) {
  return (
    <div style={{ display: "flex", direction: "row" }}>
      <div>
        {chipText ? (
          <IconText>
            <Header3 isSentenceCase={false}>{channelTitleCase}</Header3>
            <Chip label={chipText} size="small" sx={styles.chip} />
          </IconText>
        ) : (
          <Header3 isSentenceCase={false}>{channelTitleCase}</Header3>
        )}
        <BodyText style={{ marginTop: "5px" }}>{channelText}</BodyText>
      </div>
    </div>
  );
}
TitleSection.propTypes = {
  chipText: PropTypes.string,
  channelTitleCase: PropTypes.string,
  channelText: PropTypes.string,
};

const PROGRESS_CIRCLE_HEIGHT = "90px";
function ProgressCircle({ adjustedScore }) {
  return (
    <div
      style={{
        position: "relative",
        flexBasis: PROGRESS_CIRCLE_HEIGHT,
        flexGrow: 0,
        flexShrink: 0,
        height: PROGRESS_CIRCLE_HEIGHT,
        marginRight: "30px",
      }}
    >
      <Header3
        color="secondary"
        style={{
          height: PROGRESS_CIRCLE_HEIGHT,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: "2",
        }}
      >
        {adjustedScore}
      </Header3>
      <CircularProgress
        sx={styles.progressCircle}
        style={{
          zIndex: 1,
          height: PROGRESS_CIRCLE_HEIGHT,
          width: PROGRESS_CIRCLE_HEIGHT,
          color: getGradientColor(adjustedScore),
        }}
        variant="determinate"
        value={adjustedScore}
      />
      <CircularProgress
        sx={styles.progressCircle}
        style={{
          zIndex: 0,
          height: PROGRESS_CIRCLE_HEIGHT,
          width: PROGRESS_CIRCLE_HEIGHT,
          color: "#DBDFF1",
        }}
        variant="determinate"
        value={100}
      />
    </div>
  );
}
ProgressCircle.propTypes = {
  adjustedScore: PropTypes.number,
};

function MetricsDisplay({ matchMetrics, missMetrics }) {
  return (
    <div style={{ width: "100%" }}>
      <SubHead style={{ marginBottom: "10px" }}>Key criteria</SubHead>
      <Grid container>
        <Grid item xs={6}>
          <SubHead2 sx={styles.matchAndMiss} style={{ marginBottom: "8px" }}>
            match
          </SubHead2>
          <MetricsSection filteredMetrics={matchMetrics} />
        </Grid>
        <Grid item xs={6}>
          <SubHead2 sx={styles.matchAndMiss} style={{ marginBottom: "8px" }}>
            miss
          </SubHead2>
          <MetricsSection filteredMetrics={missMetrics} />
        </Grid>
      </Grid>
    </div>
  );
}
MetricsDisplay.propTypes = {
  matchMetrics: PropTypes.arrayOf(PropTypes.object),
  missMetrics: PropTypes.arrayOf(PropTypes.object),
};

function metricComponentCreator(adjustedFields) {
  function creator({ id, decimals, adornment }) {
    return (
      <SubHead style={{ textAlign: "center" }}>
        {`${adornment || ""}${addCommasToNumber(
          parseFloat(adjustedFields[id]).toFixed(decimals),
        )}`}
      </SubHead>
    );
  }
  return creator;
}

export default function NewChannelRecommendationCard(props) {
  const {
    score,
    channel = "",
    metrics,
    channelText,
    expanded,
    handleAccordionClick,
    chipText,
    audience,
  } = props;

  const channelTitleCase = stringToTitleCase(channel.replace(/_/g, " "));
  // eslint-disable-next-line no-restricted-globals
  const adjustedScore = isNaN(Math.round(score * SCORE_MULTIPLIER))
    ? "--"
    : Math.round(score * SCORE_MULTIPLIER);
  const adjustedFields = {
    ...props,
    score: adjustedScore,
    audience:
      audience?.total_audience_reached?.N ||
      audience?.total_audience_reached?.S,
  };

  const metricNames = metrics ? Object.keys(metrics) : [];
  const metricIsFavorable = (metricName) => {
    const metric = metrics[metricName]?.S;
    if (!metric) {
      return false;
    }
    if (typeof metric === "boolean") {
      return metric;
    }
    if (typeof metric === "number") {
      return metric > 0.5;
    }
    if (typeof metric === "string") {
      if (metric === "True") {
        return true;
      }
      if (metric === "False") {
        return false;
      }
      try {
        return parseFloat(metric) > 0.5;
      } catch {
        return false;
      }
    }
    return false;
  };
  const getMetric = (name, isFavorable) => {
    return {
      name: capitalize(name.replace(/_overlap/g, "").replaceAll("_", " ")),
      isFavorable,
    };
  };
  const matchMetrics = metricNames
    .filter(metricIsFavorable)
    .slice(0, MAX_METRICS_PER_SECTION)
    .map((metricName) => getMetric(metricName, true));
  const missMetrics = metricNames
    .filter((metric) => !metricIsFavorable(metric))
    .slice(0, MAX_METRICS_PER_SECTION)
    .map((metricName) => getMetric(metricName, false));

  const MetricComponentCreator = metricComponentCreator(adjustedFields);

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      onChange={() => handleAccordionClick(channel)}
      key={`newChannelAccordion-${channel}`}
    >
      <AccordionSummary
        style={{ flexDirection: "row-reverse" }}
        id={`${channel}panel-header`}
        expandIcon={<ExpandMoreRounded />}
      >
        <div style={styles.summaryContainer}>
          <CardDivider
            titleComponent={
              <TitleSection
                chipText={chipText}
                channelTitleCase={channelTitleCase}
                channelText={channelText}
              />
            }
            metricComponentCreator={MetricComponentCreator}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{ padding: "20px", backgroundColor: LIGHT_GREY }}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ProgressCircle adjustedScore={adjustedScore} />
          <MetricsDisplay
            matchMetrics={matchMetrics}
            missMetrics={missMetrics}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

NewChannelRecommendationCard.propTypes = {
  score: PropTypes.number,
  channel: PropTypes.string,
  metrics: PropTypes.object,
  channelText: PropTypes.string,
  expanded: PropTypes.bool,
  handleAccordionClick: PropTypes.func,
  chipText: PropTypes.string,
  audience: PropTypes.object,
};
