import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { perserveKeysInObject } from "utils/data/arrays";
import { sentenceCase } from "utils/data/strings";
import { createCurves } from "utils/graphing/data";
import { adjustDataPoints, aggregateDataSeries } from "utils/graphing/series";
import SubHeadLight from "library/text/headers/SubHeadLight";
import { METRIC_COLOURS } from "assets/colours";
import PropTypes from "prop-types";

import { Box, Grid } from "@mui/material";
import OutPointCard from "library/surface/OutPointCard";
import OPCircularProgress from "library/progress/Spinner";

import LegendBox from "library/graphing/LegendBox";
import LegendItem from "library/graphing/LegendItem";

import LineAndScatterChart from "library/graphing/LineChart";
import DownloadButtons from "library/graphing/DownloadButtons";
import ReturnAndSpendGraph from "./ReturnAndSpendGraph";

import { tickFormatters, tooltipTitleFormatters } from "../OverviewHelpers";
import { OP_COLORS } from "../../../assets/palette";

const styles = {
  csvButton: {
    fontSize: "16px",
    lineHeight: "14px",
    color: OP_COLORS.INDEPENDENCE,
    display: "flex",
    letterSpacing: "0.01em",
    paddingTop: "2px",
    width: "95px",
    height: "40px",
    backgroundColor: "white",
  },
  cardMargin: {
    padding: "32px",
    marginBottom: "24px",
  },
  flexDisplay: {
    display: "flex",
  },
  spaceBetween: { justifyContent: "space-between" },
  heading: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "28px",
  },
};

function MacroRoas(props) {
  const { timeRangeLabel = "daily", timeRangeDays = 1 } = props;
  const { macroRoas } = useSelector(({ reportingData }) => reportingData);
  const [isExportReadyRoas, setExportReadyRoas] = useState([]);
  const [isExportReadyReturnSpend, setExportReadyReturnSpend] = useState([]);

  const handleChangeInMacroRoas = () => {
    const roas = perserveKeysInObject(macroRoas, ["timestamp", "roas"]);
    const returnSpend = perserveKeysInObject(macroRoas, [
      "timestamp",
      "revenue",
      "spend",
    ]);

    setExportReadyRoas(roas);
    setExportReadyReturnSpend(returnSpend);
  };

  useEffect(handleChangeInMacroRoas, [
    macroRoas,
    timeRangeDays,
    timeRangeLabel,
  ]);
  useEffect(() => {}, [isExportReadyReturnSpend, isExportReadyRoas]);

  const roasCurves = [
    {
      linePoints: adjustDataPoints(
        createCurves(macroRoas, { roas: "y", timestamp: "x" }),
      ),
      name: "ROAS",
      color: METRIC_COLOURS.roas,
    },
  ];
  const timeAggregatedRoasCurves = aggregateDataSeries(
    roasCurves,
    timeRangeDays,
    "x",
    "y",
    true, // use averages to combine points
  );

  const spendCurve = {
    linePoints: adjustDataPoints(
      createCurves(macroRoas, { spend: "y", timestamp: "x" }),
    ),
    name: sentenceCase("spend"),
    color: METRIC_COLOURS.spend,
  };

  const revenueCurve = {
    linePoints: adjustDataPoints(
      createCurves(macroRoas, { revenue: "y", timestamp: "x" }),
    ),
    name: sentenceCase("revenue"),
    color: METRIC_COLOURS.revenue,
  };

  const spendReturnCurves = [spendCurve, revenueCurve];

  const timeAggregatedSpendReturnCurves = aggregateDataSeries(
    spendReturnCurves,
    timeRangeDays,
    "x",
    "y",
  );

  const timeAggregatedSpendCurves = aggregateDataSeries(
    [spendCurve],
    timeRangeDays,
    "x",
    "y",
  );

  const timeAggregatedReturnCurves = aggregateDataSeries(
    [revenueCurve],
    timeRangeDays,
    "x",
    "y",
  );

  const roasGraphOptions = {
    xScaleOptions: {
      formatTicks: tickFormatters[timeRangeLabel],
    },
    yScaleOptions: {
      formatTicks: (val) => val,
    },
    tooltipPluginOptions: {
      isNotDollar: true,
      label: (context) => {
        const { dataset, element = {}, dataIndex } = context || {};
        const value = dataset.data[dataIndex].y;
        const { active: isActive = false } = element;

        if (!(Number(value) && dataIndex && dataset && isActive)) return null;

        return `${context.dataset.label}: $${Number(value).toFixed(2)}`;
      },
      formatTitle: tooltipTitleFormatters[timeRangeLabel],
    },
  };

  const isRoasDataFetched = roasCurves?.[0].linePoints?.length > 0;
  const isReturnAndSpendDataFetched =
    timeAggregatedSpendReturnCurves?.[0].linePoints?.length > 0;

  const blendedRoasOverTimeGraph = isRoasDataFetched ? (
    <LineAndScatterChart
      data={timeAggregatedRoasCurves}
      moreOptions={roasGraphOptions}
    />
  ) : (
    <OPCircularProgress />
  );
  const blendedRoasHeaderText = `Blended ${sentenceCase(
    timeRangeLabel,
  )} ROAS Over Time`;

  const blendedRoasRef = useRef(null);
  const blendedRoasOverTimeSection = (
    <Grid item>
      <div style={{ position: "relative" }}>
        <DownloadButtons
          style={{ position: "absolute", right: "20px", top: "20px" }}
          data={isExportReadyRoas}
          filename="roas"
          componentRef={blendedRoasRef}
        />
        <div ref={blendedRoasRef}>
          <OutPointCard sx={styles.cardMargin}>
            <Box
              sx={{
                ...styles.flexDisplay,
                ...styles.spaceBetween,
              }}
            >
              <SubHeadLight isSentenceCase={false}>
                {blendedRoasHeaderText}
              </SubHeadLight>
            </Box>
            {blendedRoasOverTimeGraph}
          </OutPointCard>
        </div>
      </div>
    </Grid>
  );

  const totalReturnAndSpendOverTimeGraph = isReturnAndSpendDataFetched ? (
    <Grid item sx={{ marginTop: "16px" }}>
      <ReturnAndSpendGraph
        spendCurves={timeAggregatedSpendCurves}
        returnCurves={timeAggregatedReturnCurves}
        timeRangeLabel={timeRangeLabel}
      />
    </Grid>
  ) : (
    <OPCircularProgress />
  );

  const returnSpendHeaderText = `Total ${sentenceCase(
    timeRangeLabel,
  )} Return & Spend Over Time`;

  const totalReturnAndSpendRef = useRef(null);
  const totalReturnAndSpendOverTimeSection = (
    <div style={{ position: "relative" }}>
      <DownloadButtons
        style={{ position: "absolute", right: "20px", top: "20px" }}
        data={isExportReadyReturnSpend}
        filename="return_and_spend"
        componentRef={totalReturnAndSpendRef}
      />
      <div ref={totalReturnAndSpendRef}>
        <OutPointCard sx={styles.cardMargin}>
          <Box
            sx={{
              ...styles.flexDisplay,
              ...styles.spaceBetween,
            }}
          >
            <SubHeadLight isSentenceCase={false}>
              {returnSpendHeaderText}
            </SubHeadLight>
          </Box>

          {totalReturnAndSpendOverTimeGraph}
          <LegendBox
            moreLegendBoxStyles={{ marginBottom: "24px", marginLeft: "12px" }}
            title=""
          >
            {timeAggregatedSpendReturnCurves.map(({ color, name }) => {
              const standardisedName = name === "Revenue" ? "Return" : name; /// one-off name-standardising

              return (
                <LegendItem
                  title={standardisedName}
                  key={name + color}
                  iconColor={color}
                  icon="circle"
                />
              );
            })}
          </LegendBox>
        </OutPointCard>
      </div>
    </div>
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item xs={12}>
        {blendedRoasOverTimeSection}
      </Grid>
      <Grid item xs={12}>
        {totalReturnAndSpendOverTimeSection}
      </Grid>
    </Grid>
  );
}

MacroRoas.propTypes = {
  timeRangeDays: PropTypes.number,
  timeRangeLabel: PropTypes.string,
};

export default MacroRoas;
