import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";

import PrimaryButton from "library/buttons/PrimaryButton";
import TextFieldSimple from "library/form/OutPointTextField";

import { isValidName } from "utils/data/validators";

import {
  toggleIsSubmitting,
  bulkEdit,
  selectObjects,
} from "redux/campaignsSlice";
import { makeAuthenticatedPostRequest } from "utils/backend-api";

import { editorStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import Spacer from "library/containers/Spacer";
import LabelText from "library/text/body/LabelText";

/**
 * Strategy Editor is a simple dialog that helps the
 * user create a new campaign group. The action button is only
 * enabled if a valid campaign group name is typed into the
 * input textfield.
 * */
function StrategyEditor(props) {
  const { handleCloseDrawer } = props;
  const dispatch = useDispatch();

  const currentCampaigns = useSelector(selectObjects("campaigns"));
  const currentCampaignGroups = useSelector(selectObjects("campaign_groups"));
  const currentStrategies = useSelector(selectObjects("strategies"));
  const [changed, setChanged] = useState(false);

  const [updatedStrategyNameToOldStrategyNameMap, setUpdatedStrategyNames] =
    useState(
      currentStrategies.reduce(
        (map, strategy) => ({ ...map, [strategy.id]: strategy.id }),
        {},
      ),
    );

  const handleGroupEditSubmission = async () => {
    try {
      setChanged(false);
      const updatedCampaignGroupToStrategyMap = {};
      const updatedCampaigns = currentCampaigns.map((campaign) => {
        updatedCampaignGroupToStrategyMap[campaign.campaign_group] =
          updatedStrategyNameToOldStrategyNameMap[campaign.strategy];
        return {
          ...campaign,
          strategy: updatedStrategyNameToOldStrategyNameMap[campaign.strategy],
        };
      });
      const updatedStrategies = currentStrategies.map((strategy) => ({
        ...strategy,
        id: updatedStrategyNameToOldStrategyNameMap[strategy.id],
      }));

      dispatch(toggleIsSubmitting());
      const groupingPostResponse = await makeAuthenticatedPostRequest(
        "campaigns",
        { changes: updatedCampaigns },
      );
      const { success: groupingPostSuccess } = groupingPostResponse;

      if (!groupingPostSuccess) {
        const errorMsg =
          "The updating of campaigns grouping to the db was unsuccessful. Please try again or reach out to us.";
        throw new Error(errorMsg);
      }

      dispatch(
        bulkEdit({
          updatedCampaigns,
          updatedCampaignGroups: currentCampaignGroups,
          updatedStrategies,
          updatedCampaignGroupIdToStrategyIdMap:
            updatedCampaignGroupToStrategyMap,
        }),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error when submitting changes for campaign grouping:", e);
    } finally {
      dispatch(toggleIsSubmitting());
      handleCloseDrawer();
    }
  };

  const nameFieldPerStrategy = currentStrategies.reduce(
    (map, strategy) => ({
      ...map,
      [strategy.id]: (
        <TextFieldSimple
          sx={styles.strategyInputField}
          value={updatedStrategyNameToOldStrategyNameMap[strategy.id]}
          onChange={(text) => {
            setChanged(true);
            setUpdatedStrategyNames({
              ...updatedStrategyNameToOldStrategyNameMap,
              [strategy.id]: text.trim(),
            });
          }}
        />
      ),
    }),
    {},
  );

  const isValidInput = Object.values(
    updatedStrategyNameToOldStrategyNameMap,
  ).reduce((isValid, newName) => {
    return isValid && isValidName(newName);
  });

  const cancelButton = (
    <PrimaryButton
      variant="outlined"
      onClick={handleCloseDrawer}
      sx={styles.button}
    >
      <Typography sx={styles.buttonLabel}>Cancel and go back</Typography>
    </PrimaryButton>
  );
  const confirmSaveButton = (
    <PrimaryButton
      disabled={!isValidInput || !changed}
      onClick={handleGroupEditSubmission}
      sx={styles.button}
    >
      <Typography sx={styles.buttonLabel}>Save changes</Typography>
    </PrimaryButton>
  );

  const actionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancelButton}
      {confirmSaveButton}
    </Grid>
  );

  return (
    <Grid container direction="column" justifyContent="space-around">
      <Spacer sx={{ height: "10px" }} />
      <Grid container direction="row" justifyContent="space-between">
        <LabelText sx={{ width: "500px" }}>Strategy name</LabelText>
      </Grid>
      {currentStrategies
        .filter((strategy) => strategy.id !== "ungrouped")
        .map((strategy) => {
          return (
            <Grid
              key={strategy.id}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="end"
            >
              {nameFieldPerStrategy[strategy.id]}
            </Grid>
          );
        })}
      <Spacer sx={{ height: "20px" }} />
      {actionButtons}
    </Grid>
  );
}

StrategyEditor.propTypes = {
  handleCloseDrawer: PropTypes.func,
};

export default StrategyEditor;
