import React from "react";
import PropTypes from "prop-types";
import LegendBox from "library/graphing/LegendBox";

import Badge from "library/display/OutPointBadge";
import LegendItem from "library/graphing/LegendItem";

import { addCommasToNumber } from "utils/data/strings";
import { liftPageTemplateStyles as styles } from "./LiftPageTemplateStyles";

function CustomLegendBox(props) {
  const {
    title = "Legend",
    items,
    icon = "normal",
    selectedChannels,
    chartView = 100,
  } = props;
  const { badgeBoxStyle, badgeTextStyle } = styles;

  let renderBadge = false;

  if (title.toLowerCase() === "revenue" && Number(chartView) !== 100) {
    renderBadge = true;
  }

  return (
    <LegendBox
      title={title}
      endAdornment={
        renderBadge ? (
          <Badge
            moreTextStyles={badgeTextStyle}
            moreBoxStyles={badgeBoxStyle}
            text={`${chartView}%`}
          />
        ) : undefined
      }
    >
      {items.map(({ color, name, total_dollar_value: totalDollarValue }) => {
        const disableItem =
          !name.toLowerCase().includes("revenue") &&
          !selectedChannels.includes(name);

        return (
          <LegendItem
            title={name}
            disabled={disableItem}
            key={name + color + totalDollarValue}
            body={`$${addCommasToNumber(totalDollarValue)}`}
            iconColor={color}
            icon={icon}
          />
        );
      })}
    </LegendBox>
  );
}

CustomLegendBox.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  icon: PropTypes.string,
  selectedChannels: PropTypes.arrayOf(PropTypes.string),
  chartView: PropTypes.number,
};

export default CustomLegendBox;
