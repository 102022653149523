import React from "react";
import Caption from "library/text/body/Caption";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

const styles = {
  container: {
    width: "100%",
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "1fr",
    marginBottom: "14px",
  },
  barContainer: {
    padding: "2px",
  },
  bar: {
    height: "4px",
    borderRadius: "4px",
    width: "100%",
  },
  label: {
    position: "absolute",
    left: "0",
    top: "4px",
    width: "100%",
    textAlign: "center",
  },
  textContainer: {
    position: "relative",
  },
  getActiveBarStyle: (theme) => ({
    background: theme.palette.primary.main,
  }),
  getInactiveBarStyle: (theme) => ({
    background: theme.palette.secondary.main,
  }),
};

export default function OutpointBlockStepper(props) {
  const { labels, activeStep } = props;
  const theme = useTheme();

  const getMergedBarStyles = (stepIdx) => ({
    ...styles.bar,
    ...(stepIdx <= activeStep
      ? styles.getActiveBarStyle(theme)
      : styles.getInactiveBarStyle(theme)),
  });
  return (
    <div style={styles.container}>
      {labels.map((label, stepIdx) => (
        <div style={styles.barContainer} key={label}>
          <div style={getMergedBarStyles(stepIdx)} />
          <div style={styles.textContainer}>
            <Caption
              color={stepIdx <= activeStep ? "primary" : "secondary"}
              sx={styles.label}
            >
              {label}
            </Caption>
          </div>
        </div>
      ))}
    </div>
  );
}

OutpointBlockStepper.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  activeStep: PropTypes.number,
};
