import React, { useState } from "react";
import PrimaryButton from "library/buttons/PrimaryButton";
import PropTypes from "prop-types";
import OnboardingHeader from "./components/OnboardingHeader";

const styles = {
  root: {
    justifyContent: "center",
  },
  logo: {
    height: "30px",
  },
  header: {
    marginTop: "2%",
  },
  contentContainer: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    display: "block",
    margin: "20px 0",
  },
  allButtonsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "50px",
  },
  leftButton: {
    maxWidth: "100px",
    left: "0",
  },
  rightButton: {
    right: "0",
    maxWidth: "100px",
  },
};

export default function OnboardingPageTemplate(props) {
  const {
    Component,
    activeStep,
    stepperLabels,
    headerText,
    secondaryText,
    nextButtonText,
    nextButtonOnClick,
    backButtonText,
    backButtonOnClick,
    setPage,
  } = props;

  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        background:
          "linear-gradient(323deg, rgba(164,144,245,0) 0%, rgba(164,144,245,0) 54%, rgba(164,144,245,0.3253676470588235) 100%)",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ width: "600px" }}>
        <div style={styles.header}>
          <OnboardingHeader
            activeStep={activeStep}
            stepperLabels={stepperLabels}
            headerText={headerText}
            secondaryText={secondaryText}
          />
        </div>

        <div style={styles.contentContainer}>
          <div>
            <Component
              setPage={setPage}
              setNextButtonDisabled={setNextButtonDisabled}
            />
          </div>
        </div>

        <div style={styles.allButtonsContainer}>
          {backButtonText ? (
            <PrimaryButton
              size="large"
              sx={styles.leftButton}
              variant="text"
              color="primary"
              style={{ backgroundColor: "transparent" }}
              onClick={() => {
                setNextButtonDisabled(false);
                backButtonOnClick();
              }}
              isSentenceCase={false}
            >
              {backButtonText}
            </PrimaryButton>
          ) : null}
          {nextButtonText ? (
            <PrimaryButton
              size="large"
              sx={styles.rightButton}
              variant="contained"
              color="primary"
              onClick={nextButtonOnClick}
              isSentenceCase={false}
              isDisabled={nextButtonDisabled}
            >
              {nextButtonText}
            </PrimaryButton>
          ) : null}
        </div>
      </div>
    </div>
  );
}

OnboardingPageTemplate.propTypes = {
  Component: PropTypes.element,
  activeStep: PropTypes.number,
  stepperLabels: PropTypes.arrayOf(PropTypes.string),
  headerText: PropTypes.string,
  secondaryText: PropTypes.string,
  nextButtonText: PropTypes.string,
  nextButtonOnClick: PropTypes.func,
  backButtonText: PropTypes.string,
  backButtonOnClick: PropTypes.func,
  setPage: PropTypes.func,
};
