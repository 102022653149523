import React, { useState } from "react";

import WantToKnow from "pages/ncr/components/WantToKnow";
import PrimaryButton from "library/buttons/PrimaryButton";
import SubHead from "library/text/headers/SubHead";
import Title from "library/text/titles/Title";
import NewChannelRecommendationsFormModal from "./NewChannelRecommendationsFormModal";

const styles = {
  container: {
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
  title: {
    marginBottom: "20px",
    marginTop: "25% !important",
    textAlign: "center",
  },
  button: {
    width: "200px",
  },
  subHead: {
    textAlign: "center",
  },
};

export default function DataNotEnteredYetPage() {
  const [modalOpen, setModalOpen] = useState(false);

  function handleModalOpen() {
    setModalOpen(true);
  }

  return (
    <>
      <NewChannelRecommendationsFormModal
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      />
      <div style={styles.container}>
        <Title text="Ready to launch a new channel?" sx={styles.title} />
        <SubHead sx={styles.subHead}>
          We’ll help you discover where to spend next and give you the data you
          need to understand why it’s a good fit,
        </SubHead>
        <WantToKnow />
        <PrimaryButton onClick={() => handleModalOpen()} sx={styles.button}>
          Get started
        </PrimaryButton>
      </div>
    </>
  );
}
