import { Box, ToggleButton } from "@mui/material";
import React from "react";
import LabelText from "library/text/body/LabelText";
import PropTypes from "prop-types";
import {
  PRIMARY_LIGHT,
  PRIMARY_COLOR,
  PRIMARY_DARK,
} from "../../../assets/palette";

const styles = {
  toggleButtonStyle: {
    textTransform: "none",
    borderWidth: "1.5px",
    "&.Mui-selected": {
      backgroundColor: PRIMARY_LIGHT || PRIMARY_COLOR,
      borderColor: PRIMARY_DARK, // TODO: CHECK_COLOR: on main branch, this was "blue.main"
    },
  },
};

function StrategyChannelToggle(props) {
  const { groupByStrategy, setGroupByStrategy } = props;
  const handleToggle = (clickType) => {
    if (clickType === "strategy" && !groupByStrategy) {
      setGroupByStrategy(true);
    } else if (clickType === "channel" && groupByStrategy) {
      setGroupByStrategy(false);
    }
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <LabelText
        sx={{
          marginRight: "10px",
        }}
      >
        Aggregate by
      </LabelText>
      <ToggleButton
        value={groupByStrategy}
        size="small"
        selected={groupByStrategy}
        onClick={() => handleToggle("strategy")}
        sx={{ ...styles.toggleButtonStyle, borderRadius: "8px 0px 0px 8px" }}
      >
        Strategy
      </ToggleButton>
      <ToggleButton
        size="small"
        value={!groupByStrategy}
        selected={!groupByStrategy}
        onClick={() => handleToggle("channel")}
        sx={{
          ...styles.toggleButtonStyle,
          borderRadius: "0px 8px 8px 0px",
          marginRight: "8px",
        }}
      >
        Channel
      </ToggleButton>
    </Box>
  );
}

export default StrategyChannelToggle;

StrategyChannelToggle.propTypes = {
  groupByStrategy: PropTypes.bool,
  setGroupByStrategy: PropTypes.func,
};
