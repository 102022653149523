const { useState, useCallback } = require("react");

const useHover = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const hoverOn = useCallback(() => setState(true), []);
  const hoverOff = useCallback(() => setState(false), []);
  const toggleHover = useCallback(() => {
    if (state) return hoverOff();

    return hoverOn();
  }, [state]);

  return {
    state,
    hoverOn,
    hoverOff,
    toggleHover,
  };
};

export default useHover;
