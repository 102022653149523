import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";

const styles = {
  getHyperlinkStyle: (theme) => ({
    color: theme.palette.primary.main,
    textDecoration: "underline",
    fontFamily: "IBM Plex Sans",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    cursor: "pointer",
  }),
};

export default function Hyperlink({
  children,
  where,
  className,
  newPage,
  onClick,
  sx = {},
  ...rest
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const customNavigate = () => {
    if (newPage) {
      window.open(where);
    } else if (where.slice(0, 4) === "http") {
      window.location = where;
    } else {
      navigate(where);
    }
  };

  let handleClick = () => {};
  if (where) {
    handleClick = customNavigate;
  }

  if (onClick) {
    handleClick = onClick;
  }

  const anchorStyle = {
    ...styles.getHyperlinkStyle(theme),
    ...sx,
  };

  return (
    <a
      style={anchorStyle}
      onClick={handleClick}
      onKeyDown={handleClick}
      className={className || ""}
      role="button"
      tabIndex={0}
      {...rest}
    >
      {children}
    </a>
  );
}

Hyperlink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  where: PropTypes.string,
  newPage: PropTypes.bool,
  onClick: PropTypes.func,
  sx: PropTypes.object,
};
