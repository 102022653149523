/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getOnboarding = createAsyncThunk(
  "asyncRedux/onboardingflow",
  async () => {
    return makeAuthenticatedGetRequest("onboardingflow");
  },
);

const onboardingSlice = createSlice({
  name: "onboardingSlice",
  initialState: {
    object: {
      stage: null,
      is_onboarded: null,
    },
    status: null,
    authError: null,
  },
  reducers: {
    onboardingLogOut: (state) => {
      state.object.is_onboarded = null;
      state.object.stage = null;
      state.status = null;
      state.authError = null;
    },
  },
  extraReducers: {
    [getOnboarding.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getOnboarding.fulfilled]: (state, { payload }) => {
      state.object = payload.data;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getOnboarding.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const { onboardingLogOut } = onboardingSlice.actions;
export default onboardingSlice.reducer;
