/**
 * Contains the scenario specific recommendation
 * */

import { Grid, Typography } from "@mui/material";
import MetricDisplay from "library/display/MetricDisplay";
import Selector from "library/form/OutPointSelector";
import OutPointCard from "library/surface/OutPointCard";
import RecommendedSpendBreakdown from "pages/recommendations/components/RecommendedSpendBreakdownTable";
import PredictedReturnVsSpendGraph from "pages/recommendations/components/PredictedReturnVsSpendGraph";
import { recommendationStyles as reccoStyles } from "pages/recommendations/styles/recommendationsComponentsStyles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  createChannelDataSelector,
  createChannelRanksSelector,
} from "redux/recommendationsSlice";
import { capitalize } from "utils/data/strings";
import LabelChip from "library/display/LabelChip";
import { ERROR_THEME, SUCCESS_THEME } from "assets/colours";
import { sortBySpend } from "utils/dataset/roas";
import PropTypes from "prop-types";

const styles = {
  ...reccoStyles,
  background: {
    backgroundColor: "base.main",
  },
};

export default function Recommendation(props) {
  const { scenario } = props;
  const isCustomScenario = scenario === "custom";

  const channelRankings = useSelector(createChannelRanksSelector(scenario));
  const [channel, setChannel] = useState(channelRankings[0]);
  const baselineChannelData = useSelector(
    createChannelDataSelector("baseline", channel),
  );
  let scenarioChannelData = useSelector(
    createChannelDataSelector(scenario, channel),
  );
  const {
    rawPredictionData,
    sortedAndGroupedPredictionsData,
    predictionScenario,
  } = useSelector((state) => state.predictionsStore);

  if (isCustomScenario) {
    scenarioChannelData = predictionScenario.category_data?.[channel] || {};
  }

  if (!Object.keys(scenarioChannelData)?.length) return null;
  const predictionRows = (rawPredictionData || []).filter(
    (row) => row.campaign_group.toLowerCase() === channel.toLowerCase(),
  );

  const toolbar = (
    <Grid
      container
      justifyContent="space-between"
      style={{ display: "flex", flexDirection: "row" }}
      sx={styles.channelRecommendationToolbar}
      rowSpacing="14px"
    >
      <Grid
        item
        xs={9}
        container
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        {channel === channelRankings[0] && (
          <Typography sx={styles.subheaderText}>TOP RECOMMENDATION</Typography>
        )}
        <Typography sx={styles.channelLabelText}>
          {capitalize(channel)}
        </Typography>
      </Grid>
      <Grid item>
        <Selector
          sx={{
            ...styles.channelSelector,
            ...styles.background,
          }}
          menuItems={channelRankings}
          onChange={setChannel}
          renderValueOverride={() => `Channel: ${capitalize(channel)}`}
          selectedValue={channel}
        />
      </Grid>
    </Grid>
  );

  const change = scenarioChannelData.spend - baselineChannelData.spend;

  let valueAdornment = null;
  const isDifferenceChipDisplayable = !!change;
  if (isDifferenceChipDisplayable) {
    const moreBoxStyles = {
      backgroundColor: SUCCESS_THEME.backgroundColor,
      margin: "8px",
      borderRadius: "100px",
    };
    const moreTextStyles = {
      color: SUCCESS_THEME.color,
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "12px",
    };

    const changeAdornment = change < 0 ? "-" : "+";

    if (change < 0) {
      // Set color to red theme, as per design
      moreBoxStyles.backgroundColor = ERROR_THEME.backgroundColor;
      moreTextStyles.color = ERROR_THEME.color;
    }

    valueAdornment = (
      <LabelChip
        text={`${changeAdornment}${Math.abs(change)}%`}
        chipTextStyles={{ ...moreTextStyles, ...moreBoxStyles }}
      />
    );
  }

  const recommendationDetails = (
    <Grid sx={styles.recommendationDetailsContainer} item xs={6}>
      <Grid item xs={12}>
        <OutPointCard sx={styles.detailsCard}>
          <MetricDisplay
            keyText="Current Spend"
            valueText={baselineChannelData.spend}
            unit="day"
          />
        </OutPointCard>
        <OutPointCard
          sx={styles.detailsCard}
          data-cy={`reco-card-${
            scenarioChannelData?.spend === undefined ? "empty" : "filled"
          }`}
        >
          <MetricDisplay
            keyText="Recommended Spend"
            valueText={scenarioChannelData.spend}
            unit="day"
            valueAdornment={valueAdornment}
          />
        </OutPointCard>
      </Grid>
    </Grid>
  );

  const predictionGraph = (
    <Grid
      sx={{
        ...styles.predictionGraphContainer,
        ...styles.background,
      }}
      item
      minHeight="434px"
    >
      <PredictedReturnVsSpendGraph
        roas={sortBySpend(predictionRows)}
        key={channel}
        currentSpend={baselineChannelData.spend}
        recommendedSpend={scenarioChannelData.spend}
        seriesData={sortedAndGroupedPredictionsData[channel.toLowerCase()]}
      />
    </Grid>
  );

  const recommendationContent = (
    <Grid container sx={styles.recommendationContentContainer} wrap="nowrap">
      {recommendationDetails}

      <Grid item xs={9}>
        <OutPointCard
          sx={{
            ...styles.recommendedSpendBreakdownContainer,
            ...styles.background,
          }}
        >
          <RecommendedSpendBreakdown
            baselineChannelData={baselineChannelData}
            scenarioChannelData={scenarioChannelData}
          />
        </OutPointCard>
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="column" sx={styles.recommendationsContainer}>
      {toolbar}

      {recommendationContent}
      {predictionGraph}
    </Grid>
  );
}

Recommendation.propTypes = {
  scenario: PropTypes.string,
};
