import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid, Card, Typography } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

import { UPLOAD_VALIDITY_STATUS } from "utils/enums";
import IconText from "library/containers/IconText";
import BodyText from "library/text/body/BodyText";
import {
  FEEDBACK_OR_ERROR_100,
  FEEDBACK_OR_ERROR_900,
  LIGHT_GREEN,
  BRAND_GRAY_700,
} from "assets/palette";

const PADDING = "16px";
const BORDER_RADIUS = "8px";
const BOTTOM_MARGIN = "8px";
const MSG_CARD_WIDTH = "570px";
const BOLD_FONT_WEIGHT = 500;

const styles = {
  loadingDiv: {
    height: "80px",
    width: "100%",
    display: "flex",
  },
  errorMsgBanner: {
    boxShadow: "none",
    width: MSG_CARD_WIDTH,
    height: "Hug (80px)",
    backgroundColor: FEEDBACK_OR_ERROR_100,
    gap: "24px",
    padding: PADDING,
    borderRadius: BORDER_RADIUS,
    marginBottom: BOTTOM_MARGIN,
  },
  iconContainer: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
  },
  topContentText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontWeight: BOLD_FONT_WEIGHT,
  },
  descriptionText: {
    fontFamily: "IBM Plex Sans",
    color: BRAND_GRAY_700,
  },
  descriptionTextBold: {
    fontFamily: "IBM Plex Sans",
    color: BRAND_GRAY_700,
    fontWeight: BOLD_FONT_WEIGHT,
  },
  errorMsgCard: {
    boxShadow: "none",
    width: MSG_CARD_WIDTH,
    borderRadius: BORDER_RADIUS,
    border: `1px solid ${FEEDBACK_OR_ERROR_900}`,
    padding: PADDING,
    marginBottom: BOTTOM_MARGIN,
  },
  infoMsgCard: {
    boxShadow: "none",
    width: MSG_CARD_WIDTH,
    borderRadius: BORDER_RADIUS,
    border: `1px solid ${LIGHT_GREEN}`,
    padding: PADDING,
    marginBottom: BOTTOM_MARGIN,
  },
  rowErrorSpan: {
    fontFamily: "IBM Plex Sans",
    color: BRAND_GRAY_700,
    backgroundColor: FEEDBACK_OR_ERROR_100,
    fontWeight: BOLD_FONT_WEIGHT,
  },
};

function getHighlightClassName(
  highlightType,
  colName,
  classes,
  colNameToHighlight,
) {
  if (highlightType === "error" && colNameToHighlight === colName) {
    return classes.rowErrorSpan;
  }

  return classes.descriptionText; // default className
}

function RowDisplay(props) {
  const { row, highlightType = null, colNameToHighlight = null } = props;

  if (!row?.content || typeof row.content !== "object") {
    return null;
  }

  const rowContentEntries = Object.entries(row.content);
  const entrySpans = rowContentEntries.map(([colName, cellVal]) => {
    return (
      <span
        key={colName}
        style={getHighlightClassName(
          highlightType,
          colName,
          styles,
          colNameToHighlight,
        )}
      >
        <span>{`[${colName}:`}</span>
        <span>{`${cellVal}] `}</span>
      </span>
    );
  });

  return <BodyText sx={styles.descriptionText}>{entrySpans}</BodyText>;
}
RowDisplay.propTypes = {
  row: PropTypes.object,
  highlightType: PropTypes.string,
  colNameToHighlight: PropTypes.string,
};

/**
 * Component for displaying messages.
 * */
function Message(props) {
  const { msg } = props;

  if (Array.isArray(msg)) {
    // has nested msgs
    return msg.map((elem) => <Message key={elem.msgText} msg={elem} />);
  }

  const getMsgClassName = (msgType) => {
    if (msgType === "error") {
      return styles.errorMsgCard;
    }

    if (msgType === "info") {
      return styles.infoMsgCard;
    }

    return null;
  };

  const { msg_type: msgType, msg_text: msgText, row, column, reason } = msg;

  return (
    <Card sx={getMsgClassName(msgType)}>
      <IconText>
        <WarningRoundedIcon
          fontSize="large"
          sx={{
            color: "white",
            marginRight: PADDING,
          }}
        />
        <Grid container direction="column">
          <BodyText sx={styles.descriptionTextBold}>
            {`${row?.id ? `Row ${row.id}` : ""} ${
              row?.id && column?.id ? "," : ""
            } ${column?.id ? `Column "${column.id}"` : ""}`}
          </BodyText>
          <RowDisplay
            row={row}
            highlightType={msgType}
            colNameToHighlight={column?.id}
          />
          <BodyText>{`${msgText || ""}`}</BodyText>
          <BodyText>{`${reason || ""}`}</BodyText>
        </Grid>
      </IconText>
    </Card>
  );
}
Message.propTypes = {
  msg: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
};

/**
 * An error message is either a single error message or is an array that
 * that contains at least one error message.
 * */
const isErrorMsg = (msg) => {
  if (Array.isArray(msg)) {
    return msg.reduce((accum, currMsg) => accum || isErrorMsg(currMsg), false);
  }

  return msg.msg_type === "error";
};

export default function UploadMessageBoard(props) {
  const { isUploading, droppedFile, backendMsgLog } = props;

  const errorMessageBanner = (
    <Card sx={styles.errorMsgBanner}>
      <IconText sx={styles.iconContainer}>
        <WarningRoundedIcon
          fontSize="large"
          sx={{
            color: FEEDBACK_OR_ERROR_900,
            marginRight: PADDING,
          }}
        />
        <Grid container direction="column">
          <Typography sx={styles.topContentText}>
            We faced some issues validating your CSV
          </Typography>
          <BodyText sx={styles.descriptionText}>
            Please review the following:
          </BodyText>
        </Grid>
      </IconText>
    </Card>
  );

  const backendErrorMsgs = backendMsgLog.filter((msg) => isErrorMsg(msg));

  const errorMessagesDisplay =
    backendErrorMsgs &&
    backendErrorMsgs.map((msg) => <Message key={msg.msgText} msg={msg} />);

  // NOTE: only error msgs shall be displayed
  return (
    <>
      {isUploading && (
        <Grid item xs={12} sx={styles.loadingDiv}>
          <CircularProgress color="primary" />
        </Grid>
      )}
      {droppedFile.validationStatus === UPLOAD_VALIDITY_STATUS.INVALID &&
        errorMessageBanner}
      {errorMessagesDisplay}
    </>
  );
}
UploadMessageBoard.propTypes = {
  isUploading: PropTypes.bool,
  droppedFile: PropTypes.object,
  backendMsgLog: PropTypes.array,
};
