import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { Error } from "@mui/icons-material";
import { Card } from "@mui/material";

import Header1 from "library/text/headers/Header1";
import { sortBySpend } from "utils/dataset/roas";
import {
  addCommasToNumber,
  normalizeChannelName,
  sentenceCase,
} from "utils/data/strings";
import {
  getPercentageChange,
  getQuotientFixed,
  getQuotientRounded,
} from "utils/data/math";
import OutPointCard from "library/surface/OutPointCard";
import SubHeadLight from "library/text/headers/SubHeadLight";
import BodyText from "library/text/body/BodyText";
import SubHead from "library/text/headers/SubHead";
import IconText from "library/containers/IconText";
import { FAINT_YELLOW, DARK_YELLOW } from "assets/palette";
import { fillInRoasData } from "utils/graphing/data";
import { filterChannelsByRoas } from "utils/dataset/recommendations";
import MetricDisplay from "library/display/MetricDisplay";
import ChangeChip from "library/display/ChangeChip";
import { selectNormalizedCampaignGroupIdToStrategyIdMap } from "redux/campaignsSlice";
import Chip from "library/display/Chip";
import { deepCopy } from "utils/data/objects";
import PredictedPerformanceGraphs from "./components/PredictedPerformanceGraphs";
import HistoricalPerformanceGraphs from "./components/HistoricalPerformanceGraphs";

const styles = {
  headerMain: {
    marginBottom: "24px",
  },
  headerBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  pos: {
    marginBottom: 12,
  },
  root: {
    flexGrow: 1,
  },
  card: {
    p: 3,
    height: "auto",
    marginBottom: "40px",
  },
  diminishingCard: {
    p: 2,
    backgroundColor: FAINT_YELLOW,
    boxShadow: "none",
    marginBottom: "20px",
    borderRadius: "8px",
  },
  horizontalLine: {
    width: "100%",
    border: "none",
    height: "1px",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    marginBottom: "25px",
  },
  primary: {
    color: "primary.main",
  },
  loadingDiv: {
    paddingBottom: "41px",
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  errorIcon: {
    color: DARK_YELLOW,
    fontSize: "25px",
    mr: 2,
  },
  metricDisplay: {
    marginRight: "40px",
  },
  strategyChip: {
    width: "auto",
    marginLeft: "15px",
  },
};

function DiminishingReturnsCard({ channel }) {
  return (
    <Card sx={styles.diminishingCard}>
      <IconText>
        <Error sx={styles.errorIcon} />
        <div>
          <SubHead>{channel} is in the diminishing returns zone</SubHead>
          <BodyText color="secondary">
            At your current daily spend, efficiency likely decreases with every
            additional dollar of ad spend.
          </BodyText>
        </div>
      </IconText>
    </Card>
  );
}

function MetricSection({ title, startAmount, endAmount, inverted = false }) {
  let text = "";
  if (startAmount > endAmount) {
    text = `Decrease from $${addCommasToNumber(
      Math.round(startAmount),
    )} to $${addCommasToNumber(Math.round(endAmount))}`;
  } else if (startAmount < endAmount) {
    text = `Increase from $${addCommasToNumber(
      Math.round(startAmount),
    )} to $${addCommasToNumber(Math.round(endAmount))}`;
  } else {
    text = `Stay at ${startAmount}`;
  }
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
        marginRight: "40px",
        borderLeft: "2px solid lightgrey",
        paddingLeft: "7px",
      }}
    >
      <SubHead style={{ textTransform: "uppercase" }} color="secondary">
        {title}
      </SubHead>
      <IconText>
        <BodyText style={{ marginRight: "7px" }}>{text}</BodyText>
        {startAmount !== endAmount && (
          <ChangeChip
            change={getPercentageChange(startAmount, endAmount)}
            inverted={inverted}
            isPercentage
          />
        )}
      </IconText>
    </div>
  );
}

function ChannelPageTemplate(props) {
  const { channel } = props;
  const channelName = channel.toLowerCase();

  const roasData = useSelector((state) => state.reportingData);
  const { rawPredictionData, sortedAndGroupedPredictionsData, fetchComplete } =
    useSelector((state) => state.predictionsStore);
  const recomData = useSelector((state) => state.recommendationsData);
  const overviewResponse = useSelector((state) => state.overviewData);
  const ltv = useSelector((state) => state.overviewData)?.data?.ltv;
  const campaignGroupToStrategy = useSelector(
    selectNormalizedCampaignGroupIdToStrategyIdMap,
  );

  const { seriesData } = roasData;

  const overviewData = overviewResponse?.data;
  const goal = overviewData && overviewData.goal;
  let scenarioWord;
  if (goal === "efficiency") {
    scenarioWord = "profit";
  } else if (goal === "growth") {
    scenarioWord = "revenue";
  } else {
    scenarioWord = "revenue";
  }

  const baselineData =
    recomData.status === "success" ? recomData.data.baseline : null;
  const scenarioData =
    recomData.status === "success" ? recomData.data[scenarioWord] : null;
  const scenarioReturn =
    scenarioData?.category_data[channelName]?.total_revenue;
  const scenarioSpend = scenarioData?.category_data[channelName]?.spend;
  const baselineReturn =
    baselineData?.category_data[channelName]?.total_revenue;
  const baselineSpend = baselineData?.category_data[channelName]?.spend;

  let backendSortedRows = [];
  const interpolatedSortedRows = roasData.interpolated.roasSorted
    .filter(
      (row) =>
        normalizeChannelName(row.campaign_group) ===
        normalizeChannelName(channelName),
    )
    .map((row) => {
      return {
        ...row,
        date: new Date(row.date).getTime(),
        applyCustomStyling: true,
      };
    });

  if (roasData.roasSorted.length)
    backendSortedRows = roasData.roasSorted
      .filter(
        (row) =>
          normalizeChannelName(row.campaign_group) ===
          normalizeChannelName(channelName),
      )
      .map((row) => {
        return {
          ...row,
          date: new Date(row.date).getTime(),
        };
      });

  backendSortedRows = deepCopy(backendSortedRows);
  const aggregatedRoas = interpolatedSortedRows.concat(
    fillInRoasData(backendSortedRows),
  );

  const predictionRows = (rawPredictionData || []).filter(
    (row) => row.campaign_group.toLowerCase() === channelName.toLowerCase(),
  );

  /* Six (6) is the number agreed upon at the timing of coding,
     Feel free to change this if business requirements change */
  const useHistoricalData = fetchComplete && predictionRows.length < 6;

  const channelText = sentenceCase(normalizeChannelName(channelName));

  const [isDiminishingReturns] = baselineData?.category_data[channelName]
    ? filterChannelsByRoas(
        [baselineData.category_data[channelName]],
        "marginal",
        (roas) => roas < 1,
      )
    : [false];

  return (
    <>
      <div style={styles.headerBar}>
        <Header1 sx={styles.headerMain}>{channelText}</Header1>
        <Chip
          text={campaignGroupToStrategy[channelName] ?? channelText}
          moreBadgeBoxStyles={styles.strategyChip}
        />
      </div>

      <OutPointCard sx={styles.card}>
        {isDiminishingReturns && (
          <DiminishingReturnsCard channel={channelName} />
        )}
        <div>
          <MetricDisplay
            keyText="Current spend"
            valueText={`$${addCommasToNumber(baselineSpend)}`}
            unit="day"
            sx={styles.metricDisplay}
          />
          <MetricDisplay
            keyText="Optimal spend"
            valueText={`$${addCommasToNumber(scenarioSpend)}`}
            unit="day"
            sx={styles.metricDisplay}
            valueAdornment={
              <ChangeChip
                useIcon
                change={scenarioSpend - baselineSpend}
                isPercentage={false}
                style={{ margin: "0 0 3px 8px" }}
              />
            }
          />
        </div>

        <SubHeadLight style={{ margin: "40px 0 20px 0" }}>
          At optimal spend
        </SubHeadLight>

        <div>
          <MetricSection
            title="Return"
            startAmount={baselineReturn}
            endAmount={scenarioReturn}
          />
          <MetricSection
            title="CAC"
            inverted
            startAmount={getQuotientRounded(
              ltv * baselineSpend,
              baselineReturn,
            )}
            endAmount={getQuotientRounded(ltv * scenarioSpend, scenarioReturn)}
          />
          <MetricSection
            title="ROAS"
            startAmount={getQuotientFixed(baselineReturn, baselineSpend)}
            endAmount={getQuotientFixed(scenarioReturn, scenarioSpend)}
          />
        </div>
      </OutPointCard>

      <OutPointCard sx={styles.card}>
        <HistoricalPerformanceGraphs
          historicalRoas={backendSortedRows}
          aggregatedRoas={aggregatedRoas}
          key={channelName}
        />
      </OutPointCard>

      <OutPointCard sx={styles.card}>
        <PredictedPerformanceGraphs
          channel={channelName}
          roas={sortBySpend(
            useHistoricalData ? deepCopy(backendSortedRows) : predictionRows,
          )}
          key={channelName}
          currentSpend={baselineSpend}
          recommendedSpend={scenarioSpend}
          seriesData={
            useHistoricalData
              ? seriesData[channelName.toLowerCase()]
              : sortedAndGroupedPredictionsData[channelName.toLowerCase()]
          }
        />
      </OutPointCard>
    </>
  );
}
DiminishingReturnsCard.propTypes = {
  channel: PropTypes.string,
};

ChannelPageTemplate.propTypes = {
  channel: PropTypes.string,
};

MetricSection.propTypes = {
  title: PropTypes.string,
  startAmount: PropTypes.number,
  endAmount: PropTypes.number,
  inverted: PropTypes.bool,
};

export default ChannelPageTemplate;
