import React from "react";
import SubHead from "library/text/headers/SubHead";
import { Box, Grid } from "@mui/material";
import { BRAND_GRAY_700, DARK_GREY } from "assets/palette";
import PropTypes from "prop-types";

const styles = {
  lightHeading: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: BRAND_GRAY_700,
    marginBottom: "12px",
    fontFamily: "IBM Plex Sans",
  },
  flexDisplay: {
    alignItems: { xs: "flex-start", lg: "flex-end" },
    flexDirection: { xs: "column", lg: "row" },
    display: "flex",
  },
  headingBig: {
    fontWeight: 400,
    fontSize: "29px",
    lineHeight: "32px",
    color: DARK_GREY,
    fontFamily: "IBM Plex Sans",
    marginBottom: "8px",
  },
  unitText: {
    fontSize: "20px",
    fontWeight: 700,
    marginLeft: "10px",
  },
  displayContainer: {
    display: "inline-flex",
    flexDirection: "column",
  },
};

function MetricDisplay({
  keyText,
  valueText,
  keyAdornment = <div />,
  valueAdornment = <div />,
  sx = {},
  className = "",
  unit = null,
  ...rest
}) {
  const { flexDisplay, lightHeading, headingBig, unitText, displayContainer } =
    styles;

  return (
    <Grid
      item
      sx={{ ...displayContainer, ...sx }}
      key={`${keyText}-${valueText}`}
      className={className}
      {...rest}
    >
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <SubHead sx={lightHeading}>{keyText}</SubHead>
        {keyAdornment}
      </Box>
      <Box sx={flexDisplay}>
        <SubHead sx={headingBig}>
          {valueText}
          {unit && <span style={unitText}>/{unit}</span>}
        </SubHead>
        {valueAdornment}
      </Box>
    </Grid>
  );
}

export default MetricDisplay;

MetricDisplay.propTypes = {
  keyText: PropTypes.string,
  valueText: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  keyAdornment: PropTypes.element,
  valueAdornment: PropTypes.element,
  sx: PropTypes.object,
  className: PropTypes.string,
  unit: PropTypes.string,
};
