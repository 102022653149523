import React from "react";

import { CheckCircle, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import BodyText from "library/text/body/BodyText";
import Header4 from "library/text/headers/Header4";
import { FEEDBACK_OR_WARNING } from "assets/palette";
import { cardGroups } from "pages/connections/data/connectionsData";
import OPHyperlink from "library/text/body/HyperlinkSecondary";

const styles = {
  icon: {
    color: FEEDBACK_OR_WARNING,
    marginRight: "7px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};

const metadataStyles = {
  container: {
    padding: "15px 0",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    borderBottom: "1px solid lightgrey",
  },
  textContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  editContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    fontSize: "20px",
    margin: "6px 10px 0 5px",
  },
  body: {
    margin: "5px 0",
    textTransform: "capitalize",
  },
};

function Icon({ completed, ...props }) {
  return completed ? (
    <CheckCircle {...props} style={{ color: "green" }} />
  ) : (
    <RadioButtonUncheckedOutlined {...props} />
  );
}

Icon.propTypes = {
  completed: PropTypes.bool,
};

function MetadataSection({ title, body, page, setPage }) {
  const completed = body?.length > 0;

  const classes = metadataStyles;

  return (
    <div key={title} style={classes.container}>
      <Icon sx={classes.icon} />
      <div style={classes.textContainer}>
        <Header4>{title}</Header4>
        {completed ? (
          body.map((text) => (
            <BodyText key={text} sx={classes.body} color="secondary">
              {text}
            </BodyText>
          ))
        ) : (
          <BodyText sx={classes.body} color="secondary">
            None
          </BodyText>
        )}
      </div>
      <div style={classes.editContainer}>
        <OPHyperlink onClick={() => setPage(page)}>Edit</OPHyperlink>
      </div>
    </div>
  );
}

MetadataSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  page: PropTypes.string,
  setPage: PropTypes.func,
};

const getConnectedChannels = (jesterData, type) => {
  const channels = Object.keys(jesterData)?.filter((channel) =>
    cardGroups[type].includes(channel),
  );
  const connectedChannels = channels.filter((channel) =>
    jesterData[channel].reduce(
      (acc, data) => acc || (data.metadata && data.credentials),
      false,
    ),
  );
  return connectedChannels;
};

function ConfirmPage({ setNextButtonDisabled, setPage }) {
  const overviewData = useSelector((state) => state.overviewData)?.data;
  const jesterData = useSelector((state) => state.jester)?.data;

  const goal = overviewData?.goal;
  const notSureGoal = overviewData?.add_goal_later;
  const ltv = overviewData?.ltv;
  const notSureLtv = overviewData?.add_ltv_later;
  const connectedRevenueChannels = getConnectedChannels(jesterData, "revenue");
  const connectedAdChannels = getConnectedChannels(jesterData, "ads");

  const sections = [
    {
      title: "Customer LTV",
      body: [notSureLtv ? "No input" : ltv],
      page: "ltvPage",
    },
    {
      title: "Scenario Planning",
      body: [notSureGoal ? "No input" : goal],
      page: "goalsPage",
    },
    {
      title: "Connect revenue source",
      body: connectedRevenueChannels,
      page: "chooseRevenueDataPlatformPage",
    },
    {
      title: "Connect ad spend platform",
      body: connectedAdChannels,
      page: "chooseAdSpendPlatformPage",
    },
  ];

  setNextButtonDisabled(
    sections.reduce(
      (acc, section) => acc || !(section.body?.length > 0),
      false,
    ),
  );

  return (
    <div style={styles.container}>
      {sections.map((section) => (
        <MetadataSection {...section} setPage={setPage} />
      ))}
    </div>
  );
}

ConfirmPage.propTypes = {
  setNextButtonDisabled: PropTypes.func,
  setPage: PropTypes.func,
};

export default ConfirmPage;
