import React from "react";
import SubHead from "library/text/headers/SubHead";
import { Box, Grid } from "@mui/material";
import IconText from "library/containers/IconText";
import { ReactComponent as ChartLineIcon } from "assets/icons/ChartLine.svg";
import { ReactComponent as ChartLineDashedIcon } from "assets/icons/ChartLineDashed.svg";
import { ReactComponent as ChartLineDashedVerticalIcon } from "assets/icons/ChartLineDashedVertical.svg";
import { BRAND_GRAY_700, DARK_GREY } from "assets/palette";
import PropTypes from "prop-types";
import { FiberManualRecord } from "@mui/icons-material";

const styles = {
  legendStyling: {
    marginTop: "10px",
    marginRight: "30px",
    width: "100px",
  },
  disabledStyling: {
    opacity: "0.5",
  },
  titleBodyStyling: {
    display: "inline-block",
    marginLeft: "7px",
    marginTop: "2px",
  },
  legendItemTitle: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: BRAND_GRAY_700,
  },
  legendItemBody: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: DARK_GREY,
  },
  iconStyle: {
    display: "inline-block",
    minWidth: "24px",
  },
};

function LegendItem({
  title = "",
  body = "",
  disabled = false,
  moreTitleStyling = {},
  moreBodyStyling = {},
  iconColor,
  icon = "normal",
  children,
}) {
  const {
    legendItemTitle,
    legendItemBody,
    legendStyling,
    disabledStyling,
    titleBodyStyling,
    iconStyle,
  } = styles;

  const Icon = {
    normal: (
      <ChartLineIcon key={iconColor} fill={iconColor} style={iconStyle} />
    ),
    circle: (
      <FiberManualRecord
        key={iconColor}
        sx={{ color: iconColor }}
        style={iconStyle}
      />
    ),
    dashed: (
      <ChartLineDashedIcon key={iconColor} fill={iconColor} style={iconStyle} />
    ),
    verticalDashed: (
      <ChartLineDashedVerticalIcon
        key={iconColor}
        stroke={iconColor}
        sx={iconStyle}
      />
    ),
  };

  return (
    <Grid
      sx={{
        ...legendStyling,
        ...((disabled && disabledStyling) || {}),
      }}
      key={title}
      item
    >
      <IconText>
        <div style={{ display: "flex" }}>{children || Icon[icon]}</div>
        <Box style={titleBodyStyling}>
          <SubHead style={{ ...legendItemTitle, ...moreTitleStyling }}>
            {title}
          </SubHead>
          <SubHead style={{ ...legendItemBody, ...moreBodyStyling }}>
            {body}
          </SubHead>
        </Box>
      </IconText>
    </Grid>
  );
}

export default LegendItem;

LegendItem.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  disabled: PropTypes.bool,
  moreTitleStyling: PropTypes.object,
  moreBodyStyling: PropTypes.object,
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.any,
};
