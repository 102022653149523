import React from "react";
import AmazonLogo from "assets/small logos/Amazon.png";
import BingAdsLogo from "assets/logos/bing-ads.png";
import FacebookLogo from "assets/small logos/Facebook.png";
import GoogleAdsLogo from "assets/small logos/Google-Ads.png";
import GoogleAnalyticsLogo from "assets/small logos/Google-Analytics.png";
import GA4Logo from "assets/small logos/ga4.png";
import PinterestLogo from "assets/small logos/Pinterest.png";
import ShopifyLogo from "assets/small logos/Shopify.png";
import SnapchatLogo from "assets/small logos/Snap.png";
import SpotifyAdsLogo from "assets/small logos/Spotify.png";
import StripeLogo from "assets/small logos/Stripe.png";
import TiktokLogo from "assets/small logos/TikTok.png";

import GoogleButton from "react-google-button";

function GoogleSSOButton(props) {
  return (
    <GoogleButton
      type="light" // we don't support dark mode
      style={{ margin: "30px", display: "inline-block" }}
      {...props}
    />
  );
}

export const cardData = {
  amazon: {
    logo: AmazonLogo,
    fields: ["Amazon Profile Id"],
  },
  bing: {
    logo: BingAdsLogo,
    fields: ["Bing Ad Account Id"],
    secondStepToolTipContent: `
      To identify your Bing Ad Account Id, please login to your
      Microsoft Advertiser platform over at ads.microsoft.com/.
      After selecting your account, the Bing Ad Account Id can be found
      in your browser's addressbar's URL.
      You should see something like
      https://ui.ads.microsoft.com/campaign/vnext/overview?aid=123456789&cid=XXXXXXXXX&uid=XXXXXXXXX.
      Your account id is the 9 digit number that comes after 'aid'.
      `,
  },
  facebook: {
    logo: FacebookLogo,
    fields: ["Facebook Ad Account ID"],
  },
  google: {
    logo: GoogleAdsLogo,
    fields: ["Google Ad Account ID"],
    SSOButton: GoogleSSOButton,
  },
  google_analytics: {
    logo: GoogleAnalyticsLogo,
    fields: ["conversion metric", "value metric", "view id"],
    SSOButton: GoogleSSOButton,
  },
  google_analytics_4: {
    logo: GA4Logo,
    fields: ["conversion event", "property id"],
    SSOButton: GoogleSSOButton,
  },
  pinterest: {
    logo: PinterestLogo,
    fields: ["Pinterest Advertiser Id"],
  },
  shopify: {
    logo: ShopifyLogo,
    fields: ["Shopify Store Url"],
  },
  snapchat: {
    logo: SnapchatLogo,
    fields: ["Snapchat Ad Account ID"],
  },
  spotify: {
    logo: SpotifyAdsLogo,
    fields: ["Spotify Ad Account ID"],
  },
  stripe: {
    logo: StripeLogo,
    fields: ["Connect Account ID"],
  },
  tiktok: {
    logo: TiktokLogo,
    fields: ["Tiktok Ad Account ID"],
  },
};

export const cardGroups = {
  all: [
    "google",
    "google_analytics",
    "google_analytics_4",
    "facebook",
    "snapchat",
    "tiktok",
    "stripe",
    "bing",
    "amazon",
    "pinterest",
    "shopify",
    "spotify",
  ],
  revenue: ["stripe", "google_analytics", "google_analytics_4", "shopify"],
  ads: [
    "google",
    "facebook",
    "snapchat",
    "tiktok",
    "bing",
    "amazon",
    "pinterest",
    "spotify",
  ],
};
