import React, { useState } from "react";
import { LoadingButton as PendingButton } from "@mui/lab";
import PropTypes from "prop-types";
import PrimaryButton from "./PrimaryButton";

export default function LoadingButton({
  children,
  ButtonComponent = PrimaryButton,
  onClick,
  ...props
}) {
  const [pending, setPending] = useState(false);

  return (
    // NOTE: loading button text should be wrapped with an HTML element to prevent a known bug from MUI: https://github.com/mui/material-ui/issues/27853
    pending ? (
      <PendingButton loading {...props}>
        <span>{children}</span>
      </PendingButton>
    ) : (
      <ButtonComponent
        onClick={async () => {
          setPending(true);
          const response = await onClick();
          setPending(false);
          return response;
        }}
        {...props}
      >
        {children}
      </ButtonComponent>
    )
  );
}

LoadingButton.propTypes = {
  children: PropTypes.any,
  ButtonComponent: PropTypes.element,
  onClick: PropTypes.func,
};
