import axios from "axios";

const BACKEND_DOMAIN = process.env.REACT_APP_DOMAIN;
const NCR_PREFIX = "ncr";

function handle401Request() {
  window.location = "/login";
}

export async function makeAuthenticatedGetRequest(route, queryParams = {}) {
  const Token = localStorage.getItem("Token");
  let response;

  const uriFriendlyQueryParams = Object.keys(queryParams)
    .map((queryParam, index) => {
      return `${index ? "" : "?"}${queryParam}=${queryParams[queryParam]}`;
    })
    .join("&");

  try {
    response = await axios.get(
      `${BACKEND_DOMAIN}/${route}${uriFriendlyQueryParams}`,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      },
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Unable to make authenticated GET request ${route}`, e);
    if (e.response?.status === 401) handle401Request();

    throw e;
  }

  return response.data;
}

export async function makeAuthenticatedPutRequest(route, data) {
  const Token = localStorage.getItem("Token");
  let response;

  try {
    response = await axios.put(`${BACKEND_DOMAIN}/${route}`, data, {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Unable to make authenticated PUT request for ${route}`, e);
    if (e.response?.status === 401) handle401Request();

    throw e;
  }

  // FIXME, handle bad put requests in the front-end through the interface
  return response.data;
}

export async function makeAuthenticatedPostRequest(route, data = {}) {
  const Token = localStorage.getItem("Token");
  let response;

  try {
    response = await axios.post(
      `${process.env.REACT_APP_DOMAIN}/${route}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      },
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Unable to make authenticated POST request for ${route}`, e);
    if (e.response?.status === 401) handle401Request();

    throw e;
  }

  return response.data;
}

export async function makeNcrGetRequest(route) {
  return makeAuthenticatedGetRequest(`${NCR_PREFIX}/${route}`);
}

export async function makeNcrPutRequest(route, data) {
  return makeAuthenticatedPutRequest(`${NCR_PREFIX}/${route}`, data);
}
