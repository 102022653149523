/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { calculatePredictionScenario } from "utils/dataset/predictions";

export const getPredictions = createAsyncThunk(
  "asyncRedux/predictions",
  async () => {
    return makeAuthenticatedGetRequest("predictions");
  },
);

const initialState = {
  spendList: [],
  predictionsTableData: [], // List all data from *_predictions
  fetchComplete: false,
  predictionScenario: {}, // the scenario data used when populating and running recommendation calculations
  rawPredictionData: [],
  sortedAndGroupedPredictionsData: [],
};

const predictionsSlice = createSlice({
  name: "predictionsSlice",
  initialState,
  reducers: {
    predictionsLogOut: () => {
      return initialState;
    },
    recalculatePredictionChannelData: (state, { payload }) => {
      const { platformSpendChannelData } = payload;
      const currentState = current(state);

      const { predictionsTableData } = currentState;

      /* 
              NOTE: This functionality can be extracted to the backend, however, there are a few draw backs.
              1. We would need to STILL recalculate all fields (-1), similarily to how do it now in `calculatePredictionScenario`
              (HTTP Request (-1), Additional overhead to backend (-1))
              2. We would have to ping our DB every time a user makes a change to the data. (minimium 5 times, but up to a maximum of infinity, depending
                on how persistent the user is and how many predictions they want)
                (-1 (or -infinity))
                Total drawbacks = -4
                For those reasons, it's best to recalculate the prediction scenario data via a reducer action. 
                It increases speed and removes overhead to the other services, increasing performance and runtime execution.
            */
      const extractedData = calculatePredictionScenario({
        platformSpendChannelData,
        predictionsTableData,
      });

      state.predictionScenario = extractedData;
    },
    resetPredictionChannelData: (state) => {
      state.predictionScenario = {};
    },
  },
  extraReducers: {
    [getPredictions.fulfilled]: (state, { payload = {} }) => {
      const {
        data: {
          predictions,
          spend_list: spendList,
          predictions_raw: predictionsRaw,
          sorted_and_grouped_predictions: sortedAndGroupedPredictionsData,
        },
      } = payload;

      state.spendList = spendList;
      state.predictionsTableData = predictions;
      state.rawPredictionData = predictionsRaw;
      state.sortedAndGroupedPredictionsData = sortedAndGroupedPredictionsData;
      state.fetchComplete = true;
    },
    [getPredictions.rejected]: (state) => {
      state.predictions = null;
      state.fetchComplete = true;
    },
  },
});

export const {
  predictionsLogOut,
  recalculatePredictionChannelData,
  resetPredictionChannelData,
} = predictionsSlice.actions;

export default predictionsSlice.reducer;
