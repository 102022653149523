import React from "react";
import PropTypes from "prop-types";

import { MINECRAFT_CLAY_GREY } from "assets/palette";
import { normalizeChannelName } from "utils/data/strings";

const styles = {
  logoChip: {
    borderColor: MINECRAFT_CLAY_GREY,
    borderWidth: "1px",
    borderRadius: "8px",
    borderStyle: "solid",
    position: "relative",
    padding: "10px",
  },
  logoChipImage: {
    height: "inherit",
    width: "inherit",
  },
};

function LogoChip(props) {
  const { logo, channel, size = "35px", style, className } = props;
  return (
    <div
      className={`${className}`}
      style={{ ...styles.logoChip, height: size, width: size, ...style }}
    >
      <img
        src={logo}
        style={styles.logoChipImage}
        alt={`${normalizeChannelName(channel)} logo`}
      />
    </div>
  );
}

LogoChip.propTypes = {
  logo: PropTypes.string,
  channel: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default LogoChip;
