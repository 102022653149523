import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Button, Checkbox } from "@mui/material";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@mui/icons-material";

import CampaignsFilter from "pages/campaigns/components/CampaignsFilter";
import FilterDeletionChip from "pages/campaigns/components/FilterDeletionChip";
import { campaignsFilterFormStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

import { Predicate } from "classes/Predicate";

/**
 * CampaignsFilterForm keeps a map of predicate objects in state.
 * When important filter related events happen,
 * e.g. removal of filter, toggling on and off of filter, ...
 * then the these predicates are merged and the resultant composite
 * predicate function is passed to the setCompositeFilter() to update
 * the composite filter function used by the CampaignsFinder.
 * */
function CampaignsFilterForm(props) {
  const {
    handleFilteredSelection,
    setCompositeFilter,
    numFilteredCampaigns,
    isBulkSelectorChecked,
    isBulkSelectorIndeterminate,
  } = props;

  const [predicates, setPredicates] = useState({
    [crypto.randomUUID()]: new Predicate(),
  });

  const updateCompositeFilter = () => {
    const applicablePredicates = Object.values(predicates).filter((pred) =>
      pred.isApplicable(),
    );
    const compositePredicate = Predicate.mergePredicates(applicablePredicates);
    // Note [GOTCHA] to store functions via useState(), it needs to be wrapped as a callback:
    setCompositeFilter(() => compositePredicate);
  };

  useEffect(() => {
    updateCompositeFilter();
  }, [predicates]);

  const handleAddFilter = () => {
    const newPredicates = {
      ...predicates,
    };

    const clauseKey = crypto.randomUUID();
    newPredicates[clauseKey] = new Predicate();
    setPredicates(newPredicates);
  };

  const handleRemoveFilter = (targetClauseKey) => {
    const isSingleInputExisting = Object.keys(predicates).length === 1;
    if (isSingleInputExisting) {
      const [[clauseKey, predicate]] = Object.entries(predicates);
      predicate.fini();
      // triggers refresh via state change:
      setPredicates({ [clauseKey]: predicate });
      return;
    }

    const newPredicates = {
      ...predicates,
    };

    delete newPredicates[targetClauseKey];
    setPredicates(newPredicates);
  };

  const addFilterButton = (
    <Grid
      container
      sx={styles.addFilterButtonContainer}
      justifyContent="center"
    >
      <Button
        sx={styles.addFilterButton}
        onClick={handleAddFilter}
        variant="outlined"
      >
        <Typography sx={styles.addFilterButtonText}>+ Add Filter</Typography>
      </Button>
    </Grid>
  );

  const filters = Object.keys(predicates).map((clauseId) => (
    <CampaignsFilter
      key={clauseId}
      predicate={predicates[clauseId]}
      updateCompositeFilter={updateCompositeFilter}
    />
  ));

  const filterChips = (
    <Grid
      item
      container
      sx={styles.filtersIndicatorContainer}
      justifyContent="flex-start"
      alignItems="center"
    >
      {Object.entries(predicates).map(([clauseKey, predicate]) => {
        return (
          <Grid
            key={clauseKey}
            sx={styles.filterDeletionChipContainer}
            container
            wrap="nowrap"
          >
            <FilterDeletionChip
              sx={styles.filterDeletionChip}
              key={clauseKey}
              predicate={predicate}
              onDelete={() => handleRemoveFilter(clauseKey)}
            />
          </Grid>
        );
      })}
    </Grid>
  );

  /**
   * When selected, appends all the campaigns that matches the applicable filters
   * to the set of selected campaigns (ensuring no duplicate entries) and when deselected,
   * removes all the filtered campaigns that match the currently applied filters from the
   * current set of selected campaigns.
   * */
  const handleBulkSelectionOfFilteredCampaigns = () => {
    const newState = !isBulkSelectorChecked;

    handleFilteredSelection(newState);
  };

  const bulkSelectorText = `Add all ${numFilteredCampaigns} filtered ${
    numFilteredCampaigns > 1 ? "campaigns" : "campaign"
  }`;
  const bulkSelector = (
    <Grid
      container
      item
      sx={styles.bulkSelectorContainer}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" color="primary" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        checked={isBulkSelectorChecked}
        indeterminate={isBulkSelectorIndeterminate}
        onChange={handleBulkSelectionOfFilteredCampaigns}
      />
      <Typography>{bulkSelectorText}</Typography>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Typography sx={styles.filterSearchLabelText}>Filter search</Typography>
      {filterChips}
      {bulkSelector}
      {filters}
      {addFilterButton}
    </Grid>
  );
}

CampaignsFilterForm.propTypes = {
  handleFilteredSelection: PropTypes.func,
  setCompositeFilter: PropTypes.func,
  numFilteredCampaigns: PropTypes.number,
  isBulkSelectorChecked: PropTypes.bool,
  isBulkSelectorIndeterminate: PropTypes.bool,
};

export default CampaignsFilterForm;
