// TODO: refactor deferred
import React from "react";
import SubHead from "library/text/headers/SubHead";
import { Box } from "@mui/material";
import {
  BG_YELLOW,
  FEEDBACK_WARNING_WARNING,
  PRIMARY_LIGHT,
  PRIMARY_COLOR,
  BG_GREEN,
  OP_COLORS,
} from "assets/palette";
import PropTypes from "prop-types";

export const BANNER_STATE_LEVELS = {
  warning: {
    backgroundColor: BG_YELLOW,
    iconColor: FEEDBACK_WARNING_WARNING,
  },
  neutral: {
    backgroundColor: PRIMARY_LIGHT,
    iconColor: PRIMARY_COLOR,
  },
  success: {
    backgroundColor: BG_GREEN,
    iconColor: OP_COLORS.GREEN_CADMIUM_GREEN,
  },
};

const styles = {
  bannerStyle: (backgroundColor) => ({
    display: "flex",
    flexGrow: 1,
    height: "100%",
    justifyContent: "flex-start",
    padding: "7px 4px",
    alignItems: "center",
    backgroundColor,
  }),
  titleStyle: (extendedStyle = false) => ({
    fontWeight: (extendedStyle && 500) || 400,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    paddingLeft: "12px",
  }),
  subtitleStyle: () => ({
    fontWeight: 400,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    paddingLeft: "12px",
    marginTop: "4px",
  }),
  getDefaultBadgeIconStyle: (level) => ({
    color: BANNER_STATE_LEVELS[level]?.iconColor,
    marginLeft: "12px",
  }),
};

export function OutPointBanner({
  /*
    Our banner follows 1 of 2 patterns consistently
    1. Display text only
    2. Display text + sub display text

    If we need to display more than a heading or subheading, we should think
    about dynamically mapping sub text, however I don't see this scenario existing
    considering the use case of a banner is to be information light (vs dense)
  */
  displayText,
  subDisplayText = "",
  level = "warning",
  BadgeIcon = (_) => <div />,
  badgeStyle = {},
  moreBannerStyles = {},
  children,
}) {
  const { backgroundColor = BANNER_STATE_LEVELS.warning.backgroundColor } =
    BANNER_STATE_LEVELS[level] || {};

  // TODO: rename useExtendedStyle -- skipped because of unknown styling side-effects
  const useExtendedStyle = displayText && subDisplayText;
  const { bannerStyle, titleStyle, subtitleStyle } = {
    ...styles,
  };
  const badgeIconMergedStyle = {
    ...styles.getDefaultBadgeIconStyle(level),
    ...badgeStyle,
  };
  const mergedBannerBoxStyle = {
    ...bannerStyle(backgroundColor),
    ...moreBannerStyles,
  };

  return (
    <Box sx={mergedBannerBoxStyle}>
      <BadgeIcon style={badgeIconMergedStyle} />
      <div>
        <SubHead sx={titleStyle(useExtendedStyle)}>{displayText}</SubHead>
        <SubHead sx={subtitleStyle()}>{subDisplayText}</SubHead>
      </div>
      {children}
    </Box>
  );
}

export default OutPointBanner;

OutPointBanner.propTypes = {
  displayText: PropTypes.string,
  subDisplayText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  level: PropTypes.string,
  BadgeIcon: PropTypes.func,
  badgeStyle: PropTypes.object,
  moreBannerStyles: PropTypes.object,
  children: PropTypes.any,
};
