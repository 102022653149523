import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";

import PrimaryButton from "library/buttons/PrimaryButton";
import OPDialog from "library/surface/OutPointDialog";
import OPAutoComplete from "library/form/OutPointAutoComplete";

import {
  toggleIsSubmitting,
  deregisterStrategy,
  selectCampaignGroupIdToCampaignMap,
  selectObjectProperty,
  selectStrategyIdToCampaignGroupIdsMap,
} from "redux/campaignsSlice";
import { makeAuthenticatedPostRequest } from "utils/backend-api";

import { deletorStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

/**
 * Strategy Deletor allows the user to select any strategy that is not default,
 * i.e. not "ungrouped" to be deleted. There's a confirmation dialog that comes up
 * to make the user double-check the deletion.
 * */
function StrategyDeletor(props) {
  const dispatch = useDispatch();

  const { handleCloseDrawer } = props;
  const strategyToCampaignGroups = useSelector(
    selectStrategyIdToCampaignGroupIdsMap,
  );
  const campaignGroupToCampaigns = useSelector(
    selectCampaignGroupIdToCampaignMap,
  );
  const strategies = useSelector(selectObjectProperty("strategies", "id"));
  const deletableStrategies = strategies.filter(
    (strategy) => strategy !== "ungrouped",
  );

  const [selectedStrategy, setSelectedStrategy] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const resetState = () => {
    setSelectedStrategy(null);
    setShowConfirmationDialog(false);
  };

  const openConfirmationDialog = () => {
    // eslint-disable-next-line no-console
    console.assert(
      !showConfirmationDialog,
      "Should only open a currently closed dialog",
    );
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    // eslint-disable-next-line no-console
    console.assert(
      showConfirmationDialog,
      "Should only close a currently open dialog",
    );
    setShowConfirmationDialog(false);
  };

  const handleStrategySelection = (e, strategyId) => {
    setSelectedStrategy(strategyId);
  };

  const handleDeletionConfirmation = async () => {
    if (!selectedStrategy) {
      return;
    }

    dispatch(deregisterStrategy({ targetId: selectedStrategy }));

    if ((strategyToCampaignGroups?.[selectedStrategy]?.length ?? 0) === 0) {
      resetState();
      handleCloseDrawer();

      return;
    }

    const updatedCampaigns = [];
    strategyToCampaignGroups[selectedStrategy].forEach((campaignGroup) => {
      campaignGroupToCampaigns[campaignGroup].forEach((campaign) => {
        updatedCampaigns.push({ ...campaign, strategy: campaignGroup });
      });
    });

    try {
      dispatch(toggleIsSubmitting());
      const strategyPostResponse = await makeAuthenticatedPostRequest(
        "campaigns",
        { changes: updatedCampaigns },
      );
      const { success: strategyPostSuccess } = strategyPostResponse;

      if (!strategyPostSuccess) {
        const errorMsg =
          "The updating of strategies to the db was unsuccessful. Please try again or reach out to us.";
        throw new Error(errorMsg);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error when submitting changes for strategies:", e);
    } finally {
      resetState();
      dispatch(toggleIsSubmitting());
      handleCloseDrawer();
    }
  };

  const strategySelector = (
    <OPAutoComplete
      name="Select strategy"
      sx={styles.groupSelector}
      options={deletableStrategies}
      onChange={handleStrategySelection}
      value={selectedStrategy}
    />
  );

  const deleteButton = (
    <PrimaryButton
      disabled={!selectedStrategy}
      onClick={openConfirmationDialog}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>
        Delete {selectedStrategy ? `"${selectedStrategy}"` : ""}
      </Typography>
    </PrimaryButton>
  );

  const cancelAndGoBackButton = (
    <PrimaryButton
      variant="outlined"
      onClick={handleCloseDrawer}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>Cancel and go back</Typography>
    </PrimaryButton>
  );

  const firstDialogActionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancelAndGoBackButton}
      {deleteButton}
    </Grid>
  );

  const cancellationButton = (
    <PrimaryButton
      variant="outlined"
      onClick={closeConfirmationDialog}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>Cancel</Typography>
    </PrimaryButton>
  );

  const confirmButton = (
    <PrimaryButton
      disabled={!selectedStrategy}
      onClick={handleDeletionConfirmation}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>Confirm</Typography>
    </PrimaryButton>
  );

  const actionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancellationButton}
      {confirmButton}
    </Grid>
  );

  const confirmationTitle = (
    <Typography sx={styles.titleText}>Confirm Deletion</Typography>
  );

  const deleteConfirmationDialog = (
    <OPDialog
      isOpen={showConfirmationDialog}
      fullWidth
      scroll="body"
      handleClose={closeConfirmationDialog}
      dialogTitleChildren={confirmationTitle}
      dialogActionsChildren={actionButtons}
    >
      <Typography>
        You've selected <b>{selectedStrategy || ""}</b> to be deleted. <br />
        All of the{" "}
        <b>{strategyToCampaignGroups[selectedStrategy]?.length || 0}</b>{" "}
        campaign groups under this group will be moved to default strategies.
        <br />
        Please click 'Confirm' to carry on with this deletion.
      </Typography>
    </OPDialog>
  );

  const explainerText = (
    <Typography>
      Select a strategy to be deleted. The campaign groups associated with that
      strategy will be moved to default strategies.
    </Typography>
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      sx={{
        maxWidth: "600px",
        ...styles.deletorComponentsContainer,
      }}
    >
      {explainerText}
      {strategySelector}
      {firstDialogActionButtons}
      {deleteConfirmationDialog}
    </Grid>
  );
}

StrategyDeletor.propTypes = {
  handleCloseDrawer: PropTypes.func,
};

export default StrategyDeletor;
