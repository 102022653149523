import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { getChannelColour } from "assets/colours";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { normalizeChannelName, sentenceCase } from "utils/data/strings";
import {
  adjustDataPoints,
  aggregateDataSeries,
  makeRelatedCurvesContiguous,
} from "utils/graphing/series";
import { createCurves, handleExplicitlySetRange } from "utils/graphing/data";
import {
  daysToMilliSeconds,
  mixRangeToNumDays,
  trimDataSeries,
} from "utils/data/dates";
import BodyText from "library/text/body/BodyText";
import { deepCopy } from "utils/data/objects";
import StrategyChannelToggle from "./StrategyChannelToggle";
import ActualsTemplate from "./ActualsTemplate";

function RevenueActualsCard(props) {
  const { timeRangeLabel = "daily", timeRangeDays = 1 } = props;
  const roasResponse = useSelector((state) => state.reportingData);

  const [isGroupedByStrategy, setIsGroupedByStrategy] = useState(true);

  const mixRanges = [
    "Last 2 weeks",
    "Last 3 months",
    "Last 6 months",
    "Last year",
    "All time",
  ];
  const [mixRange, setMixRange] = useState("All time");
  const [curves, setCurves] = useState([]);
  const [explicitRange, setExplicitRange] = useState([]);
  const [showLift, setShowLift] = useState(true);

  useEffect(() => {
    const actualsData = isGroupedByStrategy
      ? deepCopy(roasResponse.actualsGroupStrategy?.data || {})
      : deepCopy(roasResponse.actualsGroup?.data || {});
    const supportedCategories = isGroupedByStrategy
      ? roasResponse.actualsGroupStrategy?.ordered_categories || []
      : roasResponse.actualsGroup?.ordered_categories || [];
    if (!actualsData) return;

    const endDate = Math.max(
      ...Object.values(actualsData).map((categoryData) =>
        Math.max(
          ...categoryData
            .filter((row) => !row.interpolated)
            .map((row) => row.timestamp),
        ),
      ),
    );
    const trimmedData = trimDataSeries(
      actualsData,
      mixRange === "All time"
        ? 0
        : endDate - daysToMilliSeconds(mixRangeToNumDays[mixRange]),
      endDate,
      "timestamp",
    );

    supportedCategories.forEach((category) => {
      const normalCurves = showLift
        ? createCurves(trimmedData[category], {
            prev_channel_total_revenue: "z",
            timestamp: "x",
            accumulative_total_revenue: "y",
            total_revenue: "value",
          })
        : createCurves(trimmedData[category], {
            prev_channel_direct_revenue: "z",
            timestamp: "x",
            accumulative_direct_revenue: "y",
            direct_revenue: "value",
          });

      trimmedData[category] = normalCurves;
    });

    const updatedCurves = supportedCategories
      .map((category, index) => {
        const linePoints = trimmedData[category];
        if (!linePoints.length) return null;

        return {
          linePoints,
          name: normalizeChannelName(category),
          /*
          Supported Channels is the source of truth for ordering
          This ensures, that order is preserved on all display periods
        */
          order: index + 1,
          color: getChannelColour(category),
          fill: {
            target: "origin",
          },
        };
      })
      .filter((e) => e);

    const adjustedCurves = updatedCurves.map((curve) => {
      const newCurve = { ...curve };
      newCurve.linePoints = adjustDataPoints(newCurve.linePoints);

      return newCurve;
    });

    /// TODO: verify if I should be doing smooth then aggregate or aggregate then smooth. If it's aggregate then smooth, then the window size for smoothing has to vary based on what the timeRangeDays is!!
    const timeAggregatedCurves = makeRelatedCurvesContiguous(
      aggregateDataSeries(adjustedCurves, timeRangeDays, "x", "y"),
    ); // TODO: verify if "y" is the correct key, since there are a bunch of options for this

    setExplicitRange(
      handleExplicitlySetRange({ curves: timeAggregatedCurves }),
    );
    setCurves(
      timeAggregatedCurves.filter((curve) => curve.linePoints?.length > 1),
    );
  }, [
    mixRange,
    roasResponse.status,
    isGroupedByStrategy,
    showLift,
    timeRangeDays,
    timeRangeLabel,
  ]);

  const toggleHeader = (
    <Grid item>
      <Box display="flex" flexDirection="row" alignItems="center">
        <StrategyChannelToggle
          groupByStrategy={isGroupedByStrategy}
          setGroupByStrategy={setIsGroupedByStrategy}
        />
        <FormControlLabel
          sx={{
            marginLeft: "0px",
          }}
          control={
            <Checkbox
              checked={showLift}
              onChange={() => setShowLift(!showLift)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={<BodyText>Include lift</BodyText>}
        />
      </Box>
    </Grid>
  );

  const groupingStr = isGroupedByStrategy ? "Strategies" : "Channels";
  const headerText = `Historical ${sentenceCase(
    timeRangeLabel,
  )} Return Across ${groupingStr}`;

  return (
    <div
      style={{
        padding: "32px 24px 8px 24px",
        height: "100%",
        position: "relative",
      }}
      data-cy={`revenue-actuals-card-${
        curves?.length > 0 ? "filled" : "empty"
      }`}
    >
      <ActualsTemplate
        aggregationControls={toggleHeader}
        headerText={headerText}
        groupByStrategy={isGroupedByStrategy}
        setGroupByStrategy={setIsGroupedByStrategy}
        mixRange={mixRange}
        setMixRange={setMixRange}
        mixRanges={mixRanges}
        curves={curves}
        explicitRange={explicitRange}
        timeRangeLabel={timeRangeLabel}
      />
    </div>
  );
}

RevenueActualsCard.propTypes = {
  timeRangeDays: PropTypes.number,
  timeRangeLabel: PropTypes.string,
};

export default RevenueActualsCard;
