import React from "react";
import { useCSVDownloader } from "react-papaparse";
import PropTypes from "prop-types";

import PrimaryButton from "library/buttons/PrimaryButton";
import {
  isDownloadableData,
  addDateColumnToDataset,
} from "utils/data/downloading";

/**
 * This component supports the download of data into csv files. The data props may be one of
 * 3 distinct formats, as elaborated by the format checking functions above.
 * */
export default function CSVDownloader(props) {
  const {
    filename = "outpoint_download",
    data,
    label = "Download File",
    ButtonProps = {},
    config = {
      format: null, // or 'field-date' - performance impact if enabled,
      checkMultiDimensionalFields: false, // performance impact if enabled
    },
    startIcon,
    endIcon,
  } = props;

  const formattedData = addDateColumnToDataset(data);

  const { CSVDownloader: Downloader } = useCSVDownloader();
  const isDisabled = !isDownloadableData(data, config);

  if (isDisabled) {
    return (
      <PrimaryButton
        startIcon={startIcon}
        text={label}
        isDisabled={isDisabled}
        {...ButtonProps}
      />
    );
  }

  return (
    <Downloader filename={filename} bom data={formattedData}>
      <PrimaryButton
        startIcon={startIcon}
        endIcon={endIcon}
        text={label}
        isDisabled={isDisabled}
        {...ButtonProps}
      />
    </Downloader>
  );
}

CSVDownloader.propTypes = {
  filename: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string,
  ButtonProps: PropTypes.object,
  config: PropTypes.shape({
    format: PropTypes.any,
    checkMultiDimensionalFields: PropTypes.bool,
  }),
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};
