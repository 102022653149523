import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, Checkbox, Grid } from "@mui/material";
import { partition } from "lodash";

import { createGraphItem, handleExplicitlySetRange } from "utils/graphing/data";
import Header3 from "library/text/headers/Header3";
import LineAndScatterChart, {
  getEasilyExtendableOptions,
} from "library/graphing/LineChart";
import { GRAPH_DATE_FORMAT, TOOLTIP_DATE_FORMAT } from "utils/enums";
import Selector from "library/form/OutPointSelector";
import { CalendarToday } from "@mui/icons-material";
import SubHead from "library/text/headers/SubHead";
import LegendItem from "library/graphing/LegendItem";
import LegendBox from "library/graphing/LegendBox";
import IconText from "library/containers/IconText";
import { dateSecondsToString, mixRangeToNumDays } from "utils/data/dates";
import { BRAND_GRAY_700, OP_COLORS } from "assets/palette";
import { addCommasToNumber } from "utils/data/strings";

const styles = {
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "30px",
  },
  header: {
    paddingBottom: "8px",
    textDecorationStyle: "dotted",
    textDecorationLine: "underline",
  },
  subHead: {
    textTransform: "uppercase",
  },
  subheaderContainer: {
    minHeight: "40px",
  },
  subheaderText: {
    display: "flex",
    alignItems: "center",
    color: BRAND_GRAY_700,
    fontWeight: 400,
  },
  subheaderOptions: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  select: {
    marginLeft: "12px",
  },
  switch: {
    marginLeft: "12px",
  },
};

const ONE_DAY = 60 * 60 * 24 * 1000; // milliseconds
const NUM_TICKS = 30;

const mixRanges = [
  "Last 2 weeks",
  "Last 3 months",
  "Last 6 months",
  "Last year",
];

const timeOptions = [
  {
    key: "All time",
    text: "All time",
    value: 0,
  },
  ...mixRanges.map((range) => ({
    key: `${mixRangeToNumDays[range]}`,
    text: range,
    value: mixRangeToNumDays[range],
  })),
];

function translateRoasPanelToSeries(itemName, sortedRoas = [], numDays = 1) {
  // chunk
  const chunkedRoas = [];
  for (let i = 0; i < sortedRoas.length; i += numDays) {
    chunkedRoas.push(sortedRoas.slice(i, i + numDays));
  }

  const aggregatedRoas = [];
  chunkedRoas.forEach((set) => {
    let totalRoas = 0;
    set.forEach((row) => {
      totalRoas += row[itemName];
    });
    aggregatedRoas.push({
      date: set[0].date,
      [itemName]: totalRoas / numDays,
    });
  });

  return aggregatedRoas
    .reverse()
    .map((row) =>
      createGraphItem(row.date, parseFloat(row[itemName].toFixed(2))),
    );
}

function HistoricalPerformanceGraphs(props) {
  const { historicalRoas, aggregatedRoas } = props;

  const [sortedRoasRange, setSortedRoas] = useState(
    aggregatedRoas || historicalRoas,
  );
  const [displayPeriod, setDisplayPeriod] = useState(0);
  const [usebrandLift, setUsebrandLift] = useState(true);
  const [scatterOn, setScatterOn] = useState(false);
  const [explicitlySetRange, setExplicitlySetRange] = useState([]);
  const [showAggregated, setShowAggregated] = useState(false);
  const backendSortedRoas = showAggregated
    ? aggregatedRoas || historicalRoas
    : historicalRoas;

  useEffect(() => {
    if (backendSortedRoas) {
      let roasWithDate = [...backendSortedRoas];
      // remove data points not during the given display period
      if (displayPeriod > 0) {
        const mostRecentDate = roasWithDate[0].date;
        let rowsToInclude = 0;
        for (
          rowsToInclude;
          rowsToInclude < roasWithDate.length;
          rowsToInclude += 1
        ) {
          if (
            (mostRecentDate - roasWithDate[rowsToInclude].date) / ONE_DAY >=
            displayPeriod
          ) {
            break;
          }
        }
        roasWithDate = roasWithDate.slice(0, rowsToInclude + 1);
        setSortedRoas(roasWithDate);
      } else {
        setSortedRoas(backendSortedRoas);
      }
    }
  }, [displayPeriod, showAggregated, historicalRoas, aggregatedRoas]);

  const [rows, interpolatedRows] = partition(
    sortedRoasRange,
    (e) => !e.interpolated,
  );

  // NOTE: Rows and interpolated rows are sorted opposite! Rows: descending, interpolatedRows: ascending
  let totalDays;
  if (displayPeriod !== 0 || !rows.length) {
    totalDays = displayPeriod;
  } else if (!interpolatedRows.length) {
    totalDays = (rows[0].date - rows[rows.length - 1].date) / ONE_DAY;
  } else {
    totalDays =
      (interpolatedRows[interpolatedRows.length - 1].date -
        rows[rows.length - 1].date) /
      ONE_DAY;
  }

  const timePeriod = Math.max(Math.floor(totalDays / NUM_TICKS), 1);

  const returnSeries =
    sortedRoasRange &&
    translateRoasPanelToSeries(
      usebrandLift ? "lifted_actual_revenue" : "actual_revenue",
      rows,
      timePeriod,
    );

  const spendSeries =
    sortedRoasRange && translateRoasPanelToSeries("spend", rows, timePeriod);

  const returnScatter =
    sortedRoasRange &&
    translateRoasPanelToSeries(
      usebrandLift ? "lifted_actual_revenue" : "actual_revenue",
      rows,
    );

  const spendScatter =
    sortedRoasRange && translateRoasPanelToSeries("spend", rows);

  const returnSeriesInterpolated =
    sortedRoasRange &&
    translateRoasPanelToSeries(
      usebrandLift ? "lifted_actual_revenue" : "actual_revenue",
      interpolatedRows,
      // lines are drawn with a minimum of two points, if there are only two data points
      // with a large period of time in between, you will be unable to draw the line, as the time period interpolated
      // may exceed the number of records. In that case, turn on high granularity of data (time period set to 1)
      interpolatedRows.length === 1 ? 1 : timePeriod,
    );

  const spendSeriesInterpolated =
    sortedRoasRange &&
    translateRoasPanelToSeries(
      "spend",
      interpolatedRows,
      interpolatedRows.length === 1 ? 1 : timePeriod,
    );

  const returnScatterInterpolated =
    sortedRoasRange &&
    translateRoasPanelToSeries(
      usebrandLift ? "lifted_actual_revenue" : "actual_revenue",
      interpolatedRows,
    );

  const spendScatterInterpolated =
    sortedRoasRange && translateRoasPanelToSeries("spend", interpolatedRows);

  if (returnSeries.length && returnSeriesInterpolated.length) {
    const { x, y } = returnSeries[returnSeries.length - 1];
    const [{ xInterpolated }] = returnSeriesInterpolated;

    if (x !== xInterpolated) {
      returnSeriesInterpolated.unshift({ x, y });
    }
  }

  if (spendSeries.length && spendSeriesInterpolated.length) {
    const { x, y } = spendSeries[spendSeries.length - 1];
    const [{ xInterpolated }] = spendSeriesInterpolated;

    if (x !== xInterpolated) {
      spendSeriesInterpolated.unshift({ x, y });
    }
  }

  const curves = [
    {
      linePoints: returnSeries,
      color: OP_COLORS.ROYAL_BLUE,
      name: "Return",
    },
    {
      linePoints: returnSeriesInterpolated,
      color: OP_COLORS.ROYAL_BLUE,
      name: "Aggregated return",
      applyCustomStyling: true,
    },
    {
      linePoints: spendSeries,
      color: "red",
      name: "Spend",
    },
    {
      linePoints: spendSeriesInterpolated,
      color: "red",
      name: "Aggregated spend",
      applyCustomStyling: true,
    },
  ];

  const scatterCurves = [
    {
      type: "scatter",
      linePoints: returnScatter,
      color: OP_COLORS.ROYAL_BLUE,
      name: "Return scatter",
    },
    {
      type: "scatter",
      linePoints: returnScatterInterpolated,
      color: OP_COLORS.ROYAL_BLUE,
      name: "Aggregated return scatter",
    },
    {
      type: "scatter",
      linePoints: spendScatter,
      color: "red",
      name: "Spend scatter",
    },
    {
      type: "scatter",
      linePoints: spendScatterInterpolated,
      color: "red",
      name: "Aggregated spend scatter",
    },
  ];

  const [dataset, setDataset] = useState(curves);

  useEffect(() => {
    let totalCurves = curves;

    if (scatterOn) {
      totalCurves = totalCurves.concat(scatterCurves);
    }

    setExplicitlySetRange(handleExplicitlySetRange({ curves: totalCurves }));
    setDataset(totalCurves);
  }, [scatterOn, displayPeriod, sortedRoasRange, usebrandLift, showAggregated]);

  const menuItemsTimePeriod = timeOptions.map(({ text }) => text);
  const menuItemsDisplay = Object.assign(
    ...timeOptions.map(({ value, text }) => {
      return { [value]: text };
    }),
  );

  function handleDropdownChange(e) {
    const { value } = timeOptions.find(({ text }) => text === e);
    setDisplayPeriod(value);
  }

  const historicalGraphOptions = getEasilyExtendableOptions();

  historicalGraphOptions.xScaleOptions.formatTicks = (val) =>
    dateSecondsToString(val, GRAPH_DATE_FORMAT);
  historicalGraphOptions.xScaleOptions.explicitlySetRange =
    explicitlySetRange.length ? explicitlySetRange : null;
  historicalGraphOptions.tooltipPluginOptions.formatTitle = (val) =>
    dateSecondsToString(val, TOOLTIP_DATE_FORMAT);
  historicalGraphOptions.tooltipPluginOptions.label = (context) =>
    `${context.dataset.label}: $${addCommasToNumber(context.parsed.y)}`;

  const renderStartAdornment = (outerProps) => (
    <CalendarToday {...outerProps} />
  );

  return (
    <div>
      <IconText style={styles.headerContainer}>
        <div>
          <Header3 sx={styles.header}>
            Current and aggregated performance
          </Header3>
          <SubHead sx={styles.subheaderText}>
            Analyze trends in spend and return and monitor aggregated
            performance.
          </SubHead>
        </div>
        <FormControl sx={styles.select}>
          <Selector
            disabled={!backendSortedRoas?.length}
            onChange={(e) => handleDropdownChange(e)}
            selectedValue={menuItemsDisplay[displayPeriod]}
            menuItems={menuItemsTimePeriod}
            StartAdornment={renderStartAdornment}
          />
        </FormControl>
      </IconText>
      <IconText style={styles.headerContainer}>
        <SubHead sx={styles.subHead}>return over time</SubHead>
        <div>
          <FormControlLabel
            sx={styles.switch}
            control={
              <Checkbox
                checked={scatterOn}
                onChange={() => setScatterOn(!scatterOn)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Scatter plots"
          />
          <FormControlLabel
            sx={styles.switch}
            control={
              <Checkbox
                checked={usebrandLift}
                onChange={() => setUsebrandLift(!usebrandLift)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Include lift"
          />
          {(Boolean(interpolatedRows.length) || !showAggregated) && (
            <FormControlLabel
              sx={styles.switch}
              control={
                <Checkbox
                  checked={showAggregated}
                  onChange={() => setShowAggregated(!showAggregated)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Show aggregations"
            />
          )}
        </div>
      </IconText>
      {spendSeries && returnSeries && (
        <>
          <LineAndScatterChart
            data={dataset}
            moreOptions={historicalGraphOptions}
            graphStyle={{
              maxHeight: "600px",
            }}
          />
          <Grid
            container
            data-cy={`ci-hist-graph-legend-${
              spendSeries.length > 0 && returnSeries.length > 0
                ? "populated"
                : "loading"
            }`}
          >
            <Grid item xs={12} sm={6}>
              <LegendBox title="CURRENT">
                {dataset.map(({ color, name }) => {
                  if (name.toLowerCase().includes("aggregated")) return null;

                  return (
                    <LegendItem
                      title={name}
                      key={name + color}
                      iconColor={color}
                    />
                  );
                })}
              </LegendBox>
            </Grid>
            {Boolean(interpolatedRows.length) && (
              <Grid item xs={12} sm={6}>
                <LegendBox title="AGGREGATIONS">
                  {dataset.map(({ color, name }) => {
                    if (!name.toLowerCase().includes("aggregated")) return null;

                    return (
                      <LegendItem
                        title={name.replace("Aggregated ", "")}
                        key={name + color}
                        iconColor={color}
                        icon="circle"
                      />
                    );
                  })}
                </LegendBox>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </div>
  );
}

HistoricalPerformanceGraphs.propTypes = {
  historicalRoas: PropTypes.array,
  aggregatedRoas: PropTypes.array,
};

export default HistoricalPerformanceGraphs;
