import { Button } from "@mui/material";
import { BRAND_GRAY_100 } from "assets/palette";
import React from "react";
import PropTypes from "prop-types";

const styles = {
  activeButton: {
    boxShadow:
      "0px 0px 2px rgba(143, 140, 140, 0.2), 0px 2px 10px rgba(143, 140, 140, 0.1)",
    marginRight: "3px",
  },
  inactiveButton: {
    backgroundColor: BRAND_GRAY_100,
    marginRight: "3px",
  },
  buttonGroup: {
    backgroundColor: BRAND_GRAY_100,
    padding: "3px 0 3px 3px",
    borderRadius: "4px",
    display: "inline-block",
  },
};

function OutPointButtonGroup(props) {
  const { values, curState, setter, className = "", style, ...rest } = props;

  return (
    <div
      className={className}
      style={{ ...styles.buttonGroup, ...style }}
      {...rest}
    >
      {values.map((curVal) => (
        <Button
          key={curVal}
          variant={curState === curVal ? "contained" : "text"}
          onClick={() => setter(curVal)}
          color={curState === curVal ? "base" : "grey"}
          sx={curState === curVal ? styles.activeButton : styles.inactiveButton}
        >
          {curVal}
        </Button>
      ))}
    </div>
  );
}

export default OutPointButtonGroup;

OutPointButtonGroup.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string),
  curState: PropTypes.string,
  setter: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};
