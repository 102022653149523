/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";

import { Link, Card } from "@mui/material";
import { Help, Info } from "@mui/icons-material";

import BodyText from "library/text/body/BodyText";
import Hyperlink from "library/text/body/Hyperlink";
import Header2 from "library/text/headers/Header2";
import IconText from "library/containers/IconText";
import SubHead from "library/text/headers/SubHead";
import OutPointModal from "library/surface/ModalCentered";
import { PERIWINKLE_BLUE } from "assets/palette";
import PrimaryButton from "library/buttons/PrimaryButton";
import { URLS } from "utils/enums";
import PropTypes from "prop-types";

const styles = {
  hyperlink: {
    color: PERIWINKLE_BLUE,
    textDecoration: "underline",
    fontWeight: 600,
  },
  infoCard: {
    backgroundColor: `${PERIWINKLE_BLUE}1A`,
    padding: "20px 15px 20px 15px",
    marginBottom: "30px",
    borderRadius: "10px",
    boxShadow: "none",
  },
  infoIcon: {
    color: "#5F75EE",
    marginRight: "15px",
  },
  doneButton: {
    width: "40%",
  },
};

const BODY_PARAGRAPHS = [
  {
    title: "Audience overlap",
    body: " How effectively can a specific channel help capture your target audience?",
  },
  {
    title: "Strategy overlap",
    body: "Are you able to implement the strategy you’d like to use?",
  },
  {
    title: "Timeline overlap",
    body: "How long will it take to develop new creatives for a specific channel, and how long will it take before these ads have an impact? ",
  },
  {
    title: "Creative reuse",
    body: "Will you be able to reuse any current creative with minimal effort on a new channel?",
  },
  {
    title: "Budget overlap",
    body: "When it comes to creating new ads, how efficient will your budget be?",
  },
];

function QuestionMarkText({ greyText, blueText, classes, onClick }) {
  return (
    <IconText style={{ margin: "12px 0 12px 0" }}>
      <Help
        style={{ fontSize: "15", color: PERIWINKLE_BLUE, marginRight: "5px" }}
      />
      <BodyText
        color="secondary"
        style={{ margin: "10px 0 10px 0", textAlign: "center" }}
      >
        {greyText}{" "}
        <Link
          component="button"
          onClick={onClick}
          className={classes.hyperlink}
        >
          {blueText}
        </Link>
      </BodyText>
    </IconText>
  );
}

QuestionMarkText.propTypes = {
  greyText: PropTypes.string,
  blueText: PropTypes.string,
  classes: PropTypes.object,
  onClick: PropTypes.func,
};

export default function WantToKnow() {
  const getInTouch = (
    <Hyperlink href={URLS.GET_IN_TOUCH_SURVEY} target="_blank">
      Get in touch
    </Hyperlink>
  );

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <OutPointModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        sx={styles.card}
      >
        <div>
          <Header2>How this works</Header2>
          <Card sx={styles.infoCard}>
            <IconText>
              <Info sx={styles.infoIcon} />
              <BodyText color="secondary">
                We rank channels based your needs and our scoring system, which
                helps answer some key questions.
              </BodyText>
            </IconText>
          </Card>
          {BODY_PARAGRAPHS.map(({ title, body }) => (
            <React.Fragment key={title}>
              <SubHead color="secondary" style={{ marginBottom: "5px" }}>
                {title}
              </SubHead>
              <BodyText color="secondary" style={{ marginBottom: "20px" }}>
                {body}
              </BodyText>
            </React.Fragment>
          ))}
        </div>
        <QuestionMarkText
          greyText="Still have questions?"
          blueText={getInTouch}
          onClick={() => setModalOpen(false)}
          classes={styles}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <PrimaryButton
            onClick={() => setModalOpen(false)}
            sx={styles.doneButton}
          >
            Done
          </PrimaryButton>
        </div>
      </OutPointModal>
      <QuestionMarkText
        greyText="Want more info on how we rank channels?"
        blueText="Get a quick overview"
        onClick={() => setModalOpen(true)}
        classes={styles}
      />
    </>
  );
}
