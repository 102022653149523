//
// Methods in this file should handle string related processing.
//
import { CUSTOM_CHANNEL_REGEX } from "utils/regex";

/* Capitalize the first character of the given string and returns it */
export function capitalize(s) {
  if (s.length > 1) return s[0].toUpperCase() + s.slice(1);

  return s[0].toUpperCase();
}

/* Given an array of items, generate a string which reads as a sentence */
export function genItemsString(arr) {
  const len = arr.length;
  const newArr = arr.slice();

  if (len > 1) {
    newArr[len - 1] = `and ${newArr[len - 1]}`;
  }

  if (len === 2) {
    return newArr.join(" ");
  }
  return newArr.join(", ");
}

export function sentenceCase(s) {
  if (!s || typeof s !== "string") return s;
  if (s.length === 1) return s.toUpperCase();
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export function isValidEmail(email) {
  // TODO: make more robust
  if (typeof email !== "string") return false;
  return email.includes("@");
}

/**
 * Validates custom channel provided by the user
 * */
export function isValidCustomChannel(channel) {
  if (typeof channel !== "string") {
    return false;
  }

  return CUSTOM_CHANNEL_REGEX.test(channel);
}

/* Given a string (eg. "Hello world"), return it in a label format (eg. "hello-world") */
export function createHTMLLabel(s) {
  return s
    .split(" ")
    .map((item) => item.toLowerCase())
    .join("-");
}

export function addCommasToNumber(number) {
  // user facing friendly display for NaN (friendlier than ,nan or NaN, and may be better than zero (0), since 0 is still a value)
  if (Number.isNaN(number)) return "--";

  // NOTE, possible region specific locale support
  return Number(number).toLocaleString("en-US");
}

export function reverseString(str) {
  return str.split("").reverse().join("");
}

export const stringToTitleCase = (str = "") => {
  if (!str) return str;

  return str
    .split(" ")
    .map((word) => {
      const lowerWord = word.toLowerCase();
      return lowerWord.charAt(0).toUpperCase(0) + lowerWord.substring(1);
    })
    .join(" ");
};

export const kDigitConversion = (number, leadingDecorator = "$") => {
  if (Number.isNaN(number)) return null;

  const thousandthNum = Number(number) / 1000;

  if (thousandthNum < 0) return null;

  return `${leadingDecorator}${parseFloat(thousandthNum.toFixed(2))}K`;
};

export const normalizeChannelName = (channelName) => {
  return channelName?.toLowerCase().replaceAll("_", " ").replaceAll("-", " ");
};

/**
 * Returns true if inputString is a substring of the testString.
 * */
export const isSubstringOf = (testString, inputString, options = {}) => {
  const inputsAreStrings = [testString, inputString].every(
    (s) => typeof s === "string" || s instanceof String,
  );

  if (!inputsAreStrings) {
    // eslint-disable-next-line no-console
    console.assert(
      inputsAreStrings,
      "Inputs to [isSubstringOf] are not strings",
    );

    return false;
  }

  const testSubstring = (target, query) =>
    Boolean(target.indexOf(query) !== -1);

  const { isCaseInsensitive = false } = options;

  if (isCaseInsensitive) {
    return testSubstring(testString.toLowerCase(), inputString.toLowerCase());
  }

  return testSubstring(testString, inputString);
};

export const isValidNumber = (string) => {
  const regex = /^[0-9]*$/;
  return regex.test(string);
};
