import React, { useEffect, useState, useMemo } from "react";

import { useSelector } from "react-redux";

import SpendPage from "pages/manual_upload/SpendPage";
import { makeAuthenticatedPutRequest } from "utils/backend-api";
import RevenuePage from "pages/manual_upload/RevenuePage";
import OnboardingPageTemplate from "./OnboardingPageTemplate";
import StartPage from "./components/StartPage";
import SuccessPage from "./components/SuccessPage";
import GoalsPage from "./components/GoalsPage";
import LtvPage from "./components/LtvPage";
import ConfirmPage from "./components/ConfirmPage";
import CampaignsPage from "./components/CampaignsPage";

import ChoosePlatformPageTemplate from "./components/ChoosePlatformPageTemplate";

const pageMap = [
  "startPage",
  "ltvPage",
  "goalsPage",
  "chooseRevenueDataPlatformPage",
  "chooseAdSpendPlatformPage",
  "revenuePage",
  "spendPage",
  "campaignsPage",
  "confirmPage",
  "successPage",
];

const stepperLabels = [
  "Customer LTV",
  "Scenario planning",
  "Revenue tools",
  "Ad spend tools",
  "Revenue upload",
  "Ad Spend upload",
  "Campaign groupings",
  "Confirmation",
];

const getPageIndex = (currPage) => {
  return pageMap.findIndex((element) => element === currPage);
};

const getNextPage = (currPage) => {
  const currPageIndex = getPageIndex(currPage);
  if (currPageIndex === -1 || currPageIndex === pageMap.length - 1) {
    return currPage;
  }
  return pageMap[currPageIndex + 1];
};

const getPrevPage = (currPage) => {
  const currPageIndex = getPageIndex(currPage);
  if (currPageIndex === -1 || currPageIndex === 0) {
    return currPage;
  }
  return pageMap[currPageIndex - 1];
};

const getPages = (updatePage, setActivePage) => ({
  ltvPage: {
    Component: LtvPage,
    activeStep: getPageIndex("ltvPage") - 1,
    headerText: "What is the lifetime value (LTV) of your customers?",
    secondaryText:
      "This helps us determine spend levels and recommendations for you that are more accurate.",
    nextButtonText: "Next",
    nextButtonOnClick: () => updatePage(getNextPage("ltvPage"), setActivePage),
  },
  goalsPage: {
    Component: GoalsPage,
    activeStep: getPageIndex("goalsPage") - 1,
    headerText: "What type of spend recommendations would you like to see?",
    secondaryText:
      "Choose the option that best aligns with your goals " +
      "and we’ll help you plan for different scenarios. " +
      "You can change this and run custom scenarios once your account has been created.",
    nextButtonText: "Next",
    nextButtonOnClick: () =>
      updatePage(getNextPage("goalsPage"), setActivePage),
    backButtonText: "Back",
    backButtonOnClick: () =>
      updatePage(getPrevPage("goalsPage"), setActivePage),
  },
  chooseRevenueDataPlatformPage: {
    Component: (props) => (
      <ChoosePlatformPageTemplate {...props} platforms="revenue" />
    ),
    activeStep: getPageIndex("chooseRevenueDataPlatformPage") - 1,
    headerText: "What tools do you use to track and analyze revenue?",
    secondaryText:
      "Easily integrate your revenue data with OutPoint by connecting at least one platform. You can change or remove your selections any time.",
    nextButtonText: "Next",
    nextButtonOnClick: () =>
      updatePage(getNextPage("chooseRevenueDataPlatformPage"), setActivePage),
    backButtonText: "Back",
    backButtonOnClick: () =>
      updatePage(getPrevPage("chooseRevenueDataPlatformPage"), setActivePage),
  },
  chooseAdSpendPlatformPage: {
    Component: (props) => (
      <ChoosePlatformPageTemplate {...props} platforms="ads" />
    ),
    activeStep: getPageIndex("chooseAdSpendPlatformPage") - 1,
    headerText: "Connect your ad spend platform",
    nextButtonText: "Next",
    nextButtonOnClick: () =>
      updatePage(getNextPage("chooseAdSpendPlatformPage"), setActivePage),
    backButtonText: "Back",
    backButtonOnClick: () =>
      updatePage(getPrevPage("chooseAdSpendPlatformPage"), setActivePage),
  },
  spendPage: {
    Component: () => <SpendPage whereFrom="onboarding" />,
    activeStep: getPageIndex("spendPage") - 1,
    headerText: "Upload your past ad spend data",
    nextButtonText: "Next",
    nextButtonOnClick: () =>
      updatePage(getNextPage("spendPage"), setActivePage),
    backButtonText: "Back",
    backButtonOnClick: () =>
      updatePage(getPrevPage("spendPage"), setActivePage),
  },
  revenuePage: {
    Component: () => <RevenuePage whereFrom="onboarding" />,
    activeStep: getPageIndex("revenuePage") - 1,
    headerText: "Upload your past revenue data",
    nextButtonText: "Next",
    nextButtonOnClick: () =>
      updatePage(getNextPage("revenuePage"), setActivePage),
    backButtonText: "Back",
    backButtonOnClick: () =>
      updatePage(getPrevPage("revenuePage"), setActivePage),
  },
  campaignsPage: {
    Component: CampaignsPage,
    activeStep: getPageIndex("campaignsPage") - 1,
    headerText: "Select your campaign groupings",
    nextButtonText: "Next",
    nextButtonOnClick: () =>
      updatePage(getNextPage("campaignsPage"), setActivePage),
    backButtonText: "Back",
    backButtonOnClick: () =>
      updatePage(getPrevPage("campaignsPage"), setActivePage),
  },
  confirmPage: {
    Component: ConfirmPage,
    activeStep: getPageIndex("confirmPage") - 1,
    headerText: "Confirm your account details",
    secondaryText:
      "Make sure everything looks correct before submitting your account information.",
    nextButtonText: "Confirm and submit",
    nextButtonOnClick: () => updatePage("successPage", setActivePage),
    backButtonText: "Back",
    backButtonOnClick: () =>
      updatePage(getPrevPage("confirmPage"), setActivePage),
  },
  successPage: {
    Component: SuccessPage,
    activeStep: getPageIndex("successPage") - 1,
  },
});

async function updateOnboardingPage(stage, setActivePage) {
  const data = {
    userpool_id: process.env.REACT_APP_USER_POOL_ID,
    stage,
  };
  makeAuthenticatedPutRequest("onboardingflow", data);
  setActivePage(stage);
}

export default function OnboardingPage() {
  const onboardingStage = useSelector((state) => state.onboarding);
  const [activePage, setActivePage] = useState(null);

  // prevents empty url fragments in addressbar:
  useEffect(() => {
    if (onboardingStage.status === "success") {
      setActivePage(onboardingStage.object.stage);
    }
  }, [onboardingStage.object.stage, onboardingStage.status]);

  const pages = useMemo(
    () => getPages(updateOnboardingPage, setActivePage),
    [setActivePage],
  );

  if (activePage === "startPage" || (activePage && !pages[activePage])) {
    return <StartPage onClick={() => updateOnboardingPage("ltvPage")} />;
  }

  if (activePage) {
    return (
      <OnboardingPageTemplate
        {...pages[activePage]}
        setPage={setActivePage}
        stepperLabels={stepperLabels}
      />
    );
  }

  return <div />;
}
