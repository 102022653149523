import axios from "axios";

// TODO: suggest to use JSON.stringify() instead of this to support the more general case   -- https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify @Thomas
function jsonToString(jsonObj) {
  let s = "{";
  Object.keys(jsonObj).forEach((key) => {
    s += `"${key}":"${jsonObj[key]}"`;
  });
  s += "}";
  return s;
}

export async function jesterPostRequest(route, payload) {
  const Token = localStorage.getItem("Token");
  if (payload.credentials) {
    // eslint-disable-next-line no-param-reassign
    payload.credentials = jsonToString(payload.credentials);
  }

  const result = await axios.post(
    `${process.env.REACT_APP_JESTER_DOMAIN}/${route}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    },
  );
  return result;
}

export async function jesterGetRequest(route) {
  const Token = localStorage.getItem("Token");
  const result = await axios.get(
    `${process.env.REACT_APP_JESTER_DOMAIN}/${route}`,
    {
      headers: {
        Authorization: `Bearer ${Token}`,
      },
    },
  );
  return result.data;
}

export async function goToURL(channelName, name, whereFrom) {
  try {
    const req = {
      channel: channelName,
      name,
      from: whereFrom,
    };
    const result = await jesterPostRequest("connect", req);
    window.location.href = result.data.url;
  } catch {
    /* empty */
  }
}

export const deleteCredentials = async (channel, name) => {
  try {
    const response = await jesterPostRequest("credentials", {
      channel,
      name,
      delete: true,
    });
    if (response.data?.success) {
      return true;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
  }

  return false;
};
