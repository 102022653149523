import React from "react";
import PropTypes from "prop-types";
import {
  registerChartJsComponents,
  createOptionsObj,
  createScaleOptions,
  getDefaultTooltipOptions,
  getDefaultDatasetProps,
} from "library/graphing/ChartJsHelpers";
import { Line } from "react-chartjs-2";
import { kDigitConversion, addCommasToNumber } from "utils/data/strings";

import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import { getMinMaxValuesFromDataSet } from "../../../utils/data/arrays";
import { tickFormatters, tooltipTitleFormatters } from "../OverviewHelpers";

registerChartJsComponents([
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
]);

function ReturnAndSpendGraph(props) {
  const { spendCurves, returnCurves, timeRangeLabel } = props;
  const defaultDatasetProps = {
    ...getDefaultDatasetProps(),
    pointRadius: 0,
  };
  const spendDatasets = spendCurves.map((curve) => ({
    ...defaultDatasetProps,
    ...curve,
    label: "Spend",
    backgroundColor: curve.color,
    borderColor: curve.color,
    data: curve.linePoints,
    yAxisID: "ySpend",
  }));

  const returnDatasets = returnCurves.map((curve) => ({
    ...defaultDatasetProps,
    ...curve,
    label: "Revenue",
    backgroundColor: curve.color,
    borderColor: curve.color,
    data: curve.linePoints,
    yAxisID: "yReturn",
  }));

  const { maxPoint: maxSpendPoint } = getMinMaxValuesFromDataSet(
    spendDatasets[0]?.linePoints || [],
  );
  const { maxPoint: maxRevenuePoint } = getMinMaxValuesFromDataSet(
    returnDatasets[0]?.linePoints || [],
  );

  const datasets = spendDatasets.concat(returnDatasets);

  const xAxisScaleOptions = createScaleOptions(
    "x",
    {
      // x-axis' title props:
    },
    {
      // x-axis' tick props:
      callback: function xTicksNamedCallback(value) {
        // NOTE: the named function here allows us to capture the current closure and hence access this.*
        // eslint-disable-next-line react/no-this-in-sfc -- can't find another way to get the default function within chartjs
        return tickFormatters[timeRangeLabel](this.getLabelForValue(value));
      },
      autoSkip: true,
      maxTicksLimit: 8,
    },
    {
      // other x axis props:
      grid: {
        display: false,
      },
    },
  );

  const ySpendAxisScaleOptions = createScaleOptions(
    "ySpend",
    {
      // y-axis' title props:
      display: true,
      align: "center",
      text: "Spend ($)",
    },
    {
      /* y-axis' tick props */
      autoSkip: false,
      callback: function namedCallback(value) {
        return kDigitConversion(value);
      },
    },
    {
      // other y axis props:
      type: "linear",
      position: "left",
      axis: "y",
      grace: "2%",
      /// scales spend axis to be lower than return axis, while maintaining auto-fit behaviour:
      suggestedMax: (maxRevenuePoint.y / maxSpendPoint.y) * maxSpendPoint.y,
    },
    // isCustomAxis?
    true,
  );

  const yReturnAxisScaleOptions = createScaleOptions(
    "yReturn",
    {
      // y-axis' title props:
      display: true,
      align: "center",
      text: "Return ($)",
    },
    {
      /* y-axis' tick props */
      autoSkip: false,
      callback: function namedCallback(value) {
        return kDigitConversion(value);
      },
    },
    {
      // other y axis props:
      type: "linear",
      axis: "y",
      grace: "2%",
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
    // isCustomAxis?
    true,
  );
  const scaleOptions = {
    x: xAxisScaleOptions,
    yReturn: yReturnAxisScaleOptions,
    ySpend: ySpendAxisScaleOptions,
  };
  const pluginOptions = {
    tooltip: {
      ...getDefaultTooltipOptions(),
      callbacks: {
        label: (context) => {
          const { dataset, dataIndex } = context || {};
          const value = dataset.data[dataIndex].y;

          if (!(Number(value) && dataIndex && dataset)) return null;

          const formattedVal = addCommasToNumber(Number(value).toFixed(2));
          return `${context.dataset.label}: $${formattedVal}`;
        },
        title: (context) => {
          const { label: dateString } = context[0];

          const formattedTitle = tooltipTitleFormatters[timeRangeLabel](
            Number(dateString),
          );

          return formattedTitle;
        },
      },
    },
  };

  const graphOptions = createOptionsObj(scaleOptions, pluginOptions, {
    /// other options
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
  });
  const xLabels = spendCurves?.[0]?.linePoints.map((point) => point.x);
  const data = {
    labels: xLabels,
    datasets,
  };

  return <Line options={graphOptions} data={data} />;
}

ReturnAndSpendGraph.propTypes = {
  spendCurves: PropTypes.array,
  returnCurves: PropTypes.array,
  timeRangeLabel: PropTypes.string,
};

export default ReturnAndSpendGraph;
