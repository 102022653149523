import React from "react";
import { Card } from "@mui/material";
import PropTypes from "prop-types";
import { OP_COLORS } from "assets/palette";

const styles = {
  card: {
    borderRadius: "8px",
    flexGrow: 1,
    height: "100%",
    boxShadow: `0px 2px 1px ${OP_COLORS.BLUE_BOX_SHADOW_5_PERCENT}, 0px 0px 1px ${OP_COLORS.BLUE_BOX_SHADOW_25_PERCENT}`,
  },
};

export default function OutPointCard({
  children,
  className,
  sx = {},
  ...rest
}) {
  return (
    <Card sx={{ ...styles.card, ...sx }} className={className} {...rest}>
      {children}
    </Card>
  );
}

OutPointCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  sx: PropTypes.object,
};
