import React from "react";
import { Switch } from "@mui/material";
import PropTypes from "prop-types";

export default function OutPointToggle({ checked, onChange, ...rest }) {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      inputProps={{ "aria-label": "controlled" }}
      {...rest}
      sx={{
        "& .MuiSwitch-thumb": {
          width: 10,
          height: 10,
          margin: "5px",
        },
        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
          backgroundColor: "primary",
          opacity: "100%",
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
          color: "white",
        },
      }}
    />
  );
}

OutPointToggle.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
