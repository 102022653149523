/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  makeAuthenticatedGetRequest,
  makeAuthenticatedPostRequest,
} from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getOverview = createAsyncThunk("asyncRedux/overview", async () => {
  return makeAuthenticatedGetRequest("overview");
});

export const postMetadata = createAsyncThunk(
  "asyncRedux/metadata",
  async (data) => {
    if (!data) return false;

    return makeAuthenticatedPostRequest("/metadata", data);
  },
);

const overviewSlice = createSlice({
  name: "overviewSlice",
  initialState: {
    data: null,
    status: null,
    authError: null,
  },
  reducers: {
    overviewLogOut: (state) => {
      state.data = null;
      state.status = null;
      state.authError = null;
    },
    updateMetadata: (state, { payload }) => {
      Object.entries(payload).forEach(([field, data]) => {
        state.data[field] = data;
      });
    },
  },
  extraReducers: {
    [getOverview.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getOverview.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getOverview.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
    [postMetadata.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [postMetadata.fulfilled]: (state, { payload }) => {
      const { data } = payload.data;

      state.data = data?.item;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [postMetadata.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const { overviewLogOut, updateMetadata } = overviewSlice.actions;

export default overviewSlice.reducer;
