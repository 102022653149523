/**
 * This file contains all the validators used in the application.
 * Examples of types of validators:
 * 1. input validators
 * 2. data format validators
 *
 * Note: Please ensure that every input in the application is validated.
 * */

import { STD_ASCII_CHAR_REGEX, EXTENDED_ASCII_CHAR_REGEX } from "utils/regex";

/**
 * Returns true if string characters are all ascii.
 * */
function isAsciiString(str, extended = false) {
  return (extended ? EXTENDED_ASCII_CHAR_REGEX : STD_ASCII_CHAR_REGEX).test(
    str,
  );
}

/**
 * Returns true if the string is safe:
 * */
export function validateGenericString(str = "") {
  if (!str) {
    return false;
  }

  if (typeof str !== "string") {
    return false;
  }

  const trimmed = str.trim();

  if (!trimmed) {
    return false;
  }

  if (!isAsciiString(trimmed)) {
    return false;
  }

  return true;
}

/**
 * Valid campaign group/strategy names are generic valid strings.
 *
 * Note:
 *  - Surrounding spaces are allowing at input but when saving state, the string should be trimmed.
 * */
export function isValidName(groupName) {
  return validateGenericString(groupName);
}
