import React from "react";
import PropTypes from "prop-types";
import {
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";

import OPAutoComplete from "library/form/OutPointAutoComplete";

const styles = {
  selector: {
    width: 200,
    m: 1,
  },
  textFieldStyle: {
    borderRadius: "100px",
  },
};

/**
 * Customized Free-Solo implementation that allows autocomplete-based dropdown selection
 * and adding of new options via a dialog.
 * */
export default function OPAutoCompleteDynamic(props) {
  const {
    name,
    options,
    margin = "dense",
    handleSelection,
    isValidInput,
    textFieldLabel,
    dialogTextFieldLabel,
    dialogTitle,
    dialogContentText,
    selectorClassName,
    sx = {},
    textFieldSx = {},
    ...rest
  } = props;

  const [open, toggleOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [dialogValue, setDialogValue] = React.useState("");

  const displayedDialogTextFieldLabel = dialogTextFieldLabel || (
    <Typography>{textFieldLabel}</Typography>
  );

  const handleClose = () => {
    setDialogValue("");
    toggleOpen(false);
  };

  const onAutoCompleteInputChange = (e, val) => {
    if (!val || !isValidInput(val)) {
      return;
    }

    if (!options.includes(val)) {
      /// timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOpen(true);
        setDialogValue(val);
      });
      return;
    }
    setValue(val);
    handleSelection(val);
  };

  const handleDialogFormSubmit = () => {
    handleSelection(dialogValue);
    handleClose();
  };

  return (
    <>
      <OPAutoComplete
        name={`${name}-autocomplete-${value}`}
        key="dynamicAutoComplete"
        options={options}
        margin={margin}
        value={value}
        autoComplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        openOnFocus
        forcePopupIcon
        className={selectorClassName || ""}
        sx={{ ...styles.selector, ...sx }}
        renderInput={(params) => (
          <TextField
            sx={{ ...styles.textFieldStyle, ...textFieldSx }}
            {...params}
            label={value || textFieldLabel}
          />
        )}
        onChange={onAutoCompleteInputChange}
        {...rest}
      />
      {/** TODO: might be good to use OPDialog component here */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id={`${name}-text`}
            value={dialogValue}
            onChange={(event) => {
              setDialogValue(event.target.value);
            }}
            label={displayedDialogTextFieldLabel}
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={!isValidInput(dialogValue)}
            type="submit"
            color="primary"
            sx={styles.button}
            onClick={handleDialogFormSubmit}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

OPAutoCompleteDynamic.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  margin: PropTypes.string,
  handleSelection: PropTypes.func,
  isValidInput: PropTypes.func,
  textFieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dialogTitle: PropTypes.string,
  dialogContentText: PropTypes.string,
  dialogTextFieldLabel: PropTypes.string,
  selectorClassName: PropTypes.string,
  sx: PropTypes.object,
  textFieldSx: PropTypes.object,
};
