import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
} from "amazon-cognito-identity-js";
import { authSuccess, authFailed } from "redux/loginSlice";
import axios from "axios";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";

import { featuresLogOut } from "redux/featuresSlice";
import { recomLogOut } from "redux/recommendationsSlice";
import { jesterLogOut } from "redux/jesterSlice";
import { onboardingLogOut } from "redux/onboardingSlice";
import { reportingLogOut } from "redux/reportingSlice";
import { overviewLogOut } from "redux/overviewSlice";
import { spendLogOut } from "redux/spendSlice";
import { predictionsLogOut } from "redux/predictionsSlice";
import { newChannelLogOut } from "redux/newChannelRecommendationsSlice";

const userPoolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
};
const userPool = new CognitoUserPool(userPoolData);

export const getToken = (_) => localStorage.getItem("Token");
const setToken = (token) => localStorage.setItem("Token", token);
const removeToken = (_) => localStorage.removeItem("Token");
const setIDToken = (token) => localStorage.setItem("IDToken", token);
const removeIDToken = (_) => localStorage.removeItem("IDToken");

export const authenticateUser = (email, password, dispatch) => {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });
  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        dispatch(authSuccess());
        const Token = data.getAccessToken().getJwtToken();
        const IDToken = data.getIdToken().getJwtToken();
        setToken(Token);
        setIDToken(IDToken);
        resolve("Authenticated");
      },
      onFailure: (err) => {
        dispatch(authFailed());
        reject(err.message);
      },
      // TODO: Is this a success or not a success
      newPasswordRequired: (_) => {
        resolve("New Password Required");
      },
    });
  });
};

export const logOut = (dispatch) => {
  dispatch(authFailed());
  removeToken();
  removeIDToken();
  dispatch(recomLogOut());
  dispatch(predictionsLogOut());
  dispatch(jesterLogOut());
  dispatch(onboardingLogOut());
  dispatch(reportingLogOut());
  dispatch(overviewLogOut());
  dispatch(featuresLogOut());
  dispatch(spendLogOut());
  dispatch(newChannelLogOut());

  if (window.Cypress && authFailed) {
    window.isLoggedOut = true;
  }

  window.location.pathname = "/login";
};

export const authenticateToken = async (dispatch) => {
  const token = getToken();
  if (!token) {
    dispatch(authFailed());
    return false;
  }
  try {
    const result = await axios.get(`${process.env.REACT_APP_DOMAIN}/auth`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const success = result.data.message === "Authenticated";
    if (success) {
      dispatch(authSuccess());
    } else {
      logOut(dispatch);
    }
    return success;
  } catch {
    logOut(dispatch);
    return false;
  }
};

/**
 * NOTE:
 * Returns true if a token exists, without checking it's actual validity
 * */
export const tokenValid = () => {
  try {
    const token = getToken();
    if (token) {
      const decoded = jwt_decode(token);
      const now = Date.now() / 1000;
      const { exp } = decoded;
      return now < exp;
    }
    return false;
  } catch {
    return false;
  }
};
