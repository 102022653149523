import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { uniqBy } from "lodash";
import { Typography, Grid, Chip, Button, TextField } from "@mui/material";
import { matchSorter } from "match-sorter";
import { Search as SearchIcon } from "@mui/icons-material";

import { finderStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import OPAutoComplete from "library/form/OutPointAutoComplete";
import OPAutoCompleteDynamic from "library/form/AutoCompleteDynamic";
import CampaignsFilterForm from "pages/campaigns/components/CampaignsFilterForm";
import PrimaryButton from "library/buttons/PrimaryButton";
import ClearButton from "pages/campaigns/components/ClearButton";

import { isValidName } from "utils/data/validators";
import {
  registerNewCampaignGroup,
  bulkAssignCampaignsToGroup,
  toggleIsSubmitting,
  selectObjects,
  selectObjectProperty,
} from "redux/campaignsSlice";

import { makeAuthenticatedPostRequest } from "utils/backend-api";

const createRenderSearchInput = (label) => {
  const renderSearchInput = (params) => {
    return (
      <TextField
        {...params}
        label={label}
        InputProps={{
          /** ******************************************************* */
          /* GOTCHA: copying over the existing params is req if not */
          /*         things get accidentally overwritten            */
          /*         ref: https://stackoverflow.com/a/58953806      */
          /** ******************************************************* */
          ...params.InputProps,
          sx: styles.searchInputProps,
        }}
      />
    );
  };

  return renderSearchInput;
};

/**
 * Campaigns Finder contains a selector that a user can use to search for specific campaigns that
 * match the currently applied filters, or just bulk-select all these campaigns.
 *
 * The finder also has a form for filters, where multiple filters may be created by the user,
 * the application of which modifies the compositeFilter function that is kept in the finder's state.
 * This filter function is used by the OPAutoComplete component to filter the campaigns options,
 * before calling the fuzzy-matching function.
 *
 * Lastly, the Finder has a free-solo input for the destination campaigns group that all the selected
 * campaigns will be assigned to, whereby if the user inputs a new custom group, then a new campaigns
 * group shall be created.
 * */
function CampaignsFinder(props) {
  const dispatch = useDispatch();

  const { handleCloseDrawer, handleActionSelection } = props;
  const campaigns = useSelector(selectObjects("campaigns"));
  const campaignGroups = useSelector(selectObjects("campaign_groups"));
  const campaignGroupIds = useSelector(
    selectObjectProperty("campaign_groups", "id"),
  );
  const campaignGroupToStrategyMap = useSelector(
    selectObjects("campaign_group_id_to_strategy_id_map"),
  );

  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const selectedCampaignIds = selectedCampaigns
    ? selectedCampaigns.map((x) => x.id)
    : [];

  const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);
  const filteredCampaignIds = filteredCampaigns
    ? filteredCampaigns.map((x) => x.id)
    : [];

  const [destinationGroup, setDestinationGroup] = useState();
  const [compositeFilter, setCompositeFilter] = useState();

  const resetFinderState = () => {
    setSelectedCampaigns([]);
    setDestinationGroup(undefined);
    setCompositeFilter(undefined);
    setFilteredCampaigns(campaigns);
  };

  const onGroupCreatorClick = () => {
    handleActionSelection("addGroup");
  };

  const handleCampaignsSelection = (_, newlySelectedCampaigns) => {
    setSelectedCampaigns(newlySelectedCampaigns);
  };

  /**
   * Handles the select-all event where all the currently filtered campaigns
   * are selected by the user.
   *
   * This select all event appends to the existing selected campaigns instead of
   * overwriting them.
   * */
  const handleFilteredSelection = (select = true) => {
    let newSelectedCampaigns = [];

    if (select) {
      // merges current selections with filtered campaigns and removes duplicates:
      const mergedSelections = [].concat(selectedCampaigns, filteredCampaigns);
      const uniqueMergedSelections = uniqBy(mergedSelections, "id");

      newSelectedCampaigns = uniqueMergedSelections;
    } else {
      // unselect the filtered campaigns:
      newSelectedCampaigns = selectedCampaigns.filter(
        (campaign) => !filteredCampaignIds.includes(campaign.id),
      );
    }

    setSelectedCampaigns(newSelectedCampaigns);
  };

  const handleDestinationGroupSelection = (group) => {
    const isNewGroup = !campaignGroupIds.includes(group);

    if (isNewGroup) {
      dispatch(
        registerNewCampaignGroup({
          groupName: group,
          strategy: group,
          isBrand: false,
        }),
      );
    }
    setDestinationGroup(group);
  };

  const updateFilters = (newFilterPredicate) => {
    setCompositeFilter(newFilterPredicate);
  };

  /**
   * Updates the filteredCampaigns in state whenever the compositeFilter
   * changes.
   * */
  useEffect(() => {
    if (!compositeFilter && typeof compositeFilter !== "function") {
      return;
    }

    const newlyFilteredCampaigns = campaigns.filter(compositeFilter);
    setFilteredCampaigns(newlyFilteredCampaigns);
  }, [compositeFilter]);

  /**
   * When the destination group changes, the currently selected campaigns need
   * to have their parentGroupIds updated to reflect this change. This function
   * either updates the selection (if provided as a param) or the current
   * selectedCampaigns that are stored in component state.
   * */
  const updateGroupForSelectedCampaigns = (
    destinationCampaignGroup,
    selection = null,
  ) => {
    const selected = selection || selectedCampaigns;
    const updatedCampaigns = selected.map((campaign) => ({
      ...campaign,
      campaign_group: destinationCampaignGroup,
      strategy: campaignGroupToStrategyMap[destinationCampaignGroup],
      is_brand: campaignGroups.find(
        (group) => group.id === destinationCampaignGroup,
      ).is_brand,
    }));

    setSelectedCampaigns(updatedCampaigns);
  };

  const handleSubmission = async () => {
    updateGroupForSelectedCampaigns(destinationGroup);
    const assignmentPayload = {
      selectedCampaigns,
      newCampaignGroupId: destinationGroup,
    };

    dispatch(bulkAssignCampaignsToGroup(assignmentPayload));

    try {
      dispatch(toggleIsSubmitting());
      const groupingPostResponse = await makeAuthenticatedPostRequest(
        "campaigns",
        {
          changes: selectedCampaigns.map((campaign) => {
            return {
              ...campaign,
              campaign_group: destinationGroup,
            };
          }),
        },
      );
      const { success: groupingPostSuccess } = groupingPostResponse;

      if (!groupingPostSuccess) {
        const errorMsg =
          "The updating of campaigns grouping to the db was unsuccessful. Please try again or reach out to us.";
        throw new Error(errorMsg);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error when submitting changes for campaign grouping:", e);
    } finally {
      resetFinderState();
      dispatch(toggleIsSubmitting());
      handleCloseDrawer();
    }
  };

  /**
   * Filter function used by the autocomplete component to determine which
   * options to render. This uses the matchSorter() which is a recommended
   * library in the mui documentation.
   *
   * Ref docs: https://github.com/kentcdodds/match-sorter
   * */
  const filterOptions = (options, state) => {
    const { inputValue } = state;
    const matchSorterOptions = {
      keys: ["campaign"],
    };

    const filteredOptions = compositeFilter
      ? options.filter(compositeFilter)
      : options;
    const matchedOptions = matchSorter(
      filteredOptions,
      inputValue,
      matchSorterOptions,
    );

    return matchedOptions;
  };

  const renderCampaignFinderOption = (listProps, option) => {
    return (
      <li {...listProps}>
        {option.campaign} | {option.source} | {option.medium}
      </li>
    );
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        size="small"
        variant="filled"
        label={option.campaign}
        {...getTagProps({ index })}
      />
    ));

  const noOptionsText = (
    <Typography> No matching options were found </Typography>
  );

  const campaignSearchInputPlaceholder = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <SearchIcon
        sx={{
          color: "#2F2F33",
        }}
      />
      <Grid item xs={6}>
        <Typography sx={styles.inputPlaceholderText}>
          Search Campaigns
        </Typography>
      </Grid>
    </Grid>
  );

  const searchBox = (
    <OPAutoComplete
      multiple
      disableCloseOnSelect
      limitTags={1}
      name="Search Campaigns"
      sx={styles.searchBox}
      options={campaigns}
      value={selectedCampaigns}
      filterOptions={filterOptions}
      renderOption={renderCampaignFinderOption}
      noOptionsText={noOptionsText}
      getOptionLabel={(option) => option.id}
      onChange={handleCampaignsSelection}
      renderTags={renderTags}
      renderInput={createRenderSearchInput(campaignSearchInputPlaceholder)}
    />
  );

  const campaignsSelectionIndicator = (
    <Grid
      item
      container
      sx={styles.campaignsSelectionIndicatorContainer}
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography sx={styles.campaignsSelectionIndicatorText}>
        {selectedCampaigns.length} of {campaigns.length} selected
      </Typography>
      <ClearButton handleOnClick={() => setSelectedCampaigns([])} />
    </Grid>
  );

  const campaignsSelector = (
    <Grid
      container
      item
      direction="column"
      sx={styles.campaignsSelectorContainer}
    >
      <Typography sx={styles.subTitleText}>
        What campaign(s) would you like to add to this group?
      </Typography>
      {campaignsSelectionIndicator}
      {searchBox}
    </Grid>
  );

  const isBulkSelectorChecked =
    selectedCampaignIds?.length > 0 &&
    filteredCampaignIds?.length > 0 &&
    filteredCampaignIds.every((id) => selectedCampaignIds.includes(id));

  const isBulkSelectorIndeterminate = filteredCampaigns?.length === 0;

  const filterForm = (
    <Grid item sx={styles.filterFormContainer}>
      <CampaignsFilterForm
        setCompositeFilter={updateFilters}
        handleFilteredSelection={handleFilteredSelection}
        numFilteredCampaigns={filteredCampaigns ? filteredCampaigns.length : 0}
        isBulkSelectorChecked={isBulkSelectorChecked}
        isBulkSelectorIndeterminate={isBulkSelectorIndeterminate}
      />
    </Grid>
  );

  const groupSearchInputPlaceholder = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography sx={styles.inputPlaceholderText}>
        Select Campaign Group
      </Typography>
    </Grid>
  );

  const groupSelector = (
    <Grid container direction="column" sx={styles.groupSelectorContainer}>
      <Typography sx={styles.subTitleText}>
        What group would you like to add campaigns to?
      </Typography>
      <OPAutoCompleteDynamic
        sx={{ width: "100%" }}
        name="Select Group to Assign"
        handleSelection={handleDestinationGroupSelection}
        options={campaignGroupIds}
        value={destinationGroup}
        textFieldLabel={
          <Typography sx={styles.inputPlaceholderText}>
            Select Campaign Group
          </Typography>
        }
        dialogTextFieldLabel={
          <Typography sx={styles.inputPlaceholderText}>
            New campaign group name
          </Typography>
        }
        selectorClassName={styles.groupSelector}
        dialogTitle="Create a new Campaigns Group"
        isValidInput={isValidName}
        renderInput={createRenderSearchInput(groupSearchInputPlaceholder)}
      />
      <Typography sx={styles.groupCreatorText} onClick={onGroupCreatorClick}>
        + Create group
      </Typography>
    </Grid>
  );

  const actionArea = (
    <Grid
      container
      sx={styles.actionArea}
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
    >
      <Button
        sx={{
          ...styles.actionButton,
          ...styles.cancelDrawerButton,
        }}
        variant="outlined"
        onClick={handleCloseDrawer}
      >
        <Typography sx={styles.actionButtonText}>Cancel</Typography>
      </Button>
      <PrimaryButton
        disabled={!(destinationGroup && selectedCampaigns)}
        onClick={handleSubmission}
        variant="contained"
        // TODO: support the styling for disabled
        sx={{
          ...styles.actionButton,
          ...styles.forwardActionButton,
        }}
      >
        <Typography
          sx={{
            ...styles.actionButtonText,
            ...styles.forwardActionButtonText,
          }}
        >
          Add campaigns to group
        </Typography>
      </PrimaryButton>
    </Grid>
  );

  const body = (
    <Grid container item>
      {groupSelector}
      {campaignsSelector}
      {filterForm}
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      sx={styles.campaignsFinderContainer}
      justifyContent="space-between"
    >
      <Grid container direction="column" item sx={styles.contentContainer}>
        <Grid container item sx={styles.labelContainer}>
          <Typography sx={styles.titleText}>
            Find and group campaigns
          </Typography>
        </Grid>
        {body}
      </Grid>
      {actionArea}
    </Grid>
  );
}
CampaignsFinder.propTypes = {
  handleCloseDrawer: PropTypes.func,
  handleActionSelection: PropTypes.func,
};

export default CampaignsFinder;
