import React from "react";
import PropTypes from "prop-types";

import LogoChip from "pages/connections/components/LogoChip";
import SubHeadLight from "library/text/headers/SubHeadLight";
import OutPointCard from "library/surface/OutPointCard";
import { normalizeChannelName } from "utils/data/strings";
import { MINECRAFT_CLAY_GREY } from "assets/palette";
import AddCredentialsButton from "./AddCredentialsButton";

const styles = {
  card: {
    boxShadow: "none",
    borderStyle: "solid",
    position: "relative",
    borderColor: MINECRAFT_CLAY_GREY,
    borderWidth: "1px",
  },
  hoverable: {
    "&:hover": {
      borderColor: "primary.main",
      borderWidth: "1px",
    },
  },
  topContainer: {
    padding: "12px",
  },
  bottomContainer: {
    paddingLeft: "12px",
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "60px",
    width: "100%",
    borderTop: `1px solid ${MINECRAFT_CLAY_GREY}`,
    display: "flex",
    alignItems: "center",
  },
  addButton: {
    color: "grey",
    width: "75px",
  },
  logoChip: {
    marginBottom: "10px",
  },
};

function PlatformCardTemplate(props) {
  const { channel, whereFrom, logo, height = "175px" } = props;

  return (
    <OutPointCard
      style={{ height }}
      sx={{
        ...styles.card,
        ...styles.hoverable,
      }}
    >
      <div style={styles.topContainer}>
        <LogoChip style={styles.logoChip} logo={logo} channel={channel} />
        <SubHeadLight>{normalizeChannelName(channel)}</SubHeadLight>
      </div>
      <div style={styles.bottomContainer}>
        <AddCredentialsButton whereFrom={whereFrom} channel={channel} />
      </div>
    </OutPointCard>
  );
}

PlatformCardTemplate.propTypes = {
  channel: PropTypes.string,
  whereFrom: PropTypes.string,
  logo: PropTypes.string,
  height: PropTypes.string,
};

export default PlatformCardTemplate;
