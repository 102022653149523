import React from "react";
import PropTypes from "prop-types";

import { Button } from "@mui/material";

import Header3 from "library/text/headers/Header3";
import BodyText from "library/text/body/BodyText";
import PrimaryButton from "library/buttons/PrimaryButton";
import OutPointModal from "library/surface/ModalCentered";

const styles = {
  submitButtons: {
    width: "200px",
  },
};
export default function NewChannelRecommendationsFormModal(props) {
  const { modalOpen, setModalOpen, onClose } = props;

  return (
    <OutPointModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      closeButton={false}
    >
      <Header3 style={{ marginBottom: "15px" }}>
        Are you sure you want to exit?
      </Header3>
      <BodyText color="secondary">
        We won’t be able to save your work if you leave this page.
      </BodyText>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 30px 0 30px",
          marginTop: "50px",
        }}
      >
        <Button
          sx={styles.submitButtons}
          variant="text"
          onClick={() => setModalOpen(false)}
        >
          No, go back
        </Button>
        <PrimaryButton sx={styles.submitButtons} onClick={onClose}>
          Discard and exit
        </PrimaryButton>
      </div>
    </OutPointModal>
  );
}

NewChannelRecommendationsFormModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  onClose: PropTypes.func,
};
