import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Grid, Box } from "@mui/material";

import { makeAuthenticatedGetRequest } from "utils/backend-api";
import Title from "library/text/titles/Title";
import Header4 from "library/text/headers/Header4";
import CSVDownloader from "library/buttons/CSVDownloader";
import {
  dateSecondsToString,
  getDateRange,
  formatDate,
} from "utils/data/dates";
import OPDatePicker from "library/form/DatePicker";

import OPAutoComplete from "library/form/OutPointAutoComplete";
import LineAndScatterChart, {
  getEasilyExtendableOptions,
} from "library/graphing/LineChart";
import { getChannelColour } from "assets/colours";
import { GRAPH_DATE_FORMAT, TOOLTIP_DATE_FORMAT } from "utils/enums";
import Spacer from "library/containers/Spacer";

const UPLOAD_HISTORY_ENDPOINT = "/upload-history";

const styles = {
  channelSelector: {
    borderRadius: "100px",
    width: "230px",
  },
  title: {
    fontSize: 28,
  },
  noDisplayMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  downloaderBox: {
    width: "50px",
  },
};

export default function UploadHistoryPage(props) {
  const { uploadType } = props;

  const channelOptions = useSelector(
    (state) => state.overviewData?.data?.valid_channels || [],
  );
  const [curves, setCurves] = useState([]);
  const [xRange, setXRange] = useState([]);
  const [data, setData] = useState();
  const [selectedChannels, setSelectedChannels] = useState();
  const [dateRange, setDateRange] = useState(
    getDateRange({
      options: {
        rangeMeasurement: "day",
        rangeUnit: 90,
      },
    }),
  );

  useEffect(() => {
    let [startDate, endDate] = dateRange;

    const getHistory = async () => {
      const {
        data: {
          raw,
          accumulated_value: accumulatedValue = [],
          upload_dates: uploadDates = [],
        },
      } = await makeAuthenticatedGetRequest(UPLOAD_HISTORY_ENDPOINT, {
        uploadType,
        startDate,
        endDate,
        selectedChannels,
      });

      const rangeCurves = [];

      Object.keys(accumulatedValue).forEach((channel) => {
        const series = {
          linePoints: [],
          name: channel,
          color: getChannelColour(channel),
        };

        series.linePoints = Object.keys(accumulatedValue[channel]).map(
          (dateSpendEntry) => {
            const { value, date } = accumulatedValue[channel][dateSpendEntry];
            return { x: date, y: value };
          },
        );

        rangeCurves.push(series);
      });

      setData(raw);
      setCurves(rangeCurves);
      setXRange(uploadDates);
    };

    if (!startDate || !endDate) {
      throw new Error(
        `Start date or end date doesn't exist, please check your date range ${dateRange}`,
      );
    }

    startDate = formatDate({ date: startDate });
    endDate = formatDate({ date: endDate });
    if (startDate > endDate) {
      // eslint-disable-next-line no-console
      console.warn("start date is after end date!", dateRange);
      return;
    }

    getHistory();
  }, [dateRange, selectedChannels]);

  const setStartDate = (startDate) => {
    const newDateRange = [startDate, dateRange[1]];
    setDateRange(newDateRange);
  };

  const setEndDate = (endDate) => {
    const newDateRange = [dateRange[0], endDate];
    setDateRange(newDateRange);
  };

  const handleChannelsSelection = (e, value) => {
    const channels = value.map((channel) => channel.trim().toLowerCase());
    setSelectedChannels(channels);
  };

  const getDownloadableData = () => {
    const res = [];

    if (!data) {
      return res;
    }

    const customKeysToRemove = ["x", "y", "is_anomalous", "unique_key"];

    Object.entries(data).forEach(([, channelData]) => {
      channelData.forEach((entry) => {
        const formatted = { ...entry }; // NOTE: tolerable performance from this copy :(
        customKeysToRemove.forEach((key) => delete formatted[key]);

        res.push(formatted);
      });
    });

    return res;
  };

  const noDisplayMessage = (
    <div style={styles.noDisplayMessage}>
      <Header4 margin="auto">
        🔍 No Data found for chosen date range and selected channel(s)
      </Header4>
    </div>
  );

  const graphOptions = getEasilyExtendableOptions();

  graphOptions.xScaleOptions = {
    formatTicks: (val) => dateSecondsToString(val, GRAPH_DATE_FORMAT),
    explicitlySetRange: xRange,
  };
  graphOptions.tooltipPluginOptions = {
    formatTitle: (val) => dateSecondsToString(val, TOOLTIP_DATE_FORMAT),
  };

  const graphDisplay =
    curves.length > 0 ? (
      <>
        <LineAndScatterChart data={curves} moreOptions={graphOptions} />
        <Box sx={styles.downloaderBox}>
          <CSVDownloader
            label="export"
            data={getDownloadableData()}
            filename={`${uploadType}_history_download`}
            ButtonProps={{
              style: {
                fontSize: "16px",
                color: "black",
                display: "flex",
                letterSpacing: "0.01em",
              },
              variant: "text",
            }}
          />
        </Box>
      </>
    ) : (
      noDisplayMessage
    );

  return (
    <>
      <Title
        isSentenceCase
        sx={styles.title}
        text={`${uploadType} upload  history`}
      />

      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        rowSpacing="15px"
      >
        <Grid item>
          {channelOptions && (
            <OPAutoComplete
              multiple
              name="Select Specific Channels"
              options={channelOptions}
              value={selectedChannels}
              onChange={handleChannelsSelection}
              sx={styles.channelSelector}
            />
          )}
        </Grid>
        <Spacer />
        <Grid item>
          <OPDatePicker
            startDate={dateRange[0]}
            endDate={dateRange[1]}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Grid>
      </Grid>
      {graphDisplay}
    </>
  );
}
UploadHistoryPage.propTypes = {
  uploadType: PropTypes.string,
};
