import { Button } from "@mui/material";
import React from "react";
import { sentenceCase } from "utils/data/strings";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import PropTypes from "prop-types";
import {
  PRIMARY_BACKGROUND,
  PRIMARY_BACKGROUND_CONTRAST,
} from "assets/palette";

const styles = {
  button: {
    width: "100%",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "0.02em",
    borderRadius: "10px",
    "&:disabled": {
      backgroundColor: PRIMARY_BACKGROUND,
      color: PRIMARY_BACKGROUND_CONTRAST,
    },
  },
};

function PrimaryButton(props) {
  const {
    variant = "contained",
    style,
    className,
    onClick,
    text,
    isSentenceCase = true,
    isDisabled = false,
    color = "primary",
    endIcon = <div />,
    startIcon = <div />,
    sx = {},
    children,
    ...rest
  } = props;
  const newText = sentenceCase(text || children);
  const newStyle = { fontWeight: 600, ...style };

  return (
    <Button
      type="button"
      color={color}
      variant={variant}
      style={newStyle}
      className={`${className}`}
      sx={{ ...styles.button, ...sx }}
      onClick={onClick}
      disabled={isDisabled}
      endIcon={endIcon}
      startIcon={startIcon}
      {...rest}
    >
      {isSentenceCase ? newText : text || children}
    </Button>
  );
}

export default PrimaryButton;

PrimaryButton.propTypes = {
  variant: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  isSentenceCase: PropTypes.bool,
  isDisabled: PropTypes.bool,
  color: PropTypes.string,
  endIcon: PropTypes.element,
  startIcon: PropTypes.element,
  sx: PropTypes.object,
  children: PropTypes.any,
};
