const INTERNAL_ORG_NAME =
  process.env?.REACT_APP_INTERNAL_ORG_NAME || "outpoint";

export const BLACK_60_PERCENT = "rgba(0, 0, 0, 0.6)";

export const GREEN = "#66D064";
export const LIGHT_GREEN = "#80B362";
export const BG_GREEN = "#E5F5F0";

export const BRIGHT_YELLOW = "#FFE81E";
export const DARK_YELLOW = "#ffce44";
export const FAINT_YELLOW = "#FBF7EF";
export const BG_YELLOW = "#FFFAEE";

export const RED = "#F41D43";
export const LIGHT_RED = "#FF7276";
export const BG_RED = "#FEE8E8";

export const LIGHT_GREY = "#F9F9F9";
export const LIGHT_MEDIUM_GREY = "#D3D3D3";
export const MINECRAFT_CLAY_GREY = "#E3E6EC";
export const BRAND_GRAY_700 = "#606066";
export const BRAND_GRAY_300 = "#E6E6ED";
export const BRAND_GRAY_100 = "#FAFAFC";
export const BORDER_GREY = "#FBFBFB";
export const MEDIUM_DARK_GREY = "#2F2F33";
export const DARK_GREY = "#1C1C1F";
export const SONIC_SILVER = "#76757D";

export const FAINT_BLUE = "#F4F3F7";
export const PERIWINKLE_BLUE = "#5F75EE";
export const MAXIMUM_YELLOW_RED = "#EBBA4A"; // FIXME: previously, periwinkle blue used to be something like the primary colour, for the nectar branches, it has to be changed  to this
export const PERIWINKLE_BLUE_LIGHT = `${PERIWINKLE_BLUE}1A`;
export const TEAL = "#167A81";
export const FEEDBACK_OR_WARNING = "#FFC453";
export const FEEDBACK_OR_ERROR_100 = "#FEE8E8";
export const FEEDBACK_OR_ERROR_900 = "#F41212";

export const FEEDBACK_ERROR_ERROR = "#D40808";
export const FEEDBACK_WARNING_WARNING = "#DE7A00";
export const FEEDBACK_SUCCESS = "#009F66";

export const OP_COLORS = {
  HAN_PURPLE: "#4A21EA",
  EGGSHELL_LAVENDER: "#f8f4fc", // TODO: COLOUR_CHECK TO SET THIS AS primary.light in the palette
  LAVENDER_WEB: "#E5E4F2",
  PALE_LAVENDER: "#DBD3FB",
  BRIGHT_LAVENDER: "#A490F5",
  DARK_LAVENDER: "#9891FD",
  ROYAL_BLUE: "#304FFE",
  HOT_PINK: "#E040FB",
  GRAPE: "#713DBB",
  OUTER_SPACE_CRAYOLA: "#323045",
  WHITE_TRANSLUCENT: "rgba(255, 255, 255, 0.4)",
  BLUE_ALICE_BLUE: "#F1F0FF",
  BLUE_GRAY_NEUTRAL: "rgba(156, 154, 184, 0.08)",
  BLUE_BOX_SHADOW_5_PERCENT: "rgba(22, 8, 79, 0.05)",
  BLUE_BOX_SHADOW_25_PERCENT: "rgba(22, 8, 79, 0.25)",
  BLUE_PALATINATE_BLUE: "#3D31ED",
  GREEN_CADMIUM_GREEN: "#006B45",
  GRAY_BRIGHT_GRAY: "#eaeaf0",
  TANGERINE: "#FFAA66",
  PLASTIC_GREEN: "#50C878",
  DARK_GUNMETAL: "#24232F",
  INDEPENDENCE: "#555373",
};

export const NECTAR_COLORS = {
  WHITE_FLORAL_WHITE: "#FFFAEE", // PRIMARY BACKGROUND FOR NECTAR
  MAXIMUM_YELLOW_RED,
  JASPER_ORANGE: "#DE904B",
};

const CUSTOM_THEME_COLORS = {
  outpoint: {
    PRIMARY: OP_COLORS.HAN_PURPLE,
    PRIMARY_LIGHT: OP_COLORS.BLUE_ALICE_BLUE,
    PRIMARY_DARK: OP_COLORS.BLUE_PALATINATE_BLUE,
    PRIMARY_BACKGROUND: OP_COLORS.BRIGHT_LAVENDER,
    PRIMARY_BACKGROUND_CONTRAST: "white",
    PRIMARY_TEXT_CONTRAST: "white",
  },
  nectar_first: {
    PRIMARY: NECTAR_COLORS.MAXIMUM_YELLOW_RED,
    PRIMARY_DARK: NECTAR_COLORS.JASPER_ORANGE,
    PRIMARY_BACKGROUND: NECTAR_COLORS.WHITE_FLORAL_WHITE,
    PRIMARY_LIGHT: NECTAR_COLORS.WHITE_FLORAL_WHITE,
    PRIMARY_BACKGROUND_CONTRAST: "grey",
    PRIMARY_TEXT_CONTRAST: "black",
  },
};

export const PRIMARY_TEXT_CONTRAST =
  CUSTOM_THEME_COLORS[INTERNAL_ORG_NAME]?.PRIMARY_TEXT_CONTRAST;
export const PRIMARY_BACKGROUND =
  CUSTOM_THEME_COLORS[INTERNAL_ORG_NAME]?.PRIMARY_BACKGROUND;
export const PRIMARY_BACKGROUND_CONTRAST =
  CUSTOM_THEME_COLORS[INTERNAL_ORG_NAME]?.PRIMARY_BACKGROUND_CONTRAST;

export const PRIMARY_COLOR = CUSTOM_THEME_COLORS[INTERNAL_ORG_NAME]?.PRIMARY;
export const PRIMARY_LIGHT =
  CUSTOM_THEME_COLORS[INTERNAL_ORG_NAME]?.PRIMARY_LIGHT;
export const PRIMARY_DARK =
  CUSTOM_THEME_COLORS[INTERNAL_ORG_NAME]?.PRIMARY_DARK;
