import React from "react";
import PropTypes from "prop-types";
import { Grid, Tooltip } from "@mui/material";

import Header3 from "library/text/headers/Header3";
import SubHead from "library/text/headers/SubHead";
import Selector from "library/form/OutPointSelector";
import OPDatePicker from "library/form/DatePicker";
import Hyperlink from "library/text/body/Hyperlink";
import SubHeadLight from "library/text/headers/SubHeadLight";
import { startCase } from "lodash";
import { liftPageTemplateStyles as styles } from "./LiftPageTemplateStyles";

function SubHeading(props) {
  const {
    liftType,
    chartView,
    startDate,
    endDate,
    setChartView,
    setStartDate,
    setEndDate,
  } = props;
  const { lightHeading, heading, hyperLinkStyle } = styles;
  const header3Text = startCase(`historical ${liftType} revenue`);
  const subheadText = `Understand how channel spend correlates to ${liftType} revenue.`;

  const liftLabel = (
    <Grid item xs={12} md={4}>
      <Header3 sx={heading}>{header3Text}</Header3>
      <SubHead
        sx={lightHeading}
        style={{
          marginTop: "8px",
        }}
      >
        {subheadText}
      </SubHead>
    </Grid>
  );

  const datePicker = (
    <Grid item xs={7}>
      <OPDatePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
      />
    </Grid>
  );

  const whatsThisTooltip = (
    <Tooltip
      title={
        <SubHeadLight>
          Edit your chart view to easily compare your spend and revenue numbers.
        </SubHeadLight>
      }
    >
      <Grid item>
        <Hyperlink sx={hyperLinkStyle} newPage={false}>
          What's this?
        </Hyperlink>
      </Grid>
    </Tooltip>
  );

  const chartViewSelector = (
    <Grid
      item
      xs={5}
      container
      direction="row"
      justifyContent="flex-end"
      columnSpacing={1}
    >
      <Grid item>
        <Selector
          menuItems={["100", "50", "40", "30", "20", "15", "10", "5"]}
          onChange={setChartView}
          selectedValue={chartView}
          leading="Chart view: "
          trailing="%"
          menuItemAdornment={{ value: "%", position: "end" }}
        />
      </Grid>
      <Grid item>{whatsThisTooltip}</Grid>
    </Grid>
  );

  const controlItems = (
    <Grid
      item
      container
      direction="row"
      md={7}
      xs={12}
      columnSpacing={1}
      justifyContent="flex-end"
      alignItems="flex-start"
    >
      {datePicker}
      {chartViewSelector}
    </Grid>
  );

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      columnSpacing={2}
    >
      {liftLabel}
      {controlItems}
    </Grid>
  );
}

SubHeading.propTypes = {
  liftType: PropTypes.string,
  chartView: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setChartView: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};

export default SubHeading;
