/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

/**
 * NOTE: some of the functions here are actually deprecated. They shall be
 * cleaned when the components from campaigns v0 are removed
 * */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { uniq } from "lodash";

import { STATUS } from "utils/enums";
import { isValidName } from "utils/data/validators";
import { normalizeChannelName } from "utils/data/strings";

const DEFAULT_CAMPAIGN_GROUP = "ungrouped";
const DEFAULT_STRATEGY = "ungrouped";

/**
 * Selects all ids for either stratgeies, campaign groups, or campaigns kept in redux state.
 * */
export const selectObjectProperty = (dataType, propertyType) => (state) => {
  if (
    dataType === "strategies" ||
    dataType === "campaign_groups" ||
    dataType === "campaigns"
  ) {
    return state.campaignsData.currentGrouping[dataType].map(
      (group) => group[propertyType],
    );
  }
  console.error("Please ensure the dataType is valid");
  return false;
};

/**
 * Selects all the strategy, campaign group, campaign, or group to strategy map objects kept in redux state.
 * */
export const selectObjects = (dataType) => (state) => {
  if (
    dataType === "strategies" ||
    dataType === "campaign_groups" ||
    dataType === "campaigns" ||
    dataType === "campaign_group_id_to_strategy_id_map"
  ) {
    return state.campaignsData.currentGrouping[dataType];
  }
  console.error("Please ensure the dataType is valid");
  return false;
};

/**
 * Returns a map of normalized campaign group ids to their normalized strategy ids in redux state.
 * */
export const selectNormalizedCampaignGroupIdToStrategyIdMap = (state) =>
  state.campaignsData.currentGrouping.campaign_groups.reduce(
    (map, group) => ({
      ...map,
      [normalizeChannelName(group.id)]: normalizeChannelName(
        state.campaignsData.currentGrouping
          .campaign_group_id_to_strategy_id_map[group.id],
      ),
    }),
    {},
  );

/**
 * Returns a map of strategy ids to their lists of campaign group ids in redux state.
 * */
export const selectStrategyIdToCampaignGroupIdsMap = (state) =>
  state.campaignsData.currentGrouping.strategies.reduce(
    (map, { id, campaign_group_ids: campaignGroupIds }) => ({
      ...map,
      [id]: campaignGroupIds,
    }),
    {},
  );

/**
 * Returns map of campaign ID to campaign objects
 * */
export const selectCampaignIdToCampaignMap = (state) =>
  state.campaignsData.currentGrouping.campaigns.reduce(
    (campaignIdToCampaignMap, campaign) => ({
      ...campaignIdToCampaignMap,
      [campaign.id]: campaign,
    }),
    {},
  );

/**
 * Returns map of campaign group ID to campaign objecs
 * */
export const selectCampaignGroupIdToCampaignMap = (state) => {
  const campaignIdToCampaignMap =
    state.campaignsData.currentGrouping.campaigns.reduce(
      (map, campaign) => ({ ...map, [campaign.id]: campaign }),
      {},
    );

  return state.campaignsData.currentGrouping.campaign_groups.reduce(
    (map, { id, campaign_ids: campsignIds }) => ({
      ...map,
      [id]: campsignIds.map(
        (campaignId) => campaignIdToCampaignMap[campaignId],
      ),
    }),
    {},
  );
};

/**
 * Returns a selector that selects all unique values of given campaign attribute.
 * */
export const createUniqueCampaignsAttributeSelector =
  (attributeKey) => (state) => {
    const { campaigns } = state.campaignsData.currentGrouping;

    const attributeValues = campaigns.map((campaign) => campaign[attributeKey]);

    return uniq(attributeValues);
  };

/**
 * Returns a selector that selects all campaign entities that are nested under
 * the given channel group.
 * */
export const selectCampaignsUnderGroup = (campaignGroupId) => (state) => {
  const ids = state.campaignsData.currentGrouping.campaign_groups.find(
    (group) => group.id === campaignGroupId,
  )?.campaign_ids;
  const entities =
    ids &&
    ids.map((id) =>
      state.campaignsData.currentGrouping.campaigns.find(
        (campaign) => campaign.id === id,
      ),
    );

  return entities;
};

/**
 * Returns a promise for the grouping object from the backend, which is already normalized
 * into the relevant entities we desire.This normalized redux state is
 * intended to make it easier for complex state logic to be handled by
 * granular reducer functions.
 * */
export const getCampaignGrouping = createAsyncThunk(
  "asyncRedux/campaignGroupingAsync",
  async () => {
    return makeAuthenticatedGetRequest("campaigns");
  },
);

/**
 * Returns a blanked out group of initial entities to be kept in redux store.
 * There are two types of entities being stored:
 *  1. pure entity objects (e.g. campaign, campaignGroup, channel)
 *  2. relationship entity object (e.g. campaignGroupToCampaign)
 * */
const initializeStore = () => ({
  // pure entities:
  strategies: [],
  campaign_groups: [],
  campaigns: [],

  // relationship entities:
  campaign_group_id_to_strategy_id_map: {},
});

const initialState = {
  currentGrouping: initializeStore(),
  isSubmitting: false,
  status: null,
  authError: null,
};

const tryCreateAndAddNewStrategy = (state, newStrategyName) => {
  const foundStrategyName = state.currentGrouping.strategies.find(
    (strategy) => strategy.id === newStrategyName,
  );
  if (foundStrategyName) {
    const msg = `Your new strategy name, ${newStrategyName}, already exists. Please select a unique strategy name.`;
    console.warn(msg);
    return;
  }

  const newStrategy = {
    id: newStrategyName,
    campaign_group_ids: [],
  };
  state.currentGrouping.strategies.push(newStrategy);
};

/**
 * Creates a new campaignGroup entity in state and adds relationship
 * between strategy and new campaign group.
 * */
const createNewCampaignGroup = (state, { payload }) => {
  const newCampaignGroupName = payload.groupName?.trim();
  const { strategyId, isBrand } = payload;

  if (!isValidName(newCampaignGroupName)) {
    const msg = `Your new campaign group name, ${newCampaignGroupName}, is not a valid group name. `;
    console.warn(msg);
    return;
  }

  const foundCampaignGroupName = state.currentGrouping.campaign_groups.find(
    (group) => group.id === newCampaignGroupName,
  );
  if (foundCampaignGroupName) {
    const msg = `Your new campaign group name, ${newCampaignGroupName}, already exists. Please select a unique campaign group name.`;
    console.warn(msg);
    return;
  }

  const newCampaignGroup = {
    id: newCampaignGroupName,
    is_brand: isBrand,
    campaign_ids: [],
  };
  state.currentGrouping.campaign_groups.push(newCampaignGroup);

  state.currentGrouping.campaign_group_id_to_strategy_id_map[
    newCampaignGroupName
  ] = strategyId;

  tryCreateAndAddNewStrategy(state, strategyId);
};

/**
 * Creates a new strategy entity in state.
 * */
const createNewStrategy = (state, { payload }) => {
  const newStrategyName = payload.strategyName?.trim();

  if (!isValidName(newStrategyName)) {
    const msg = `Your new strategy name, ${newStrategyName}, is not a valid strategy name. `;
    console.warn(msg);
    return;
  }

  tryCreateAndAddNewStrategy(state, newStrategyName);
};

/**
 * Removes a campaignGroup entity from state.
 *
 * Along with the following changes:
 * 1. Shifts all the constituent campaigns
 * under that target campaign group to the default group, 'ungrouped'
 * 2. Deletes the campaignGroup entity
 * 3. Updates all the campaign objects with the correct parent group names and strategy names
 * */
const removeCampaignGroup = (state, { payload }) => {
  const { targetId } = payload;
  const currentAllCampaignGroupIds = state.currentGrouping.campaign_groups.map(
    (group) => group.id,
  );

  if (
    targetId === DEFAULT_CAMPAIGN_GROUP ||
    !currentAllCampaignGroupIds.includes(targetId)
  ) {
    return;
  }

  // 1. shifts constituents:
  const constituentCampaignIds = state.currentGrouping.campaign_groups.find(
    (group) => group.id === targetId,
  ).campaign_ids;
  const currentCampaignsMarkedAsUngrouped =
    state.currentGrouping.campaign_groups.find(
      (group) => group.id === DEFAULT_CAMPAIGN_GROUP,
    ).campaign_ids;

  state.currentGrouping.campaign_groups.find(
    (group) => group.id === DEFAULT_CAMPAIGN_GROUP,
  ).campaign_ids = constituentCampaignIds.concat(
    currentCampaignsMarkedAsUngrouped,
  );

  // 2. deletes campaignGroupEntity:
  state.currentGrouping.campaign_groups =
    state.currentGrouping.campaign_groups.filter(
      (group) => group.id !== targetId,
    );

  // 3. updates all the campaign objects with the correct parent group names
  state.currentGrouping.campaigns
    .filter((campaign) => campaign.campaign_group === targetId)
    .forEach((campaign) => {
      campaign.campaign_group = DEFAULT_CAMPAIGN_GROUP;
      campaign.strategy = DEFAULT_STRATEGY;
      campaign.is_brand = false;
    });
};

/**
 * Removes a strategy entity from state.
 *
 * Along with the following changes:
 * 1. Shifts all the constituent campaign groups
 * under that target strategy to a default strategy which is the same as the campaign group.
 * 3. Updates all the campaign objects with the correct strategy names
 * 4. Updates the campaignGroupToStrategy relationship entities
 * */
const removeStrategy = (state, { payload }) => {
  const { targetId } = payload;

  if (targetId === DEFAULT_STRATEGY) return;

  // 1.1 finds constituents and deletes strategyEntit
  const constituentCampaignGroupIds = state.currentGrouping.strategies.find(
    (strategy) => strategy.id === targetId,
  )?.campaign_group_ids;
  state.currentGrouping.strategies = state.currentGrouping.strategies.filter(
    (strategy) => strategy.id !== targetId,
  );
  // can exit if no campaign groups under strategy or strategy doesn't exist
  if (constituentCampaignGroupIds.length === 0 || !constituentCampaignGroupIds)
    return;

  // 1.2 shifts constituents to other strategies
  const currentAllStrategyIds = state.currentGrouping.strategies.map(
    (strategy) => strategy.id,
  );
  constituentCampaignGroupIds.forEach((campaignGroupId) => {
    if (currentAllStrategyIds.includes(campaignGroupId)) {
      const targetStrategy = state.currentGrouping.strategies.find(
        (strategy) => strategy.id === campaignGroupId,
      );
      targetStrategy.campaign_group_ids.push(campaignGroupId);
    } else {
      state.currentGrouping.strategies.push({
        id: campaignGroupId,
        campaign_group_ids: [],
      });
    }
  });

  // 2. updates all the campaign objects with the correct strategy names:
  state.currentGrouping.campaigns
    .filter((campaign) => campaign.strategy === targetId)
    .forEach((campaign) => {
      campaign.strategy = campaign.campaign_group;
    });

  // 3. updates campaignGroupToStrategy relationship map:
  constituentCampaignGroupIds.forEach((campaignGroupId) => {
    state.currentGrouping.campaign_group_id_to_strategy_id_map[
      campaignGroupId
    ] = campaignGroupId;
  });
};

/**
 * For each campaignGroup object, updates its object fields and strategy relationship:
 * 1. updates campaign group objects
 * 2. updates campaign objects
 * 3. updates strategy objects
 * 4. changes relationship object in state
 * 5. changes strategy objects in state
 * 6. updates campaign strategy field
 * */
const bulkUpdateAll = (state, { payload }) => {
  const {
    updatedCampaigns,
    updatedCampaignGroups,
    updatedStrategies,
    updatedCampaignGroupIdToStrategyIdMap,
  } = payload;

  // 1. updates campaign group objects
  state.currentGrouping.campaign_groups = updatedCampaignGroups;

  // 2. updates campaign objects
  state.currentGrouping.campaigns = updatedCampaigns;

  // 3. updates strategy objects
  state.currentGrouping.strategies = updatedStrategies;

  // 4. updates relationship object in state
  state.currentGrouping.campaign_group_id_to_strategy_id_map =
    updatedCampaignGroupIdToStrategyIdMap;

  Object.entries(updatedCampaignGroupIdToStrategyIdMap).forEach(
    ([campaignGroupId, newStrategyId]) => {
      const currentStrategyId =
        state.currentGrouping.campaign_group_id_to_strategy_id_map[
          campaignGroupId
        ];

      // 5. changes campaign group ids field of strategy objects in state
      // 5.1 remove campaign group id from list of campaign group ids of old strategy
      state.currentGrouping.strategies.find(
        (strategy) => strategy.id === currentStrategyId,
      ).campaign_group_ids = state.currentGrouping.strategies
        .find((strategy) => strategy.id === currentStrategyId)
        .campaign_group_ids.filter((groupId) => groupId !== campaignGroupId);

      // 5.2 add campaign group id to list of campaign group ids for new strategy
      state.currentGrouping.strategies
        .find((strategy) => strategy.id === newStrategyId)
        .campaign_group_ids.push(campaignGroupId);

      // 6. updates campaign strategy field
      state.currentGrouping.campaign_groups
        .find((group) => group.id === campaignGroupId)
        .campaign_ids.forEach((campaignId) => {
          state.currentGrouping.campaigns.find(
            (campaign) => campaign.id === campaignId,
          ).strategy = newStrategyId;
        });
    },
  );
};

/**
 * For each selectedCampaign object, updates the relationship b/w campaign group and campaign object by
 * changing the relationship entity as well as the relevant relationships:
 * 1. changes campaign group entities:
 *    1.1. removes campaign from old campaignGroup, adds to new campaignGroup
 *    1.2. updates the campaign's campaignGroup
 * */
const bulkUpdateCampaignToGroups = (state, { payload }) => {
  const { selectedCampaigns, newCampaignGroupId } = payload;

  selectedCampaigns.forEach((selectedCampaign) => {
    const oldCampaignGroupId = state.currentGrouping.campaigns.find(
      (campaign) => campaign.id === selectedCampaign.id,
    ).campaign_group;

    // prevents duplicates:
    if (oldCampaignGroupId === newCampaignGroupId) {
      return;
    }

    // 1.1 adds campaigns to new campaignGroupObj, removes from old:
    const newCampaignGroup = state.currentGrouping.campaign_groups.find(
      (group) => group.id === newCampaignGroupId,
    );
    const campaignIdsUnderOldGroup =
      state.currentGrouping.campaign_groups.find(
        (group) => group.id === oldCampaignGroupId,
      ).campaign_ids || [];
    const campaignIdsUnderNewGroup = newCampaignGroup.campaign_ids || [];
    const updatedCampaignsUnderNewGroup = campaignIdsUnderNewGroup.concat([
      selectedCampaign.id,
    ]);
    const updatedCampaignsUnderOldGroup = campaignIdsUnderOldGroup.filter(
      (oldCampaignId) => oldCampaignId !== selectedCampaign.id,
    );
    state.currentGrouping.campaign_groups.find(
      (group) => group.id === oldCampaignGroupId,
    ).campaign_ids = updatedCampaignsUnderOldGroup;
    state.currentGrouping.campaign_groups.find(
      (group) => group.id === newCampaignGroupId,
    ).campaign_ids = updatedCampaignsUnderNewGroup;

    // 1.2 updates campaign's campaign group and strategy and is_brand
    Object.assign(
      state.currentGrouping.campaigns.find(
        (campaign) => campaign.id === selectedCampaign.id,
      ),
      {
        campaign_group: newCampaignGroupId,
        strategy:
          state.currentGrouping.campaign_group_id_to_strategy_id_map[
            newCampaignGroupId
          ],
        is_brand: newCampaignGroup.is_brand,
      },
    );
  });
};

const campaignsSlice = createSlice({
  name: "campaignsSlice",
  initialState,
  reducers: {
    registerNewStrategy: createNewStrategy,
    deregisterStrategy: removeStrategy,
    registerNewCampaignGroup: createNewCampaignGroup,
    deregisterCampaignGroup: removeCampaignGroup,
    bulkEdit: bulkUpdateAll,
    bulkAssignCampaignsToGroup: bulkUpdateCampaignToGroups,
    toggleIsSubmitting: (state) => {
      state.isSubmitting = !state.isSubmitting;
    },
  },
  extraReducers: {
    [getCampaignGrouping.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getCampaignGrouping.fulfilled]: (state, { payload }) => {
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;

      const { data } = payload;
      state.currentGrouping = data;
    },

    [getCampaignGrouping.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const {
  registerNewStrategy,
  deregisterStrategy,
  registerNewCampaignGroup,
  deregisterCampaignGroup,
  bulkEdit,
  toggleIsSubmitting,
  bulkAssignCampaignsToGroup,
} = campaignsSlice.actions;
export default campaignsSlice.reducer;
