import {
  FEEDBACK_WARNING_WARNING,
  BG_YELLOW,
  BRAND_GRAY_700,
  BRAND_GRAY_100,
  OP_COLORS,
  BRAND_GRAY_300,
} from "assets/palette";

export const recommendationStyles = {
  subheaderText: {
    // styleName: Subhead (Sm)/Caps;
    fontFamily: "IBM Plex Sans",
    color: BRAND_GRAY_700,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0.08em",
    textAlign: "left",
    marginBottom: "8px",
  },
  channelLabelText: {
    fontFamily: "IBM Plex Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "28px",
  },
  recommendationsContainer: {
    marginTop: "32px",
    marginRight: "128px",
  },
  channelRecommendationToolbar: {
    padding: "32px, 32px, 24px, 32px",
    width: "100%",
  },
  recommendationContentContainer: {
    width: "Fill(1512px)",
  },
  predictionGraphContainer: {
    border: `1px solid ${BRAND_GRAY_300}`,
    borderRadius: "8px",
    padding: "24px",
  },
  recommendationDetailsContainer: {
    display: "block",
    width: "100vh",
  },
  detailsContainer: {
    height: "fit-content",
    margin: "0 0 16px 0px",
  },
  detailsCard: {
    padding: "16px",
    border: `1px solid ${BRAND_GRAY_300}`,
    boxShadow: "none",
    maxWidth: "348px",
    margin: "24px 24px 24px 0px",
  },
  detailsLabelText: {
    // styleName: Subhead/Reg;
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.01em",
    textAlign: "left",
  },
  spendValueLabelText: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 400,
    fontSize: "35px",
    lineHeight: "44px",
    letterSpacing: "-0.01em",
    textAlign: "left",
  },
  timeScaleText: {
    marginLeft: "8px",
    fontFamily: "IBM Plex Sans",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "rgba(0, 0, 0, 0.6)",
  },
  changeString: {
    margin: "0 0 3px 8px",
  },
  recommendedSpendBreakdownContainer: {
    borderRadius: "8px",
    padding: "24px",
    margin: "24px 0px 24px 0px",
    height: "auto",
  },
  channelSelector: {
    width: "100%",
    borderRadius: "100px",
  },
};

export const predictionGraphStyles = {
  legendContainer: {
    width: "100%",
    marginBottom: "8px",
  },
  predictedGraphContainer: {
    padding: "8px",
    width: "100%",
    height: "100%",
  },
  headerContainer: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    paddingBottom: "12px",
    marginBottom: "24px",
  },
  subheaderContainer: {
    minHeight: "40px",
    width: "100%",
  },
  subheaderText: {
    width: "100%",
  },
  subheaderSelect: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
  },
  select: {
    position: "absolute",
    right: "0",
    minWidth: "100px",
  },
  graphContainer: {
    marginTop: "8px",
  },
};

export const chipTextStyles = {
  fontFamily: "IBM Plex Sans",
  color: FEEDBACK_WARNING_WARNING,
  fontWeight: "400",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.01em",
  textAlign: "left",
  backgroundColor: BG_YELLOW,
};

export const chipStyles = {
  borderRadius: "16px",
};

export const recommendedSpendBreakdownTableStyles = {
  tableCaption: {
    // styleName: Subhead (Sm)/Caps;
    fontFamily: "IBM Plex Sans",
    color: OP_COLORS.OUTER_SPACE_CRAYOLA,
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0.08em",
    textAlign: "left",
    marginBottom: "8px",
  },
  columnText: {
    // styleName: Subhead (Sm)/Reg;
    fontFamily: "IBM Plex Sans",
    color: "rgba(0, 0, 0, 0.8)",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    margin: "16px",
  },
  cellValueText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.01em",
    textAlign: "left",
    marginLeft: "8px",
    marginRight: "8px",
  },
  roasCellValueText: {
    fontFamily: "IBM Plex Sans",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.01em",
    textAlign: "left",
    marginLeft: "8px",
    marginRight: "8px",
  },
};

export const summaryTableStyles = {
  tableContainer: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  cardTitleText: {
    fontSize: "20px",
    fontWeight: 400,
  },
  cardSubtitleText: {
    fontSize: "14px",
    marginTop: "12px",
  },
  tableCellContainer: {},
  tableCellTextContainer: {
    marginRight: "12px",
  },
  tableCellText: {
    // styleName: Body/Reg;
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    color: BRAND_GRAY_700,
  },
  boldedTableCellText: {
    // styleName: Body/Reg;
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
    color: BRAND_GRAY_700,
  },
  chipStyles: {
    borderRadius: "16px",
  },
  chipTextStyles: (
    textColour = FEEDBACK_WARNING_WARNING,
    backgroundColour = BG_YELLOW,
  ) => ({
    fontFamily: "IBM Plex Sans",
    color: textColour,
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.01em",
    textAlign: "center",
    backgroundColor: backgroundColour,
  }),
  totalRowStyles: {
    background: BRAND_GRAY_100,
  },
};

export const summaryBarGraphStyles = {
  barGraphContainer: {
    marginTop: "0px",
    width: "100%",
    height: "100%",
  },
  containerCard: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  legendContainer: {
    width: "100%",
    marginBottom: "24px",
  },
  cardTitleText: {
    fontSize: "20px",
    fontWeight: 400,
  },
  cardSubtitleText: {
    fontSize: "14px",
    marginTop: "12px",
  },
  subheaderText: {
    padding: "24px",
    width: "100%",
  },
  strategyToggleContainer: {
    margin: "10px 10px 10px 10px",
  },
  aggregationText: {
    marginRight: "10px",
  },
  toggleButtonLeft: {
    textTransform: "none",
    borderWidth: "1.5px",
    borderRadius: "8px 0px 0px 8px",
    "&.Mui-selected": {
      backgroundColor: OP_COLORS.EGGSHELL_LAVENDER,
      borderColor: "blue.main",
    },
  },
  toggleButtonRight: {
    textTransform: "none",
    borderWidth: "1.5px",
    borderRadius: "0px 8px 8px 0px",
    "&.Mui-selected": {
      backgroundColor: OP_COLORS.EGGSHELL_LAVENDER,
      borderColor: "blue.main",
    },
  },
};
