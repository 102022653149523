import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";

import { cardData } from "pages/connections/data/connectionsData";
import { getJesterData } from "redux/jesterSlice";
import {
  deleteCredentials,
  jesterPostRequest,
  goToURL,
} from "utils/jester-api";
import Header2 from "library/text/headers/Header2";
import Header3 from "library/text/headers/Header3";
import TextFieldSimple from "library/form/OutPointTextField";
import PrimaryButton from "library/buttons/PrimaryButton";
import IconText from "library/containers/IconText";
import { MINECRAFT_CLAY_GREY, PERIWINKLE_BLUE } from "assets/palette";
import OutPointModal from "library/surface/ModalCentered";
import { normalizeChannelName } from "utils/data/strings";
import {
  DeleteCredentialsButton,
  EditMetadataButton,
  DeleteCredentialsModal,
} from "./PlatformCardTemplateComponents";
import PlatformCardModalSection from "./PlatformCardModalSection";
import LogoChip from "./LogoChip";

const styles = {
  formField: {
    width: "100%",
    marginBottom: "10px",
  },
  closeButton: {
    width: "175px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  signInButton: {
    width: "300px",
    margin: "10px 5px 5px 30px",
  },
  textContainer: {
    width: "100%",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "35px",
    justifyContent: "space-between",
  },
  hyperlink: {
    color: PERIWINKLE_BLUE,
    fontWeight: 600,
  },
  iconContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  line: {
    borderTop: `1px solid ${MINECRAFT_CLAY_GREY}`,
    width: "100%",
    margin: "20px 0 20px 0",
  },
  logoChip: {
    margin: "0 15px 10px 0",
  },
};

const submitMetadata = async (
  currentMetadata,
  formText,
  channel,
  name,
  fields,
  setIsError,
  refetchData,
) => {
  const makeRequest = async (formMetadataText, targetChannel) => {
    const request = {
      metadata: formMetadataText,
      channel: targetChannel,
      name,
    };
    try {
      const response = await jesterPostRequest("metadata", request);
      if (response.data?.success) {
        refetchData();
      }
      return true;
    } catch (err) {
      return false;
    }
  };

  // TODO: check object equality?
  if (currentMetadata && formText === currentMetadata) {
    return true;
  }

  let canSubmit = true;
  const errorsObject = {};
  fields.forEach((field) => {
    const fieldErrored = !formText[field];
    errorsObject[field] = fieldErrored;
    canSubmit = canSubmit && !fieldErrored;
  });

  setIsError(errorsObject);

  if (canSubmit) {
    return makeRequest(formText, channel);
  }

  return false;
};

const handleDeleteCredentials = async (
  channel,
  name,
  closeModals,
  refetchData,
) => {
  const success = await deleteCredentials(channel, name);
  if (success) {
    refetchData();
    closeModals();
    return true;
  }

  return false;
};

function PlatformCardModal(props) {
  const { channel, name, whereFrom, modalOpen, setModalOpen } = props;

  const dispatch = useDispatch();
  const refetchData = () => dispatch(getJesterData());
  const channelData =
    useSelector((state) => state.jester)?.data?.[channel] || [];
  const credentialsList = name
    ? channelData.filter((credentials) => credentials.name === name)
    : null;
  const credentials =
    name && credentialsList.length > 0 ? credentialsList[0] : null;
  const currentMetadata = credentials?.metadata || {};
  const hasCredentials = credentials?.credentials;
  const authenticatePlatform = () => goToURL(channel, name, whereFrom);

  const { fields = [], logo, SSOButton } = cardData[channel] || {};

  const [formText, setFormText] = useState(currentMetadata || {});
  const [isError, setIsError] = useState({});
  const [canEditMetadata, setCanEditMetadata] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sectionStates, setSectionStates] = useState({
    credentials: true,
    metadata: true,
  });

  useEffect(() => {
    setFormText(currentMetadata);
    setSectionStates({
      credentials: hasCredentials,
      metadata: fields
        .map((field) => currentMetadata[field])
        .reduce((field, acc) => field && acc, true),
    });
  }, [credentials]);

  const onChange = (text, targetName) => {
    setFormText((prevState) => {
      const newState = { ...prevState };
      newState[targetName] = text;
      return newState;
    });
  };

  const closeModals = useCallback(() => {
    // Reset state
    setFormText(currentMetadata || {});
    setIsError({});
    setCanEditMetadata({});

    setDeleteModalOpen(false);
    setModalOpen(false);
  }, []);

  const CredentialsButton =
    SSOButton ||
    ((propsToBePassed) => (
      <PrimaryButton
        sx={styles.signInButton}
        variant="outlined"
        {...propsToBePassed}
      >
        Sign in with {normalizeChannelName(channel)}
      </PrimaryButton>
    ));

  const credentialsForm = (
    <div>
      <CredentialsButton
        disabled={hasCredentials || (channel === "shopify" && !currentMetadata)}
        onClick={authenticatePlatform}
      />
      {hasCredentials && (
        <DeleteCredentialsButton onClick={() => setDeleteModalOpen(true)} />
      )}
    </div>
  );

  const metaDataForm = fields.map((fieldName) => (
    <div
      style={{ width: "100%", display: "flex", alignItems: "flex-end" }}
      key={`container-${fieldName}`}
    >
      <TextFieldSimple
        key={`textfield-${fieldName}`}
        disabled={currentMetadata?.[fieldName] && !canEditMetadata[fieldName]}
        error={isError[fieldName]}
        sx={styles.formField}
        name={fieldName}
        onChange={(value) => onChange(value, fieldName)}
        value={formText[fieldName] || ""}
      />
      {currentMetadata?.[fieldName] && !canEditMetadata[fieldName] && (
        <EditMetadataButton
          setCanEditMetadata={setCanEditMetadata}
          key={`editbtn-${fieldName}`}
          name={fieldName}
        />
      )}
    </div>
  ));

  return (
    <>
      <OutPointModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        sx={styles.modal}
      >
        <DeleteCredentialsModal
          setModalOpen={() => setDeleteModalOpen(false)}
          deleteCredentials={() =>
            handleDeleteCredentials(channel, name, closeModals, refetchData)
          }
        />
      </OutPointModal>

      <OutPointModal
        modalOpen={modalOpen}
        setModalOpen={closeModals}
        sx={styles.modal}
      >
        <div style={styles.textContainer}>
          <IconText style={styles.iconContainer}>
            <LogoChip style={styles.logoChip} logo={logo} channel={channel} />
            <Header2>{name}</Header2>
          </IconText>
          <Header3>
            Connect to{" "}
            <span style={styles.capitalize}>
              {normalizeChannelName(channel)}
            </span>
            :
          </Header3>
        </div>

        <PlatformCardModalSection
          number={1}
          title="Start by signing in to your account."
          form={credentialsForm}
          done={!!sectionStates.credentials}
        />

        <div style={styles.line} />

        <PlatformCardModalSection
          number={2}
          title="Enter your Account ID"
          form={metaDataForm}
          done={!!sectionStates.metadata}
        />

        <div style={styles.buttonContainer}>
          <PrimaryButton
            sx={styles.closeButton}
            variant="text"
            color="primary"
            onClick={closeModals}
          >
            Cancel
          </PrimaryButton>
          <PrimaryButton
            sx={styles.closeButton}
            onClick={async () => {
              const success = await submitMetadata(
                currentMetadata,
                formText,
                channel,
                name,
                fields,
                setIsError,
                refetchData,
              );
              if (success) closeModals();
            }}
          >
            Continue
          </PrimaryButton>
        </div>
      </OutPointModal>
    </>
  );
}

PlatformCardModal.propTypes = {
  channel: PropTypes.string,
  name: PropTypes.string,
  whereFrom: PropTypes.string,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
};

export default PlatformCardModal;
