/**
 * OP Drawer is a custom container wrapper onto the mui drawer component.
 *
 * Ref: https://mui.com/material-ui/react-drawer/
 * */
import React from "react";
import PropTypes from "prop-types";
import { Drawer, Grid } from "@mui/material";

const styles = {
  defaultDrawer: {
    width: "31%",
    height: "100%",
    overflowY: "auto",
  },
  defaultPaperStyle: {
    width: "31%",
    scrollbarWidth: "none",
    // for safari & chrome
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
  drawerContainer: {
    height: "100%",
    gap: "8px",
  },
};

export default function OPDrawer(props) {
  const {
    isOpen,
    handleClose,
    anchor,
    drawerClassName,
    paperStyle,
    children,
    ...rest
  } = props;

  const paperProps = { sx: paperStyle || styles.defaultPaperStyle };

  return (
    <Drawer
      anchor={anchor || "right"}
      open={isOpen}
      onClose={handleClose}
      sx={styles.defaultDrawer}
      className={drawerClassName}
      PaperProps={paperProps}
      {...rest}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.drawerContainer}
      >
        {children}
      </Grid>
    </Drawer>
  );
}

OPDrawer.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  anchor: PropTypes.string,
  drawerClassName: PropTypes.string,
  paperStyle: PropTypes.object,
  children: PropTypes.any,
};
