/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { UPLOAD_VALIDITY_STATUS, STATUS } from "utils/enums";

export const getRevenue = createAsyncThunk(
  "asyncRedux/revenueAsync",
  async () => {
    return makeAuthenticatedGetRequest("revenue");
  },
);

const initialState = {
  activeStep: 0,
  selectedChannel: undefined,
  coordinates: [],
  isCustomChannel: false,
  completed: false,
  droppedFile: {
    fileId: "",
    validationStatus: UPLOAD_VALIDITY_STATUS.PENDING,
  },
  status: null,
  authError: null,
};

const revenueSlice = createSlice({
  name: "revenueSlice",
  initialState,
  reducers: {
    revenueLogOut: () => {
      return initialState;
    },
    selectRevenueChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    addDroppedRevenueFileState: (state, action) => {
      state.droppedFile = action.payload;
    },
    addRevenueCoordinates: (state, action) => {
      state.coodinates = action.payload;
    },
    updateIsCustomChannel: (state, action) => {
      state.isCustomChannel = action.payload;
    },
    resetRevenueState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getRevenue.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getRevenue.fulfilled]: (state, { payload }) => {
      state.list = payload.data;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getRevenue.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const {
  updateIsCustomChannel,
  revenueLogOut,
  selectRevenueChannel,
  addDroppedRevenueFileState,
  addRevenueCoordinates,
  resetRevenueState,
} = revenueSlice.actions;
export default revenueSlice.reducer;
