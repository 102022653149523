import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, Box } from "@mui/material";

import OutPointButtonGroup from "library/buttons/OutPointButtonGroup";
import OutPointTooltip from "library/display/OutPointTooltip";
import SubHead2 from "library/text/headers/SubHead2";
import LineAndScatterChart, {
  getEasilyExtendableOptions,
} from "library/graphing/LineChart";
import { createCurves, getClosestValueInList } from "utils/graphing/data";
import { kDigitConversion } from "utils/data/strings";
import IconText from "library/containers/IconText";
import SubHeadLight from "library/text/headers/SubHeadLight";
import LegendBox from "library/graphing/LegendBox";
import LegendItem from "library/graphing/LegendItem";
import { OP_COLORS } from "assets/palette";

const styles = {
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: "30px",
  },
  subHead: {
    textTransform: "uppercase",
  },
  subheaderContainer: {
    minHeight: "40px",
  },
  metricCardHeader: {
    margin: "0 100px 10px 0",
  },
  subheaderText: {
    display: "flex",
    alignItems: "center",
  },
  subheaderSelect: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
  },
  select: {
    position: "absolute",
    right: "0",
    minWidth: "100px",
  },
};

function PredictedPerformanceGraphs({
  channel,
  roas,
  seriesData,
  currentSpend,
  recommendedSpend,
}) {
  const [curves, setCurves] = useState(null);

  const graphTypes = ["Return", "ROAS", "CAC"];
  const subGraphTypes = ["Average", "Marginal"];
  const [graphType, setGraphType] = useState(graphTypes[0]);
  const [subGraphType, setSubGraphType] = useState(subGraphTypes[0]);
  const [nonZeroReturn, setNonZeroReturn] = useState(true);

  const cacMarginalKey = "CAC Marginal";
  const cacAverageKey = "CAC Average";
  const roasMarginalKey = "ROAS Marginal";
  const roasAverageKey = "ROAS Average";

  let digitConversion;
  if (graphType === "Return") {
    digitConversion = kDigitConversion;
  } else if (graphType === "CAC") {
    digitConversion = (digit) => `$${parseFloat(digit.toFixed(2))}`;
  } else {
    digitConversion = (digit) => parseFloat(digit.toFixed(2));
  }

  useEffect(() => {
    if (!seriesData) return;

    setNonZeroReturn(
      roas
        .map((row) => row.total_predicted_revenue)
        .reduce((a, b) => a + b, 0) /
        roas.length >
        10,
    );

    setCurves({
      Return: {
        linePoints: createCurves(seriesData.revenue, {
          spend: "x",
          predicted_revenue: "y",
        }),
        color: OP_COLORS.ROYAL_BLUE,
        name: "Predicted Return",
      },
      [roasAverageKey]: {
        linePoints: createCurves(seriesData.roas_average, {
          spend: "x",
          dividend: "y",
        }),
        color: "orange",
        name: "ROAS",
      },
      [roasMarginalKey]: {
        linePoints: createCurves(seriesData.roas_marginal, {
          spend: "x",
          roas_marginal_y: "y",
        }),
        color: "orange",
        name: "Marginal ROAS",
      },
      [cacAverageKey]: {
        linePoints: createCurves(seriesData.cac_average, {
          spend: "x",
          dividend: "y",
        }),
        color: "green",
        name: "CAC",
      },
      [cacMarginalKey]: {
        linePoints: createCurves(seriesData.cac_marginal, {
          spend: "x",
          marginal_cac_y: "y",
        }),
        color: "green",
        name: "Marginal CAC",
      },
    });
  }, [roas]);

  const [selectedPoint, setSelectedPoint] = useState(null);

  const curve =
    curves &&
    curves[graphType + (graphType === graphTypes[0] ? "" : ` ${subGraphType}`)];

  const infoText =
    "Hover your cursor over the graph to see predictions at different spend levels.";

  const cardInfo = [
    {
      name: "Avg CAC",
      curve:
        curves &&
        selectedPoint !== null &&
        curves[cacAverageKey].linePoints[selectedPoint].y.toFixed(0),
      hint: "(Spend * LTV) / predicted return",
    },
    {
      name: "Marginal CAC",
      curve:
        curves &&
        selectedPoint !== null &&
        curves[cacMarginalKey].linePoints[selectedPoint]?.y?.toFixed(0),
      hint: "(Marginal spend * LTV) / marginal return",
    },
    {
      name: "Avg ROAS",
      curve:
        curves &&
        selectedPoint !== null &&
        curves[roasAverageKey].linePoints[selectedPoint].y.toFixed(1),
      hint: "Predicted return / spend",
    },
    {
      name: "Marginal ROAS",
      curve:
        curves &&
        selectedPoint !== null &&
        curves[roasMarginalKey].linePoints[selectedPoint]?.y?.toFixed(1),
      hint: "Marginal return / marginal spend",
    },
  ];

  const graphOptions = getEasilyExtendableOptions();

  graphOptions.annotationsPluginOptions = [
    {
      annotationSettings: {
        xMin: getClosestValueInList({
          value: recommendedSpend,
          dataset: curve?.linePoints,
          key: "x",
        }),
        xMax: getClosestValueInList({
          value: recommendedSpend,
          dataset: curve?.linePoints,
          key: "x",
        }),
      },
      labelSettings: {
        content: "Recommended Spend",
        position: "end",
        backgroundColor: OP_COLORS.PLASTIC_GREEN,
      },
    },
    {
      annotationSettings: {
        xMin: getClosestValueInList({
          value: currentSpend,
          dataset: curve?.linePoints,
          key: "x",
        }),
        xMax: getClosestValueInList({
          value: currentSpend,
          dataset: curve?.linePoints,
          key: "x",
        }),
        borderColor: OP_COLORS.TANGERINE,
      },
      labelSettings: {
        content: "Current Spend",
        position: "center",
        backgroundColor: OP_COLORS.TANGERINE,
      },
    },
  ];

  graphOptions.onHover = (e, item) => {
    if (item.length) {
      const [currentItem] = item;
      setSelectedPoint(currentItem.index);
    }
  };

  graphOptions.yScaleOptions = {
    formatTicks: digitConversion,
    title: graphType === "ROAS" ? graphType : `${graphType} ($)`,
  };

  graphOptions.xScaleOptions = {
    formatTicks: kDigitConversion,
    ticks: { maxTicksLimit: 18 },
  };

  graphOptions.tooltipPluginOptions = {
    label: (context) => {
      return `${context.dataset.label}: ${
        graphType === "ROAS"
          ? context.formattedValue
          : `$${context.formattedValue}`
      }`;
    },
  };

  return (
    <div>
      {nonZeroReturn ? (
        <>
          <IconText style={styles.headerContainer}>
            <div style={styles.subheaderText}>
              <SubHead2 color="secondary">
                {curves && curve.name.concat(" vs Daily Spend")}
              </SubHead2>
              <OutPointTooltip info={infoText} placement="top" icon="info" />
            </div>
            <div>
              {channel && (
                <OutPointButtonGroup
                  values={graphTypes}
                  curState={graphType}
                  setter={setGraphType}
                />
              )}
              {graphType !== graphTypes[0] && curves && (
                <OutPointButtonGroup
                  values={subGraphTypes}
                  curState={subGraphType}
                  setter={setSubGraphType}
                  style={{ marginLeft: "15px" }}
                />
              )}
            </div>
          </IconText>
          <Grid container>
            {cardInfo.map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key -- there doesn't seem to be an alternative way to give unique key names
              <div key={`${item.name}-${idx}`}>
                <SubHeadLight
                  sx={styles.metricCardHeader}
                  color="secondary"
                  isSentenceCase={false}
                >
                  {item.name}
                </SubHeadLight>
                <SubHeadLight color="secondary">
                  {(item.name.includes("ROAS") ? "" : "$") +
                    ((selectedPoint && item.curve) || "-")}
                </SubHeadLight>
              </div>
            ))}
          </Grid>
          {curves && (
            <LineAndScatterChart
              data={[curve]}
              graphStyle={{
                maxHeight: "400px",
              }}
              moreOptions={graphOptions}
            />
          )}
          {curves && (
            <Box display="flex">
              <LegendBox>
                <LegendItem
                  title={curve.name}
                  key={curve.name}
                  iconColor={curve.color}
                  icon="circle"
                />
                {graphOptions.annotationsPluginOptions.map((options) => (
                  <LegendItem
                    title={options.labelSettings.content}
                    key={options.labelSettings.content}
                    iconColor={options.labelSettings.backgroundColor}
                    icon="verticalDashed"
                  />
                ))}
              </LegendBox>
            </Box>
          )}
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          Since your return from this channel is nearly 0, we cannot
          meaningfully provide all performance metrics.
        </div>
      )}
    </div>
  );
}
PredictedPerformanceGraphs.propTypes = {
  channel: PropTypes.string,
  roas: PropTypes.array,
  seriesData: PropTypes.object,
  currentSpend: PropTypes.number,
  recommendedSpend: PropTypes.number,
};

export default PredictedPerformanceGraphs;
