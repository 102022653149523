import React from "react";
import PropTypes from "prop-types";
import LineAndScatterChart, {
  getEasilyExtendableOptions,
} from "library/graphing/LineChart";

import { dateSecondsToString } from "utils/data/dates";
import { GRAPH_DATE_FORMAT, TOOLTIP_DATE_FORMAT } from "utils/enums";

export default function UploadPreview({ coordinates }) {
  if (!coordinates?.length) {
    return null;
  }

  const curves = [
    {
      linePoints: coordinates,
      color: "blue",
      name: "Spend",
    },
  ];

  const graphOptions = getEasilyExtendableOptions();

  graphOptions.xScaleOptions = {
    formatTicks: (val) => dateSecondsToString(val, GRAPH_DATE_FORMAT),
  };
  graphOptions.tooltipPluginOptions = {
    formatTitle: (val) => dateSecondsToString(val, TOOLTIP_DATE_FORMAT),
  };

  return <LineAndScatterChart data={curves} moreOptions={graphOptions} />;
}
UploadPreview.propTypes = {
  coordinates: PropTypes.array,
};
