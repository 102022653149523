import { Help, Info } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const styles = {
  tooltipStyle: {
    marginLeft: "5px",
    fontSize: "16px",
    color: "black",
    opacity: "0.6",
  },
};

function OutPointTooltip(props) {
  const { info, placement, icon, className, sxToolTip = {} } = props;

  const iconComponent =
    icon === "info" ? (
      <Info
        sx={{ ...styles.tooltipStyle, ...sxToolTip }}
        className={className}
      />
    ) : (
      <Help
        sx={{ ...styles.tooltipStyle, ...sxToolTip }}
        className={className}
      />
    );

  return (
    <Tooltip title={info} placement={placement} arrow>
      {iconComponent}
    </Tooltip>
  );
}

export default OutPointTooltip;

OutPointTooltip.propTypes = {
  info: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  placement: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  sxToolTip: PropTypes.object,
};
