import { Collapse, List } from "@mui/material";
import React from "react";
import { sentenceCase } from "utils/data/strings";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import NavItem from "library/navigation/NavItem";

const styles = {
  getNestedStyle: (theme = undefined) => ({
    paddingLeft: theme.spacing(4),
  }),
};

function NavCollapse(props) {
  const {
    curPath,
    navEntries,
    title,
    startIcon,
    isCollapsed,
    setIsCollapsed,
    EndAdornment,
  } = props;

  const theme = useTheme();

  return (
    <>
      <NavItem
        path={curPath}
        extraClickFunction={() => setIsCollapsed(!isCollapsed)}
        startIcon={startIcon}
        EndAdornment={EndAdornment}
      >
        {title}
      </NavItem>

      <Collapse
        data-cy="collapse-component"
        in={!isCollapsed}
        timeout="auto"
        unmountOnExit
      >
        {navEntries.map(
          ({ toPath, entryText, EndAdornment: EntryEndAdornment }) => (
            <List key={toPath} disablePadding sx={styles.getNestedStyle(theme)}>
              <NavItem
                path={curPath}
                to={toPath}
                EndAdornment={EntryEndAdornment}
              >
                {sentenceCase(entryText)}
              </NavItem>
            </List>
          ),
        )}
      </Collapse>
    </>
  );
}

export default NavCollapse;

NavCollapse.propTypes = {
  curPath: PropTypes.string,
  navEntries: PropTypes.arrayOf(
    PropTypes.shape({
      toPath: PropTypes.string,
      entryText: PropTypes.string,
      EndAdornment: PropTypes.func,
    }),
  ),
  title: PropTypes.string,
  startIcon: PropTypes.element,
  isCollapsed: PropTypes.bool,
  setIsCollapsed: PropTypes.func,
  EndAdornment: PropTypes.func,
};
