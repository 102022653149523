import React from "react";
import { Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";

function OPMenu({ menuOptions, anchorEl, menuState, handleMenuClose }) {
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={menuState}
      onClose={handleMenuClose}
      sx={{
        marginTop: "4px",
        width: "208px",
      }}
    >
      {menuOptions.map(({ display, onClick, value, ...rest }) => {
        return (
          <MenuItem
            key={display}
            sx={{
              width: "208px",
              height: "40px",
              "&:hover": {
                backgroundColor: "#F1F1F9",
              },
            }}
            value={value}
            onClick={onClick}
            {...rest}
          >
            {display}
          </MenuItem>
        );
      })}
    </Menu>
  );
}

export default OPMenu;

OPMenu.propTypes = {
  menuOptions: PropTypes.arrayOf(PropTypes.object),
  anchorEl: PropTypes.any,
  menuState: PropTypes.bool,
  handleMenuClose: PropTypes.func,
};
