import React from "react";
import PropTypes from "prop-types";

import { Chip, Typography } from "@mui/material";
import { Cancel as CancelIcon } from "@mui/icons-material";

import { filterDeletionStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import { Predicate } from "classes/Predicate";

function FilterDeletionChip(props) {
  const { predicate, onDelete } = props;

  const label = (
    <Typography sx={styles.filterDeletionLabelText}>
      {predicate.stringify()}
    </Typography>
  );

  return predicate.isApplicable() ? (
    <Chip
      sx={styles.filterDeletionChip}
      label={label}
      onDelete={onDelete}
      deleteIcon={<CancelIcon sx={{ color: "#2F2F3" }} />}
    />
  ) : null;
}

FilterDeletionChip.propTypes = {
  predicate: PropTypes.instanceOf(Predicate),
  onDelete: PropTypes.func,
};

export default FilterDeletionChip;
