import React, { useEffect } from "react";
import { Chip } from "@mui/material";
import PropTypes from "prop-types";

export default function ChipButtonGroup(props) {
  const { options, selections, setSelections } = props;

  const updateSelections = (id, value) => {
    setSelections((prev) => {
      const newSelections = { ...prev };
      newSelections[id] = value;
      return newSelections;
    });
  };

  useEffect(() => {
    setSelections((_) =>
      options.reduce((acc, { id }) => {
        acc[id] = false;
        return acc;
      }, {}),
    );
  }, []);

  return (
    <>
      {options.map(({ label, id }) => (
        <Chip
          style={{ marginRight: "7px", marginBottom: "7px" }}
          variant={!selections[id] && "outlined"}
          color="primary"
          onClick={() => {
            updateSelections(id, !selections[id]);
          }}
          label={label}
          onDelete={
            selections[id] ? () => updateSelections(id, !selections[id]) : false
          }
        />
      ))}
    </>
  );
}

ChipButtonGroup.propTypes = {
  options: PropTypes.array,
  selections: PropTypes.object,
  setSelections: PropTypes.func,
};
