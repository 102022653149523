import { CAMPAIGN_ATTRIBUTES } from "utils/enums";
import { isSubstringOf } from "utils/data/strings";

export const CONDITION_TYPES = {
  EQUALS_TO: "equals to",
  NOT_EQUALS_TO: "not equals to",
  CONTAINS: "contains",
  DOES_NOT_CONTAIN: "does not contain",
};

/**
 * Predicate class that keeps boolean logic used for filtration.
 * */
export class Predicate {
  constructor(attributeName = null, condition = null, value = null) {
    this.attributeName = attributeName;
    this.condition = condition;
    this.value = value;
    this.isApplied = true;
    this.isComplete = Boolean(attributeName && condition && value);
  }

  /*
   * A fini function that clears state as a way to reset the predicate:
   */
  fini() {
    this.attributeName = null;
    this.condition = null;
    this.value = null;
    this.isApplied = true;
    this.isComplete = false;
  }

  /// A predicate is applicable if it's complete and has been marked as to-be-applied
  isApplicable() {
    return Boolean(this.isApplied && this.isComplete);
  }

  setIsApplied(state) {
    if (typeof state !== "boolean") {
      return;
    }

    this.isApplied = state;
  }

  setAttributeName(attributeName) {
    if (!attributeName) {
      return;
    }
    // TODO: add validation for setter, it should be a supported condition
    this.attributeName = attributeName;
    this.isComplete = this.isCompletePredicate();
  }

  setCondition(condition) {
    if (!condition) {
      return;
    }
    // TODO: add validation for setter, it should be a supported condition
    this.condition = condition;
    this.isComplete = this.isCompletePredicate();
  }

  setValue(targetValue) {
    // TODO: add validation for setter, it should be a supported condition
    this.value = !targetValue ? null : targetValue;
    this.value = targetValue;
    this.isComplete = this.isCompletePredicate();
  }

  /**
   * Returns true if attributes are non-null
   * (and hence a representative callback can be generated).
   * */
  isCompletePredicate() {
    return Boolean(this.attributeName && this.condition && this.value);
  }

  /**
   * Returns a predicate function that can be used to filter an array of campaigns.
   * */
  getPredicateFunction() {
    // TODO: guard against incomplete predicates
    return (campaignOption) => {
      const key = CAMPAIGN_ATTRIBUTES[this.attributeName]?.keyName;
      const currentAttributeValue = campaignOption[key];
      const outcome = Predicate.applyConditionalFunction(
        this.condition,
        currentAttributeValue,
        this.value,
      );
      return outcome;
    };
  }

  /**
   * Returns a meaningful string representation of the predicate if applicable,
   * else returns a default string.
   * */
  stringify() {
    if (!this.isApplicable()) {
      return "not applicable filter";
    }

    if (this.condition === CONDITION_TYPES.EQUALS_TO) {
      return `${this.attributeName} = ${this.value}`;
    }

    if (this.condition === CONDITION_TYPES.NOT_EQUALS_TO) {
      return `${this.attributeName} ≠ ${this.value}`;
    }

    if (this.condition === CONDITION_TYPES.CONTAINS) {
      return `"${this.value}" in ${this.attributeName}`;
    }

    if (this.condition === CONDITION_TYPES.DOES_NOT_CONTAIN) {
      return `"${this.value}" not in ${this.attributeName}`;
    }

    return "Not applicable filter"; // fallthrough
  }

  static applyConditionalFunction(condition, operandA, operandB) {
    switch (condition) {
      case CONDITION_TYPES.EQUALS_TO:
        return operandA === operandB;

      case CONDITION_TYPES.NOT_EQUALS_TO:
        return operandA !== operandB;

      case CONDITION_TYPES.CONTAINS:
        return isSubstringOf(operandA, operandB, { isCaseInsensitive: true });

      case CONDITION_TYPES.DOES_NOT_CONTAIN:
        return !isSubstringOf(operandA, operandB, { isCaseInsensitive: true });

      default:
        /// ///////////////////////////////////////////////////////////////
        // The fallthrough is set to true because all the filters       //
        // are set to AND conditions. By having this 'default' of true, //
        // a faulty filter doesn't affect the outcome of other filters. //
        /// ///////////////////////////////////////////////////////////////

        return true;
    }
  }

  /**
   * Given a list of applicable predicates (i.e. complete and marked as wanted),
   * returns a new predicate that is a composite function of these predicates.
   *
   * Note: for now, it shall just a conjunction (AND operation) on all predicates.
   *
   * Precondition:
   *  - predicates are applicable (i.e complete and marked as wanted / to be applied)
   * */
  static mergePredicates(predicates) {
    if (!predicates) {
      return null; // set predicate fn to null to skip filtering step
      // return campaignOption => true; // TODO: double-check the default value to apply here
    }

    return (campaignOption) => {
      const predicateFunctions = predicates.map((predicate) => {
        return predicate.getPredicateFunction();
      });
      const reducedOutcome = predicateFunctions.reduce(
        (accum, currentFn) => accum && currentFn(campaignOption),
        true,
      );

      return reducedOutcome;
    };
  }
}
