import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { matchSorter } from "match-sorter";
import {
  Grid,
  Box,
  Checkbox,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
} from "@mui/material";

import { campaignGroupTableStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import {
  bulkAssignCampaignsToGroup,
  toggleIsSubmitting,
  selectCampaignIdToCampaignMap,
  selectObjects,
  selectObjectProperty,
} from "redux/campaignsSlice";
import { getComparator } from "utils/data/arrays";
import { SORT_ORDER } from "utils/enums";

import OPDialog from "library/surface/OutPointDialog";
import PrimaryButton from "library/buttons/PrimaryButton";
import CustomTableHeader from "pages/campaigns/components/CustomTableHeader";
import CustomToolbar from "pages/campaigns/components/CustomTableToolbar";
import AutoComplete from "library/form/OutPointAutoComplete";

const matchSorterOptions = {
  keys: ["campaign"],
};

/**
 * Acknowledgement: adapted from a demo from the mui documentation -- https://codesandbox.io/s/0f7uhf?file=/demo.js
 * */
function CustomTable(props) {
  const {
    headerCells,
    rows,
    initialOrderByProp,
    displayDense: dense,
    initialRowsPerPage,
    keysToDisplay,
    campaignGroupId,
  } = props;

  const dispatch = useDispatch();

  const campaignGroups = useSelector(selectObjects("campaign_groups"));
  const campaignGroupIds = useSelector(
    selectObjectProperty("campaign_groups", "id"),
  );
  const campaignGroupToStrategyMap = useSelector(
    selectObjects("campaign_group_id_to_strategy_id_map"),
  );
  const campaignIdToCampaignMap = useSelector(selectCampaignIdToCampaignMap);
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASC);
  const [orderBy, setOrderBy] = useState(initialOrderByProp);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
  const [searchInput, setSearchInput] = useState("");
  const [canChooseDestinationGroup, setCanChooseDestinationGroup] =
    useState(false);
  const [showEditConfirmationDialog, setShowEditConfirmationDialog] =
    useState(false);
  const [editDestinationGroup, setEditDestinationGroup] = useState("ungrouped");

  const searchResults = searchInput
    ? matchSorter(rows, searchInput, matchSorterOptions)
    : null;
  const sortedRows = searchInput
    ? searchResults
    : rows.slice().sort(getComparator(sortOrder, orderBy));
  const sortedRowsInRange = sortedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  const isRowSelected = (rowName) => selectedRows.indexOf(rowName) !== -1;

  /**
   * Handles the clicking of the rows, where it adds the row name to the
   * selectedRows state only if the row doesn't exist in the selectedRows state yet.
   * If it exists, then it effects on actual logical change.
   * */
  const handleClick = (_, campaignId) => {
    const selectedIndex = selectedRows.indexOf(campaignId);
    let newSelected = [];

    const rowHasNotBeenSelected = selectedIndex === -1;
    const rowIsAtBeginningOfSelection = selectedIndex === 0;
    const rowIsAtEndOfSelection = selectedIndex === selectedRows.length - 1;
    const rowIsInTheMiddleOfSelection = selectedIndex > 0;

    if (rowHasNotBeenSelected) {
      newSelected = newSelected.concat(selectedRows, campaignId);
    } else if (rowIsAtBeginningOfSelection) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (rowIsAtEndOfSelection) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (rowIsInTheMiddleOfSelection) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(newSelected);
  };

  const displayRow = (row) => {
    const isItemSelected = isRowSelected(row.id);

    return (
      <TableRow
        key={row.id}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        tabIndex={-1}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox color="primary" checked={isItemSelected} />
        </TableCell>

        {keysToDisplay.map((keyName) => (
          <TableCell key={`${keyName}`} align="left">
            {row[keyName]}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const sortedRowsDisplay = sortedRowsInRange.map(displayRow);

  const closeConfirmationDialog = () => {
    // eslint-disable-next-line no-console
    console.assert(
      showEditConfirmationDialog,
      "Should only close a currently open dialog",
    );
    // pre-emptively set to defaults:
    setEditDestinationGroup("ungrouped");
    setCanChooseDestinationGroup(false);
    setShowEditConfirmationDialog(false);
  };

  const openConfirmationDialog = () => {
    // eslint-disable-next-line no-console
    console.assert(
      !showEditConfirmationDialog,
      "Should only open a currently closed dialog",
    );
    setShowEditConfirmationDialog(true);
  };

  const handleSearchInputChange = (input) => {
    setSearchInput(input);
  };

  /*
   * Sets orderBy for new property, if it hadn't been in focus yet. If
   * property is already the orderBy attribute, then flips the sortOrder.
   */
  const handleRequestSort = (e, property) => {
    const isCurrentlyAsc = orderBy === property && sortOrder === SORT_ORDER.ASC;
    setSortOrder(isCurrentlyAsc ? SORT_ORDER.DSC : SORT_ORDER.ASC);
    setOrderBy(property);
  };

  /**
   * Toggles bulk selection of all the entries in the table, across all pages.
   *
   * NOTE: rows prop should have id as an attribute. -- for now it's not a generic component
   * only campaigns are passed as props so this is safe.
   * */
  const handleSelectAllClick = (e) => {
    if (e?.target?.checked) {
      const allSelectedIds = (searchResults || rows).map(
        (campaign) => campaign.id,
      );
      setSelectedRows(allSelectedIds);
      return;
    }
    setSelectedRows([]);
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e?.target?.value, 10));
    setPage(0);
  };

  // determines the number of empty rows to be added as "padding" for the table:
  let emptyRows = 0;
  if (searchResults) {
    emptyRows = Math.max(0, (1 + page) * rowsPerPage - searchResults.length);
  } else {
    emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  }
  // TODO: extract to styles
  const emptyRowsDisplay = emptyRows > 0 && (
    <TableRow
      style={{
        height: (dense ? 33 : 53) * emptyRows,
      }}
    >
      <TableCell colSpan={6} />
    </TableRow>
  );

  // TODO: generalize this eventually:
  const tablePagination = (
    <TablePagination
      rowsPerPageOptions={[20, 50, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  const handleMoveSelectedRowsClick = () => {
    setCanChooseDestinationGroup(true);
    openConfirmationDialog();
  };

  const handleSelectedRowsDeletionClick = () => {
    openConfirmationDialog();
  };

  /**
   * Handles the confirmation action for either delete or edit actions.
   *
   * First, the bulk allocation action is dispatched to associate the relevant campaigns
   * with the new campaign group.
   *
   * Next, the relevant POST API call is made
   *
   * Finally, the dialog is closed, which effectively resets the state back to the initial / default states.
   * */
  const handleConfirmation = async () => {
    if (!selectedRows) {
      return;
    }

    const assignmentPayload = {
      selectedCampaigns: selectedRows.map((id) => campaignIdToCampaignMap[id]),
      newCampaignGroupId: editDestinationGroup,
    };

    dispatch(bulkAssignCampaignsToGroup(assignmentPayload));

    const updatedSelections = selectedRows.map((id) => ({
      ...campaignIdToCampaignMap[id],
      campaign_group: editDestinationGroup,
      strategy: campaignGroupToStrategyMap[editDestinationGroup],
      is_brand: campaignGroups.find(
        (group) => group.id === editDestinationGroup,
      ).is_brand,
    }));

    try {
      dispatch(toggleIsSubmitting());
      const groupingPostResponse = await makeAuthenticatedPostRequest(
        "campaigns",
        { changes: updatedSelections },
      );
      const { success: groupingPostSuccess } = groupingPostResponse;

      if (!groupingPostSuccess) {
        const errorMsg =
          "The updating of campaigns grouping to the db was unsuccessful. Please try again or reach out to us.";
        throw new Error(errorMsg);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error when submitting changes for campaign grouping:", e);
    } finally {
      setSelectedRows([]);
      dispatch(toggleIsSubmitting());
      closeConfirmationDialog();
    }
  };

  const cancelEditButton = (
    <PrimaryButton
      variant="outlined"
      onClick={closeConfirmationDialog}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>Cancel and go back</Typography>
    </PrimaryButton>
  );

  const confirmEditButton = (
    <PrimaryButton onClick={handleConfirmation} sx={styles.deleteButton}>
      {canChooseDestinationGroup ? (
        <Typography sx={styles.buttonLabel}>
          Move {selectedRows.length > 1 ? "campaigns" : "campaign"}
        </Typography>
      ) : (
        <Typography sx={styles.buttonLabel}>
          Remove {selectedRows.length > 1 ? "campaigns" : "campaign"}
        </Typography>
      )}
    </PrimaryButton>
  );

  const editActionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancelEditButton}
      {confirmEditButton}
    </Grid>
  );

  const confirmEditTitle = canChooseDestinationGroup ? (
    <Typography sx={styles.titleText}>
      Move {selectedRows.length > 1 ? "campaigns" : "campaign"} from group?
    </Typography>
  ) : (
    <Typography sx={styles.titleText}>
      Remove {selectedRows.length > 1 ? "campaigns" : "campaign"} from group?
    </Typography>
  );

  const handleDestinationGroupSelection = (e, destinationGroup) => {
    setEditDestinationGroup(destinationGroup);
  };

  const destinationGroupSelector = (
    <AutoComplete
      sx={styles.destinationGroupSelector}
      name="Target Group"
      options={campaignGroupIds}
      onChange={handleDestinationGroupSelection}
      value={editDestinationGroup}
    />
  );

  const editConfirmationDialog = (
    <OPDialog
      isOpen={showEditConfirmationDialog}
      fullWidth
      scroll="body"
      handleClose={closeConfirmationDialog}
      dialogTitleChildren={confirmEditTitle}
      dialogActionsChildren={editActionButtons}
    >
      <Grid container direction="column">
        {canChooseDestinationGroup && destinationGroupSelector}
        {selectedRows.length > 1 ? (
          <Typography>
            These {selectedRows.length} campaigns will be moved from{" "}
            <b>{campaignGroupId}</b> to <b>{editDestinationGroup}</b>.
          </Typography>
        ) : (
          <Typography>
            This campaign will be moved from <b>{campaignGroupId}</b> to{" "}
            <b>{editDestinationGroup}</b>.
          </Typography>
        )}
      </Grid>
    </OPDialog>
  );

  return (
    <Box sx={{ width: "100%", mb: 2 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <CustomToolbar
          campaignGroupId={campaignGroupId}
          selectedRows={selectedRows}
          handleSearchInputChange={handleSearchInputChange}
          handleSelectedRowsDeletionClick={handleSelectedRowsDeletionClick}
          handleMoveSelectedRowsClick={handleMoveSelectedRowsClick}
        />
        <TableContainer sx={styles.tableContainer}>
          <Table
            sx={styles.table}
            size={dense ? "small" : "medium"}
            stickyHeader
          >
            <CustomTableHeader
              searchResults={searchResults}
              headerCells={headerCells}
              onSelectAllClick={handleSelectAllClick}
              sortOrder={sortOrder}
              orderBy={orderBy}
              numSelected={selectedRows.length}
              rowCount={rows.length}
              onRequestSort={handleRequestSort}
              handleSearchInputChange={handleSearchInputChange}
              handleSelectedRowsDeletionClick={handleSelectedRowsDeletionClick}
            />

            <TableBody>
              {sortedRowsDisplay}
              {emptyRowsDisplay}
            </TableBody>
          </Table>
        </TableContainer>
        {sortedRows.length > initialRowsPerPage ? tablePagination : null}
      </Paper>
      {editConfirmationDialog}
    </Box>
  );
}

CustomTable.propTypes = {
  campaignGroupId: PropTypes.string,
  keysToDisplay: PropTypes.arrayOf(PropTypes.string),
  headerCells: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  initialOrderByProp: PropTypes.string,
  displayDense: PropTypes.bool,
  initialRowsPerPage: PropTypes.number,
};

export default CustomTable;
