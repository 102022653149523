import React from "react";
import { Typography } from "@mui/material";
import { sentenceCase } from "utils/data/strings";
import PropTypes from "prop-types";

const styles = {
  titleStyle: {
    width: "auto",
    fontSize: "3rem",
    lineHeight: "3.75rem",
    fontWeight: "bold",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-0.02em",
  },
};

export default function Title({
  text,
  className,
  isSentenceCase = true,
  sx = {},
  ...rest
}) {
  const mergedStyles = { ...styles.titleStyle, ...sx };

  return (
    <Typography sx={mergedStyles} className={className} {...rest}>
      {isSentenceCase ? sentenceCase(text) : text}
    </Typography>
  );
}

Title.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  isSentenceCase: PropTypes.bool,
  sx: PropTypes.object,
};
