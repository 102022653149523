import React, { useState } from "react";
import { Grid } from "@mui/material";
import OutPointCard from "library/surface/OutPointCard";
import OptimalMediaMix from "pages/overview/components/OptimalMediaMix";
import RecommendationsOverview from "pages/overview/components/RecommendationsOverview";
import { useSelector } from "react-redux";
import { sortOptimalMediaMix } from "utils/dataset/recommendations";
import AveragesCard from "./components/AveragesCard";
import SectionTemplate from "./components/SectionTemplate";
import { AGGREGATION_DAY_RANGES } from "../../utils/enums";

function AveragesSection() {
  const recomData = useSelector((state) => state.recommendationsData);
  const overviewResponse = useSelector((state) => state.overviewData);
  const overviewData = overviewResponse?.data;
  const goal = overviewData && overviewData.goal;

  const [chosenTimeRange, setChosenTimeRange] = useState("monthly");
  const [mixRange, setMixRange] = useState("Last 3 months");
  const [groupByStrategy, setGroupByStrategy] = useState(true);

  let goalWord;
  let recomDataField;
  let scenarioWord;
  if (goal === "efficiency") {
    goalWord = "profit";
    scenarioWord = "profit";
    recomDataField = "profit";
  } else if (goal === "growth") {
    goalWord = "return";
    scenarioWord = "revenue";
    recomDataField = "total_revenue";
  } else {
    goalWord = null;
    recomDataField = "total_revenue";
  }

  const baselineGroup = groupByStrategy
    ? recomData.dataByStrategy?.baseline
    : recomData.data?.baseline;
  const baselineData = recomData.status === "success" ? baselineGroup : null;
  const scenarioGroup = groupByStrategy
    ? recomData.dataByStrategy?.[scenarioWord]
    : recomData.data?.[scenarioWord];
  const scenarioData = recomData.status === "success" ? scenarioGroup : null;
  const channelDetails =
    baselineData && scenarioData
      ? sortOptimalMediaMix(
          baselineData.category_data,
          scenarioData.category_data,
          "spend",
        )
      : {};

  const sectionContent = (
    <Grid container item xs={12}>
      <Grid
        container
        direction="column"
        item
        justifyContent="space-between"
        xs={12}
        lg={6}
        sx={{
          paddingRight: "16px",
        }}
      >
        <Grid container item>
          <RecommendationsOverview
            goalWord={goalWord}
            currentData={baselineData}
            predictedData={scenarioData}
            dataGoalKey={recomDataField}
            timeRangeLabel={chosenTimeRange}
            timeRangeDays={AGGREGATION_DAY_RANGES[chosenTimeRange]}
            mixRange={mixRange}
          />
        </Grid>
        <Grid item sx={{ marginTop: "16px" }}>
          <OutPointCard>
            <OptimalMediaMix
              channelDetails={channelDetails}
              groupByStrategy={groupByStrategy}
              setGroupByStrategy={setGroupByStrategy}
            />
          </OutPointCard>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <OutPointCard>
          <AveragesCard
            timeRangeLabel={chosenTimeRange}
            timeRangeDays={AGGREGATION_DAY_RANGES[chosenTimeRange]}
            mixRange={mixRange}
          />
        </OutPointCard>
      </Grid>
    </Grid>
  );

  return (
    <SectionTemplate
      sectionHeading="Home"
      sectionContent={sectionContent}
      chosenTimeRange={chosenTimeRange}
      setChosenTimeRange={setChosenTimeRange}
      mixRange={mixRange}
      setMixRange={setMixRange}
    />
  );
}

export default AveragesSection;
