import React from "react";

import Header1 from "library/text/headers/Header1";
import Header4 from "library/text/headers/Header4";
import SubHead from "library/text/headers/SubHeadLight";
import useUrlPrettifier from "hooks/useUrlPrettifier";
import PlatformList from "pages/connections/components/PlatformList";
import PlatformCards from "./components/PlatformCards";

const styles = {
  logo: {
    height: "30px",
  },
  headerMain: {
    marginBottom: "5px",
  },
  smallHeader: {
    marginTop: "50px",
    marginBottom: "5px",
  },
  subhead: {
    marginBottom: "24px",
  },
};

function ConnectionsPage() {
  useUrlPrettifier();

  return (
    <>
      <Header1 sx={styles.headerMain}>Integrations</Header1>
      <SubHead sx={styles.subhead} color="secondary">
        Easily migrate your data to OutPoint by connecting the apps you use to
        track and measure revenue and ad spend.
      </SubHead>
      <PlatformList whereFrom="connections" />
      <Header4 sx={styles.smallHeader}>Available apps</Header4>
      <SubHead sx={styles.subhead} color="secondary">
        These apps are currently not connected to your OutPoint account.
      </SubHead>
      <PlatformCards whereFrom="connections" />
    </>
  );
}

export default ConnectionsPage;
