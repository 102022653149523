import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import ScenarioBanner from "./components/ScenarioBanner";
import AveragesSection from "./AveragesSection";
import RecommendationsSection from "./RecommendationsSection";
import HistoricalsAndActualsSection from "./HistoricalsAndActualsSection";
import MacroRoasSection from "./MacroRoasSection";

export default function OverviewPage() {
  const overviewResponse = useSelector((state) => state.overviewData);
  const showMacroGraphs = useSelector(
    (state) => state.features.flags,
  )?.show_macrographs;

  const overviewData = overviewResponse?.data;
  const goal = overviewData && overviewData.goal;

  return (
    <Grid container spacing={4}>
      <ScenarioBanner scenario={goal} />
      <AveragesSection />
      {showMacroGraphs && <MacroRoasSection />}
      <RecommendationsSection />
      <HistoricalsAndActualsSection />
    </Grid>
  );
}
