/**
 * This file is intended to be a specialized version of the PredictedPerformanceGraphs.
 * While a lot of the code is duplicated, the graph logic
 * seemed non-trivial to generify and hence I'll make do with this duplication
 * for the meantime.
 *
 * TODO [stretch goal]: merge the two graphs together by making a more generalized version of prediction performance graphs:w
 * */

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import SubHead2 from "library/text/headers/SubHead2";
import LineAndScatterChart, {
  getEasilyExtendableOptions,
} from "library/graphing/LineChart";
import { createCurves, getClosestValueInList } from "utils/graphing/data";
import { kDigitConversion } from "utils/data/strings";
import { predictionGraphStyles as styles } from "pages/recommendations/styles/recommendationsComponentsStyles";
import LegendItem from "library/graphing/LegendItem";
import { OP_COLORS } from "assets/palette";
import { METRIC_COLOURS } from "assets/colours";
import PropTypes from "prop-types";

function PredictedReturnVsSpendGraph(props) {
  const { roas, seriesData, currentSpend, recommendedSpend } = props;

  const [curves, setCurves] = useState(null);

  const [nonZeroReturn, setNonZeroReturn] = useState(true);

  useEffect(() => {
    if (!seriesData) return;

    setNonZeroReturn(
      roas
        .map((row) => row.total_predicted_revenue)
        .reduce((a, b) => a + b, 0) /
        roas.length >
        10,
    );

    setCurves({
      Return: {
        linePoints: createCurves(seriesData.revenue, {
          spend: "x",
          predicted_revenue: "y",
        }),
        color: METRIC_COLOURS.revenue,
        name: "Predicted Return",
      },
    });
  }, [roas]);

  const [selectedPoint, setSelectedPoint] = useState(null);

  const curve = curves?.Return;

  const graphOptions = getEasilyExtendableOptions();

  graphOptions.annotationsPluginOptions = [
    {
      annotationSettings: {
        xMin: getClosestValueInList({
          value: recommendedSpend,
          dataset: curve?.linePoints,
          key: "x",
        }),
        xMax: getClosestValueInList({
          value: recommendedSpend,
          dataset: curve?.linePoints,
          key: "x",
        }),
        borderColor: OP_COLORS.ROYAL_BLUE,
      },
      labelSettings: {
        backgroundColor: OP_COLORS.ROYAL_BLUE,
      },
    },
    {
      annotationSettings: {
        xMin: getClosestValueInList({
          value: currentSpend,
          dataset: curve?.linePoints,
          key: "x",
        }),
        xMax: getClosestValueInList({
          value: currentSpend,
          dataset: curve?.linePoints,
          key: "x",
        }),
        borderColor: OP_COLORS.HOT_PINK,
      },
      labelSettings: {
        position: "center",
        backgroundColor: OP_COLORS.HOT_PINK,
      },
    },
  ];

  const formatSpendTicks = (x) => `${kDigitConversion(x)}`;

  graphOptions.onHover = (e, item) => {
    if (item.length) {
      const [currentItem] = item;
      setSelectedPoint(currentItem.index);
    }
  };

  graphOptions.yScaleOptions = {
    formatTicks: formatSpendTicks,
  };

  graphOptions.xScaleOptions = {
    ticks: { maxTicksLimit: 6 },
    formatTicks: formatSpendTicks,
  };

  const hoverPointLegend = (
    <LegendItem
      title="Predicted return"
      key="1"
      icon="circle"
      iconColor={METRIC_COLOURS.revenue}
      body={`$${curves?.Return?.linePoints[selectedPoint]?.y || "--"}`}
    />
  );

  const currentSpendLegend = (
    <LegendItem
      title="Current Spend"
      key="1"
      body={`$${currentSpend}`}
      icon="verticalDashed"
      iconColor={OP_COLORS.HOT_PINK}
    />
  );

  const recommendedSpendLegend = (
    <LegendItem
      title="Recommended Spend"
      key="1"
      body={`$${recommendedSpend}`}
      icon="verticalDashed"
      iconColor={OP_COLORS.ROYAL_BLUE}
    />
  );

  const legend = (
    <Grid container direction="row" sx={styles.legendContainer}>
      <Grid item xs={4}>
        {hoverPointLegend}
      </Grid>

      <Grid item xs={4}>
        {currentSpendLegend}
      </Grid>

      <Grid item xs={4}>
        {recommendedSpendLegend}
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={styles.predictedGraphContainer}
    >
      {nonZeroReturn ? (
        <>
          <Grid item sx={styles.subheaderText}>
            <SubHead2 color="secondary">
              {curves && curve.name.concat(" vs Daily Spend")}
            </SubHead2>
          </Grid>
          <Grid container sx={styles.graphContainer} maxHeight="300px">
            {curves && (
              <LineAndScatterChart
                data={[curve]}
                graphStyle={{
                  maxHeight: "262px",
                  maxWidth: "100%",
                  margin: "0px",
                }}
                moreOptions={graphOptions}
              />
            )}
            {legend}
          </Grid>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          Since your return from this channel is nearly 0, we cannot
          meaningfully provide all performance metrics.
        </div>
      )}
    </Grid>
  );
}

export default PredictedReturnVsSpendGraph;

PredictedReturnVsSpendGraph.propTypes = {
  roas: PropTypes.arrayOf(PropTypes.object),
  seriesData: PropTypes.object,
  currentSpend: PropTypes.number,
  recommendedSpend: PropTypes.number,
};
