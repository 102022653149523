/*
 * Contains easily referenceable helpers used in components that make up the Overview Page.
 */
import {
  dateSecondsToString,
  getQuarterDesc,
  getWeekDesc,
} from "utils/data/dates";
import {
  GRAPH_DATE_FORMAT,
  GRAPH_YEAR_FORMAT,
  GRAPH_MONTH_FORMAT,
  TOOLTIP_DATE_FORMAT,
} from "utils/enums";

export const tickFormatters = {
  daily: (val) => dateSecondsToString(new Date(val), GRAPH_DATE_FORMAT),
  weekly: (val) => dateSecondsToString(val, GRAPH_DATE_FORMAT), // shows starting date
  monthly: (val) => dateSecondsToString(val, GRAPH_MONTH_FORMAT),
  quarterly: (val) => getQuarterDesc(new Date(val)),
  yearly: (val) => dateSecondsToString(val, GRAPH_YEAR_FORMAT),
};

export const tooltipTitleFormatters = {
  daily: (val) => dateSecondsToString(val, TOOLTIP_DATE_FORMAT),
  weekly: (val) => getWeekDesc(new Date(val)),
  monthly: (val) => dateSecondsToString(val, GRAPH_MONTH_FORMAT),
  quarterly: (val) => getQuarterDesc(new Date(val)),
  yearly: (val) => dateSecondsToString(val, GRAPH_YEAR_FORMAT),
};
