import React, { useState } from "react";

import { Grid } from "@mui/material";

import OutpointModal from "library/surface/ModalCentered";
import BodyText from "library/text/body/BodyText";
import Header1 from "library/text/headers/Header1";
import TextFieldSimple from "library/form/OutPointTextField";
import PrimaryButton from "library/buttons/PrimaryButton";
import PropTypes from "prop-types";

const styles = {
  button: {
    width: "170px",
  },
  buttonContainer: {
    justifyContent: "center",
    display: "flex",
    marginTop: "40px",
  },
  body: {
    color: "secondary.main",
    margin: "20px 0 30px 0",
  },
};

const formFields = [
  {
    label: "Full name",
    id: "name",
    size: 6,
  },
  {
    label: "Email",
    id: "email",
    size: 6,
  },
  {
    label: "Message",
    id: "message",
    size: 12,
    multiline: true,
    maxRows: 4,
    minRows: 4,
  },
];

export default function ContactUsModal(props) {
  const { setModalOpen } = props;

  const [formInfo, setFormInfo] = useState({});

  const changeFormInfo = (id, newValue) => {
    setFormInfo((oldState) => {
      const newState = { ...oldState };
      newState[id] = newValue;
      return newState;
    });
  };

  const canSubmit = formFields.reduce(
    (currCanSubmit, { id }) => currCanSubmit && formInfo[id]?.length > 0,
    true,
  );

  const submit = () => {
    // TODO: implement contact
    setModalOpen(false);
  };

  return (
    <OutpointModal {...props} title={<Header1>How can we help?</Header1>}>
      <BodyText sx={styles.body}>
        We’re here to give you personalized support. A member of our team will
        respond within 1-2 business days.
      </BodyText>
      <Grid container spacing={2}>
        {formFields.map(({ label, id, size, ...rest }) => (
          <Grid xs={size} item>
            <TextFieldSimple
              name={label}
              value={formInfo[id]}
              onChange={(value) => changeFormInfo(id, value)}
              {...rest}
            />
          </Grid>
        ))}
      </Grid>
      <div style={styles.buttonContainer}>
        <PrimaryButton
          disabled={!canSubmit}
          onClick={submit}
          sx={styles.button}
        >
          Submit
        </PrimaryButton>
      </div>
    </OutpointModal>
  );
}

ContactUsModal.propTypes = {
  setModalOpen: PropTypes.func,
};
