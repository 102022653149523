import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, Accordion, AccordionDetails } from "@mui/material";

import OPAutoComplete from "library/form/OutPointAutoComplete";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PrimaryButton from "library/buttons/PrimaryButton";
import OPDialog from "library/surface/OutPointDialog";
import CampaignGroupTable from "pages/campaigns/components/CampaignGroupTable";
import AccordionSummary from "pages/campaigns/components/CustomAccordionSummary";

import {
  toggleIsSubmitting,
  deregisterCampaignGroup,
  selectCampaignGroupIdToCampaignMap,
} from "redux/campaignsSlice";
import { makeAuthenticatedPostRequest } from "utils/backend-api";

import { deletorStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

/**
 * Campaign Group Deletor allows the user to select any group that is not default,
 * i.e. not "ungrouped" to be deleted. There's a confirmation dialog that comes up
 * to make the user double-check the deletion.
 * */
function CampaignGroupDeletor(props) {
  const dispatch = useDispatch();

  const { handleCloseDrawer } = props;
  const grouping = useSelector(selectCampaignGroupIdToCampaignMap);
  const campaignGroups = Object.keys(grouping);
  const deletableCampaignGroups = campaignGroups.filter(
    (group) => group !== "ungrouped",
  );

  const [selectedGroup, setSelectedGroup] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const resetState = () => {
    setSelectedGroup(null);
    setShowConfirmationDialog(false);
  };

  const openConfirmationDialog = () => {
    // eslint-disable-next-line no-console
    console.assert(
      !showConfirmationDialog,
      "Should only open a currently closed dialog",
    );
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    // eslint-disable-next-line no-console
    console.assert(
      showConfirmationDialog,
      "Should only close a currently open dialog",
    );
    setShowConfirmationDialog(false);
  };

  const handleCampaignGroupSelection = (e, groupId) => {
    setSelectedGroup(groupId);
  };

  const handleDeletionConfirmation = async () => {
    if (!selectedGroup) {
      return;
    }

    dispatch(deregisterCampaignGroup({ targetId: selectedGroup }));

    if (grouping[selectedGroup].length === 0) {
      resetState();
      handleCloseDrawer();

      return;
    }

    const updatedCampaigns = grouping[selectedGroup].map((campaign) => ({
      ...campaign,
      campaign_group: "ungrouped",
      strategy: "ungrouped",
      is_brand: false,
    }));

    try {
      dispatch(toggleIsSubmitting());
      const groupingPostResponse = await makeAuthenticatedPostRequest(
        "campaigns",
        { changes: updatedCampaigns },
      );
      const { success: groupingPostSuccess } = groupingPostResponse;

      if (!groupingPostSuccess) {
        const errorMsg =
          "The updating of campaigns grouping to the db was unsuccessful. Please try again or reach out to us.";
        throw new Error(errorMsg);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error when submitting changes for campaign grouping:", e);
    } finally {
      resetState();
      dispatch(toggleIsSubmitting());
      handleCloseDrawer();
    }
  };

  const groupSelector = (
    <OPAutoComplete
      name="Select campaign group"
      sx={styles.groupSelector}
      options={deletableCampaignGroups}
      onChange={handleCampaignGroupSelection}
      value={selectedGroup}
    />
  );

  const currentContentsDisplay = selectedGroup ? (
    <CampaignGroupTable id={selectedGroup} />
  ) : (
    <Typography> Please select a group to see its contents </Typography>
  );

  const contentsDropdown = (
    <Grid item container sx={styles.deletorContentsDropdownContainer}>
      <Accordion elevation={0} sx={styles.muiAccordionRoot}>
        <AccordionSummary expandIcon={<ChevronRightIcon />}>
          <Typography>Contents of Selected Group</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid sx={styles.contentsDisplayContainer}>
            {currentContentsDisplay}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );

  const deleteButton = (
    <PrimaryButton
      disabled={!selectedGroup}
      onClick={openConfirmationDialog}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>
        Delete {selectedGroup ? `"${selectedGroup}"` : ""}
      </Typography>
    </PrimaryButton>
  );

  const cancelAndGoBackButton = (
    <PrimaryButton
      variant="outlined"
      onClick={handleCloseDrawer}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>Cancel and go back</Typography>
    </PrimaryButton>
  );

  const firstDialogActionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancelAndGoBackButton}
      {deleteButton}
    </Grid>
  );

  const cancellationButton = (
    <PrimaryButton
      variant="outlined"
      onClick={closeConfirmationDialog}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>Cancel</Typography>
    </PrimaryButton>
  );

  const confirmButton = (
    <PrimaryButton
      disabled={!selectedGroup}
      onClick={handleDeletionConfirmation}
      sx={styles.deleteButton}
    >
      <Typography sx={styles.buttonLabel}>Confirm</Typography>
    </PrimaryButton>
  );

  const actionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancellationButton}
      {confirmButton}
    </Grid>
  );

  const confirmationTitle = (
    <Typography sx={styles.titleText}>Confirm Deletion</Typography>
  );

  const deleteConfirmationDialog = (
    <OPDialog
      isOpen={showConfirmationDialog}
      fullWidth
      scroll="body"
      handleClose={closeConfirmationDialog}
      dialogTitleChildren={confirmationTitle}
      dialogActionsChildren={actionButtons}
    >
      <Typography>
        You've selected <b>{selectedGroup || ""}</b> to be deleted. <br />
        All of the <b>{grouping[selectedGroup]?.length || 0}</b> campaigns under
        this group will be moved to <b>ungrouped</b>.<br />
        Please click 'Confirm' to carry on with this deletion.
      </Typography>
    </OPDialog>
  );

  const explainerText = (
    <Typography>
      Select a campaign group to be deleted. The contents of that group will be
      moved to <b>ungrouped</b>.
    </Typography>
  );

  return (
    <Grid
      container
      sx={styles.deletorComponentsContainer}
      direction="column"
      justifyContent="space-evenly"
    >
      {explainerText}
      {groupSelector}
      {contentsDropdown}
      {firstDialogActionButtons}
      {deleteConfirmationDialog}
    </Grid>
  );
}

CampaignGroupDeletor.propTypes = {
  handleCloseDrawer: PropTypes.func,
};

export default CampaignGroupDeletor;
