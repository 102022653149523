import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

import PrimaryButton from "library/buttons/PrimaryButton";
import TextFieldSimple from "library/form/OutPointTextField";
import OutPointModal from "library/surface/ModalCentered";
import PlatformCardModal from "./PlatformCardModal";

const styles = {
  modal: {
    width: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textfield: {
    width: "100%",
    marginBottom: "20px",
  },
  nextButton: {
    width: "100px",
    marginLeft: "auto",
  },
};

function AddCredentialsButton(props) {
  const { channel, whereFrom } = props;
  const channelData =
    useSelector((state) => state.jester)?.data?.[channel] || [];

  const [name, setName] = useState("");
  const [nameModal, setNameModal] = useState(false);
  const [platformModal, setPlatformModal] = useState(false);

  const isNameUnique = channelData
    ? channelData.reduce(
        (acc, credentials) => name !== credentials.name && acc,
        true,
      )
    : true;

  return (
    <>
      <PlatformCardModal
        whereFrom={whereFrom}
        channel={channel}
        name={name}
        modalOpen={platformModal}
        setModalOpen={setPlatformModal}
      />

      <OutPointModal
        style={styles.modal}
        modalOpen={nameModal}
        setModalOpen={setNameModal}
        closeButton={false}
      >
        <TextFieldSimple
          sx={styles.textfield}
          name="Enter account name"
          variant="standard"
          value={name}
          error={!isNameUnique}
          errorMsg="Account name must be unique amoungst names for this channel."
          onChange={(text) => setName(text)}
        />
        <PrimaryButton
          sx={styles.nextButton}
          disabled={!name || !isNameUnique}
          onClick={() => {
            setNameModal(false);
            setPlatformModal(true);
          }}
        >
          Next
        </PrimaryButton>
      </OutPointModal>

      <Button
        size="small"
        onClick={() => {
          setName("");
          setNameModal(true);
        }}
        variant="contained"
        color="primary"
        sx={{ color: "white", boxShadow: "none" }} // TODO: COLOUR_CHECK -- check if this sx color prop makes sense
      >
        + Add
      </Button>
    </>
  );
}

AddCredentialsButton.propTypes = {
  channel: PropTypes.string,
  whereFrom: PropTypes.string,
};

export default AddCredentialsButton;
