/**
 * Custom Styled Accordion Summary that shows a right chevron
 * and is animated to turn clockwise by 90 degree when collapsed.
 * */
import React from "react";
import { AccordionSummary as MuiAccordionSummary } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";

const styles = {
  customAccordionSummary: {
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {},
  },
};

const AccordionSummary = styled(
  // original component:
  (props) => (
    <MuiAccordionSummary
      expandIcon={<ChevronRightIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ),
)(
  // styles override:
  () => styles.customAccordionSummary,
);

export default AccordionSummary;
