import React from "react";
import PropTypes from "prop-types";

import Title from "library/text/titles/Title";
import NewChannelRecommendationCard from "pages/ncr/components/NewChannelRecommendationCard";
import SubHead from "library/text/headers/SubHead";
import Caption from "library/text/body/Caption";
import IconText from "library/containers/IconText";
import WantToKnow from "pages/ncr/components/WantToKnow";
import CardDivider from "pages/ncr/components/CardDivider";
import Chip from "library/display/Chip";

import { LIGHT_GREY } from "assets/palette";

const styles = {
  sectionHeader: {
    backgroundColor: LIGHT_GREY,
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    padding: "12px 15px 12px 30px",
    marginTop: "30px",
  },
  label: {
    color: "secondary.main",
    fontWeight: 600,
    textTransform: "uppercase",
  },
};

function MetricComponent({ label }) {
  return (
    <Caption
      sx={styles.label}
      style={{
        textAlign: "center",
      }}
    >
      {label}
    </Caption>
  );
}
MetricComponent.propTypes = {
  label: PropTypes.string,
};

export default function RecommendationsDashboard({
  accordionExpanded,
  newChannels,
  handleAccordionClick,
}) {
  return (
    <>
      <IconText style={{ marginBottom: "25px" }}>
        <Title text="Channel Recommendations" style={{ marginRight: "15px" }} />
        <Chip size="small" text="New" />
      </IconText>
      <SubHead>
        Using our score card, we’ve ranked channels based on how well they match
        your criteria.
      </SubHead>
      <WantToKnow />
      <div style={styles.sectionHeader}>
        <CardDivider
          titleComponent={
            <Caption color="secondary" sx={styles.label}>
              Channel
            </Caption>
          }
          metricComponentCreator={MetricComponent}
        />
      </div>
      {accordionExpanded &&
        newChannels.map((newChannel, idx) => (
          <NewChannelRecommendationCard
            key={newChannel.channel}
            {...newChannel}
            chipText={idx === 0 && "Top Recommendation"}
            expanded={accordionExpanded[newChannel.channel]}
            handleAccordionClick={handleAccordionClick}
          />
        ))}
    </>
  );
}

RecommendationsDashboard.propTypes = {
  accordionExpanded: PropTypes.bool,
  newChannels: PropTypes.arrayOf(PropTypes.object),
  handleAccordionClick: PropTypes.func,
};
