import { Grid, Box } from "@mui/material";
import PngContainer from "library/containers/PngContainer";
import TableRow from "pages/overview/components/TableRow";
import Caption1 from "library/text/body/Caption";
import Hyperlink from "library/text/body/Hyperlink";
import SubHeadLight from "library/text/headers/SubHeadLight";
import OutPointCard from "library/surface/OutPointCard";
import React, { useEffect, useMemo, useState } from "react";
import { calculateCac } from "utils/data/metrics";
import {
  addCommasToNumber,
  normalizeChannelName,
  sentenceCase,
} from "utils/data/strings";
import { getLogoFromPartialName, getIconLogo } from "utils/resolveLogos";
import PropTypes from "prop-types";
import Chip from "library/display/Chip";
import { PRIMARY_COLOR } from "assets/palette";
import OverviewButtonGroup from "./OverviewButtonGroup";

const DIMINISHING = "diminishing";
const POSITIVE = "positive";
const COLUMN_NAMES = [
  {
    label: "Current spend",
    id: "spend",
    adornment: "$",
  },
  {
    label: "Recommended spend",
    id: "spendRecommended",
    adornment: "$",
  },
  {
    label: "Current ROAS",
    id: "roasBaseline",
    toFixed: 2,
  },
  {
    label: "Recommendation ROAS",
    id: "roasScenario",
    toFixed: 2,
  },
  {
    label: "Current CAC",
    id: "cacBaseline",
    adornment: "$",
  },
  {
    label: "Recommendation CAC",
    id: "cacScenario",
    adornment: "$",
  },
];

const reformatData = ({
  baselineData = {},
  scenarioData = {},
  diminishingChannels = [],
  positiveGrowthChannels = [],
  ltv = 200,
}) => {
  const data = {
    diminishing: [],
    positive: [],
  };

  const constructData = (channel, type = POSITIVE) => {
    const baseline = baselineData[channel];
    const scenario = scenarioData[channel];

    if (!baseline || !scenario) return null;

    const predicted = {
      revenue: scenario.total_revenue || 0,
      spend: scenario.spend || 0,
      roasAverage: scenario.average_roas || 0,
      roasMarginal: scenario.marginal_roas || 0,
    };

    const current = {
      revenue: baseline.total_revenue || 0,
      spend: baseline.spend || 0,
      roasAverage: baseline.average_roas || 0,
      roasMarginal: baseline.marginal_roas || 0,
    };

    // get cac and get predicted cac
    const cacBaseline = calculateCac(ltv, current.spend, current.revenue);
    const cacScenario = calculateCac(ltv, predicted.spend, predicted.revenue);

    return {
      title: channel,
      spend: current.spend,
      spendRecommended: predicted.spend,
      roasBaseline: current.roasAverage,
      roasScenario: predicted.roasAverage,
      cacBaseline,
      cacScenario,
      type,
    };
  };

  diminishingChannels.forEach((channel) => {
    const constructedData = constructData(channel, DIMINISHING);

    if (!constructedData) return;

    data.diminishing.push(constructedData);
  });

  positiveGrowthChannels.forEach((channel) => {
    const constructedData = constructData(channel);

    if (!constructedData) return;

    data.positive.push(constructedData);
  });

  return data;
};

const getColumnValues = (
  data,
  columnNames,
  selectedInsightType,
  groupByStrategy,
) => {
  return data
    .map((_data) => {
      if (_data.type !== selectedInsightType) {
        return null;
      }

      const columnValues = [];
      columnNames.forEach(({ id, adornment, toFixed }) => {
        columnValues.push({ label: _data[id] || "", adornment, toFixed });
      });

      if (!groupByStrategy) {
        columnValues.push({
          label: "VIEW INSIGHTS",
          href: `channel-insights/${_data.title}`,
        });
      }

      const rowContent = {
        title: _data.title || "",
        values: columnValues,
        type: _data.type,
      };

      return rowContent;
    })
    .filter((x) => !!x);
};

function KeyPerformanceInsights(props) {
  const {
    groupByStrategy,
    baselineData,
    scenarioData,
    diminishingChannels,
    positiveGrowthChannels,
    ltv,
    timeRangeLabel = "daily",
    timeRangeDays = 1,
  } = props;

  const data = useMemo(
    () =>
      reformatData({
        baselineData,
        scenarioData,
        diminishingChannels,
        positiveGrowthChannels,
        ltv,
      }),
    [
      baselineData,
      scenarioData,
      diminishingChannels,
      positiveGrowthChannels,
      ltv,
    ],
  );

  const insightTypes = {
    0: {
      text: "growth opportunity",
      isSelected: false,
      type: POSITIVE,
      level: "neutral",
    },
    1: {
      text: "diminishing returns",
      isSelected: true,
      type: DIMINISHING,
      level: "warning",
    },
  };

  const [selectedInsightKey, setSelectedInsightKey] = useState(0);
  const completeDataset = useMemo(() => {
    return data.diminishing.concat(data.positive).map((row) => {
      const newRow = { ...row };
      const fieldsToMultiply = ["spend", "spendRecommended"];
      fieldsToMultiply.forEach((field) => {
        if (field in row) newRow[field] *= timeRangeDays;
      });
      return newRow;
    });
  }, [data, timeRangeDays]);
  const columnData = getColumnValues(
    completeDataset,
    COLUMN_NAMES,
    insightTypes[selectedInsightKey].type,
    groupByStrategy,
  );

  useEffect(() => {
    if (columnData.length > 0) return;
    setSelectedInsightKey(selectedInsightKey ? 0 : 1);
  }, [data]);

  const renderOverviewButtonGroup = (buttonOptions) => (
    <OverviewButtonGroup buttonOptions={buttonOptions} />
  );
  function InsightTypeButtons() {
    const buttonOptions = Object.keys(insightTypes).map((key = "0") => {
      const { text, type } = insightTypes[key];
      const keyNumber = Number(key);

      return {
        text,
        key: text,
        isSelected: keyNumber === selectedInsightKey,
        disableElevation: true,
        onClick: () => setSelectedInsightKey(keyNumber),
        endIconText: data[type]?.length || null,
      };
    });

    return renderOverviewButtonGroup(buttonOptions);
  }

  // TODO: refactor: shift to separate component just for the table stuff:
  const insightsTableColumnHeaders = (
    <TableRow
      columns={9}
      isHeaderRow
      moreTitleProps={{ xs: 1 }}
      moreContainerStyles={{
        padding: "12px 15px 12px 30px",
      }}
      renderColumn={({ label, id }) => {
        return (
          <Caption1
            key={id}
            style={{
              textAlign: "right",
              color: "rgba(36, 35, 47, 1)",
              fontFamily: "IBM Plex Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            {label}
          </Caption1>
        );
      }}
      columnsAll={COLUMN_NAMES}
    />
  );

  // TODO: refactor: TO SHIFT TO SEPARATE COMPONENT just for table:
  const insightsTableContent = columnData.map(({ title = "", values }, idx) => {
    const isLastRow = idx + 1 >= columnData.length;
    const IconLogo = getIconLogo(title);

    return (
      <TableRow
        key={title}
        columns={9}
        hoverStyle={{ backgroundColor: "#f1f1f9" }}
        moreTitleProps={{ xs: 1 }}
        moreContainerStyles={{
          padding: "12px 15px 12px 30px",
          ...(isLastRow && {
            borderBottomLeftRadius: "6px",
            borderBottomRightRadius: "6px",
          }),
        }}
        titleComponent={
          <Box sx={{ display: "flex" }}>
            {IconLogo ? (
              <IconLogo sx={{ width: "20px", margin: "8px" }} />
            ) : (
              <PngContainer
                icon={getLogoFromPartialName(title)}
                boxStyle={{ width: "24px", marginRight: "12px" }}
              />
            )}
            <Caption1
              style={{
                fontFamily: "IBM Plex Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "20px",
              }}
            >
              {sentenceCase(normalizeChannelName(title))}
            </Caption1>
          </Box>
        }
        renderColumn={({ label, id, toFixed = 0, adornment = "", href }) => {
          const formattedLabel = Number.isNaN(label)
            ? label
            : `${adornment} ${addCommasToNumber(
                Number(label).toFixed(toFixed),
              )}`.trim();
          if (label.toString().toLowerCase() === "view insights") {
            return (
              <Hyperlink style={{ textDecoration: "none" }} where={href}>
                <Caption1
                  key={`c1-${id}`}
                  style={{
                    textAlign: "right",
                    color: PRIMARY_COLOR,
                  }}
                >
                  {label}
                </Caption1>
              </Hyperlink>
            );
          }
          return (
            <Caption1
              key={`c2-${id}`}
              style={{
                textAlign: "right",
                marginRight: "5px",
              }}
            >
              {formattedLabel}
            </Caption1>
          );
        }}
        columnsAll={values.map((value, i) => {
          return { id: i, ...value };
        })}
      />
    );
  });

  const insightsTable = (
    <>
      {insightsTableColumnHeaders}
      {insightsTableContent}
    </>
  );

  const insightsTopSection = (
    <Grid container direction="column">
      {/* -- Heading and Toggle Switch */}
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <SubHeadLight style={{ margin: "16px" }}>
              {`Key ${timeRangeLabel} performance insights`}
            </SubHeadLight>
            <Chip
              text={insightTypes[selectedInsightKey].text}
              moreBadgeBoxStyles={{
                padding: "6px 14px",
                width: "auto",
                margin: "8px",
              }}
            />
          </div>
        </Grid>
      </Grid>

      {/* --- Insight Type buttons----*/}
      <Grid
        item
        container
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <Grid item xs={8}>
          <InsightTypeButtons />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <OutPointCard
      style={{
        borderRadius: "6px",
      }}
    >
      {insightsTopSection}
      {insightsTable}
    </OutPointCard>
  );
}

export default KeyPerformanceInsights;

KeyPerformanceInsights.propTypes = {
  groupByStrategy: PropTypes.bool,
  baselineData: PropTypes.object,
  scenarioData: PropTypes.object,
  diminishingChannels: PropTypes.arrayOf(PropTypes.string),
  positiveGrowthChannels: PropTypes.arrayOf(PropTypes.string),
  ltv: PropTypes.number,
  timeRangeDays: PropTypes.number,
  timeRangeLabel: PropTypes.string,
};
