import React from "react";

import { Grid } from "@mui/material";

import OutpointStepper from "library/display/StepperBlock";
import BodyText from "library/text/body/BodyText";
import Header2 from "library/text/headers/Header2";
import PropTypes from "prop-types";

const styles = {
  container: {
    paddingTop: "50px",
    width: "100%",
    justifyContent: "start",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    height: "30px",
    margin: "30px 0 30px 0",
  },
  emptyHeader: {
    marginTop: "100px",
  },
  bodyText: {
    marginTop: "30px",
    color: "secondary.main",
  },
};

export default function OnboardingHeader(props) {
  const { activeStep, stepperLabels, headerText, secondaryText } = props;

  return (
    <Grid container sx={styles.container}>
      {activeStep >= 0 ? (
        <Grid
          xs={12}
          item
          sx={styles.gridItem}
          style={{ marginBottom: "40px" }}
        >
          <OutpointStepper activeStep={activeStep} labels={stepperLabels} />
        </Grid>
      ) : (
        <Grid xs={12} item sx={styles.emptyHeader} />
      )}
      <Grid xs={12} item>
        <Header2>{headerText}</Header2>
        <BodyText sx={styles.bodyText}>{secondaryText}</BodyText>
      </Grid>
    </Grid>
  );
}

OnboardingHeader.propTypes = {
  activeStep: PropTypes.number,
  stepperLabels: PropTypes.arrayOf(PropTypes.string),
  headerText: PropTypes.string,
  secondaryText: PropTypes.string,
};
