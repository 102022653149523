import React, { useRef } from "react";
import { Grid } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import LegendBox from "library/graphing/LegendBox";
import LegendItem from "library/graphing/LegendItem";
import LineAndScatterChart, {
  getEasilyExtendableOptions,
} from "library/graphing/LineChart";
import OPCircularProgress from "library/progress/Spinner";
import { addCommasToNumber, kDigitConversion } from "utils/data/strings";
import SubHeadLight from "library/text/headers/SubHeadLight";
import Selector from "library/form/OutPointSelector";
import { CalendarToday } from "@mui/icons-material";
import DownloadButtons from "library/graphing/DownloadButtons";
import { OutPointBanner } from "../../../library/display/OutPointBanner";

import { tickFormatters, tooltipTitleFormatters } from "../OverviewHelpers";
import { mixRangeToNumDays } from "../../../utils/data/dates";
import { AGGREGATION_DAY_RANGES } from "../../../utils/enums";

const styles = {
  headerContainer: {
    paddingBottom: "8px",
  },
  subheaderContainer: {
    minHeight: "40px",
    marginBottom: "20px",
  },
  subheaderText: {
    display: "flex",
    alignItems: "center",
    lineHeight: "16px",
  },
  subheaderSelect: {
    position: "relative",
    display: "flex",
    alignItems: "right",
  },
  loadingDiv: {
    height: "400px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function ActualsTemplate(props) {
  const {
    groupByStrategy,
    mixRange,
    setMixRange,
    mixRanges,
    curves,
    explicitRange,
    headerText,
    aggregationControls,
    timeRangeLabel = "daily",
  } = props;

  const graphOptions = getEasilyExtendableOptions();
  graphOptions.yScaleOptions = {
    ticks: {
      beginAtZero: true,
    },
    beginAtZero: true,
    formatTicks: kDigitConversion,
  };

  graphOptions.xScaleOptions = {
    formatTicks: tickFormatters[timeRangeLabel],
    explicitlySetRange: explicitRange.length ? explicitRange : null,
  };

  graphOptions.tooltipPluginOptions = {
    label: (context) => {
      const { dataset, dataIndex } = context || {};
      const { value } = dataset.data[dataIndex];

      if (!(Number(value) && dataset)) return null;

      return `${context.dataset.label}: $${addCommasToNumber(
        Number(value).toFixed(2),
      )}`;
    },
    formatTitle: tooltipTitleFormatters[timeRangeLabel],
  };

  const renderStartAdornment = (adornmentProps) => (
    <CalendarToday {...adornmentProps} />
  );

  const lineGraphAndLegend = (
    <>
      <Grid item xs={12}>
        <LineAndScatterChart data={curves} moreOptions={graphOptions} />
      </Grid>
      <LegendBox
        moreLegendBoxStyles={{ marginBottom: "24px", marginLeft: "12px" }}
        title={groupByStrategy ? "STRATEGIES" : "CHANNELS"}
      >
        {curves.map(({ color, name }) => {
          return (
            <LegendItem
              title={name}
              key={name + color}
              iconColor={color}
              icon="circle"
            />
          );
        })}
      </LegendBox>
    </>
  );

  const timePeriodSelector = (
    <Grid item sx={styles.subheaderSelect}>
      <Selector
        onChange={setMixRange}
        selectedValue={mixRange}
        menuItems={mixRanges}
        StartAdornment={renderStartAdornment}
      />
    </Grid>
  );

  const timeRange = mixRangeToNumDays[mixRange];
  const aggregationRange = AGGREGATION_DAY_RANGES[timeRangeLabel];
  const isCompatibleRangeChoice = aggregationRange <= timeRange;

  const incompatText = `Please ensure that time range chosen is larger than the aggregation range.
Currently, the time range is set to "${mixRange}" (i.e. ${timeRange} days) and the aggregation range is "${timeRangeLabel}" (i.e. ${aggregationRange} days).`;
  let curveDisplayArea = (
    <Grid container sx={{ padding: "24px" }}>
      <OutPointBanner
        displayText="Incompatible settings detected"
        subDisplayText={
          <SubHeadLight isSentenceCase={false}>{incompatText}</SubHeadLight>
        }
        level="warning"
        // eslint-disable-next-line react/no-unstable-nested-components -- it's a leaf
        BadgeIcon={() => <InfoOutlinedIcon />}
        moreBannerStyles={{
          padding: "8px",
        }}
      />
    </Grid>
  );
  if (isCompatibleRangeChoice) {
    curveDisplayArea = curves?.length ? (
      lineGraphAndLegend
    ) : (
      <OPCircularProgress />
    );
  }

  const chartRef = useRef(null);
  return (
    <div style={{ position: "relative" }}>
      <DownloadButtons
        style={{ position: "absolute", right: 0, top: "55px" }}
        filename={headerText}
        componentRef={chartRef}
      />
      <div ref={chartRef}>
        <Grid
          container
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          sx={styles.subheaderContainer}
        >
          <Grid item xs={4} sx={styles.subheaderText}>
            <SubHeadLight isSentenceCase={false}>{headerText}</SubHeadLight>
          </Grid>
          <Grid
            item
            xs={8}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            {aggregationControls}
            {timePeriodSelector}
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          {curveDisplayArea}
        </Grid>
      </div>
    </div>
  );
}

export default ActualsTemplate;

ActualsTemplate.propTypes = {
  groupByStrategy: PropTypes.bool,
  mixRange: PropTypes.string,
  setMixRange: PropTypes.func,
  mixRanges: PropTypes.arrayOf(PropTypes.string),
  curves: PropTypes.arrayOf(PropTypes.object),
  explicitRange: PropTypes.arrayOf(PropTypes.number),
  headerText: PropTypes.string,
  aggregationControls: PropTypes.element,
  timeRangeLabel: PropTypes.string,
};
