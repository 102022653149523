import React from "react";

import { FormControl, Select, MenuItem, Checkbox } from "@mui/material";

import { FAINT_BLUE } from "assets/palette";
import SubHeadLight from "library/text/headers/SubHeadLight";
import { deepCopy } from "utils/data/objects";
import PropTypes from "prop-types";

const styles = {
  formControl: {
    minWidth: 120,
  },
  selectStyle: {
    borderRadius: "100px",
    height: "48px",
    width: "183px",
    color: "black",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "IBM Plex Sans",
  },
  menuItemStyle: {
    display: "flex",

    alignItems: "center",
    padding: "16px 24px",
    gap: "10px",

    // width: '132px',
    height: "56px",
  },
};

export default function OutPointSelector(props) {
  const {
    onChange,
    menuItems = [],
    selectedValue, // TODO add default [ not to self & reviewers don't aprove pr if this isn't removed]
    leading = "", // leading value
    trailing = "", // trailing value
    StartAdornment = () => <div />,
    startAdornmentStyle = { marginRight: 5 },
    sx = {},
    multiple = false,
    menuItemAdornment = {},
    defaultText = "",
    renderValueOverride = null, // Supply a custom component render value
    ...rest
  } = props;

  const handleChange = (event) => {
    const eventTargetValue = event.target.value;

    if (!multiple) {
      onChange(eventTargetValue);
      return;
    }

    const values = deepCopy(selectedValue);
    const [, selectedTargetValue] = eventTargetValue;

    const indexOfValue = values.indexOf(selectedTargetValue);

    if (indexOfValue > -1) {
      values.splice(indexOfValue, 1);
      onChange(values);
      return;
    }

    values.push(selectedTargetValue);

    // add
    onChange(values);
  };

  let formattedMenuItems = menuItems.map((item) => {
    return {
      display: item,
      decoratedDisplay: `${leading}${item}${trailing}`,
    };
  });

  let value;
  if (defaultText) {
    value =
      selectedValue === null ||
      selectedValue === undefined ||
      selectedValue === ""
        ? defaultText
        : `${leading}${selectedValue}${trailing}`;
  } else {
    value = `${leading}${selectedValue}${trailing}`;
  }

  if (multiple) {
    formattedMenuItems = menuItems.map((item) => {
      return {
        display: item,
      };
    });

    value = [`${leading}${selectedValue.length}${trailing}`];
  }

  return (
    <FormControl required sx={styles.formcontrol}>
      <Select
        sx={{
          ...styles.selectStyle,
          ...sx,
        }}
        value={value}
        multiple={multiple}
        onChange={handleChange}
        startAdornment={<StartAdornment style={startAdornmentStyle} />}
        renderValue={() => {
          if (renderValueOverride) return renderValueOverride();

          return <SubHeadLight>{value}</SubHeadLight>;
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: "white",
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: FAINT_BLUE,
              },
            },
          },
        }}
        {...rest}
      >
        {formattedMenuItems.map(({ display = "" }) => {
          const { itemValue, position } = menuItemAdornment;

          let menuItemDisplay = display;

          if (position === "start" && itemValue) {
            menuItemDisplay = `${itemValue}${menuItemDisplay}`;
          }

          if (position === "end" && itemValue) {
            menuItemDisplay = `${menuItemDisplay}${itemValue}`;
          }

          return (
            <MenuItem
              key={itemValue}
              value={display}
              sx={styles.menuItemDisplay}
            >
              {multiple && (
                <Checkbox
                  hidden={!display}
                  checked={selectedValue.indexOf(display) > -1}
                />
              )}
              <SubHeadLight>{menuItemDisplay}</SubHeadLight>
            </MenuItem>
          );
        })}
        {/* {
        MuI doesn't allow a different value option if not included in menu options

        Render shadow menu items that are hidden to the user
        The purpose of this is two fold:

        1. Meet design requirements where the display to the user includes formatting to the
        menu items.
          Example:
            - menu items = [1, 2, 3] (if the user clicks 1, the value is 1)
            However design requirements: show the user the menu Items as above when selecting, but once selected, add formatting. Like so:
            - "You selected: 1" (<- this is the "value")
          items.include(value) === false, and therefore is not permitted.
        
        The solution: rendering shadow hidden items as below to silence the warnings
      } */}
        {(!!leading || !!trailing) &&
          formattedMenuItems.map(({ decoratedDisplay }) => (
            <MenuItem key={decoratedDisplay} value={decoratedDisplay} hidden>
              <SubHeadLight isSentenceCase={false}>
                {decoratedDisplay}
              </SubHeadLight>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

OutPointSelector.propTypes = {
  onChange: PropTypes.func,
  menuItems: PropTypes.arrayOf(PropTypes.string),
  selectedValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  leading: PropTypes.string,
  trailing: PropTypes.string,
  StartAdornment: PropTypes.func,
  startAdornmentStyle: PropTypes.object,
  sx: PropTypes.object,
  multiple: PropTypes.bool,
  menuItemAdornment: PropTypes.object,
  defaultText: PropTypes.string,
  renderValueOverride: PropTypes.func,
};
