import React from "react";

import { CardActionArea } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import OutPointCard from "library/surface/OutPointCard";
import PropTypes from "prop-types";

const styles = {
  card: {
    boxShadow: "none",
    borderStyle: "solid",
    borderWidth: "2px",
    borderRadius: "8px",
    position: "relative",
  },
  getCardStyle: (height) => ({ height }),
  cardActionAreaStyle: { height: "100%" },
  getHoverableStyle: (theme) => ({
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  }),
  getBorderStyle: (isActive, theme) => ({
    borderColor: isActive
      ? theme.palette?.primary?.main
      : theme.palette?.primary?.inactive || "lightgrey",
  }),
};

export default function SelectionCard({
  children,
  height,
  handleClick,
  checked,
  disabled = false,
}) {
  const theme = useTheme();

  return (
    <OutPointCard
      style={styles.getCardStyle(height)}
      sx={{
        ...styles.card,
        ...styles.getBorderStyle(checked, theme),
        ...((!disabled && styles.getHoverableStyle(theme)) || {}),
      }}
    >
      {disabled ? (
        children
      ) : (
        <CardActionArea
          style={styles.cardActionAreaStyle}
          onClick={handleClick}
        >
          {children}
        </CardActionArea>
      )}
    </OutPointCard>
  );
}

SelectionCard.propTypes = {
  children: PropTypes.any,
  height: PropTypes.string,
  handleClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};
