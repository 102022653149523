import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Menu, MenuItem, Typography } from "@mui/material";

import PrimaryButton from "library/buttons/PrimaryButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { campaignsActionsButtonsStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

/**
 * Campaign Actions button is a buttongroup that acts as a control panel of sorts
 * and effects state changes that determine the contents of the drawer shown to the user.
 * * */
function CampaignActionsButtons(props) {
  const { groupMenuOptions, strategyMenuOptions, handleActionSelection } =
    props;

  const [showGroupMenu, setShowGroupMenu] = useState(false);
  const [showStrategyMenu, setShowStrategyMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // used for anchoring popup menu

  const handleGroupMenuButtonClick = (event) => {
    // eslint-disable-next-line no-console
    console.assert(!showGroupMenu, "Should only open a currently closed menu");
    setShowGroupMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleGroupMenuClose = () => {
    setAnchorEl(null);
    // eslint-disable-next-line no-console
    console.assert(showGroupMenu, "Should only close a currently open menu");
    setShowGroupMenu(false);
  };

  const handleGroupMenuOptionClick = (option) => {
    handleGroupMenuClose();
    handleActionSelection(option);
  };

  const handleStrategyMenuButtonClick = (event) => {
    // eslint-disable-next-line no-console
    console.assert(
      !showStrategyMenu,
      "Should only open a currently closed menu",
    );
    setShowStrategyMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleStrategyMenuClose = () => {
    setAnchorEl(null);
    // eslint-disable-next-line no-console
    console.assert(showStrategyMenu, "Should only close a currently open menu");
    setShowStrategyMenu(false);
  };

  const handleStrategyMenuOptionClick = (option) => {
    handleStrategyMenuClose();
    handleActionSelection(option);
  };

  const handleFinderButtonClick = () => {
    handleActionSelection("find");
  };

  const finderButton = (
    <PrimaryButton sx={styles.finderButton} onClick={handleFinderButtonClick}>
      <Typography sx={{ ...styles.buttonLabel, color: "white" }}>
        Find and group campaigns
      </Typography>
    </PrimaryButton>
  );

  const groupActionsMenuButton = (
    <PrimaryButton
      sx={styles.groupDropdownButton}
      color="primary"
      onClick={handleGroupMenuButtonClick}
      endIcon={<KeyboardArrowDownIcon />}
      variant="outlined"
    >
      <Typography sx={styles.buttonLabel}>Manage Groups</Typography>
    </PrimaryButton>
  );

  const groupActionsMenu = (
    <Menu
      open={showGroupMenu}
      sx={styles.actionsMenu}
      onClose={handleGroupMenuClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {groupMenuOptions.map((option) => {
        return (
          <MenuItem
            key={option.command}
            onClick={() => handleGroupMenuOptionClick(option.command)}
          >
            {option.label}
          </MenuItem>
        );
      })}
    </Menu>
  );

  const strategyActionsMenuButton = (
    <PrimaryButton
      sx={styles.strategyDropdownButton}
      color="primary"
      onClick={handleStrategyMenuButtonClick}
      endIcon={<KeyboardArrowDownIcon />}
      variant="outlined"
    >
      <Typography sx={styles.buttonLabel}>Manage Strategies</Typography>
    </PrimaryButton>
  );

  const strategyActionsMenu = (
    <Menu
      open={showStrategyMenu}
      sx={styles.actionsMenu}
      onClose={handleStrategyMenuClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {strategyMenuOptions.map((option) => {
        return (
          <MenuItem
            key={option.command}
            onClick={() => handleStrategyMenuOptionClick(option.command)}
          >
            {option.label}
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <Grid
      item
      display="inline-flex"
      alignItems="center"
      sx={styles.buttonsContainer}
      width="100%"
    >
      {finderButton}
      {groupActionsMenuButton}
      {groupActionsMenu}
      {strategyActionsMenuButton}
      {strategyActionsMenu}
    </Grid>
  );
}

CampaignActionsButtons.propTypes = {
  groupMenuOptions: PropTypes.arrayOf(PropTypes.object),
  strategyMenuOptions: PropTypes.arrayOf(PropTypes.object),
  handleActionSelection: PropTypes.func,
};

export default CampaignActionsButtons;
