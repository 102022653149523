import React from "react";
import PropTypes from "prop-types";

import { Paper, Button } from "@mui/material";
import Header1 from "library/text/headers/Header1";

const styles = {
  smallHeader: {
    fontSize: 14,
    marginBottom: "24px",
  },
};

export default function CompletionBanner(props) {
  const { handleStepReset, text, resetText } = props;

  return (
    <Paper square elevation={0} sx={{ p: 3 }}>
      <Header1 sx={styles.smallHeader} color="secondary">
        {text}
      </Header1>
      <Button onClick={handleStepReset} sx={{ mt: 1, mr: 1 }}>
        {resetText}
      </Button>
    </Paper>
  );
}
CompletionBanner.propTypes = {
  handleStepReset: PropTypes.func,
  text: PropTypes.string,
  resetText: PropTypes.string,
};
