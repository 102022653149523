//
// Methods in this file should reliably work with data from the reportingData dataset, often used for ROAS.
//

const chronologicallySortDateStrings = (a, b) => {
  const dateA = a.date.toUpperCase(); // ignore upper and lowercase
  const dateB = b.date.toUpperCase(); // ignore upper and lowercase
  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  // names must be equal
  return 0;
};

export function sortByDate(roas) {
  return roas.sort(chronologicallySortDateStrings);
}

export function sortBySpend(roas) {
  return roas.sort((a, b) => a.spend - b.spend);
}

export function sortByChannel(roas) {
  return roas.slice().sort((a, b) => {
    const A = a.campaign_group.toUpperCase();
    const B = b.campaign_group.toUpperCase();
    if (A < B) {
      return -1;
    }
    if (A > B) {
      return 1;
    }
    return 0;
  });
}
