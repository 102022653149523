import React, { useState } from "react";
import MacroRoas from "pages/overview/components/MacroRoas";

import SectionTemplate from "./components/SectionTemplate";
import { AGGREGATION_DAY_RANGES } from "../../utils/enums";

function MacroRoasSection() {
  const [chosenTimeRange, setChosenTimeRange] = useState("daily");
  const [mixRange, setMixRange] = useState("Last 3 months");

  const sectionContent = (
    <MacroRoas
      timeRangeLabel={chosenTimeRange}
      timeRangeDays={AGGREGATION_DAY_RANGES[chosenTimeRange]}
    />
  );

  return (
    <SectionTemplate
      sectionHeading="Blended historical data"
      sectionContent={sectionContent}
      chosenTimeRange={chosenTimeRange}
      setChosenTimeRange={setChosenTimeRange}
      mixRange={mixRange}
      setMixRange={setMixRange}
      shouldShowTimeRangeSelector={false}
    />
  );
}

export default MacroRoasSection;
