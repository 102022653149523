import React from "react";
import { PERIWINKLE_BLUE } from "assets/palette";
import PropTypes from "prop-types";
import Hyperlink from "./Hyperlink";

const styles = {
  hyperlink: {
    color: PERIWINKLE_BLUE,
    fontWeight: 500,
  },
};

export default function OPHyperlink({ className, sx = {}, ...rest }) {
  const mergedStyles = {
    ...styles.hyperlink,
    ...sx,
  };

  return <Hyperlink {...rest} sx={mergedStyles} className={className} />;
}

OPHyperlink.propTypes = {
  className: PropTypes.string,
  sx: PropTypes.object,
};
