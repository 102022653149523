import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import { getChannelColour } from "assets/colours";
import LegendBox from "library/graphing/LegendBox";
import LegendItem from "library/graphing/LegendItem";
import OPCircularProgress from "library/progress/Spinner";
import SubHeadLight from "library/text/headers/SubHeadLight";
import { useSelector } from "react-redux";
import PieChart, {
  getEasilyExtendableOptions,
} from "library/graphing/PieChart";
import {
  addCommasToNumber,
  normalizeChannelName,
  sentenceCase,
} from "utils/data/strings";
import {
  daysToMilliSeconds,
  mixRangeToNumDays,
  trimDataSeries,
} from "utils/data/dates";
import { deepCopy } from "utils/data/objects";
import PropTypes from "prop-types";
import {
  manuallyHideToolTip,
  manuallyShowTooltip,
} from "utils/graphing/visual";
import { capitalize } from "lodash";
import StrategyChannelToggle from "./StrategyChannelToggle";

const styles = {
  container: {
    padding: "32px 24px 8px 24px",
    height: "100%",
    position: "relative",
  },
  headerContainer: {
    paddingBottom: "8px",
  },
  subheaderContainer: {
    minHeight: "40px",
  },
  subheaderText: {
    display: "flex",
    alignItems: "center",
    lineHeight: "16px",
  },
  subheaderSelect: {
    display: "flex",
  },
  loadingDiv: {
    height: "400px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function AveragesCard(props) {
  const {
    timeRangeLabel = "daily",
    timeRangeDays = 1,
    mixRange = "Last 3 months",
  } = props;
  const roasResponse = useSelector((state) => state.reportingData);

  const [groupByStrategy, setGroupByStrategy] = useState(true);
  const [pieData, setPieData] = useState();
  const [totalSpend, setTotalSpend] = useState();
  const [pieDataWithLift, setPieDataWithLift] = useState();
  const [totalRevenueWithLift, setTotalRevenueWithLift] = useState();
  const [categories, setCategories] = useState();
  const graphLeftRef = useRef(null);
  const graphRightRef = useRef(null);

  const emptyData = {
    name: "No data",
    values: [1],
    colors: ["#f8f4fc"],
    hoverOffset: 4,
  };

  useEffect(() => {
    const actualsData = groupByStrategy
      ? deepCopy(roasResponse.actualsGroupStrategy?.data || {})
      : deepCopy(roasResponse.actualsGroup?.data || {});
    const supportedCategories = groupByStrategy
      ? roasResponse.actualsGroupStrategy?.ordered_categories || []
      : roasResponse.actualsGroup?.ordered_categories || [];

    if (!actualsData) return;

    const endDate = Math.max(
      ...Object.values(actualsData).map((categoryData) =>
        Math.max(
          ...categoryData
            .filter((row) => !row.interpolated)
            .map((row) => row.timestamp),
        ),
      ),
    );
    const trimmedData = trimDataSeries(
      actualsData,
      mixRange === "All time"
        ? 0
        : endDate - daysToMilliSeconds(mixRangeToNumDays[mixRange]),
      endDate,
      "timestamp",
    );

    const averagedSpend = [];
    const averagedRevenueWithLift = [];
    const categoryColors = [];
    supportedCategories.forEach((category) => {
      averagedSpend.push(
        trimmedData[category]
          .map((point) => point.spend * timeRangeDays)
          .reduce((sum, spend) => spend + sum, 0) /
          (mixRange === "All time"
            ? trimmedData[category].length
            : mixRangeToNumDays[mixRange]),
      );
      averagedRevenueWithLift.push(
        trimmedData[category]
          .map((point) => point.total_revenue * timeRangeDays)
          .reduce((sum, revenue) => revenue + sum, 0) /
          (mixRange === "All time"
            ? trimmedData[category].length
            : mixRangeToNumDays[mixRange]),
      );
      categoryColors.push(getChannelColour(category));
    });

    setPieData({
      name: "Average revenue",
      values: averagedSpend,
      colors: categoryColors,
      hoverOffset: 4,
    });
    setTotalSpend(averagedSpend.reduce((sum, spend) => sum + spend, 0));
    setPieDataWithLift({
      name: "Average revenue with lift",
      values: averagedRevenueWithLift,
      colors: categoryColors,
      hoverOffset: 4,
    });
    setTotalRevenueWithLift(
      averagedRevenueWithLift.reduce((sum, rev) => sum + rev, 0),
    );
    setCategories(supportedCategories);
  }, [mixRange, roasResponse.status, groupByStrategy, timeRangeDays]);

  const graphOptions = getEasilyExtendableOptions();
  graphOptions.cutout = "50%";

  const createPieChart = (total, pieChartData, pieChartType, ref) => (
    <PieChart
      chartRef={ref}
      data={total ? pieChartData : emptyData}
      dataLabels={total ? categories : ["No data"]}
      moreOptions={{
        ...graphOptions,
        tooltipPluginOptions: total
          ? {
              formatTitle: (context) => {
                return capitalize(`${context.label} ${pieChartType}`);
              },
              label: (context) =>
                `${capitalize(normalizeChannelName(context.label))}: ${Number(
                  (context.raw / total) * 100,
                ).toFixed(2)}% ($${addCommasToNumber(
                  Number(context.raw).toFixed(2),
                )})`,
            }
          : {
              formatTitle: () => {},
              label: (context) => context.label,
            },
        titlePluginOptions: total
          ? {
              display: true,
              text: `Average ${timeRangeLabel.toLowerCase()} ${pieChartType}`,
              font: {
                family: "IBM Plex Sans",
              },
            }
          : {
              display: true,
              text: `No ${pieChartType} available`,
              font: {
                family: "IBM Plex Sans",
              },
            },
      }}
    />
  );

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.subheaderContainer} alignItems="flex-start">
        <Grid item xs={3} md={4} sx={styles.subheaderText}>
          <SubHeadLight isSentenceCase={false}>
            {`Average ${sentenceCase(timeRangeLabel)} Spend & Return`}
          </SubHeadLight>
        </Grid>
        <Grid item xs={9} md={8}>
          <Grid
            container
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <StrategyChannelToggle
                groupByStrategy={groupByStrategy}
                setGroupByStrategy={setGroupByStrategy}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container display="flex" flexDirection="row">
        <Grid item xs={6}>
          {(categories?.length ?? 0) > 0 ? (
            createPieChart(totalSpend, pieData, "spend", graphLeftRef)
          ) : (
            <OPCircularProgress />
          )}
        </Grid>
        <Grid item xs={6}>
          {(categories?.length ?? 0) > 0 ? (
            createPieChart(
              totalRevenueWithLift,
              pieDataWithLift,
              "return",
              graphRightRef,
            )
          ) : (
            <OPCircularProgress />
          )}
        </Grid>
      </Grid>
      <LegendBox
        moreLegendBoxStyles={{ marginBottom: "24px", marginLeft: "12px" }}
        title={groupByStrategy ? "STRATEGIES" : "CHANNELS"}
      >
        {(categories ?? []).map((category, i) => {
          return (
            <div
              key={`${category}-{i}`}
              onMouseEnter={() => {
                manuallyShowTooltip(graphLeftRef, i);
                manuallyShowTooltip(graphRightRef, i);
              }}
              onMouseLeave={() => {
                manuallyHideToolTip(graphLeftRef, i);
                manuallyHideToolTip(graphRightRef, i);
              }}
            >
              <LegendItem
                title={normalizeChannelName(category)}
                key={category}
                icon="circle"
                iconColor={getChannelColour(category)}
              />
            </div>
          );
        })}
      </LegendBox>
    </Grid>
  );
}

AveragesCard.propTypes = {
  timeRangeDays: PropTypes.number,
  timeRangeLabel: PropTypes.string,
  mixRange: PropTypes.string,
};

export default AveragesCard;
