import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Label from "library/text/body/LabelText";
import PropTypes from "prop-types";

export default function RadioButtons({
  name,
  labels,
  value,
  setValue,
  row = false,
  ...rest
}) {
  return (
    <FormControl component="fieldset" {...rest}>
      {name && <Label style={{ marginBottom: "3px" }}>{name}</Label>}
      <RadioGroup
        row={row}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      >
        {labels.map((label) => (
          <FormControlLabel
            key={`radiobuttons-${label}`}
            value={label}
            label={label[0].toUpperCase() + label.slice(1, label.length)}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

RadioButtons.propTypes = {
  name: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  setValue: PropTypes.func,
  row: PropTypes.bool,
};
