import React, { useEffect, useState } from "react";

import TextFieldSimple from "library/form/OutPointTextField";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { useDispatch, useSelector } from "react-redux";
import { updateMetadata } from "redux/overviewSlice";
import RadioButton from "library/buttons/RadioButton";
import PropTypes from "prop-types";

const styles = {
  container: {
    textAlign: "left",
    width: "50%",
  },
  textField: {
    marginBottom: "20px",
  },
};

const sendData = async (data, dispatch) => {
  try {
    dispatch(updateMetadata(data));
    await makeAuthenticatedPostRequest("metadata", data);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn("unable to update ltv in the backend", err);
  }
};

export default function LtvPage(props) {
  const dispatch = useDispatch();
  const { setNextButtonDisabled } = props;

  const [ltv, setLtv] = useState("");
  const [addLaterRadio, setAddLaterRadio] = useState(false);
  const overviewData = useSelector((state) => state.overviewData)?.data;

  const setNextButtonState = (ltvValue, radioValue) => {
    setNextButtonDisabled(!(radioValue || ltvValue));
  };

  useEffect(() => {
    const savedLtv = overviewData?.ltv;
    const savedAddLater = overviewData?.add_ltv_later;
    if (savedLtv) {
      setLtv(savedLtv);
    }

    if (savedAddLater) {
      setAddLaterRadio(true);
    }
    setNextButtonState(savedLtv || ltv, savedAddLater);
  }, [overviewData]);

  return (
    <div style={styles.container}>
      <TextFieldSimple
        sx={styles.textField}
        name="Customer LTV"
        startAdornment="$"
        value={ltv}
        onChange={(newLtv) => {
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(newLtv)) {
            setNextButtonState(newLtv, addLaterRadio);
            setLtv(newLtv);
            setAddLaterRadio(false);
            sendData(
              {
                ltv: newLtv,
                add_ltv_later: false,
              },
              dispatch,
            );
          }
        }}
      />
      <RadioButton
        label={"I'll add this later"}
        checked={addLaterRadio}
        setChecked={setAddLaterRadio}
        canUncheckSelf={false}
        onClick={() => {
          if (!addLaterRadio) {
            setNextButtonState(ltv, true);
            setLtv("");
            sendData(
              {
                add_ltv_later: true,
                ltv: null,
              },
              dispatch,
            );
          }
        }}
      />
    </div>
  );
}

LtvPage.propTypes = {
  setNextButtonDisabled: PropTypes.func,
};
