import React from "react";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import PropTypes from "prop-types";
import BaseHeader from "./BaseHeader";

const styles = {
  header: {
    fontSize: "1.25rem",
    lineHeight: "1.5",
    fontWeight: 500,
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
  },
};

function Header4({ children, className, sx = {}, ...rest }) {
  const mergedStyles = { ...styles.header, ...sx };
  return (
    <BaseHeader sx={mergedStyles} className={className} {...rest}>
      {children}
    </BaseHeader>
  );
}

export default Header4;

Header4.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  sx: PropTypes.object,
};
