import { Grid } from "@mui/material";
import { ERROR_THEME, SUCCESS_THEME } from "assets/colours";
import MetricDisplayRow from "library/display/MetricDisplayRow";
import LabelChip from "library/display/LabelChip";
import OutPointCard from "library/surface/OutPointCard";
import React, { useEffect, useState } from "react";
import { getQuotientFixed } from "utils/data/math";
import { addCommasToNumber } from "utils/data/strings";
import { useSelector } from "react-redux";
import {
  trimDataSeries,
  mixRangeToNumDays,
  daysToMilliSeconds,
} from "utils/data/dates";
import { deepCopy } from "utils/data/objects";
import PropTypes from "prop-types";

const calculateDataGoalFromActuals = (dataGoalKey, actualsRow) => {
  if (dataGoalKey === "profit") {
    return actualsRow.total_revenue - actualsRow.spend;
  }
  if (dataGoalKey === "total_revenue") {
    return actualsRow.total_revenue;
  }
  return null;
};

function RecommendationsOverview({
  goalWord = "",
  predictedData = {},
  dataGoalKey,
  timeRangeLabel = "daily",
  timeRangeDays = 1,
  mixRange = "Last 3 months",
}) {
  const roasResponse = useSelector((state) => state.reportingData);
  const [currentSpendValue, setCurrentSpendValue] = useState(0);
  const [currentReturnValue, setCurrentReturnValue] = useState(0);
  const [currentGoalValue, setCurrentGoalValue] = useState(0);

  useEffect(() => {
    const actualsData = deepCopy(roasResponse.actualsGroup?.data || {});
    if (!actualsData) return;

    const endDate = Math.max(
      ...Object.values(actualsData).map((categoryData) =>
        Math.max(
          ...categoryData
            .filter((row) => !row.interpolated)
            .map((row) => row.timestamp),
        ),
      ),
    );
    const trimmedData = trimDataSeries(
      actualsData,
      endDate - daysToMilliSeconds(mixRangeToNumDays[mixRange]),
      endDate,
      "timestamp",
    );
    const maxNumDays = Math.max(
      ...Object.values(trimmedData).map((categoryData) => categoryData.length),
    );

    const spendValue = Number(
      Object.values(trimmedData)
        .map((categoryData) => {
          return categoryData.reduce((sum, row) => sum + row.spend, 0);
        })
        .reduce((sum, categorySum) => sum + categorySum, 0) / maxNumDays,
    ).toFixed(0);
    setCurrentSpendValue(spendValue);

    const returnValue = Number(
      Object.values(trimmedData)
        .map((categoryData) => {
          return categoryData.reduce((sum, row) => {
            return sum + (row.total_revenue || 0);
          }, 0);
        })
        .reduce((sum, categorySum) => sum + categorySum, 0) / maxNumDays,
    ).toFixed(0);

    setCurrentReturnValue(returnValue);

    const goalValue = Number(
      Object.values(trimmedData)
        .map((categoryData) => {
          return categoryData.reduce(
            (sum, row) => sum + calculateDataGoalFromActuals(dataGoalKey, row),
            0,
          );
        })
        .reduce((sum, categorySum) => sum + categorySum, 0) / maxNumDays,
    ).toFixed(0);

    setCurrentGoalValue(goalValue);
  }, [mixRange, roasResponse.status, goalWord]);

  const predictedSpendValue = Math.round(predictedData?.spend);
  const predictedGoalValue = Math.round(predictedData?.[dataGoalKey]);
  const predictedReturnValue = Math.round(predictedData?.total_revenue);
  const currentRoi =
    Math.round((currentReturnValue / currentSpendValue) * 10) / 10;
  const predictedRoi =
    Math.round((predictedReturnValue / predictedSpendValue) * 10) / 10;
  const spendDifferencePercentage = Math.round(
    (getQuotientFixed(predictedSpendValue, currentSpendValue) - 1) * 100,
  );
  const goalDifferencePercentage = Math.round(
    (getQuotientFixed(predictedGoalValue, currentGoalValue) - 1) * 100,
  );
  const roiDifferencePercentage = Math.round(
    (getQuotientFixed(predictedRoi, currentRoi) - 1) * 100,
  );

  const contentData = [
    {
      key: `Average ${timeRangeLabel} spend`,
      value: currentSpendValue * timeRangeDays,
    },
    {
      key: `Average ${timeRangeLabel} ${goalWord || ""}`,
      value: currentGoalValue * timeRangeDays,
    },
    {
      key: `Average ${timeRangeLabel} ROI`,
      value: currentRoi,
      isDollarValue: false,
    },
    {
      key: `Recommended ${timeRangeLabel} spend`,
      value: predictedSpendValue * timeRangeDays,
      differencePercentage: spendDifferencePercentage,
      percentageAdornment: spendDifferencePercentage < 0 ? "" : "+",
    },
    {
      key: `Recommendation ${timeRangeLabel} ${goalWord || ""}`,
      value: predictedGoalValue * timeRangeDays,
      differencePercentage: goalDifferencePercentage,
      percentageAdornment: goalDifferencePercentage < 0 ? "" : "+",
    },
    {
      key: `Recommendation ${timeRangeLabel} ROI`,
      value: predictedRoi,
      differencePercentage: roiDifferencePercentage,
      percentageAdornment: roiDifferencePercentage < 0 ? "" : "+",
      isDollarValue: false,
    },
  ];

  return (
    <div>
      <Grid
        container
        columns={2}
        maxWidth={`${348 * 2 + 24 + 16}px`}
        display="flex"
        spacing="24px"
        sx={{ marginBottom: "16px" }}
      >
        {contentData.map(
          ({
            key,
            value,
            differencePercentage,
            percentageAdornment,
            isDollarValue = true,
          }) => {
            let valueAdornment = null;

            const isDifferenceChipDisplayable =
              differencePercentage && differencePercentage !== Infinity;
            if (isDifferenceChipDisplayable) {
              const moreBoxStyles = {
                backgroundColor: SUCCESS_THEME.backgroundColor,
                margin: "8px",
                borderRadius: "100px",
              };
              const moreTextStyles = {
                color: SUCCESS_THEME.color,
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "12px",
              };

              if (differencePercentage < 0) {
                // Set color to red theme, as per design
                moreBoxStyles.backgroundColor = ERROR_THEME.backgroundColor;
                moreTextStyles.color = ERROR_THEME.color;
              }

              valueAdornment = (
                <LabelChip
                  text={`${percentageAdornment}${differencePercentage}%`}
                  chipTextStyles={{ ...moreTextStyles, ...moreBoxStyles }}
                />
              );
            }

            return (
              <Grid key={key} item xs={12}>
                <OutPointCard
                  sx={{
                    padding: "8px",
                  }}
                >
                  <MetricDisplayRow
                    keyText={key}
                    valueText={`${isDollarValue ? "$" : ""}${addCommasToNumber(
                      value,
                    )}`}
                    sx={{
                      m: "16px",
                      marginBottom: "0px",
                    }}
                    valueAdornment={valueAdornment}
                  />
                </OutPointCard>
              </Grid>
            );
          },
        )}
      </Grid>
    </div>
  );
}

export default RecommendationsOverview;

RecommendationsOverview.propTypes = {
  goalWord: PropTypes.string,
  predictedData: PropTypes.object,
  dataGoalKey: PropTypes.string,
  timeRangeDays: PropTypes.number,
  timeRangeLabel: PropTypes.string,
  mixRange: PropTypes.string,
};
