import React, { useState } from "react";

import { useDispatch } from "react-redux";

import BodyText from "library/text/body/BodyText";
import OutPointTextField from "library/form/OutPointTextField";
import { isValidNumber } from "utils/data/strings";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import PropTypes from "prop-types";
import OutPointSelector from "library/form/OutPointSelector";
import SeeResultsButton from "./SeeResultsButton";
import { AGGREGATION_DAY_RANGES } from "../../../utils/enums";

function FixedBudgetPage({
  setOptimization,
  setErrorText,
  chosenTimeRange,
  setChosenTimeRange,
  setDisplayTimeRange,
}) {
  const [budget, setBudget] = useState("");
  const dispatch = useDispatch();

  return (
    <>
      <BodyText>Choose a budget to optimize within.</BodyText>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          marginTop: "10px",
        }}
      >
        <OutPointTextField
          sx={{ width: "250px" }}
          name="Budget"
          startAdornment="$"
          value={budget}
          onChange={(newValue) =>
            isValidNumber(newValue) && setBudget(newValue)
          }
        />
        <OutPointSelector
          sx={{
            minWidth: "200px",
            width: "auto",
            padding: "16px 16px",
            marginLeft: "16px",
          }}
          selectedValue={chosenTimeRange}
          onChange={(timeRange) => setChosenTimeRange(timeRange)}
          menuItems={["monthly", "weekly", "daily"]}
        />
      </div>
      <SeeResultsButton
        disabled={!budget}
        request={async () => {
          setOptimization({});
          setDisplayTimeRange(chosenTimeRange);
          return makeAuthenticatedGetRequest("optimize_fixed_budget", {
            budget: `${budget / AGGREGATION_DAY_RANGES[chosenTimeRange]}`,
          });
        }}
        setOptimization={setOptimization}
        setErrorText={setErrorText}
        dispatch={dispatch}
      />
    </>
  );
}

export default FixedBudgetPage;

FixedBudgetPage.propTypes = {
  setOptimization: PropTypes.func,
  setErrorText: PropTypes.func,
  chosenTimeRange: PropTypes.string,
  setChosenTimeRange: PropTypes.func,
  setDisplayTimeRange: PropTypes.func,
};
