/**
 * This file contains various helpers for calculating marketing data.
 * */

/**
 * Caculates customer acquisition value and returns an unformatted number.
 *
 * TODO: QQ: verify that this is correct
 * */
export const calculateCac = (ltvVal, spendVal, returnVal) => {
  if (!returnVal) {
    return 0;
  }

  return (ltvVal * spendVal) / returnVal;
};

/*
 * Formats a metric value.
 */
export const formatMetric = (
  val,
  isPercentage = false,
  withSign = true,
  variableDecimals = false,
) => {
  if (!val && parseInt(val, 10) !== 0) {
    return `0${isPercentage ? "%" : ""}`;
  }

  if (val === Infinity) {
    return `>100${isPercentage ? "%" : ""}`;
  }

  try {
    let roundedVal = null;
    if (variableDecimals) {
      roundedVal =
        Math.abs(parseFloat(val)) >= 10
          ? Math.round(parseFloat(val))
          : parseFloat(val).toFixed(2);
    } else {
      roundedVal = parseFloat(val).toFixed(2);
    }

    const areBothDecimalsZero = roundedVal % 1 === 0;
    if (areBothDecimalsZero) {
      roundedVal = parseInt(roundedVal, 10);
    }

    return `${withSign ? roundedVal : Math.abs(roundedVal)}${
      isPercentage ? "%" : ""
    }`;
  } catch (e) {
    return val;
  }
};
