/**
 * These functions intend to make it easier for us to use ChartJS and is agnostic to graph types.
 *
 * The ChartJS documentation is extensive and clear and it might be better
 * to use it directly, as opposed to having a OutPoint-specific wrapper for it.
 *
 * Therefore, these functions are helper functions that demonstrate how to use ChartJS
 * and, in some cases, intentionally redundant.
 * */
import { GRAPH_TICK_COLOR } from "assets/colours";
import { LIGHT_MEDIUM_GREY } from "assets/palette";

import { Chart } from "chart.js";

/**
 * Registering components is the way that chartJs can do some tree-shaking to reduce the size of the
 * compiled bundle.
 *
 * Ref: https://www.chartjs.org/docs/latest/getting-started/usage.html#tree-shaking
 * */
export const registerChartJsComponents = (components) =>
  Chart.register(...components);

/**
 * This is the top-level object that can be passed to the chart object (or to a chart as props)
 * */
export const createChartConfig = (
  type = "",
  data = {},
  options = {},
  plugins = [],
  otherProps = {},
) => {
  return {
    type,
    data,
    options,
    plugins,
    ...otherProps,
  };
};

/// //////////////////////////
// Helpers for Data Object //
/// //////////////////////////
/**
 * Creates a single dataset obj using a primitive numbers array.
 * This is the simplest, most performant way of supplying data to the api because it doesn't add an extra parsing step.
 *
 *
 * NOTE: we can supply dataset options for fine-tuned modifications to options. This will override options defined at the top-level config object, for that
 *       particular dataset. Such options may be passed via the catch-all parameter called "datasetProps".
 *
 * @param numArray
 * @param datasetProps other data set specific props that you might want to add in.
 * */
export const createDatasetViaNumArray = (numArray, datasetProps = {}) => {
  // eslint-disable-next-line no-console
  console.assert(
    Array.isArray(numArray) && numArray.every((x) => !Number.isNaN(x)),
    "It has to be a num array",
  );

  const dataset = {
    data: numArray,
    ...datasetProps,
  };

  return dataset;
};

/**
 * Creates a single dataset obj in an alternative way. ChartJS supports custom object formats and (e.g. using custom keys) as long
 * as ChartJs's parser is told what keys to look at for the various axes. This also introduces a parsing step, which can be non-performant
 * for larger datasets hence it's better to use num arrays to form dataset (like above).
 *
 * Ref: https://www.chartjs.org/docs/latest/general/data-structures.html#object
 *
 * @param xAxisKey keyname for the x-axis that the parser needs to use, this is used for checking only
 * @param yAxisKey keyname for the y-axis that the parser needs to use, this is used for checking only
 * @param dataArr array of data point objects that fall under this dataset
 * @param datasetProps any other props to add
 * */
export const createDatasetViaCustomObjArray = (
  xAxisKey,
  yAxisKey,
  dataArr,
  datasetProps,
) => {
  // eslint-disable-next-line no-console
  console.assert(
    dataArr.every((point) => [xAxisKey, yAxisKey].every((key) => key in point)),
    `data array must consist of objects that have the xAxisKey, yAxisKey = (${xAxisKey}, ${yAxisKey})`,
  );

  const dataset = {
    data: dataArr,
    ...datasetProps,
  };

  return dataset;
};

/**
 * Creates a data object in its default format, which uses an array of labels and an array of dataset objects.
 *
 * @param labels: a list of labels, either string labels or int labels.
 * @param primitiveDatasets: a list of lists, where each sublist represents the datapoints. There's an implicit relationship b/w the index of the labels and the index of a datapoint within this list.
 * */
export const createDataObjViaNumArrays = (labels, primitiveDatasets) => {
  const assertMsg =
    "" +
    "the labels property of the main data property is used," +
    " it has to contain the same amount of elements as the dataset with the most values";
  // eslint-disable-next-line no-console
  console.assert(
    primitiveDatasets.every(
      (dataset) => dataset?.data?.length <= labels.length,
    ),
    assertMsg,
  );

  return {
    labels,
    datasets: primitiveDatasets,
  };
};

/// /////////////////////////////
// Helpers for Options Object //
/// /////////////////////////////

/**
 * Creates an options object that modifies ChartJS components' options.
 *
 * @param scaleOptions:
 * @param pluginOptions: plugin components that have been registered have options that can be modified and this parameter outlines such modifications.
 *                       Note: the plugins used can be registered either inline or as a global registration.
 *                       Ref: https://www.chartjs.org/docs/latest/developers/plugins.html#plugin-options
 * @param otherOptionProps: generic catchall varargs for other options.
 * */
export const createOptionsObj = (
  scaleOptions = {},
  pluginOptions = {},
  otherOptionProps = {},
) => {
  const options = {
    scales: scaleOptions,
    plugins: pluginOptions,
    ...otherOptionProps,
  };

  return options;
};

/// ///////////////////
// Other utilities: //
//  * styles        //
/// ///////////////////
// common styles amongst tool tip components
export const toolTipCommonStyles = {
  font: {
    size: 16,
    fontFamily: "IBM Plex Sans",
    lineHeight: "24px",
  },
  color: "black",
};

/**
 * Returns a standardized tooltip options object, intended to be overridden for
 * more custom styling.
 * */
export const getDefaultTooltipOptions = () => ({
  mode: "nearest",
  intersect: false,
  backgroundColor: "white",
  borderColor: LIGHT_MEDIUM_GREY,
  borderWidth: 1,
  titleColor: toolTipCommonStyles.color,
  titleFont: toolTipCommonStyles.font,
  bodyColor: toolTipCommonStyles.color,
  bodyFont: toolTipCommonStyles.font,
  padding: 24,
  cornerRadius: 8,
  usePointStyle: true,
  boxWidth: 12,
});

/**
 * Returns a standardized scale tick props that is intended to be overridden should
 * custom styling be required.
 * */
const getDefaultScaleTickProps = () => {
  return {
    autoSkip: true,
    maxTicksLimit: 8,
    color: GRAPH_TICK_COLOR,
    maxRotation: 0,
    minRotation: 0,
  };
};

export const getDefaultDatasetProps = (overrides = {}) => {
  return {
    borderWidth: 1.5,
    pointRadius: 1,
    ...overrides,
  };
};

/**
 * Defines a scale to be used (usually w.r.t axis scales).
 *
 * Ref default scales: https://www.chartjs.org/docs/latest/axes/#default-scales
 *
 * The following params are key areas that are generally configured when modifying the graph:
 *
 * @param axis (optional) explicitly defined axis id for the scale. This field is optional because by right, the axis of scales can be inferred
 *              by the first char of the scale prop e.g. xAxis: {...}
 * @param scaleTickProps
 * @param scaleTitleProps props related to the rendering of axis ticks.
 *                        Note: callbacks can be attached, which will trigger to modify the rendering of ticks!
 *                        Ref to the config options for ticks: https://www.chartjs.org/docs/latest/axes/#common-tick-options-to-all-axes
 *
 * @param otherScaleProps other props that can be added to the top level scales object.
 *                        Ref info about axes' options: https://www.chartjs.org/docs/latest/axes/#common-options-to-all-axes
 * */
export const createScaleOptions = (
  axis = "",
  scaleTitleProps = {},
  scaleTicksProps = {},
  otherScaleProps = {},
  isCustomAxis = false,
) => {
  const mergedScaleTicksProps = {
    ...getDefaultScaleTickProps,
    ...scaleTicksProps, // may override default props
  };

  const scale = {
    ...otherScaleProps,
    title: scaleTitleProps,
    ticks: mergedScaleTicksProps,
  };

  const SUPPORTED_AXIS_IDS = ["x", "y", "r"];
  if (!isCustomAxis && axis && axis in SUPPORTED_AXIS_IDS) {
    scale[axis] = axis;
  }

  return scale;
};
