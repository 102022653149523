import React from "react";

import BodyText from "library/text/body/BodyText";
import Title from "library/text/titles/Title";
import PrimaryButton from "library/buttons/PrimaryButton";
import PropTypes from "prop-types";

const styles = {
  text: {
    color: "secondary.main",
    textAlign: "center",
    width: "400px",
  },
  container: {
    padding: "200px 25% 35px 25%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    marginBottom: "15px",
  },
  button: {
    marginTop: "50px",
    width: "500px",
  },
};

function StartPage({ onClick }) {
  return (
    <div
      style={{
        minHeight: "100vh",
        background:
          "linear-gradient(323deg, rgba(164,144,245,0) 0%, rgba(164,144,245,0) 54%, rgba(164,144,245,0.3253676470588235) 100%)",
      }}
    >
      <div style={styles.container}>
        <Title
          sx={styles.title}
          text="Welcome to OutPoint"
          isSentenceCase={false}
        />
        <BodyText sx={styles.text}>
          Tell us a bit more about your goals and connect your revenue and ad
          spend accounts to get started.
        </BodyText>
        <PrimaryButton sx={styles.button} onClick={onClick}>
          Let's go
        </PrimaryButton>
      </div>
    </div>
  );
}

StartPage.propTypes = {
  onClick: PropTypes.func,
};

export default StartPage;
