/**
 * Our custom wrapper for dialog interactions.
 * */
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  dialogContent: {
    height: "fit-content",
    overflow: "hidden",
  },
};

export default function OutPointDialog(props) {
  const {
    isOpen,
    handleClose,
    dialogTitleChildren,
    dialogActionsChildren,
    dialogContentText,
    dialogClassName,
    children,
    ...rest
  } = props;

  const title = dialogTitleChildren && (
    <DialogTitle>{dialogTitleChildren}</DialogTitle>
  );

  const contentText = dialogContentText && (
    <DialogContentText>{dialogContentText}</DialogContentText>
  );

  const content = children && (
    <DialogContent sx={styles.dialogContent}>
      {contentText}
      {children}
    </DialogContent>
  );

  const actionComponents = dialogActionsChildren && (
    <DialogActions>{dialogActionsChildren}</DialogActions>
  );

  return content ? (
    <Dialog
      sx={styles.dialogClassName}
      className={dialogClassName}
      open={isOpen}
      onClose={handleClose}
      {...rest}
    >
      {title}
      {content}
      {actionComponents}
    </Dialog>
  ) : null;
}

OutPointDialog.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  dialogTitleChildren: PropTypes.any,
  dialogActionsChildren: PropTypes.any,
  dialogContentText: PropTypes.string,
  dialogClassName: PropTypes.string,
  children: PropTypes.any,
};
