import React from "react";
import Grid from "@mui/material/Grid";
import SubHead from "library/text/headers/SubHead";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  legendBox: {
    width: "100%",
  },
  legendTitle: {
    lineHeight: "16px",
    fontSize: "14px",
    fontWeight: 600,
    paddingTop: "4px",
    letterSpacing: "0.08em",
    textTransform: "uppercase",
  },
};

function LegendBox({
  title = "",
  endAdornment = <div />,
  children,
  moreLegendBoxStyles = {},
}) {
  return (
    <Box sx={moreLegendBoxStyles} style={styles.legendBox}>
      <Box sx={{ display: "flex", height: "auto" }}>
        <SubHead sx={styles.legendTitle}>{title}</SubHead>
        {endAdornment}
      </Box>
      <Grid style={{ justifyContent: "start" }} container>
        {children}
      </Grid>
    </Box>
  );
}

export default LegendBox;

LegendBox.propTypes = {
  title: PropTypes.string,
  endAdornment: PropTypes.element,
  children: PropTypes.any,
  moreLegendBoxStyles: PropTypes.object,
};
