import React, { useState } from "react";
import { Grid } from "@mui/material";
import OutPointCard from "library/surface/OutPointCard";
import SpendActualsCard from "./components/SpendActualsCard";
import RevenueActualsCard from "./components/RevenueActualsCard";

import SectionTemplate from "./components/SectionTemplate";
import { AGGREGATION_DAY_RANGES } from "../../utils/enums";

function HistoricalsAndActualsSection() {
  const [chosenTimeRange, setChosenTimeRange] = useState("daily");
  const [mixRange, setMixRange] = useState("Last 3 months");
  const sectionContent = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <OutPointCard>
          <SpendActualsCard
            timeRangeLabel={chosenTimeRange}
            timeRangeDays={AGGREGATION_DAY_RANGES[chosenTimeRange]}
          />
        </OutPointCard>
      </Grid>
      <Grid item xs={12}>
        <OutPointCard>
          <RevenueActualsCard
            timeRangeLabel={chosenTimeRange}
            timeRangeDays={AGGREGATION_DAY_RANGES[chosenTimeRange]}
          />
        </OutPointCard>
      </Grid>
    </Grid>
  );

  return (
    <SectionTemplate
      sectionHeading="Historical spend and return"
      sectionContent={sectionContent}
      chosenTimeRange={chosenTimeRange}
      setChosenTimeRange={setChosenTimeRange}
      mixRange={mixRange}
      setMixRange={setMixRange}
      shouldShowTimeRangeSelector={false}
    />
  );
}

export default HistoricalsAndActualsSection;
