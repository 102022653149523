/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jesterGetRequest } from "utils/jester-api";
import { STATUS } from "utils/enums";

export const getJesterData = createAsyncThunk("asyncRedux/jester", async () => {
  return jesterGetRequest("integrations");
});

const jesterSlice = createSlice({
  name: "jesterSlice",
  initialState: {
    data: null,
    status: null,
    authError: null,
  },
  reducers: {
    jesterLogOut: (state) => {
      state.data = null;
      state.status = null;
      state.authError = null;
    },
  },
  extraReducers: {
    [getJesterData.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getJesterData.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getJesterData.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const { jesterLogOut } = jesterSlice.actions;

export default jesterSlice.reducer;
