import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Card, Button } from "@mui/material";
import { ErrorOutlineOutlined, InfoOutlined } from "@mui/icons-material";

import { useSelector, useDispatch } from "react-redux";

import Header3 from "library/text/headers/Header3";
import BodyText from "library/text/body/BodyText";
import SubHead from "library/text/headers/SubHead";
import OutPointModal from "library/surface/ModalCentered";
import { BG_RED, RED } from "assets/palette";

import { getJesterData } from "redux/jesterSlice";
import { jesterPostRequest } from "utils/jester-api";
import PrimaryButton from "library/buttons/PrimaryButton";
import IconText from "library/containers/IconText";
import { cardGroups, cardData } from "pages/connections/data/connectionsData";
import { normalizeChannelName } from "utils/data/strings";
import LogoChip from "./LogoChip";
import PlatformListTemplate from "./PlatformListTemplate";

const styles = {
  modal: {
    width: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  deleteButtonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "35px",
    justifyContent: "flex-end",
  },
  errorCard: {
    width: "100%",
    backgroundColor: BG_RED,
    padding: "10px",
    boxShadow: "none",
    border: "none",
    marginBottom: "20px",
    borderRadius: "8px",
  },
  errorIcon: {
    marginRight: "10px",
    fontSize: "28px",
    color: RED,
  },
  infoIcon: {
    fontSize: "25px",
    marginRight: "10px",
  },
};

const handleDeleteData = async (channel, name, refetchData) => {
  try {
    const response = await jesterPostRequest("delete", {
      channel,
      name,
    });
    if (response.data?.success) {
      refetchData();
      return true;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return false;
  }

  return false;
};

export function DeleteDataModal({ modalOpen, setModalOpen, name, channel }) {
  const dispatch = useDispatch();
  const refetchData = () => dispatch(getJesterData());

  return (
    <OutPointModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      style={styles.modal}
    >
      <Header3>Remove account: {name}?</Header3>
      <BodyText color="secondary" style={{ margin: "20px 0" }}>
        All of your data associated with this account will be lost.
      </BodyText>
      <BodyText color="secondary">
        If you decide to add this account again, we will need to validate your
        details which can take up to a day.
      </BodyText>

      <div style={styles.deleteButtonContainer}>
        <Button
          variant="outlined"
          style={{ color: RED, marginRight: "30px", borderColor: RED }}
          onClick={() => {
            handleDeleteData(channel, name, refetchData);
            setModalOpen(false);
          }}
        >
          Remove account
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setModalOpen(false)}
        >
          Cancel and go back
        </Button>
      </div>
    </OutPointModal>
  );
}

function InfoModalSection({ header, body }) {
  return (
    <>
      <SubHead style={{ marginBottom: "5px" }}>{header}</SubHead>
      <BodyText color="secondary" style={{ marginBottom: "20px" }}>
        {body}
      </BodyText>
    </>
  );
}

export function InfoModal(props) {
  const {
    modalOpen,
    setModalOpen,
    logo,
    channel,
    name,
    metadata,
    status,
    statusColor,
  } = props;
  const metadataFields = cardData[channel]?.fields || [];
  return (
    <OutPointModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      style={styles.modal}
    >
      <LogoChip logo={logo} channel={channel} />

      <Header3 style={{ margin: "20px 0" }}>
        {normalizeChannelName(channel)} account information
      </Header3>

      <InfoModalSection header="Account" body={name} />
      {metadataFields.map((field) => (
        <InfoModalSection
          header={field}
          body={metadata ? metadata[field] : "None"}
        />
      ))}
      <InfoModalSection
        header="Status"
        body={
          <span style={{ fontWeight: 700, color: statusColor }}>{status}</span>
        }
      />
    </OutPointModal>
  );
}

function ConnectionPendingModal({ modalOpen, setModalOpen }) {
  return (
    <OutPointModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      style={styles.modal}
    >
      <IconText>
        <InfoOutlined sx={styles.infoIcon} color="primary" />
        <Header3>Connection pending</Header3>
      </IconText>
      <BodyText style={{ margin: "20px 0" }}>
        We’re in the process of validating this account which can take up to a
        day.
      </BodyText>
      <BodyText>
        If you’ve been waiting for longer than a day, contact us.
      </BodyText>

      <PrimaryButton
        onClick={() => setModalOpen(false)}
        style={{ width: "100px", marginTop: "20px", marginLeft: "auto" }}
      >
        Done
      </PrimaryButton>
    </OutPointModal>
  );
}

function NeedsAttentionModal({ modalOpen, setModalOpen, name, channel }) {
  const dispatch = useDispatch();
  const refetchData = () => dispatch(getJesterData());

  return (
    <OutPointModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      style={styles.modal}
    >
      <IconText>
        <InfoOutlined sx={styles.infoIcon} style={{ color: RED }} />
        <Header3>Error validating account</Header3>
      </IconText>
      <BodyText style={{ margin: "20px 0" }}>
        Please remove this account and add it again, checking that all account
        details are correct.
      </BodyText>
      <BodyText>If you need additional assistance, please contact us.</BodyText>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="primary"
          variant="outlined"
          style={{ width: "150px" }}
          onClick={() => {
            handleDeleteData(channel, name, refetchData);
            setModalOpen(false);
          }}
        >
          Remove account
        </Button>
        <PrimaryButton
          onClick={() => setModalOpen(false)}
          style={{ width: "100px", marginLeft: "10px" }}
        >
          Done
        </PrimaryButton>
      </div>
    </OutPointModal>
  );
}

function PlatformList(props) {
  const { whereFrom, group } = props;

  const jesterData = useSelector((state) => state.jester)?.data || {};
  const credentialsLists = Object.keys(jesterData)
    .filter((channel) => (group ? cardGroups[group].includes(channel) : true))
    .map((channel) => jesterData[channel]);
  const errorInLists = credentialsLists.map((credentialsList) =>
    credentialsList.reduce(
      (acc, credentials) => credentials.valid === false || acc,
      false,
    ),
  );
  const errorCardOpen = errorInLists.reduce(
    (acc, valid) => valid || acc,
    false,
  );

  const channelList = Object.keys(jesterData).filter((channel) =>
    group ? cardGroups[group].includes(channel) : true,
  );
  channelList.sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState({});
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [infoModalState, setInfoModalState] = useState({});
  const [connectionPendingModalOpen, setConnectionPendingModalOpen] =
    useState(false);
  const [needsAttentionModalOpen, setNeedsAttentionModalOpen] = useState(false);
  const [needsAttentionModalState, setNeedsAttentionModalState] = useState({});

  useEffect(() => {
    if (Object.keys(deleteModalState).length > 0) setDeleteModalOpen(true);
  }, [deleteModalState]);

  useEffect(() => {
    if (Object.keys(infoModalState).length > 0) setInfoModalOpen(true);
  }, [infoModalState]);

  useEffect(() => {
    if (Object.keys(needsAttentionModalState).length > 0)
      setNeedsAttentionModalOpen(true);
  }, [needsAttentionModalState]);

  return (
    <>
      <InfoModal
        modalOpen={infoModalOpen}
        setModalOpen={setInfoModalOpen}
        {...infoModalState}
      />
      <DeleteDataModal
        modalOpen={deleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        {...deleteModalState}
      />
      <NeedsAttentionModal
        modalOpen={needsAttentionModalOpen}
        setModalOpen={setNeedsAttentionModalOpen}
        {...needsAttentionModalState}
      />
      <ConnectionPendingModal
        modalOpen={connectionPendingModalOpen}
        setModalOpen={setConnectionPendingModalOpen}
      />

      {errorCardOpen && (
        <Card sx={styles.errorCard}>
          <IconText>
            <ErrorOutlineOutlined sx={styles.errorIcon} />
            <div>
              <SubHead>
                We weren’t able to validate one or more of your accounts
              </SubHead>
              <BodyText color="secondary">
                Please check that all of your account information has been
                entered correctly.{" "}
              </BodyText>
            </div>
          </IconText>
        </Card>
      )}
      {channelList.map((channel) => (
        <PlatformListTemplate
          whereFrom={whereFrom}
          channel={channel}
          credentialsList={jesterData[channel]}
          setDeleteModalState={setDeleteModalState}
          setInfoModalState={setInfoModalState}
          setNeedsAttentionModalState={setNeedsAttentionModalState}
          setConnectionPendingModalOpen={setConnectionPendingModalOpen}
        />
      ))}
    </>
  );
}

DeleteDataModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  name: PropTypes.string,
  channel: PropTypes.string,
};

InfoModalSection.propTypes = {
  header: PropTypes.any,
  body: PropTypes.any,
};

InfoModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  logo: PropTypes.string,
  channel: PropTypes.string,
  name: PropTypes.string,
  metadata: PropTypes.object,
  status: PropTypes.string,
  statusColor: PropTypes.string,
};

ConnectionPendingModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
};
NeedsAttentionModal.propTypes = {
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  name: PropTypes.string,
  channel: PropTypes.string,
};

PlatformList.propTypes = {
  whereFrom: PropTypes.string,
  group: PropTypes.string,
};

export default PlatformList;
