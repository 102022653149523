import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { capitalize } from "utils/data/strings";

const defaultFilterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) => option.toString(),
});

function OutPointAutoComplete(props) {
  const {
    name,
    onChange,
    disableLabel,
    options,
    value,
    margin = "dense",
    size = "small",
    filterOptions,
    textFieldPaceholder,
    renderInput,
    startAdornment = "",
    sx = {},
    ...rest
  } = props;
  const defaultInputRender = (params) => {
    /**
     * TODO: remove this and make it generic
     * suggestion: maybe name shouldn't be always used for the textfield label?
     *
     * Refer to this comment: https://github.com/OutPoint-app/outpoint-frontend/pull/265#discussion_r976840477
     * */
    let label = "Channel";

    if (disableLabel) label = "";
    else if (name) {
      if (typeof name === "string") {
        label = capitalize(name);
      } else {
        label = name;
      }
    }

    // eslint-disable-next-line no-param-reassign -- this behaviour is intentional and unambiguious
    params.InputProps.startAdornment = startAdornment;

    return (
      <TextField
        {...params}
        label={label}
        // prefix={'$ '}
        placeholder={textFieldPaceholder}
      />
    );
  };

  return (
    <Autocomplete
      sx={sx}
      id={name}
      options={options}
      getOptionLabel={(option) => option.toString()}
      filterOptions={filterOptions || defaultFilterOptions}
      value={value}
      onChange={onChange}
      margin={margin}
      size={size}
      renderInput={renderInput || defaultInputRender}
      {...rest}
    />
  );
}

OutPointAutoComplete.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  disableLabel: PropTypes.bool,
  options: PropTypes.array,
  value: PropTypes.any,
  margin: PropTypes.string,
  size: PropTypes.string,
  filterOptions: PropTypes.object,
  textFieldPaceholder: PropTypes.string,
  renderInput: PropTypes.func,
  startAdornment: PropTypes.string,
  sx: PropTypes.object,
};

export default React.memo(OutPointAutoComplete);
