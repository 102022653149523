export const liftPageTemplateStyles = {
  csvDownloadButton: {
    style: {
      fontSize: "16px",
      color: "black",
      display: "flex",
      letterSpacing: "0.01em",
    },
    variant: "text",
  },
  cardSubtitleText: {
    fontSize: "14px",
    marginTop: "12px",
  },
  headerMain: {
    marginBottom: "24px",
  },
  cardMargin: {
    padding: "32px",
    height: "fit-content",
  },
  flexDisplay: {
    display: "flex",
  },
  hyperLinkStyle: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#5F75EE",
    display: "flex",
    marginTop: "12px",
  },
  textButtonStyle: {
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    letterSpacing: "0.01em",
  },
  minWidthMaxContent: {
    minWidth: "max-content",
  },
  lightHeading: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    whiteSpace: "nowrap",
  },
  legendStyling: {
    margin: "10px 35px",
  },
  legendItemBody: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    width: "50px",
  },
  heading: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "40px",
  },
  headingBig: {
    fontWeight: 500,
    fontSize: "29px",
    lineHeight: "32px",
  },
  channelSelectStyle: {
    width: "142px",
    height: "48px",
  },
  renderValueStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    marginLeft: "4px",
    color: "white",
    backgroundColor: "#4A21EA",
    borderRadius: "50%",
  },
  xAxisStyling: {
    width: "24px",
    height: "20px",
    fontFamily: "IBM Plex Sans",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
  },
  badgeTextStyle: {
    fontFamily: "IBM Plex Sans",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#5F75EE",
  },
  badgeBoxStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "8px",
    marginRight: "8px",
    backgroundColor: "#5f75ee1a",
    width: "39px",
    height: "24px",
    borderRadius: "4px",
    marginTop: "1px",
  },
};

export default liftPageTemplateStyles;
