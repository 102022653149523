import React from "react";

import { FormControl, InputAdornment, OutlinedInput } from "@mui/material";

import Label from "library/text/body/LabelText";
import Caption1 from "library/text/body/Caption";
import PropTypes from "prop-types";

const styles = {
  formField: {
    paddingTop: "3px",
  },
  textBox: {
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: "#FFFFFF",
  },
};

export default function OutPointTextField(props) {
  const {
    name = "",
    startAdornment,
    endAdornment,
    error,
    errorMsg,
    className,
    onChange,
    size = "small",
    margin = "dense",
    value,
    disabled,
    sx = {},
    ...rest
  } = props;

  // TODO: make the caption spacing exist whether the caption is or isnt there
  return (
    <FormControl
      className={`${className}`}
      sx={{ ...styles.formField, ...sx }}
      key={name}
      required
      fullWidth
    >
      <Label
        style={{ marginBottom: "3px", color: disabled ? "grey" : "inherit" }}
      >
        {name}
      </Label>
      <OutlinedInput
        disabled={disabled}
        fullWidth
        error={error}
        margin={margin}
        size={size}
        sx={styles.textBox}
        variant="outlined"
        aria-label={name.toLowerCase()}
        name={name.toLowerCase()}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        id={`outlined-adornment${name}`}
        startAdornment={
          startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          )
        }
        endAdornment={
          endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          )
        }
        value={value}
        {...rest}
      />
      <div style={{ position: "relative" }}>
        {error && (
          <Caption1 style={{ position: "absolute", color: "red" }}>
            {errorMsg}
          </Caption1>
        )}
      </div>
    </FormControl>
  );
}

OutPointTextField.propTypes = {
  name: PropTypes.string,
  startAdornment: PropTypes.string,
  endAdornment: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  margin: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};
