import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";

import { visuallyHidden } from "@mui/utils";

import { SORT_ORDER } from "utils/enums";

function CustomTableHeader(props) {
  const {
    searchResults,
    headerCells,
    onSelectAllClick,
    sortOrder,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  // TODO: extract styling to styles

  /**
   * Returns a sorter function as a callback, which sorts
   * using the property supplied to this handler creation function.
   * */
  const createSortHandler = (property) => (event) =>
    onRequestSort(event, property);

  const areAllSelected =
    rowCount > 0 && numSelected === (searchResults?.length || rowCount);
  const selectAllCheckBox = (
    <Checkbox
      indeterminate={numSelected > 0 && !areAllSelected}
      checked={areAllSelected}
      onChange={onSelectAllClick}
    />
  );

  const createHeaderCellComponent = (headerCell) => (
    <TableCell
      key={headerCell.id}
      align={headerCell.numeric ? "right" : "left"}
      padding={headerCell.disablePadding ? "none" : "normal"}
      sortDirection={orderBy === headerCell.id ? sortOrder : false}
    >
      <TableSortLabel
        active={orderBy === headerCell.id}
        direction={orderBy === headerCell.id ? sortOrder : SORT_ORDER.ASC}
        onClick={createSortHandler(headerCell.id)}
      >
        {headerCell.label}
        {orderBy === headerCell.id ? (
          <Box component="span" sx={visuallyHidden}>
            {sortOrder === "desc" ? "sorted descending" : "sorted ascending"}
          </Box>
        ) : null}
      </TableSortLabel>
    </TableCell>
  );

  return (
    <TableHead>
      <TableRow>
        {/* TODO: figure out padding style here */}
        <TableCell padding="checkbox">{selectAllCheckBox}</TableCell>
        {headerCells.map(createHeaderCellComponent)}
      </TableRow>
    </TableHead>
  );
}
CustomTableHeader.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.object),
  headerCells: PropTypes.arrayOf(PropTypes.object),
  onSelectAllClick: PropTypes.func,
  sortOrder: PropTypes.string,
  orderBy: PropTypes.string,
  numSelected: PropTypes.number,
  rowCount: PropTypes.number,
  onRequestSort: PropTypes.func,
};

export default CustomTableHeader;
