import React from "react";
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

const METRIC_TITLES = [
  { label: "score", id: "score", decimals: 0 },
  { label: "roas", id: "roas_p50", decimals: 2 },
  { label: "reach", id: "audience", decimals: 0 },
  { label: "budget", id: "budget", adornment: "$", decimals: 0 },
  { label: "return", id: "predicted_return", adornment: "$", decimals: 0 },
];

export default function CardDivider({
  titleComponent,
  metricComponentCreator,
}) {
  return (
    <Grid container style={{ width: "100%" }}>
      <Grid item xs={4}>
        {titleComponent}
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={2} />
        {METRIC_TITLES.map((metric) => (
          <Grid key={metric.label} item xs={2}>
            {metricComponentCreator(metric)}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

CardDivider.propTypes = {
  titleComponent: PropTypes.element,
  metricComponentCreator: PropTypes.func,
};
