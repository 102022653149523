import React from "react";

import { Card, IconButton, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import PropTypes from "prop-types";

const styles = {
  card: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    maxHeight: "90%",
    overflowY: "auto",
    width: "600px",
  },
  closeModalButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
};

export default function OutPointModal(props) {
  const {
    className,
    children,
    modalOpen,
    setModalOpen,
    style = {},
    onClose = null,
    closeButton = true,
    ...rest
  } = props;

  const closeModal = onClose
    ? () => onClose(setModalOpen)
    : () => setModalOpen(false);

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Card
        className={className}
        style={{ ...styles.card, ...style }}
        {...rest}
      >
        {closeButton && (
          <IconButton
            sx={styles.closeModalButton}
            onClick={closeModal}
            disableRipple
          >
            <Close />
          </IconButton>
        )}

        {children}
      </Card>
    </Modal>
  );
}

OutPointModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  style: PropTypes.object,
  onClose: PropTypes.func,
  closeButton: PropTypes.bool,
};
