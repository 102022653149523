import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";

import PrimaryButton from "library/buttons/PrimaryButton";
import TextFieldSimple from "library/form/OutPointTextField";

import { isValidName } from "utils/data/validators";

import {
  toggleIsSubmitting,
  bulkEdit,
  selectObjects,
} from "redux/campaignsSlice";
import { makeAuthenticatedPostRequest } from "utils/backend-api";

import { editorStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";
import OPAutoComplete from "library/form/OutPointAutoComplete";
import Spacer from "library/containers/Spacer";
import LabelText from "library/text/body/LabelText";
import RadioButton from "library/buttons/RadioButton";

/**
 * Campaign Group Editor is a simple dialog that helps the
 * user create a new campaign group. The action button is only
 * enabled if a valid campaign group name is typed into the
 * input textfield.
 * */
function CampaignGroupEditor(props) {
  const { handleCloseDrawer } = props;
  const dispatch = useDispatch();

  const currentCampaignGroups = useSelector(selectObjects("campaign_groups"));
  const currentStrategies = useSelector(selectObjects("strategies"));
  const campaignGroupIdToStrategyId = useSelector(
    selectObjects("campaign_group_id_to_strategy_id_map"),
  );
  const currentCampaigns = useSelector(selectObjects("campaigns"));
  const [changed, setChanged] = useState(false);

  const [
    updatedCampaignGroupNameToOldCampaignGroupNameMap,
    setUpdatedCampaignGroupNames,
  ] = useState(
    currentCampaignGroups.reduce(
      (map, group) => ({ ...map, [group.id]: group.id }),
      {},
    ),
  );
  const [updatedCampaignGroupIdToIsBrandMap, setUpdatedCampaignGroupIsBrand] =
    useState(
      currentCampaignGroups.reduce(
        (map, group) => ({ ...map, [group.id]: group.is_brand }),
        {},
      ),
    );
  const [updatedCampaignGroupIdToStrategyIdMap, setUpdatedStrategy] = useState(
    campaignGroupIdToStrategyId,
  );

  const handleGroupEditSubmission = async () => {
    try {
      setChanged(false);
      const updatedCampaigns = currentCampaigns.map((campaign) => ({
        ...campaign,
        campaign_group:
          updatedCampaignGroupNameToOldCampaignGroupNameMap[
            campaign.campaign_group
          ],
        strategy:
          updatedCampaignGroupIdToStrategyIdMap[campaign.campaign_group],
        is_brand: updatedCampaignGroupIdToIsBrandMap[campaign.campaign_group],
      }));
      const updatedCampaignGroups = currentCampaignGroups.map((group) => ({
        id: updatedCampaignGroupNameToOldCampaignGroupNameMap[group.id],
        is_brand: updatedCampaignGroupIdToIsBrandMap[group.id],
        campaign_ids: group.campaign_ids,
      }));

      dispatch(toggleIsSubmitting());
      const groupingPostResponse = await makeAuthenticatedPostRequest(
        "campaigns",
        { changes: updatedCampaigns },
      );
      const { success: groupingPostSuccess } = groupingPostResponse;

      if (!groupingPostSuccess) {
        const errorMsg =
          "The updating of campaigns grouping to the db was unsuccessful. Please try again or reach out to us.";
        throw new Error(errorMsg);
      }

      dispatch(
        bulkEdit({
          updatedCampaigns,
          updatedCampaignGroups,
          updatedStrategies: currentStrategies,
          updatedCampaignGroupIdToStrategyIdMap,
        }),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error when submitting changes for campaign grouping:", e);
    } finally {
      dispatch(toggleIsSubmitting());
      handleCloseDrawer();
    }
  };

  const nameFieldPerCampaignGroup = currentCampaignGroups.reduce(
    (map, campaignGroup) => ({
      ...map,
      [campaignGroup.id]: (
        <TextFieldSimple
          sx={styles.inputField}
          value={
            updatedCampaignGroupNameToOldCampaignGroupNameMap[campaignGroup.id]
          }
          onChange={(text) => {
            setChanged(true);
            setUpdatedCampaignGroupNames({
              ...updatedCampaignGroupNameToOldCampaignGroupNameMap,
              [campaignGroup.id]: text.trim(),
            });
          }}
        />
      ),
    }),
    {},
  );

  const isBrandSelectionPerCampaignGroup = currentCampaignGroups.reduce(
    (map, campaignGroup) => ({
      ...map,
      [campaignGroup.id]: (
        <div
          style={{
            width: "115px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <RadioButton
            checked={updatedCampaignGroupIdToIsBrandMap[campaignGroup.id]}
            setChecked={() => {
              setChanged(true);
              setUpdatedCampaignGroupIsBrand({
                ...updatedCampaignGroupIdToIsBrandMap,
                [campaignGroup.id]: true,
              });
            }}
            onClick={() => {}}
          />
          <RadioButton
            checked={!updatedCampaignGroupIdToIsBrandMap[campaignGroup.id]}
            setChecked={() => {
              setChanged(true);
              setUpdatedCampaignGroupIsBrand({
                ...updatedCampaignGroupIdToIsBrandMap,
                [campaignGroup.id]: false,
              });
            }}
            onClick={() => {}}
          />
        </div>
      ),
    }),
    {},
  );

  const strategySelectionPerCampaignGroup = currentCampaignGroups.reduce(
    (map, campaignGroup) => ({
      ...map,
      [campaignGroup.id]: (
        <OPAutoComplete
          sx={styles.strategySelector}
          options={currentStrategies
            .map((strategy) => strategy.id)
            .filter((id) => id !== "ungrouped")}
          value={updatedCampaignGroupIdToStrategyIdMap[campaignGroup.id]}
          disableLabel
          onChange={(e, selection) => {
            setChanged(true);
            setUpdatedStrategy({
              ...updatedCampaignGroupIdToStrategyIdMap,
              [campaignGroup.id]: selection,
            });
          }}
        />
      ),
    }),
    {},
  );

  const isValidInput = Object.values(
    updatedCampaignGroupNameToOldCampaignGroupNameMap,
  ).reduce((isValid, newName) => {
    return isValid && isValidName(newName);
  });

  const cancelButton = (
    <PrimaryButton
      variant="outlined"
      onClick={handleCloseDrawer}
      sx={styles.button}
    >
      <Typography sx={styles.buttonLabel}>Cancel and go back</Typography>
    </PrimaryButton>
  );
  const confirmSaveButton = (
    <PrimaryButton
      disabled={!isValidInput || !changed}
      onClick={handleGroupEditSubmission}
      sx={styles.button}
    >
      <Typography sx={styles.buttonLabel}>Save changes</Typography>
    </PrimaryButton>
  );

  const actionButtons = (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {cancelButton}
      {confirmSaveButton}
    </Grid>
  );

  return (
    <Grid container direction="column" justifyContent="space-around">
      <Spacer sx={{ height: "10px" }} />
      <Grid container direction="row" justifyContent="space-between">
        <LabelText sx={{ width: "200px" }}>Campaign group name</LabelText>
        <Spacer sx={{ width: "50px" }} />
        <LabelText sx={{ width: "200px" }}>Strategy</LabelText>
        <Spacer sx={{ width: "40px" }} />
        <LabelText>Brand</LabelText>
        <Spacer sx={{ width: "10px" }} />
        <LabelText>Performance</LabelText>
      </Grid>
      {currentCampaignGroups
        .filter((group) => group.id !== "ungrouped")
        .map((group) => {
          return (
            <Grid
              key={group.id}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="end"
            >
              {nameFieldPerCampaignGroup[group.id]}
              <Spacer sx={{ width: "40px" }} />
              {strategySelectionPerCampaignGroup[group.id]}
              <Spacer sx={{ width: "40px" }} />
              <Grid sx={styles.brandRadio}>
                {isBrandSelectionPerCampaignGroup[group.id]}
              </Grid>
            </Grid>
          );
        })}
      <Spacer sx={{ height: "20px" }} />
      {actionButtons}
    </Grid>
  );
}

CampaignGroupEditor.propTypes = {
  handleCloseDrawer: PropTypes.func,
};

export default CampaignGroupEditor;
