import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
} from "@mui/material";
import BodyText from "library/text/body/BodyText";

export default function UploadStepper(props) {
  const { activeStep, steps } = props;

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.label}>
          <Grid container>
            <Grid item>
              <StepLabel
                optional={
                  index === steps.length - 1 && (
                    <BodyText variant="caption">Last Step</BodyText>
                  )
                }
              >
                {step.label}
              </StepLabel>
            </Grid>
            <Grid item>{step.tooltip}</Grid>
          </Grid>
          <StepContent>
            <BodyText sx={{ fontWeight: "500" }}>{step.description}</BodyText>
            <Box sx={{ mb: 2 }}>{step.component}</Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}
UploadStepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.object),
};
