/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeNcrGetRequest } from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getNewChannelRecommendations = createAsyncThunk(
  "asyncRedux/newChannelRecommAsync",
  async () => {
    return makeNcrGetRequest("recommendations");
  },
);

const newChannelRecommendationsSlice = createSlice({
  name: "newChannelRecommendationsSlice",
  initialState: {
    data: null,
    status: null,
    authError: null,
  },
  reducers: {
    newChannelLogOut: (state) => {
      state.data = null;
      state.status = null;
      state.authError = null;
    },
    newChannelSubmit: (state) => {
      state.data.metadataComplete = true;
    },
  },
  extraReducers: {
    [getNewChannelRecommendations.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getNewChannelRecommendations.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getNewChannelRecommendations.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const { newChannelLogOut, newChannelSubmit } =
  newChannelRecommendationsSlice.actions;
export default newChannelRecommendationsSlice.reducer;
