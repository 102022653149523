import React from "react";
import LoadingButton from "library/buttons/LoadingButton";
import { addMessage } from "redux/snackbarSlice";
import { addCommasToNumber } from "utils/data/strings";
import PropTypes from "prop-types";
import { convertResponseToScenario } from "utils/dataset/predictions";

function SeeResultsButton({
  setOptimization,
  setErrorText,
  request,
  disabled,
  dispatch,
}) {
  return (
    <LoadingButton
      style={{ marginTop: "30px", width: "200px" }}
      disabled={disabled}
      onClick={async () => {
        try {
          const response = await request();
          if (response.success) {
            const recommendedSpend = response.data?.recommended_spend;
            const couldNotSpendBudget = response.data?.could_not_spend_budget;
            setOptimization(
              convertResponseToScenario(response.data?.channel_states),
            );
            if (couldNotSpendBudget) {
              setErrorText(
                "The budget you entered is outside of our predicted data range. Please enter a lower budget to get exact results.",
              );
            } else if (recommendedSpend) {
              setErrorText(`The budget you entered is higher than our recommendations, and some channels may have poor ROAS. 
            We recommend choosing a spend under $${addCommasToNumber(
              recommendedSpend,
            )}.`);
            } else {
              setErrorText("");
            }
          } else if (response.message) {
            dispatch(addMessage({ message: response.message }));
          }
        } catch (error) {
          dispatch(addMessage({ message: error.message }));
        }
      }}
    >
      See results
    </LoadingButton>
  );
}

export default SeeResultsButton;

SeeResultsButton.propTypes = {
  setOptimization: PropTypes.func,
  setErrorText: PropTypes.func,
  request: PropTypes.func,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
};
