/**
 * This file contains helpers specific to feature flags.
 * Feature flags related things can be changed from here and don't
 * need to be modified elsewhere.
 * */
import React from "react";
import Chip from "library/display/Chip";

export const DEFAULT_FLAGS = Object.freeze({
  show_campaigns: false,
  show_channels: true,
  show_connections: true,
  show_ncr: false,
  show_onboarding: false,
  show_optimization: true,
  show_organiclift: false,
  show_overview: true,
  show_recommendations: true,
  show_revenueupload: false,
  show_spendupload: false,
  show_upload_history: false,
});

function captureNavInfo({
  text,
  to,
  icon,
  extraFunc,
  isCollapse,
  EndAdornment = null,
}) {
  return { text, to, icon, extraFunc, isCollapse, EndAdornment };
}

export const FEATURE_MAP = Object.freeze({
  show_onboarding: null,
  show_overview: captureNavInfo({ text: "Home", to: "/", icon: "Home" }),
  show_organiclift: captureNavInfo({
    text: "Lift Insights",
    icon: "Insights",
    isCollapse: true,
    EndAdornment: () => <Chip text="New" />,
  }),
  show_channels: captureNavInfo({
    text: "Channel Insights",
    icon: "Insights",
    isCollapse: true,
  }),
  show_recommendations: captureNavInfo({
    text: "Recommendations",
    to: "/recommendations",
    icon: "Recommendations",
  }),
  show_optimization: captureNavInfo({
    text: "Planner",
    to: "/planner",
    icon: "Recommendations",
    EndAdornment: () => <Chip text="New" />,
  }),
  show_connections: captureNavInfo({
    text: "Integrations",
    to: "/connections",
    icon: "Integrations",
  }),
  show_campaigns: captureNavInfo({
    text: "Campaigns",
    to: "/campaigns",
    icon: "Campaigns",
  }),
  show_ncr: captureNavInfo({
    text: "Channel discovery",
    to: "/new-channels",
    icon: "ChannelDiscovery",
  }),
  show_spendupload: captureNavInfo({
    text: "Spend",
    to: "/spend",
    icon: "Upload",
  }),
  show_revenueupload: captureNavInfo({
    text: "Revenue",
    to: "/revenue",
    icon: "Upload",
  }),
});

export default FEATURE_MAP;
