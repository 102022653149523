import React from "react";
import { Chip, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

/**
 * A basic chip used as a label.
 *
 * A tooltip may be optionally rendered if content is passed via the
 * tooltipContent prop.
 * */
export default function LabelChip(props) {
  const {
    size,
    text,
    tooltipContent,
    chipStyles = {},
    chipTextStyles,
    icon,
    ...rest
  } = props;

  // both style objects are used by the change chip component
  const mergedStyles = {
    ...chipStyles,
    ...chipTextStyles,
  };

  const chip = (
    <Chip
      icon={icon}
      size={size || "small"}
      label={text}
      sx={mergedStyles}
      {...rest}
    />
  );

  return tooltipContent ? (
    <Tooltip placement="top" arrow title={tooltipContent}>
      {chip}
    </Tooltip>
  ) : (
    chip
  );
}

LabelChip.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  tooltipContent: PropTypes.string,
  chipStyles: PropTypes.object,
  chipTextStyles: PropTypes.object,
  icon: PropTypes.element,
};
