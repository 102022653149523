import React from "react";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";

import { clearButtonStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

function ClearButton(props) {
  const { handleOnClick, ...rest } = props;

  return (
    <Button
      variant="contained"
      sx={styles.clearButton}
      onClick={handleOnClick}
      {...rest}
    >
      <Typography sx={styles.clearButtonText}>Clear</Typography>
    </Button>
  );
}

ClearButton.propTypes = {
  handleOnClick: PropTypes.func,
};

export default ClearButton;
