import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { capitalize } from "utils/data/strings";
import PropTypes from "prop-types";

const styles = {
  tableRowStyles: {
    "&:last-child td, &:last-child th": { border: 0 },
  },
};

/* Given a list of rows and columns, where there may be multiple rows associated to a single group 
(ie. by "rowName" which is rendered under the left-most column) render a simple table 
*/
function BasicTable(props) {
  // rows should be of the form: { rowName: [ { column1: value, column2: value, ... } ] }
  const { columnHeaders, rowData } = props;

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columnHeaders.map((column, idx) => (
            <TableCell key={column} align={idx > 0 ? "right" : "left"}>
              {column}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowData &&
          Object.keys(rowData).map((rowName) => {
            return rowData[rowName].map((row, rowIdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${rowName}_${rowIdx}`} sx={styles.tableRowStyles}>
                <TableCell component="th" scope="row">
                  {capitalize(rowName)}
                </TableCell>
                {Object.keys(row).map((k) => (
                  <TableCell key={row[k]} align="right">
                    {row[k]}
                  </TableCell>
                ))}
              </TableRow>
            ));
          })}
      </TableBody>
    </Table>
  );
}

export default BasicTable;

BasicTable.propTypes = {
  columnHeaders: PropTypes.arrayOf(PropTypes.string),
  rowData: PropTypes.object,
};
