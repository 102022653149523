import React from "react";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import { Typography } from "@mui/material";
import { sentenceCase } from "utils/data/strings";
import PropTypes from "prop-types";

const styles = {
  header: {
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
  },
};

function SubHead2({
  children,
  className,
  isSentenceCase = true,
  sx = {},
  ...rest
}) {
  const mergedStyles = { ...styles.header, ...sx };

  return (
    <Typography sx={mergedStyles} className={className} {...rest}>
      {isSentenceCase && children
        ? sentenceCase(children)
        : children?.toUpperCase() || ""}
    </Typography>
  );
}

export default SubHead2;

SubHead2.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isSentenceCase: PropTypes.bool,
  sx: PropTypes.object,
};
