import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { UPLOAD_VALIDITY_STATUS } from "utils/enums";
import { PRIMARY_DARK } from "assets/palette";

const styles = {
  container: {
    justifyContent: "center",
    position: "relative",
    textAlign: "center",
    marginTop: "50px",
  },
};

export function StepperButton(props) {
  const { handleStep, text, variant, color } = props;

  return (
    <Button onClick={handleStep} sx={{ color }} variant={variant}>
      {text}
    </Button>
  );
}
StepperButton.propTypes = {
  handleStep: PropTypes.func,
  text: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export function UploadConfirmationButton(props) {
  const { handleUploadConfirmation, text } = props;

  return (
    <div>
      <Grid container sx={styles.container}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            sx={styles.button}
            onClick={handleUploadConfirmation}
          >
            {text}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
UploadConfirmationButton.propTypes = {
  handleUploadConfirmation: PropTypes.func,
  text: PropTypes.string,
};

export default function StepperNavButtons(props) {
  const {
    droppedFile,
    handleStepNext,
    handleStepBack,
    completed,
    frontStepText,
    backStepText,
  } = props;

  const canDisplayNextStep =
    droppedFile.fileObj &&
    (droppedFile.validationStatus === UPLOAD_VALIDITY_STATUS.PARSED ||
      droppedFile.validationStatus === UPLOAD_VALIDITY_STATUS.VALID);

  return (
    <Grid sx={{ paddingTop: "12px" }}>
      {canDisplayNextStep && (
        <StepperButton
          handleStep={handleStepNext}
          text={frontStepText}
          variant="contained"
        />
      )}
      {!completed && (
        <StepperButton
          handleStep={handleStepBack}
          text={backStepText}
          color={PRIMARY_DARK}
        />
      )}
    </Grid>
  );
}
StepperNavButtons.propTypes = {
  droppedFile: PropTypes.object,
  handleStepNext: PropTypes.func,
  handleStepBack: PropTypes.func,
  completed: PropTypes.bool,
  frontStepText: PropTypes.string,
  backStepText: PropTypes.string,
};
