import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ButtonGroup, StyledEngineProvider } from "@mui/material";
import PngContainer from "library/containers/PngContainer";
import { createObjectByKey } from "utils/data/arrays";
import { sentenceCase, normalizeChannelName } from "utils/data/strings";
import { getLogosGeneric } from "utils/resolveLogos";
import NavItem from "library/navigation/NavItem";
import NavCollapse from "library/navigation/NavCollapse";
import { selectLiftTypes } from "redux/liftDataSlice";
import { MEDIUM_DARK_GREY, OP_COLORS } from "assets/palette";
import { FEATURE_MAP, DEFAULT_FLAGS } from "library/navigation/featureFlags";
import PropTypes from "prop-types";

const styles = {
  wrapper: {
    width: "100vw",
    display: "flex",
    justifyContent: "flex-end",
    minHeight: "100%",
    height: "100%",
  },
  paddingContainer: {
    width: "70%",
    padding: "5%",
    paddingTop: "36px",
  },
  noPaddingContainer: {
    width: "80%",
  },
  drawer: {
    zIndex: 1000,
    width: "18%",
    overflowY: "auto",
    minWidth: "240px",
    left: 0,
    // NOTE: Subtract appbar height from the total height of the screen if appbar is present over drawer
    height: "calc(100vh - 56px)",
    position: "fixed",
    backgroundColor: "white",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      // for safari & chrome
      width: 0,
      height: 0,
    },
  },
  logo: {
    marginTop: "32px",
    marginBottom: "24px",
    marginRight: "108px",
    marginLeft: "1px",
    fill: MEDIUM_DARK_GREY,
  },
  navItemList: {
    display: "flex",
    paddingTop: "1rem",
    flexDirection: "column",
    padding: "12px",
  },
  divider: {
    marginTop: "20px",
    marginBottom: "20px",
    height: "2px",
    backgroundColor: OP_COLORS.WHITE_TRANSLUCENT,
  },
  navbarIcon: {
    width: "18.33px",
    height: "16.37px",
    marginBottom: "14.5px",
  },
};

function NavBar({
  children,
  noPadding,
  isChannelInsightsCollapsed,
  isLiftInsightsCollapsed,
  setIsLiftInsightsCollapsed,
  setIsChannelInsightsCollapsed,
}) {
  const location = useLocation();
  const path = decodeURI(location.pathname);

  const [featureFlags, setFeatureFlags] = useState(DEFAULT_FLAGS);

  const recomData = useSelector((state) => state.recommendationsData);
  const featureFlagsPulled = useSelector((state) => state.features.flags);
  const supportedLiftTypes = useSelector(selectLiftTypes);

  useEffect(() => {
    if (path.includes("channel-insights") && isChannelInsightsCollapsed) {
      setIsChannelInsightsCollapsed(false);
    }
    if (path.includes("lift-insights") && isLiftInsightsCollapsed) {
      setIsLiftInsightsCollapsed(false);
    }
  }, [path]);

  useEffect(() => {
    if (featureFlagsPulled) {
      const items = createObjectByKey(
        Object.keys(FEATURE_MAP),
        featureFlagsPulled,
      );
      setFeatureFlags(items);
    }
  }, [featureFlagsPulled]);

  const liftInsightsWhitelist = [];
  if (featureFlagsPulled?.show_organiclift) {
    supportedLiftTypes.forEach((liftType) => {
      liftInsightsWhitelist.push(liftType);
    });
  }

  const allChannels = liftInsightsWhitelist.concat(
    recomData.status === "success"
      ? Object.keys(recomData.data.baseline.category_data)
      : [],
  );

  const collapseProps = {
    "Lift Insights": {
      entries: allChannels
        .filter((channel) => liftInsightsWhitelist.includes(channel))
        .map((channel) => {
          const labelText = sentenceCase(
            normalizeChannelName(`${channel} lift`),
          );
          return { toPath: `/lift-insights/${channel}`, entryText: labelText };
        }),
      stateVar: isLiftInsightsCollapsed,
      stateSetter: setIsLiftInsightsCollapsed,
    },
    "Channel Insights": {
      entries: allChannels
        .filter((channel) => !liftInsightsWhitelist.includes(channel))
        .map((channel) => {
          return { toPath: `/channel-insights/${channel}`, entryText: channel };
        }),
      stateVar: isChannelInsightsCollapsed,
      stateSetter: setIsChannelInsightsCollapsed,
    },
  };

  const createCollapsibleGroup = (customProps) => {
    const resolvedAsset = getLogosGeneric(`navbar${customProps.icon}`);

    return (
      <NavCollapse
        key={customProps.key}
        curPath={customProps.path}
        navEntries={customProps.navCollapseEntries}
        title={customProps.text}
        isCollapsed={customProps.isCollapsed}
        setIsCollapsed={customProps.setIsCollapsed}
        startIcon={
          <PngContainer icon={resolvedAsset} boxStyle={styles.navbarIcon} />
        }
        EndAdornment={customProps.EndAdornment}
      />
    );
  };

  const renderNavItemForFeature = (feature, idx) => {
    if (FEATURE_MAP[feature] && featureFlags[feature]) {
      const featureProps = FEATURE_MAP[feature];
      const {
        text: featureText,
        to: featureTo,
        icon: iconName,
        extraFunc: featureFunc,
        isCollapse: featureCollapse,
      } = featureProps;

      if (featureCollapse) {
        const featureCollapseProps = collapseProps[featureText];
        return createCollapsibleGroup({
          key: idx,
          path,
          navCollapseEntries: featureCollapseProps.entries,
          isCollapsed: featureCollapseProps.stateVar,
          setIsCollapsed: featureCollapseProps.stateSetter,
          ...featureProps,
        });
      }

      const resolvedAsset = getLogosGeneric(`navbar${iconName}`);

      if (featureFunc) {
        return (
          <NavItem
            key={idx}
            path={path}
            extraClickFunction={featureFunc}
            startIcon={
              <PngContainer icon={resolvedAsset} boxStyle={styles.navbarIcon} />
            }
            EndAdornment={featureProps.EndAdornment}
          >
            {featureText}
          </NavItem>
        );
      }

      return (
        <NavItem
          key={idx}
          path={path}
          to={featureTo}
          startIcon={
            <PngContainer icon={resolvedAsset} boxStyle={styles.navbarIcon} />
          }
          EndAdornment={featureProps.EndAdornment}
        >
          {featureText}
        </NavItem>
      );
    }
    return null; // fallthrough
  };

  return (
    <main style={styles.wrapper}>
      <aside style={styles.drawer}>
        <StyledEngineProvider injectFirst>
          <ButtonGroup sx={styles.navItemList}>
            {Object.keys(featureFlags).map((feature, idx) =>
              renderNavItemForFeature(feature, idx),
            )}
          </ButtonGroup>
        </StyledEngineProvider>
      </aside>
      {noPadding ? (
        <section style={styles.noPaddingContainer}>{children}</section>
      ) : (
        <section style={styles.paddingContainer}>{children}</section>
      )}
    </main>
  );
}

export default NavBar;

NavBar.propTypes = {
  children: PropTypes.any,
  noPadding: PropTypes.bool,
  isChannelInsightsCollapsed: PropTypes.bool,
  isLiftInsightsCollapsed: PropTypes.bool,
  setIsLiftInsightsCollapsed: PropTypes.func,
  setIsChannelInsightsCollapsed: PropTypes.func,
};
