/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { STATUS, BASELINE } from "utils/enums";

export const getRecommendations = createAsyncThunk(
  "asyncRedux/recommAsync",
  async () => {
    return makeAuthenticatedGetRequest("recommendation");
  },
);

/// SELECTORS:
/**
 * Given a scenario, creates a selector that returns the channels
 * in the order of their ranking for that scenario.
 * */
export const createChannelRanksSelector =
  (scenario, groupByStrategy) => (state) => {
    if (scenario === "profit" || scenario === "revenue") {
      return groupByStrategy
        ? state.recommendationsData.dataByStrategy[scenario].category_rankings
        : state.recommendationsData.data[scenario].category_rankings;
    }

    return groupByStrategy
      ? state.recommendationsData.baseLineScenariosStrategy
      : state.recommendationsData.baseLineScenarios; // fallthrough
  };

/**
 * Creates a selector that returns channel specific data for the given scenario and channel.
 * */
export const createChannelDataSelector =
  (scenario, channel, groupByStrategy) => (state) => {
    return groupByStrategy
      ? state.recommendationsData.dataByStrategy[scenario]?.category_data[
          channel
        ]
      : state.recommendationsData.data[scenario]?.category_data[channel];
  };

/* NOTE: data in this state follows an unstructured data object
  And the entire app depends on the data (recommendations are used everywhere)
  Change to the .data field, causes app wide re-renders
  Components across the app assume data is populated as it should be,
  but changes to the structure should be done in a controlled fashion
*/
const initialState = {
  data: null,
  dataByStrategy: null,
  status: null,
  authError: null,
  baseLineScenarios: [], // Use this precalculated field to display our different platforms vs calculating it each time, during runtime
  baseLineScenariosStrategy: [], // Use this precalculated field to display our different platforms vs calculating it each time, during runtime
};

const recommendationsSlice = createSlice({
  name: "recommendationsSlice",
  initialState,
  reducers: {
    recomLogOut: (state) => {
      state.data = null;
      state.dataByStrategy = null;
      state.baseLineScenarios = null;
      state.baseLineScenariosStrategy = null;
      state.status = null;
      state.authError = null;
    },
  },
  extraReducers: {
    [getRecommendations.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getRecommendations.fulfilled]: (state, { payload }) => {
      const { data, authError } = payload;
      state.data = data.channel;
      state.dataByStrategy = data.strategy;
      state.baseLineScenarios = Object.keys(
        data.channel[BASELINE].category_data,
      );
      state.baseLineScenariosStrategy = Object.keys(
        data.strategy[BASELINE].category_data,
      );
      state.status = STATUS.SUCCESS;
      state.authError = authError;
    },
    [getRecommendations.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const { recomLogOut } = recommendationsSlice.actions;
export default recommendationsSlice.reducer;
