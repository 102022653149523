import { configureStore } from "@reduxjs/toolkit";
import recommendationsReducer from "./recommendationsSlice";
import loginReducer from "./loginSlice";
import reportingReducer from "./reportingSlice";
import spendReducer from "./spendSlice";
import revenueReducer from "./revenueSlice";
import overviewReducer from "./overviewSlice";
import onboardingReducer from "./onboardingSlice";
import campaignsReducer from "./campaignsSlice";
import newChannelReducer from "./newChannelRecommendationsSlice";
import featuresReducer from "./featuresSlice";
import jesterReducer from "./jesterSlice";
import predictionsReducer from "./predictionsSlice";
import snackbarReducer from "./snackbarSlice";
import liftDataReducer from "./liftDataSlice";

export default configureStore({
  reducer: {
    recommendationsData: recommendationsReducer,
    login: loginReducer,
    reportingData: reportingReducer,
    spendData: spendReducer,
    revenueData: revenueReducer,
    overviewData: overviewReducer,
    onboarding: onboardingReducer,
    campaignsData: campaignsReducer,
    features: featuresReducer,
    jester: jesterReducer,
    newChannelRecommendations: newChannelReducer,
    predictionsStore: predictionsReducer,
    snackbarStore: snackbarReducer,
    liftDataStore: liftDataReducer,
  },
});
