import React from "react";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import PropTypes from "prop-types";
import BaseHeader from "./BaseHeader";

/// TODO: refactor, there's no reason all the headers can't be merged since it's really all dumplicated logic. Only the styles for the headers are likely to be different and the rest of the things are expected to be the same.
const styles = {
  header: {
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
    fontWeight: "bold",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
  },
};

function Header3({
  children,
  className,
  isSentenceCase = true,
  sx = {},
  ...rest
}) {
  const mergedStyles = { ...styles.header, ...sx };

  return (
    <BaseHeader
      sx={mergedStyles}
      className={className}
      isSentenceCase={isSentenceCase}
      {...rest}
    >
      {children}
    </BaseHeader>
  );
}

export default Header3;

Header3.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isSentenceCase: PropTypes.bool,
  sx: PropTypes.object,
};
