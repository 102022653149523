import React from "react";
import SubHead from "library/text/headers/SubHead";
import { Box } from "@mui/material";
import { PRIMARY_DARK } from "assets/palette";
import PropTypes from "prop-types";

const styles = {
  textStyle: {
    fontWeight: 700,
    fontSize: "10px",
    color: "white",
  },
  badgeBoxStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "20px",
    height: "20px",
    marginLeft: "4px",
    color: "white",
    backgroundColor: PRIMARY_DARK,
    borderRadius: "50%",
  },
};

function OutPointBadge(props) {
  const {
    text = "",
    moreBoxClasses = "",
    moreTextClasses = "",
    moreBoxStyles = {},
    moreTextStyles = {},
  } = props;

  const { textStyle, badgeBoxStyle } = styles;

  const mergedBadgeBoxStyle = {
    ...badgeBoxStyle,
    ...moreBoxStyles,
  };
  const mergedTextStyles = {
    ...textStyle,
    ...moreTextStyles,
  };

  return (
    <Box sx={mergedBadgeBoxStyle} className={moreBoxClasses}>
      <SubHead sx={mergedTextStyles} className={moreTextClasses}>
        {text}
      </SubHead>
    </Box>
  );
}

export default OutPointBadge;

OutPointBadge.propTypes = {
  text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  moreBoxClasses: PropTypes.string,
  moreTextClasses: PropTypes.string,
  moreBoxStyles: PropTypes.object,
  moreTextStyles: PropTypes.object,
};
