import React from "react";
import PropTypes from "prop-types";

import { cardData } from "pages/connections/data/connectionsData";
import BodyText from "library/text/body/BodyText";
import IconText from "library/containers/IconText";
import SubHead from "library/text/headers/SubHead";
import { BORDER_GREY } from "assets/palette";
import OutPointCard from "library/surface/OutPointCard";
import { normalizeChannelName } from "utils/data/strings";
import PlatformListItem from "./PlatformListItem";
import AddCredentialsButton from "./AddCredentialsButton";
import LogoChip from "./LogoChip";

const styles = {
  container: {
    height: "auto",
    marginBottom: "20px",
  },
  headerContainer: {
    padding: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: `1px solid ${BORDER_GREY}`,
  },
  logoChip: {
    marginRight: "10px",
  },
  listContainer: {
    padding: "10px 10px 0 10px",
  },
};

function PlatformListTemplate(props) {
  const {
    channel,
    whereFrom,
    credentialsList = [],
    setDeleteModalState,
    setInfoModalState,
    setNeedsAttentionModalState,
    setConnectionPendingModalOpen,
  } = props;
  const logo = cardData[channel]?.logo;
  const validationError = credentialsList.reduce(
    (acc, credentials) => credentials.valid === false || acc,
    false,
  );

  return (
    <OutPointCard sx={styles.container}>
      <IconText style={styles.headerContainer}>
        <IconText>
          <LogoChip
            style={styles.logoChip}
            size="25px"
            logo={logo}
            channel={channel}
          />
          <div>
            <SubHead>{normalizeChannelName(channel)}</SubHead>
            <BodyText color="secondary">
              {credentialsList.length}{" "}
              {credentialsList.length > 1 ? "accounts" : "account"} added
              {validationError && (
                <span style={styles.validErrorChip}>Validation error</span>
              )}
            </BodyText>
          </div>
        </IconText>
        <AddCredentialsButton whereFrom={whereFrom} channel={channel} />
      </IconText>

      <div style={styles.listContainer}>
        {credentialsList.map((credentials) => (
          <PlatformListItem
            key={credentials.name}
            whereFrom={whereFrom}
            credentials={credentials}
            channel={channel}
            logo={logo}
            setDeleteModalState={setDeleteModalState}
            setInfoModalState={setInfoModalState}
            setNeedsAttentionModalState={setNeedsAttentionModalState}
            setConnectionPendingModalOpen={setConnectionPendingModalOpen}
          />
        ))}
      </div>
    </OutPointCard>
  );
}

PlatformListTemplate.propTypes = {
  whereFrom: PropTypes.string,
  channel: PropTypes.string,
  credentialsList: PropTypes.arrayOf(PropTypes.object),
  setDeleteModalState: PropTypes.func,
  setInfoModalState: PropTypes.func,
  setNeedsAttentionModalState: PropTypes.func,
  setConnectionPendingModalOpen: PropTypes.func,
};

export default PlatformListTemplate;
