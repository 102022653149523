import React from "react";
import PropTypes from "prop-types";

const styles = {
  iconContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
};

function IconText({ style = {}, children, className, ...rest }) {
  return (
    <span
      {...rest}
      style={{ ...styles.iconContainer, ...style }}
      className={className}
    >
      {children}
    </span>
  );
}

export default IconText;

IconText.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
};
