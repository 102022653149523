import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";

const styles = {
  containerBoxStyle: {
    width: "100%",
  },
  dividerBoxStyle: {
    borderBottom: 1,
    borderColor: "divider",
  },
  getPanelContentVisibilityStyle: (isHidden) =>
    isHidden
      ? {
          visibility: "hidden",
          height: 0,
        }
      : {
          visibility: "visible",
          height: "inherit",
        },
  componentContainerStyle: {
    p: 3,
  },
};

export default function HorizontalTabsWithPanels(props) {
  const { contents } = props;

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newVal) => {
    setValue(newVal);
  };

  return (
    <Box sx={styles.containerBoxStyle}>
      <Box sx={styles.dividerBoxStyle}>
        <Tabs value={value} onChange={handleTabChange}>
          {contents.map((content) => {
            return <Tab key={content.label} label={content.label} />;
          })}
        </Tabs>
      </Box>
      {contents.map((content, idx) => {
        return (
          <Box
            id={`simple-tabpanel-${idx}`}
            key={content.label}
            role="tabpanel"
            style={styles.getPanelContentVisibilityStyle(value !== idx)}
            aria-labelledby={`simple-tab-${idx}`}
          >
            <Box sx={styles.componentContainerStyle}>{content.component}</Box>
          </Box>
        );
      })}
    </Box>
  );
}

HorizontalTabsWithPanels.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      component: PropTypes.node,
    }),
  ),
};
