import React from "react";
import { Box, Typography } from "@mui/material";

import { campaignsBannerStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

/**
 * Campaigns Banner is just a display component that shows various messages based
 * the current UI state in the campaigns component.
 * */
function CampaignsBanner() {
  const subTitleContent =
    "Create custom groups to organize and manage your campaigns and strategies.";

  return (
    <Box sx={styles.bannerContainer}>
      <Typography sx={styles.bannerTitleText}>Campaigns</Typography>

      <Typography sx={styles.bannerSubTitleText}>{subTitleContent}</Typography>
    </Box>
  );
}

export default CampaignsBanner;
