import React from "react";
import PropTypes from "prop-types";
import UploadPageTemplate from "pages/manual_upload/UploadPageTemplate";
import UploadHistoryPage from "pages/manual_upload/UploadHistoryPage";
import {
  updateIsCustomChannel,
  selectSpendChannel,
  addSpendCoordinates,
  addDroppedSpendFileState,
  resetSpendState,
} from "redux/spendSlice";
import HorizontalTabsWithPanels from "library/display/HorizontalTabsWithPanels";

export default function SpendPage(props) {
  const { whereFrom } = props;
  const config = {
    uploadActions: {
      selectChannel: selectSpendChannel,
      addCoordinates: addSpendCoordinates,
      updateDroppedFileState: addDroppedSpendFileState,
      updateIsCustomChannel,
      resetState: resetSpendState,
    },
    selectors: {
      channelSelector: (state) => state.spendData.selectedChannel,
      isCustomChannelSelector: (state) => state.spendData.isCustomChannel,
    },
  };

  const titleText = whereFrom === "onboarding" ? "" : "Spend Upload";
  const headerText =
    whereFrom === "onboarding" ? "" : "Upload this month's spend";

  const panelContents = [
    {
      label: "New Spend Upload",
      component: (
        <UploadPageTemplate
          {...props}
          titleText={titleText}
          headerText={headerText}
          uploadType="spend"
          config={config}
        />
      ),
    },
    {
      label: "Upload History",
      component: <UploadHistoryPage uploadType="spend" />,
    },
  ];

  return <HorizontalTabsWithPanels contents={panelContents} />;
}
SpendPage.propTypes = {
  whereFrom: PropTypes.string,
};
