import React from "react";

import {
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import {
  registerChartJsComponents,
  createDatasetViaNumArray,
  createDataObjViaNumArrays,
  createOptionsObj,
  createScaleOptions,
  getDefaultTooltipOptions,
} from "library/graphing/ChartJsHelpers";
import { Bar } from "react-chartjs-2";
import { kDigitConversion } from "utils/data/strings";

import PropTypes from "prop-types";
import { capitalize } from "lodash";
import SubHeadLight from "library/text/headers/SubHeadLight";
import LegendItem from "library/graphing/LegendItem";
import { GREEN, LIGHT_GREEN, LIGHT_RED, RED } from "assets/palette";

registerChartJsComponents([
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
]);

const formatSpendTicks = (x) => `${kDigitConversion(x)}`;

const xAxisScaleOptions = createScaleOptions(
  "x",
  {
    display: false,
    align: "center",
    text: "Channels",
  },
  {
    autoSkip: false,
  },
  {
    stacked: true,
    grid: {
      display: false,
    },
  },
);

const yAxisScaleOptions = createScaleOptions(
  "y",
  {
    display: true,
    align: "center",
    text: "Dollars ($)",
  },
  {
    callback: formatSpendTicks,
    autoSkip: false,
  },
);

const scaleOptions = {
  x: xAxisScaleOptions,
  y: yAxisScaleOptions,
};

const options = createOptionsObj(
  scaleOptions,
  {
    // plugin options:
    tooltip: {
      ...getDefaultTooltipOptions(),
      callbacks: {
        label: (context) => {
          return `${context.dataset.label}: $${context.formattedValue}`;
        },
      },
    },
  },
  {
    // other options:
    indexAxis: "x",
    elements: {
      bar: {
        borderColor: "black",
        borderWidth: 1,
      },
    },
    barPercentage: 1,
    categoryPercentage: 0.5,
  },
);

const commonDataSetProps = {
  axis: "x",
  fill: false,
  maxBarThickness: 75,
};

function SpendVsRoiGraph(props) {
  const { categoryData, timeRangeDays, timeRangeLabel } = props;
  const categories = Object.keys(categoryData).sort();
  const [spendData, returnData] = categories.reduce(
    ([spendArray, returnArray], category) => {
      const { spend, total_revenue: totalReturn } = categoryData[category];
      return [
        [...spendArray, spend * timeRangeDays],
        [...returnArray, totalReturn * timeRangeDays],
      ];
    },
    [[], []],
  );

  const datasets = [
    createDatasetViaNumArray(spendData, {
      label: "Recommended spend",
      backgroundColor: LIGHT_RED,
      borderColor: RED,
      stack: "spend",
      ...commonDataSetProps,
    }),
    createDatasetViaNumArray(returnData, {
      label: "Predicted Revenue",
      backgroundColor: LIGHT_GREEN,
      borderColor: GREEN,
      stack: "return",
      ...commonDataSetProps,
    }),
  ];

  const data = createDataObjViaNumArrays(
    categories.map((category) => capitalize(category)),
    datasets,
  );

  return (
    <div>
      <SubHeadLight
        style={{ padding: "20px" }}
      >{`Recommended ${timeRangeLabel} spend vs predicted return`}</SubHeadLight>
      {categoryData && (
        <Bar
          style={{
            maxHeight: null,
            maxWidth: null,
            padding: "0px 24px 24px 24px",
          }}
          options={options}
          data={data}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "0px 0px 20px 40px",
          gap: "20px",
        }}
      >
        <LegendItem
          title="Recommended spend"
          iconColor={LIGHT_RED}
          icon="circle"
        />
        <LegendItem
          title="Predicted return"
          iconColor={LIGHT_GREEN}
          icon="circle"
        />
      </div>
    </div>
  );
}

SpendVsRoiGraph.propTypes = {
  categoryData: PropTypes.objectOf(PropTypes.object),
  timeRangeDays: PropTypes.number,
  timeRangeLabel: PropTypes.string,
};

export default SpendVsRoiGraph;
