//
// Methods in this file should handle date related data processing for a generic dataset.
//

import {
  GRAPH_YEAR_FORMAT,
  CALENDAR_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  SEC_DAY,
} from "utils/enums";
import { get, set } from "lodash";
import { format, utcToZonedTime } from "date-fns-tz";
import {
  parse,
  getWeek,
  startOfWeek,
  endOfWeek,
  subDays,
  subMonths,
  subYears,
  isSameYear,
  isSameQuarter,
  isSameMonth,
  isSameWeek,
  isSameDay,
} from "date-fns";

export const mixRangeToNumDays = {
  "30 days": 30,
  "60 days": 60,
  "Last 2 weeks": 14,
  "Last 3 months": 91,
  "Last 6 months": 182,
  "Last year": 365,
  "All time": Infinity,
};

export function daysToMilliSeconds(numDays) {
  return numDays * 24 * 60 * 60 * 1000;
}

/**
 * Given a start and end date, trim the given array of data points that represent time-series data.
 * This just applies a simple filter.
 * */
export function trimDataSeries(data, start, end, dateField) {
  const trimmedData = {};
  Object.keys(data).forEach((channel) => {
    trimmedData[channel] = data[channel].filter((point) => {
      return point[dateField] <= end && point[dateField] > start;
    });
  });

  return trimmedData;
}

export const isSameWindowFunctions = {
  1: isSameDay,
  7: isSameWeek,
  30: isSameMonth,
  90: isSameQuarter,
  365: isSameYear,
};

/**
 * Given dataPoints that correspond to the same time window,
 * sums up the dataPoint values within this window and returns a single, combined point.
 * */
function combineDataPointsInTimeWindow(
  dataPoints,
  dateAccessKey = "x",
  valAccessKey = "y",
  shouldAverage = false,
) {
  const combinedPoint = dataPoints.reduce(
    (accumulatedPoint, currentPoint, currIdx) => {
      const accumVal = get(accumulatedPoint, valAccessKey, 0);

      const currentVal = get(currentPoint, valAccessKey, 0);
      let newAccumPoint = {
        // makes a copy, using currentPoint as a template if it's first datapoint
        ...(currIdx === 0 ? currentPoint : accumulatedPoint),
      };
      const newVal = accumVal + currentVal;
      newAccumPoint = set(newAccumPoint, valAccessKey, newVal);

      return newAccumPoint;
    },
    {}, // empty point
  );
  /// the last date in window depends on whether there's data available.
  /// therefore, we set the combined point's date to the first date in the window instead
  const firstDateInWindow = get(dataPoints[0], dateAccessKey, null);

  set(combinedPoint, dateAccessKey, firstDateInWindow);

  if (shouldAverage) {
    const averagedValue =
      get(combinedPoint, valAccessKey, 0) / dataPoints.length;
    set(combinedPoint, valAccessKey, averagedValue);
  }

  return combinedPoint;
}

/**
 * Aggregates (sums) up datapoints based on the windowSize(in days)
 * and returns a new dataSeries with the updated values.
 *
 * NOTE: dateAccessKey and valAccessKey are strings onto which lodash get can work
 *
 * Assumptions:
 * - datapoints within data are chronologically sorted.
 * */
export function aggregateDataSeriesOverTimeWindow(
  data,
  windowSize = 1,
  dateAccessKey = "x",
  valAccessKey = "y",
  shouldAverage = false,
) {
  if (windowSize === 1) {
    return data; // no aggregation can be done
  }

  // eslint-disable-next-line no-console -- it's a simple assertion statement
  console.assert(
    Object.keys(isSameWindowFunctions).includes(windowSize.toString()),
    `Window Size: ${windowSize} -- time window sizes should be restricted to supported versions`,
  );

  const { mergedPoints, currentBuffer } = data.reduce(
    (accumulatedObj, currentPoint) => {
      const { mergedPoints: accumulatedPoints } = accumulatedObj;
      let { currentBuffer: tempBuffer = [] } = accumulatedObj;

      const isFirstIteration = !tempBuffer || !accumulatedPoints;
      if (isFirstIteration) {
        return { mergedPoints: [], currentBuffer: [currentPoint] };
      }

      const currDate = get(currentPoint, dateAccessKey);
      const prevDate = get(tempBuffer[tempBuffer.length - 1], dateAccessKey);
      const isInSameWindow = isSameWindowFunctions[windowSize](
        currDate,
        prevDate,
      );

      if (!isInSameWindow) {
        const combinedPoint = combineDataPointsInTimeWindow(
          tempBuffer,
          dateAccessKey,
          valAccessKey,
          shouldAverage,
        );
        accumulatedPoints.push(combinedPoint);
        tempBuffer = [];
      }
      tempBuffer.push(currentPoint);

      return { mergedPoints: accumulatedPoints, currentBuffer: tempBuffer };
    },
    {},
  );

  mergedPoints.push(
    combineDataPointsInTimeWindow(
      currentBuffer,
      dateAccessKey,
      valAccessKey,
      shouldAverage,
    ),
  ); // uses the last buffer

  return mergedPoints;
}

/* Given a date in seconds, return a string of the format DEFAULT_DATE_FORMAT */
export function dateSecondsToString(date, dateFormat = DEFAULT_DATE_FORMAT) {
  const dateString = format(new Date(date), dateFormat);
  return dateString;
}

/* Creates a new date using standard UTC time */
export function newDate(dateInitData = null) {
  const dateObj =
    dateInitData !== null || dateInitData === undefined
      ? new Date(dateInitData)
      : new Date();
  const date = utcToZonedTime(dateObj, "UTC");
  return date;
}

export const secondsToDate = (seconds) => {
  const newDateObj = new Date(0);
  newDateObj.setSeconds(seconds);

  return newDateObj.toISOString().split("T")[0];
};

export const secondsToLocaleString = (seconds, options = {}) => {
  /* 
  TODO link to read me (pls don't approve PR if u catch this)
  */
  const newDateObj = new Date(0);
  newDateObj.setSeconds(seconds);

  return newDateObj.toLocaleDateString("en-US", options);
};

/* Given a date of the form "YYYY-MM-DD", return a string of the form "Month Day, Year" */
export function dateToString(date) {
  if (date) {
    const [year, month, day] = date.split("-");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[parseInt(month, 10) - 1]}  ${parseInt(
      day,
      10,
    )}, ${year}`;
  }
  return null;
}

/* Given a date of the format DEFAULT_DATE_FORMAT, add one day and return it in the same format */
export function addDayToDateString(dateString) {
  return dateSecondsToString(newDate(dateString).getTime() + SEC_DAY);
}

export const getDateRange = ({ endDate = undefined, options = {} }) => {
  const dateB = endDate || new Date();

  const {
    rangeMeasurement = "year",
    rangeUnit = 1,
    dateFormat = CALENDAR_DATE_FORMAT,
  } = options;

  // supported date ranges for now include days, months, and years
  // feel free to add more support if required
  let dateA;

  if (rangeMeasurement === "day") {
    dateA = subDays(dateB, rangeUnit);
  }

  if (rangeMeasurement === "month") {
    dateA = subMonths(dateB, rangeUnit);
  }

  if (rangeMeasurement === "year") {
    dateA = subYears(dateB, rangeUnit);
  }

  return [format(dateA, dateFormat), format(dateB, dateFormat)];
};

export const formatDate = ({
  date,
  fromFormat = CALENDAR_DATE_FORMAT,
  toFormat = DEFAULT_DATE_FORMAT,
}) => {
  if (!date) return null;

  return format(parse(date, fromFormat, new Date()), toFormat);
};

/*
 * Gets 1-indexed quarter number for a particular date.
 */
export function getQuarterNumFromDate(date = new Date()) {
  return Math.floor(date.getMonth() / 3 + 1);
}

export function getQuarterDesc(date = new Date()) {
  const quarterNum = getQuarterNumFromDate(date);
  const year = dateSecondsToString(date, GRAPH_YEAR_FORMAT);
  const desc = `Q${quarterNum} ${year}`;

  return desc;
}

/**
 * Gets a string representing the first and last date of the week for which the date is in.
 * */
export function getWeekDesc(date = new Date()) {
  const weekNumber = getWeek(date);
  const lastDateOfWeek = dateSecondsToString(
    endOfWeek(date),
    CALENDAR_DATE_FORMAT,
  );
  const firstDateOfWeek = dateSecondsToString(
    startOfWeek(date),
    CALENDAR_DATE_FORMAT,
  );

  const desc = `Week ${weekNumber}: ${firstDateOfWeek} to ${lastDateOfWeek}`;

  return desc;
}
