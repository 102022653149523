import React, { useState, useEffect } from "react";
import "App.css";
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

import "normalize.css";
import ChannelPageTemplate from "pages/channel_insights/ChannelPageTemplate";
import LiftPageTemplate from "pages/lift_insights/LiftPageTemplate";
import RecommPage from "pages/recommendations/RecommendationsPage";
import SpendPage from "pages/manual_upload/SpendPage";
import RevenuePage from "pages/manual_upload/RevenuePage";
import UploadHistoryPage from "pages/manual_upload/UploadHistoryPage";
import ConnectionsPage from "pages/connections/ConnectionsPage";
import { useSelector, useDispatch, batch } from "react-redux";
import PrivateRoute from "routing/privateRoute";
import OnboardingRoute from "routing/onboardingRoute";
import OnboardingPage from "pages/onboarding/OnboardingPage";
import OverviewPage from "pages/overview/OverviewPage";
import CampaignsPage from "pages/campaigns/CampaignsPage";
import OptimizationPage from "pages/optimization/OptimizationPage";
import NewChannelRecommendationsPage from "pages/ncr/NewChannelRecommendationsPage";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import theme from "themes/outpointTheme";

import { getRecommendations } from "redux/recommendationsSlice";
import { getPredictions } from "redux/predictionsSlice";
import { getRoasData } from "redux/reportingSlice";
import { getOverview } from "redux/overviewSlice";
import { getOnboarding } from "redux/onboardingSlice";
import { getJesterData } from "redux/jesterSlice";
import { getCampaignGrouping } from "redux/campaignsSlice";
import { getNewChannelRecommendations } from "redux/newChannelRecommendationsSlice";
import { getFeatures } from "redux/featuresSlice";
import { getLiftData } from "redux/liftDataSlice";

import NavBar from "library/navigation/NavBar";
import LogInSignUpPage from "pages/login/LoginSignUpPage";
import { createObjectFromArray, createObjectByKey } from "utils/data/arrays";
import SnackbarManager from "library/surface/SnackbarManager";
import { STAGGERED_REQUESTS_LIMIT, STATUS } from "utils/enums";

import OPAppBar from "library/navigation/AppBar";

function ChannelInsightsPage() {
  const { channel } = useParams();
  return <ChannelPageTemplate channel={channel} />;
}

function LiftPage() {
  const { liftType } = useParams();
  return <LiftPageTemplate liftType={liftType} />;
}

function getPageInfo(component, path) {
  return { component, path };
}

const FEATURE_MAP = Object.freeze({
  show_onboarding: getPageInfo(OnboardingPage, "/onboarding"),
  show_overview: getPageInfo(OverviewPage, "/"),
  show_channels: getPageInfo(ChannelInsightsPage, "/channel-insights/:channel"),
  show_recommendations: getPageInfo(RecommPage, "/recommendations"),
  show_connections: getPageInfo(ConnectionsPage, "/connections"),
  show_spendupload: getPageInfo(SpendPage, "/spend"),
  show_revenueupload: getPageInfo(RevenuePage, "/revenue"),
  show_upload_history: getPageInfo(UploadHistoryPage, "/upload-history"),
  show_campaigns: getPageInfo(CampaignsPage, "/campaigns"),
  show_ncr: getPageInfo(NewChannelRecommendationsPage, "/new-channels"),
  show_organiclift: getPageInfo(LiftPage, "/lift-insights/:liftType"), // TODO: might have to swap out the feature flag name for this.
  show_optimization: getPageInfo(OptimizationPage, "/planner"),
});

function App() {
  const dispatch = useDispatch();

  const [featureFlags, setFeatureFlags] = useState(
    createObjectFromArray(Object.keys(FEATURE_MAP), true),
  );
  const [isChannelInsightsCollapsed, setIsChannelInsightsCollapsed] =
    useState(true);
  const [isLiftInsightsCollapsed, setIsLiftInsightsCollapsed] = useState(true);

  function mainAppWrapper(Component) {
    return (
      <>
        <OPAppBar />
        <NavBar
          isChannelInsightsCollapsed={isChannelInsightsCollapsed}
          isLiftInsightsCollapsed={isLiftInsightsCollapsed}
          setIsChannelInsightsCollapsed={setIsChannelInsightsCollapsed}
          setIsLiftInsightsCollapsed={setIsLiftInsightsCollapsed}
        >
          <Component />
        </NavBar>
      </>
    );
  }

  const authStatus = useSelector((state) => state.login.authStatus);
  const featureFlagsPulled = useSelector((state) => state.features.flags);
  const { startDate, endDate } = useSelector((state) => state.liftDataStore);
  const { status: roasStatus, requestExecutionCount } = useSelector(
    ({ reportingData }) => reportingData,
  );

  useEffect(
    (_) => {
      if (authStatus) {
        batch(() => {
          dispatch(getFeatures());
          dispatch(getOnboarding());
          dispatch(getRecommendations());
          dispatch(getPredictions());
          dispatch(getRoasData());
          dispatch(getLiftData({ startDate, endDate }));
          dispatch(getCampaignGrouping());
          dispatch(getOverview());
          dispatch(getJesterData());
          dispatch(getNewChannelRecommendations());
        });
      }
    },
    [authStatus, dispatch, endDate, startDate],
  );

  useEffect(() => {
    if (!featureFlagsPulled) return;

    const items = createObjectByKey(
      Object.keys(FEATURE_MAP),
      featureFlagsPulled,
    );
    setFeatureFlags(items);

    // enabling projected roas is feature flagged, if enabled
    // a request is sent to the backend with the enableProjectedRoas=true query param
    // for visual performance improvements to the user, we execute two requests in the case
    // that the show_projected_roas feature flag is enabled in ddb
    // 1. roas request without projected roas
    // 2. roas request with projected roas
    // To prevent an infinite event loop, throttle the request:
    if (
      !featureFlagsPulled.show_projectedroas ||
      requestExecutionCount >= STAGGERED_REQUESTS_LIMIT.ROAS_GET ||
      roasStatus === STATUS.LOADING
    )
      return;

    batch(() => {
      dispatch(getRoasData({ enableProjectedRoas: true }));
    });

    // dispatch request for roas dat
  }, [dispatch, featureFlagsPulled, requestExecutionCount, roasStatus]);

  const featureRoutes = Object.keys(featureFlags).map((feature) => {
    if (FEATURE_MAP[feature] && featureFlags[feature]) {
      const { path: featurePath, component: featureComponent } =
        FEATURE_MAP[feature];

      if (feature.includes("onboarding")) {
        return (
          <Route
            exact
            path={featurePath}
            key={feature}
            element={<OnboardingRoute component={featureComponent} />}
          />
        );
      }

      return (
        <Route
          exact
          path={featurePath}
          key={feature}
          element={
            <PrivateRoute component={mainAppWrapper(featureComponent)} />
          }
        />
      );
    }
    return null; // fallthrough
  });

  const loginRoute = (
    <Route exact path="/login" element={<LogInSignUpPage />} />
  );

  const signUpRoute = (
    <Route exact path="/signup" element={<LogInSignUpPage />} />
  );

  return (
    <div className="App">
      <BrowserRouter>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Routes>
              {featureRoutes}
              {loginRoute}
              {signUpRoute}
            </Routes>
            <SnackbarManager />
          </ThemeProvider>
        </StyledEngineProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
