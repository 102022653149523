import { Box } from "@mui/material";
import PrimaryButton from "library/buttons/PrimaryButton";
import Badge from "library/display/OutPointBadge";
import React from "react";
import PropTypes from "prop-types";
import { PRIMARY_COLOR } from "../../../assets/palette";

function OverviewButtonGroup({ buttonOptions }) {
  if (buttonOptions?.length === 0) return <div />;

  return (
    <Box sx={{ display: "flex" }}>
      {buttonOptions.map(({ endIconText, isSelected, ...options }) => {
        return (
          <Box
            key={options?.text}
            sx={{
              borderBottom: isSelected ? 1 : 0,
              color: "primary.main",
              maxWidth: "214px",
              margin: "2px",
              backgroundColor: "white",
            }}
          >
            <PrimaryButton
              {...options}
              style={{
                color: PRIMARY_COLOR,
                margin: "2px",
                backgroundColor: "white",
              }}
              disableElevation
              onClick={options.onClick}
              endIcon={
                endIconText && (
                  <Badge
                    moreBoxStyles={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "20px",
                      height: "20px",
                      marginLeft: "4px",
                      color: "white",
                      backgroundColor: "primary.main",
                      borderRadius: "50%",
                    }}
                    moreTextStyles={{
                      fontWeight: 700,
                      fontSize: "10px",
                      color: "white",
                    }}
                    text={endIconText}
                  />
                )
              }
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default OverviewButtonGroup;

OverviewButtonGroup.propTypes = {
  buttonOptions: PropTypes.arrayOf(PropTypes.object),
};
