import React from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Accordion, AccordionDetails } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { isEmpty } from "lodash";

import {
  selectCampaignGroupIdToCampaignMap,
  selectObjects,
} from "redux/campaignsSlice";
import AccordionSummary from "pages/campaigns/components/CustomAccordionSummary";
import CampaignGroupTable from "pages/campaigns/components/CampaignGroupTable";
import Caption from "library/text/body/Caption";
import Chip from "library/display/Chip";
import { normalizeChannelName } from "utils/data/strings";

const styles = {
  muiAccordionRoot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
  accordionsContainer: {
    width: "100%",
    height: "auto",
  },
  badgeBox: {
    width: "fit-content",
    height: "100%",
    margin: 0,
  },
};

/**
 * Displays the campaign groups and the campaigns they fall under.
 * */
function GroupsDisplay() {
  const campaignGroups = useSelector(selectCampaignGroupIdToCampaignMap);
  const campaignGroupToStrategyMap = useSelector(
    selectObjects("campaign_group_id_to_strategy_id_map"),
  );

  if (isEmpty(campaignGroups)) {
    return <Caption>There aren't any campaigns present.</Caption>;
  }

  const accordions = Object.entries(campaignGroups).map(([groupId, group]) => {
    return (
      <Accordion
        key={groupId}
        TransitionProps={{ unmountOnExit: true }}
        elevation={0}
        sx={styles.muiAccordionRoot}
      >
        <AccordionSummary expandIcon={<ChevronRightIcon />}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography>{`${groupId} (${group.length})`}</Typography>
            </Grid>

            <Grid item>
              {groupId !== "ungrouped" ? (
                <Chip
                  text={normalizeChannelName(
                    campaignGroupToStrategyMap[groupId],
                  )}
                  moreBadgeBoxStyles={styles.badgeBox}
                />
              ) : null}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <CampaignGroupTable id={groupId} />
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <Grid>
      <Typography sx={styles.bannerSubTitleText}>Campaign Groups</Typography>
      <div style={styles.accordionsContainer}>{accordions}</div>
    </Grid>
  );
}

export default GroupsDisplay;
