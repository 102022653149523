/* eslint-disable no-console */
/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "utils/enums";
import { makeAuthenticatedGetRequest } from "utils/backend-api";

export const getRoasData = createAsyncThunk(
  "asyncRedux/v2roas",
  async (queryParams = {}) => {
    const { enableProjectedRoas = false, groupByStrategy = true } = queryParams;

    return makeAuthenticatedGetRequest("roas", {
      enableProjectedRoas,
      groupByStrategy,
    });
  },
);

const initialState = {
  list: null,
  roasSorted: [],
  seriesData: {},
  actualsGroup: {},
  status: null,
  authError: null,
  interpolated: {
    roasSorted: [],
  },
  macroRoas: [],
  requestExecutionCount: 0,
};

const reportingSlice = createSlice({
  name: "reportingSlice",
  initialState,
  reducers: {
    reportingLogOut: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getRoasData.pending]: (state) => {
      state.status = STATUS.LOADING;
      state.requestExecutionCount += 1;
    },
    [getRoasData.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.requestExecutionCount += 1;
      state.authError = true;
    },
    [getRoasData.fulfilled]: (state, { payload }) => {
      const {
        roas_sorted_date: roasSortedDate = [],
        macro_roas: macroRoas = [],
        series_data: seriesData,
        actuals_group: actualsGroup = {},
        actuals_group_strategy: actualsGroupStrategy = {},
        interpolated = { roas_sorted_date: [] },
      } = payload?.data || {};

      state.roasSorted = roasSortedDate;
      state.seriesData = seriesData;
      state.actualsGroup = actualsGroup;
      state.actualsGroupStrategy = actualsGroupStrategy;
      state.macroRoas = macroRoas;
      state.interpolated.roasSorted = interpolated.roas_sorted_date;
      state.status = STATUS.SUCCESS;
      state.authError = false;
    },
  },
});

export const { reportingLogOut } = reportingSlice.actions;

export default reportingSlice.reducer;
