import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ChangeChip from "library/display/ChangeChip";
import OutPointCard from "library/surface/OutPointCard";
import { summaryTableStyles as styles } from "pages/recommendations/styles/recommendationsComponentsStyles";
import React from "react";
import { useSelector } from "react-redux";
import { calculateCac, formatMetric } from "utils/data/metrics";
import { capitalize } from "utils/data/strings";
import PropTypes from "prop-types";

/**
 * Displays recommendations for all channels / platforms in a single table.
 * */
function MonthlyDetailsTable(props) {
  const { scenarioData, reverseTotalsRow = false } = props;

  const ltv = useSelector((state) => state.overviewData.data?.ltv);
  const scenarioChannelData = scenarioData.category_data;

  if (!Object.keys(scenarioChannelData)?.length) return null;

  const platforms = Object.keys(scenarioChannelData);

  const createTextTableCell = (content, isBold = false) => {
    return (
      <TableCell key={content}>
        <Grid container>
          <Typography
            sx={isBold ? styles.boldedTableCellText : styles.tableCellText}
          >
            {content}
          </Typography>
        </Grid>
      </TableCell>
    );
  };

  const createTableCell = (
    content,
    changeVal = null,
    prefix = "",
    isBold = false,
    invertColour = false,
    variableDecimals = true,
  ) => {
    return (
      <TableCell>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          sx={styles.tableCellContainer}
        >
          <Grid item sx={styles.tableCellTextContainer}>
            <Typography
              sx={isBold ? styles.boldedTableCellText : styles.tableCellText}
            >
              {`${prefix}${formatMetric(
                content,
                false,
                true,
                variableDecimals,
              )}`}
            </Typography>
          </Grid>
          {(changeVal || changeVal === 0) && (
            <ChangeChip
              change={changeVal}
              isPercentage
              useIcon
              inverted={invertColour}
              variableDecimals
            />
          )}
        </Grid>
      </TableCell>
    );
  };

  const createTotalsRow = () => {
    const budgetSpend = scenarioData.spend;
    const predictedReturn = scenarioData.total_revenue;
    const predictedRoas = scenarioData.roas;
    const predictedCac = calculateCac(ltv, budgetSpend, predictedReturn);

    return (
      <TableRow sx={styles.totalRowStyles}>
        {createTextTableCell("Total", true)}
        {createTableCell(budgetSpend, null, "$", true, false, true)}
        {createTableCell(predictedReturn, null, "$", true, false, true)}
        {createTableCell(predictedRoas, null, "", true, false, false)}
        {createTableCell(predictedCac, null, "$", true, true, true)}
      </TableRow>
    );
  };

  const createRowForPlatform = (platform) => {
    const budgetSpend = scenarioChannelData[platform].spend;
    const predictedReturn = scenarioChannelData[platform].total_revenue;
    const predictedRoas = scenarioChannelData[platform].average_roas;
    const predictedCac = calculateCac(ltv, budgetSpend, predictedReturn);

    return (
      <TableRow key={platform}>
        {createTextTableCell(capitalize(platform))}
        {createTableCell(budgetSpend, null, "$", false, false, true)}
        {createTableCell(predictedReturn, null, "$", false, false, true)}
        {createTableCell(predictedRoas, null, "", false, false, false)}
        {createTableCell(predictedCac, null, "$", false, true, true)}
      </TableRow>
    );
  };

  const createTableHeaderRow = () => {
    const headers = [
      "Channel",
      "Monthly budgeted spend",
      "Predicted return",
      "Predicted ROAS",
      "Predicted CAC",
    ];

    return (
      <TableRow>{headers.map((h) => createTextTableCell(h, true))}</TableRow>
    );
  };

  return (
    <Grid container>
      <OutPointCard
        style={{
          marginTop: "24px",
        }}
      >
        <TableContainer sx={styles.tableContainer}>
          <Table>
            <TableHead>{createTableHeaderRow()}</TableHead>
            <TableBody>
              {reverseTotalsRow && createTotalsRow()}
              {platforms.map(createRowForPlatform)}
              {!reverseTotalsRow && createTotalsRow()}
            </TableBody>
          </Table>
        </TableContainer>
      </OutPointCard>
    </Grid>
  );
}

export default MonthlyDetailsTable;

MonthlyDetailsTable.propTypes = {
  scenarioData: PropTypes.object,
  reverseTotalsRow: PropTypes.bool,
};
