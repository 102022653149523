import React from "react";
import PropTypes from "prop-types";
import { IconButton, Button } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { RED } from "assets/palette";
import BodyText from "library/text/body/BodyText";

const styles = {
  button: {
    width: "200px",
    margin: "10px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
  },
  editMetadataButton: {
    height: "37px",
    marginLeft: "15px",
    marginBottom: "10px",
  },
};

export function DeleteCredentialsButton({ onClick }) {
  return (
    <IconButton onClick={onClick}>
      <DeleteForeverIcon sx={{ color: RED, fontSize: "27px" }} />
    </IconButton>
  );
}

DeleteCredentialsButton.propTypes = {
  onClick: PropTypes.func,
};

export function EditMetadataButton({ setCanEditMetadata, name }) {
  return (
    <Button
      onClick={() =>
        setCanEditMetadata((prev) => {
          const newMetadata = { ...prev };
          newMetadata[name] = true;
          return newMetadata;
        })
      }
      sx={styles.editMetadataButton}
      variant="outlined"
    >
      Edit
    </Button>
  );
}

EditMetadataButton.propTypes = {
  setCanEditMetadata: PropTypes.func,
  name: PropTypes.string,
};

export function DeleteCredentialsModal({ setModalOpen, deleteCredentials }) {
  return (
    <>
      <BodyText>
        Pressing <b style={{ color: RED }}>delete</b> will permenantly delete
        your credentials. You can still re-authenticate after.
      </BodyText>
      <div style={styles.buttonContainer}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setModalOpen(false)}
          sx={styles.button}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={deleteCredentials}
          sx={styles.button}
        >
          Delete
        </Button>
      </div>
    </>
  );
}

DeleteCredentialsModal.propTypes = {
  setModalOpen: PropTypes.func,
  deleteCredentials: PropTypes.func,
};
