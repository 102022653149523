import React from "react";
import SubHead from "library/text/headers/SubHead";
import { Box } from "@mui/material";
import { PRIMARY_COLOR, PRIMARY_LIGHT } from "assets/palette";
import PropTypes from "prop-types";

const styles = {
  textStyle: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: PRIMARY_COLOR,
  },
  badgeBoxStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 12px",
    margin: "8px",
    backgroundColor: PRIMARY_LIGHT,
    height: "24px",
    width: "54px",
    borderRadius: "100px",
  },
};

function Chip(props) {
  const { textStyle, badgeBoxStyle } = styles;
  const { text, moreTextStyles, moreBadgeBoxStyles } = props;

  const combinedContainerBoxStyle = { ...badgeBoxStyle, ...moreBadgeBoxStyles };
  const combinedTextStyle = { ...textStyle, ...moreTextStyles };

  return (
    <Box sx={combinedContainerBoxStyle}>
      <SubHead sx={combinedTextStyle}>{text}</SubHead>
    </Box>
  );
}

export default Chip;

Chip.propTypes = {
  text: PropTypes.string,
  moreTextStyles: PropTypes.object,
  moreBadgeBoxStyles: PropTypes.object,
};
