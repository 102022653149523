import AmazonLogo from "assets/small logos/Amazon.png";
import BingAdsLogo from "assets/logos/bing-ads.png";
import FacebookLogo from "assets/small logos/Facebook.png";
import GoogleAdsLogo from "assets/small logos/Google-Ads.png";
import GoogleAnalyticsLogo from "assets/small logos/Google-Analytics.png";
import ProgrammaticLogo from "assets/small logos/trade_desk_logo.png";
import GA4Logo from "assets/small logos/ga4.png";
import PinterestLogo from "assets/small logos/Pinterest.png";
import ShopifyLogo from "assets/small logos/Shopify.png";
import SnapchatLogo from "assets/small logos/Snap.png";
import SpotifyAdsLogo from "assets/small logos/Spotify.png";
import StripeLogo from "assets/small logos/Stripe.png";
import TiktokLogo from "assets/small logos/TikTok.png";
import TaboolaLogo from "assets/small logos/taboola.png";
import GoogleLogo from "assets/small logos/google.png";
import YoutubeLogo from "assets/small logos/youtube.png";
import FallbackLogo from "assets/small logos/FallbackLogo.svg";
import OutPointLogo from "assets/OPPurpleLogo.png";
import NectarFirstLogo from "assets/nectar_logo.png"; // TODO: standardise asset naming
import LoblawsLogo from "assets/small logos/loblaws_logo.jpeg";
import WalmartLogo from "assets/small logos/walmart_logo.png";
import AlbertsonsLogo from "assets/small logos/albertsons.jpeg";
import ChicoryLogo from "assets/small logos/chicory.png";
import CostcoLogo from "assets/small logos/costco.png";
import CriteoLogo from "assets/small logos/criteo.jpg";
import InstacartLogo from "assets/small logos/instacart.png";
import KrogerLogo from "assets/small logos/kroger.png";
import SproutsLogo from "assets/small logos/sprouts.png";
import WholeFoodsLogo from "assets/small logos/whole-foods.png";

import VideocamIcon from "@mui/icons-material/Videocam";
import TvIcon from "@mui/icons-material/Tv";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

// Navbar logos
import CampaignsLogoSmall from "assets/icons/navbar/campaigns.svg";
import ChannelDiscoverySmall from "assets/icons/navbar/channel_discovery.svg";
import HomeSmall from "assets/icons/navbar/home.svg";
import InsightsSmall from "assets/icons/navbar/insights.svg";
import IntegrationsSmall from "assets/icons/navbar/integrations.svg";
import RecommendationsSmall from "assets/icons/navbar/recommendations.svg";
import UploadSmall from "assets/icons/navbar/Upload.svg";
import RadioIcon from "@mui/icons-material/Radio";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import DevicesIcon from "@mui/icons-material/Devices";
import CollectionsIcon from "@mui/icons-material/Collections";

export const logosAll = {
  channel: {
    amazon: AmazonLogo,
    bing: BingAdsLogo,
    facebook: FacebookLogo,
    google: GoogleLogo,
    google_ads: GoogleAdsLogo,
    google_analytics: GoogleAnalyticsLogo,
    loblaws: LoblawsLogo,
    google_analytics_4: GA4Logo,
    pinterest: PinterestLogo,
    programmatic: ProgrammaticLogo,
    shopify: ShopifyLogo,
    snapchat: SnapchatLogo,
    spotify: SpotifyAdsLogo,
    stripe: StripeLogo,
    taboola: TaboolaLogo,
    tiktok: TiktokLogo,
    walmart: WalmartLogo,
    youtube: YoutubeLogo,
    // adusa: ,
    albertsonsco: AlbertsonsLogo,
    chicory: ChicoryLogo,
    costco: CostcoLogo,
    criteo: CriteoLogo,
    instacart: InstacartLogo,
    kroger: KrogerLogo,
    sprouts: SproutsLogo,
    "whole foods": WholeFoodsLogo,
  },
  outpointLogo: OutPointLogo,
  nectarFirstLogo: NectarFirstLogo,
  navbarCampaigns: CampaignsLogoSmall,
  navbarChannelDiscovery: ChannelDiscoverySmall,
  navbarHome: HomeSmall,
  navbarInsights: InsightsSmall,
  navbarIntegrations: IntegrationsSmall,
  navbarRecommendations: RecommendationsSmall,
  navbarUpload: UploadSmall,
  fallbackLogo: FallbackLogo,
};

// NOTE: Order should consider that lower names supercede higher names
const iconLogos = {
  media: CollectionsIcon,
  "in store": ShoppingCartIcon,
  digital: DevicesIcon,
  print: NewspaperIcon,
  radio: RadioIcon,
  "point of sale": ShoppingBagIcon,
  retail: StoreMallDirectoryIcon,
  tv: TvIcon,
  video: VideocamIcon,
};

export const resolveGoogleLogos = (channel) => {
  if (channel.includes("google")) {
    if (channel.includes("ads")) {
      return logosAll.channel.google_ads;
    }

    if (channel.includes("analytics")) {
      return logosAll.channel.google_analytics;
    }

    return logosAll.channel.google;
  }
  return null;
};

export const getIconLogo = (category = "") => {
  const lowercaseCategory = category.toLowerCase();
  const pngLogo = Object.entries(logosAll.channel).reduce(
    (acc, [key, value]) => {
      if (lowercaseCategory.includes(key)) return value;
      return acc;
    },
    null,
  );
  if (pngLogo) return null;

  const logo = Object.entries(iconLogos).reduce((acc, [key, value]) => {
    if (lowercaseCategory.includes(key)) return value;
    return acc;
  }, null);
  return logo;
};

export const getChannelLogo = (channel = "") => {
  const lowercaseChannel = channel.toLowerCase();
  const channelLogos = logosAll.channel || {};
  const logo = channelLogos[lowercaseChannel];

  if (logo) return logo;

  const googleResult = resolveGoogleLogos(lowercaseChannel);
  if (googleResult) return googleResult;

  // return fallback logo if channel does not exist
  return logosAll.fallbackLogo;
};

export const getLogoFromPartialName = (category = "") => {
  const lowercaseCategory = category.toLowerCase();

  const googleResult = resolveGoogleLogos(lowercaseCategory);
  if (googleResult) return googleResult;

  const logo = Object.entries(logosAll.channel).reduce((acc, [key, value]) => {
    if (lowercaseCategory.includes(key)) return value;
    return acc;
  }, null);
  if (logo) return logo;

  return logosAll.fallbackLogo;
};

export const getInternalOrgLogo = (internalOrgName = "outpoint") => {
  switch (internalOrgName) {
    case "outpoint":
      return logosAll.outpointLogo;

    case "nectar_first":
      return logosAll.nectarFirstLogo;

    default:
      return logosAll.fallbackLogo;
  }
};

/**
 * If the logo name is referring to something that is org-specific, then calls the appropriate getInternalOrg helper function, else it directly attempts to fetch the logo from the map of logos present. If no logos are resolved to, it shall return a fallbackLogo.
 * */
export const getLogosGeneric = (name = "") => {
  if (name === "internalOrgLogo") {
    const internalOrgName =
      process.env?.REACT_APP_INTERNAL_ORG_NAME || "outpoint";

    return getInternalOrgLogo(internalOrgName);
  }

  const logo = logosAll[name];

  if (logo) return logo;

  // return fallback logo if asset is not resolved
  return logosAll.fallbackLogo;
};

export const getOrgLogo = () => {
  return getLogosGeneric("internalOrgLogo");
};
