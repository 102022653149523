import React from "react";
import { FormControlLabel, Radio } from "@mui/material";
import PropTypes from "prop-types";

export default function RadioButton({
  label,
  checked,
  setChecked,
  canUncheckSelf = true,
  onClick,
}) {
  return (
    <FormControlLabel
      label={label}
      checked={checked}
      control={
        <Radio
          onClick={() => {
            if (canUncheckSelf || !checked) setChecked(!checked);
            onClick();
          }}
        />
      }
    />
  );
}

RadioButton.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  canUncheckSelf: PropTypes.bool,
  onClick: PropTypes.func,
};
