import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Card,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import BodyText from "library/text/body/BodyText";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import CSVDownloader from "library/buttons/CSVDownloader";
import {
  DARK_YELLOW,
  FEEDBACK_OR_ERROR_100,
  FEEDBACK_OR_ERROR_900,
  PRIMARY_BACKGROUND,
  PRIMARY_BACKGROUND_CONTRAST,
  PRIMARY_COLOR,
  PRIMARY_DARK,
  PRIMARY_LIGHT,
  PRIMARY_TEXT_CONTRAST,
} from "assets/palette";
import { Box } from "@mui/system";
import { usePapaParse } from "react-papaparse";
import { parseCsv } from "utils/graphing/data";
import { Error, WarningRounded } from "@mui/icons-material";
import IconText from "library/containers/IconText";
import { addMessage } from "redux/snackbarSlice";
import LoadingButton from "library/buttons/LoadingButton";
import { stringToTitleCase } from "utils/data/strings";
import BasicTable from "library/display/BasicTable";
import OutPointToggle from "library/buttons/OutPointToggle";
import { OutPointBanner } from "library/display/OutPointBanner";
import { capitalize } from "lodash";
import FileUpload from "./FileUpload";
import { StepperButton } from "./UploadButtons";
import MediaPlanResults from "./MediaPlanResults";

const MAX_ALLOWED_FILE_SIZE = 900000; // bytes NOTE: elb default nginx http server will have max_body_size set to 1MB, we set this filesize to 0.9Mb just to be safe
const NUM_MONTHS = 12;
const CHANNEL_HEADER = "Channel";
const TEMPLATE_CSV_FILE_NAME = stringToTitleCase(
  `${(process.env?.REACT_APP_INTERNAL_ORG_NAME || "outpoint").replace(
    "_",
    " ",
  )} media plan template`,
);
const PREDICTIONS_CSV_FILE_NAME = stringToTitleCase(
  `${(process.env?.REACT_APP_INTERNAL_ORG_NAME || "outpoint").replace(
    "_",
    " ",
  )} media plan predictions`,
);
const MONTH_YEAR_SUFFIX = " Budget";
const MAX_ERRORS_SHOWN = 5;

const styles = {
  warningBanner: {
    flexGrow: 0,
    borderRadius: "8px",
    height: "auto",
    borderColor: PRIMARY_COLOR,
    borderStyle: "solid",
    padding: "16px 18px 16px 6px",
    backgroundColor: PRIMARY_LIGHT,
    boxShadow: "none",
  },
  warningBannerContainer: {
    marginTop: "24px",
    marginBottom: "12px",
  },
  viewToggle: {
    width: "100px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  errorBanner: {
    boxShadow: "none",
    width: "570px",
    height: "Hug (80px)",
    backgroundColor: FEEDBACK_OR_ERROR_100,
    gap: "24px",
    padding: "16px",
    borderRadius: "8px",
    marginBottom: "8px",
  },
  downloadButton: {
    letterSpacing: "0.01em",
    marginTop: "20px",
    marginBottom: "20px",
    width: "200px",
    borderRadius: "10px",
    color: PRIMARY_TEXT_CONTRAST,
    backgroundColor: PRIMARY_COLOR,
    "&:disabled": {
      backgroundColor: PRIMARY_BACKGROUND,
      color: PRIMARY_BACKGROUND_CONTRAST,
    },
  },
  loadingButton: { marginTop: "30px", width: "200px" },
  errorIcon: { color: DARK_YELLOW, fontSize: "25px", marginLeft: "12px" },
};

function MediaPlanPage() {
  const [activeStep, setActiveStep] = useState(0);
  const [isUploadError, setIsUploadError] = useState(false);
  const [uploadErrorMsg, setUploadErrorMsg] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState();
  const [uploadedFileData, setUploadedFileData] = useState();
  const [predictions, setPredictions] = useState();
  const [viewErrors, setViewErrors] = useState(false);
  const dispatch = useDispatch();
  const channels = useSelector((state) =>
    Object.keys(state.predictionsStore?.predictionsTableData || {}),
  );

  const formatCSVDate = (dateObject) =>
    `${dateObject.toLocaleDateString("en", {
      month: "long",
      year: "numeric",
    })} Budget`;

  const generateMonthStrings = (numMonths = 12) => {
    const currentDate = new Date();
    return [formatCSVDate(currentDate)].concat(
      Array(numMonths)
        .fill(1)
        .map((i) => {
          currentDate.setMonth(currentDate.getMonth() + i);
          return formatCSVDate(currentDate);
        }),
    );
  };

  const generateCSVTemplateData = () => {
    const months = generateMonthStrings(NUM_MONTHS);
    return channels.map((channel) => {
      const rowData = {
        [CHANNEL_HEADER]: channel,
      };
      months.forEach((month) => {
        rowData[month] = "";
      });
      return rowData;
    });
  };

  const handleEncounterError = (errorMsg) => {
    setUploadedFileData();
    setUploadedFileName();
    setPredictions();
    setIsUploadError(true);
    setUploadErrorMsg(errorMsg);
  };
  const handleResetErrorState = () => {
    setIsUploadError(false);
    setUploadErrorMsg("");
  };

  const handleStepNext = () => {
    handleResetErrorState();
    setActiveStep((prevActiveStep) =>
      // eslint-disable-next-line no-use-before-define
      Math.min(prevActiveStep + 1, steps.length - 1),
    );
  };
  const handleStepBack = () => {
    handleResetErrorState();
    setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  };

  const validateCSVData = (data) => {
    if (channels.length < 1) return false;

    const errors = [];
    if (data.length < 1) {
      errors.push("Please upload a media plan using the provided template.");
    }
    if (
      JSON.stringify(data.map((row) => row[CHANNEL_HEADER]).sort()) !==
      JSON.stringify(channels.sort())
    ) {
      errors.push("Please do not edit the rows of the media plan template.");
    }
    if (
      JSON.stringify(Object.keys(data[0])) !==
      JSON.stringify([CHANNEL_HEADER, ...generateMonthStrings(NUM_MONTHS)])
    ) {
      errors.push("Please do not edit the columns of the media plan template.");
    }
    data.forEach((row, rowIndex) => {
      Object.entries(row).forEach(([key, value], entryIndex) => {
        if (key !== CHANNEL_HEADER && !/^\d+$/.test(value)) {
          if (errors.length === MAX_ERRORS_SHOWN) {
            errors.push("...");
          } else if (errors.length < MAX_ERRORS_SHOWN) {
            errors.push(
              `Line ${rowIndex + 1} column ${
                entryIndex + 1
              }, please make sure you have filled out the media template with valid budget numbers.`,
            );
          }
        }
      });
    });

    if (errors.length > 0) {
      handleEncounterError(
        `The following error(s) were encountered: ${errors.join(" ")}`,
      );
      return false;
    }

    return true;
  };

  const downloadButton = (
    <Grid container flexDirection="column" alignItems="start">
      <CSVDownloader
        label="download template"
        data={generateCSVTemplateData()}
        filename={TEMPLATE_CSV_FILE_NAME}
        ButtonProps={{
          style: styles.downloadButton,
          variant: "text",
          onClick: handleStepNext,
        }}
      />
      <StepperButton
        handleStep={handleStepNext}
        text="Continue if you already have a completed media plan"
        color={PRIMARY_DARK}
      />
    </Grid>
  );

  const { readString } = usePapaParse();
  const uploadParserConfig = {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      if (results.errors.length > 0) {
        handleEncounterError(
          "We had trouble reading your uploaded file, please upload a valid CSV file. ",
        );
        return;
      }
      if (!validateCSVData(results.data)) return;
      handleResetErrorState();
      setUploadedFileData(results.data);
    },
  };
  const handleFileUpload = async (event) => {
    handleResetErrorState();
    const [file] = event.target.files; // input prop set to single file
    if (!file) {
      // NB: null-check especially important for chromium browsers
      return;
    }

    if (file.size > MAX_ALLOWED_FILE_SIZE) {
      handleEncounterError(
        "Unfortunately, the file you uploaded is too large. Please re-upload a file that is at most 900kb.",
      );
      return;
    }

    await parseCsv(file, (csvString) =>
      readString(csvString, uploadParserConfig),
    );
    setUploadedFileName(file.name);
    setPredictions();
  };
  const uploadButton = (
    <FileUpload
      uploadedFileData={uploadedFileData}
      uploadedFileName={uploadedFileName}
      handleFileUpload={handleFileUpload}
      handleStepNext={handleStepNext}
      handleStepBack={handleStepBack}
    />
  );

  const formatPredictionsForDownload = () => {
    return Object.entries(predictions.predictions).reduce(
      (csvArray, [monthYear, predictionsByChannel]) => {
        const currentMonthYearPredictions = Object.entries(
          predictionsByChannel,
        ).map(([channel, channelPredictions]) => ({
          date: monthYear.replace(MONTH_YEAR_SUFFIX, ""),
          channel: capitalize(channel),
          ...channelPredictions,
        }));
        return csvArray.concat(currentMonthYearPredictions);
      },
      [],
    );
  };

  const formatErrorsForDisplay = () => {
    return Object.entries(predictions.errors).reduce(
      (displayData, [monthYear, errorsByChannel]) => {
        displayData[monthYear.replace(MONTH_YEAR_SUFFIX, "")] = Object.entries(
          errorsByChannel,
        ).map(([channel, errorMsg]) => ({
          channel: capitalize(channel),
          error: errorMsg,
        }));
        return displayData;
      },
      {},
    );
  };
  const ERROR_TABLE_COLUMNS = ["Date", "Channel", "Description"];
  const viewErrorsToggle = (
    <Grid
      container
      flexDirection="row"
      sx={styles.warningBannerContainer}
      justifyContent="space-around"
      alignItems="center"
    >
      <OutPointBanner
        displayText="We encountered some issues with your provided media plan"
        moreBannerStyles={styles.warningBanner}
      >
        <Error sx={styles.errorIcon} />
      </OutPointBanner>
      <div style={styles.viewToggle}>
        <Typography>View</Typography>
        <OutPointToggle
          checked={viewErrors}
          onChange={() => setViewErrors(!viewErrors)}
        />
      </div>
    </Grid>
  );

  const steps = [
    {
      label: "Download your media plan template",
      component: downloadButton,
      index: 0,
    },
    {
      label: "Upload completed media plan",
      component: uploadButton,
      index: 1,
    },
    {
      label: "Results",
      component: (
        <Grid container flexDirection="column" alignItems="flex-start">
          <Grid container flexDirection="row" alignItems="flex-end">
            <LoadingButton
              style={styles.loadingButton}
              onClick={async () => {
                try {
                  const response = await makeAuthenticatedPostRequest(
                    "media_planner",
                    { monthly_plan: uploadedFileData },
                  );
                  if (response.success) {
                    setPredictions(response.data);
                  } else if (response.message) {
                    dispatch(addMessage({ message: response.message }));
                  }
                } catch (error) {
                  dispatch(addMessage({ message: error.message }));
                }
              }}
            >
              Get predictions
            </LoadingButton>
            <StepperButton
              handleStep={handleStepBack}
              text="Back"
              color={PRIMARY_DARK}
            />
          </Grid>
          {predictions?.predictions && (
            <MediaPlanResults
              predictions={predictions.predictions}
              channels={channels}
            />
          )}
          {predictions?.predictions && (
            <CSVDownloader
              label="download predictions"
              data={formatPredictionsForDownload()}
              filename={PREDICTIONS_CSV_FILE_NAME}
              ButtonProps={{
                style: styles.loadingButton,
              }}
            />
          )}
          {predictions?.errors &&
            predictions.errors_encountered &&
            viewErrorsToggle}
          {predictions?.errors && viewErrors && (
            <BasicTable
              columnHeaders={ERROR_TABLE_COLUMNS}
              rowData={formatErrorsForDisplay()}
            />
          )}
        </Grid>
      ),
      index: 2,
    },
  ];

  const errorBanner = (
    <Card sx={styles.errorBanner}>
      <IconText>
        <WarningRounded
          fontSize="large"
          sx={{
            color: FEEDBACK_OR_ERROR_900,
            marginRight: "16px",
          }}
        />
        <Grid container direction="column">
          <Typography>{uploadErrorMsg}</Typography>
        </Grid>
      </IconText>
    </Card>
  );

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step key={step.label}>
          <Grid container>
            <Grid item>
              <StepLabel
                optional={
                  index === steps.length - 1 && (
                    <BodyText variant="caption">Last Step</BodyText>
                  )
                }
              >
                {step.label}
              </StepLabel>
            </Grid>
            <Grid item>{step.tooltip}</Grid>
          </Grid>
          <StepContent>
            {isUploadError && step.index === activeStep ? errorBanner : <div />}
            <BodyText sx={{ fontWeight: "500" }}>{step.description}</BodyText>
            <Box sx={{ mb: 2 }}>{step.component}</Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

export default MediaPlanPage;
