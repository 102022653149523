import { Box, Grid } from "@mui/material";
import PngContainer from "library/containers/PngContainer";
import SubHead2 from "library/text/headers/SubHead2";
import SubHeadLight from "library/text/headers/SubHeadLight";
import OutPointCard from "library/surface/OutPointCard";
import _ from "lodash";
import React, { useState } from "react";
import { getIconLogo, getLogoFromPartialName } from "utils/resolveLogos";
import PropTypes from "prop-types";
import OverviewButtonGroup from "./OverviewButtonGroup";
import StrategyChannelToggle from "./StrategyChannelToggle";

const INCREASE_BUDGET = "increase";
const DECREASE_BUDGET = "decrease";
const TURN_BACK_ON = "turnBackOn";
function OptimalMediaMix(props) {
  const { channelDetails, groupByStrategy, setGroupByStrategy } = props;
  const buttons = {
    0: {
      text: "increase budget",
      isSelected: true,
      type: INCREASE_BUDGET,
    },
    1: {
      text: "decrease budget",
      isSelected: false,
      type: DECREASE_BUDGET,
    },
    2: {
      text: "turn back on",
      isSelected: false,
      type: TURN_BACK_ON,
    },
  };

  const [selectedButton, setSelectedButton] = useState(0);

  const renderOverviewButtonGroup = (buttonOptions) => (
    <OverviewButtonGroup buttonOptions={buttonOptions} />
  );
  function RenderButtons() {
    const buttonOptions = Object.keys(buttons).map((key = "0") => {
      const { text, type } = buttons[key];
      const keyNumber = Number(key);

      return {
        text,
        key: text,
        isSelected: keyNumber === selectedButton,
        disableElevation: true,
        onClick: () => setSelectedButton(keyNumber),
        endIconText: channelDetails?.[type]?.length || null,
      };
    });

    return renderOverviewButtonGroup(buttonOptions);
  }

  const mediaMixChannels = channelDetails?.[buttons[selectedButton].type];
  return (
    <OutPointCard
      style={{
        borderRadius: "6px",
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginTop: "8px" }}
      >
        <Grid item>
          <SubHeadLight style={{ margin: "16px" }}>
            Optimal media mix
          </SubHeadLight>
        </Grid>
        <Grid item>
          <StrategyChannelToggle
            groupByStrategy={groupByStrategy}
            setGroupByStrategy={setGroupByStrategy}
          />
        </Grid>
      </Grid>
      <RenderButtons />

      <Grid container padding="16px" gap="8px">
        {mediaMixChannels &&
          _.chunk(mediaMixChannels, 2).map((chunk) => {
            return (
              <Grid item key={chunk.join("-")}>
                <Box
                  sx={{
                    width: "108px",
                  }}
                >
                  {chunk.map((channel) => {
                    const IconLogo = getIconLogo(channel);
                    return (
                      <Box key={channel} sx={{ display: "flex" }}>
                        {IconLogo ? (
                          <IconLogo sx={{ width: "20px", margin: "8px" }} />
                        ) : (
                          <PngContainer
                            icon={getLogoFromPartialName(channel)}
                            boxStyle={{ width: "20px", margin: "8px" }}
                          />
                        )}

                        <SubHead2
                          isSentenceCase
                          style={{
                            fontWeight: 400,
                            marginBottom: "16px",
                            marginTop: "8px",
                          }}
                          key={channel}
                        >
                          {channel}
                        </SubHead2>
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </OutPointCard>
  );
}

export default OptimalMediaMix;

OptimalMediaMix.propTypes = {
  channelDetails: PropTypes.object,
  groupByStrategy: PropTypes.bool,
  setGroupByStrategy: PropTypes.func,
};
