import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/system";
import { isEmpty, startCase } from "lodash";

import {
  getLiftData,
  setEndDate,
  setSelectedChannels,
  setStartDate,
  selectLiftData,
} from "redux/liftDataSlice";

import Header1 from "library/text/headers/Header1";
import OutPointCard from "library/surface/OutPointCard";
import Chip from "library/display/Chip";
import LiftGraphSummaryBar from "pages/lift_insights/LiftGraphSummaryBar";

import { dateSecondsToString } from "utils/data/dates";
import { addCommasToNumber } from "utils/data/strings";

import LineAndScatterChart from "library/graphing/LineChart";
import { GRAPH_DATE_FORMAT, TOOLTIP_DATE_FORMAT } from "utils/enums";
import CustomLegendBox from "pages/lift_insights/LiftGraphsLegendBox";
import SubHeading from "pages/lift_insights/LiftPageSubHeading";
import { liftPageTemplateStyles as styles } from "./LiftPageTemplateStyles";

const calculateSpendAndReturnTotals = (
  liftData = {},
  selectedChannels = [],
  liftType = undefined,
  staticBaselineLift = 0,
) => {
  let totalChannelSpend = 0;
  let totalReturn = 0;

  const relevantRevenueKeyName = `${liftType}-lift revenue`.toLowerCase();

  Object.keys(liftData).forEach((key) => {
    const val = liftData[key];
    if (isEmpty(val)) {
      // skips empty values, likely that data yet to be fetched.
      return;
    }
    // eslint-disable-next-line camelcase -- this is a remnant of the backend casing, modifying would otherwise require a wasteful pass through the data
    const {
      is_channel: isChannel,
      total_dollar_value: totalDollarValue,
      name,
    } = val;

    const isRelevantRevenueKey = name.toLowerCase() === relevantRevenueKeyName;
    if (isRelevantRevenueKey) {
      totalReturn += totalDollarValue;
    }

    if (!selectedChannels.includes(name)) return;

    if (isChannel) {
      totalChannelSpend += totalDollarValue;
    }
  });

  // adds baseline lift for every day in the date range:
  const numDataPoints = liftData?.revenue?.line_points?.length || 0;
  totalReturn += numDataPoints * staticBaselineLift;

  return {
    totalChannelSpend: addCommasToNumber(totalChannelSpend),
    totalReturn: addCommasToNumber(totalReturn),
  };
};

function LiftPageTemplate(props) {
  const { liftType } = props;
  const dispatch = useDispatch();
  const liftAndSpendData = useSelector(selectLiftData(liftType));

  const {
    liftData,
    rawData,
    supportedSpendChannels,
    selectedChannels,
    startDate,
    endDate,
    staticBaselineLift,
  } = liftAndSpendData;
  const [chartView, setChartView] = useState(100);
  const [isIncludingBaselineLift, setIsIncludingBaselineLift] = useState(false);

  const liftKeys = Object.keys(liftData);

  useEffect(() => {
    dispatch(getLiftData({ startDate, endDate }));
  }, [startDate, endDate]);

  const effectiveBaselineLift = isIncludingBaselineLift
    ? staticBaselineLift
    : 0;
  const { totalChannelSpend, totalReturn } = useMemo(() =>
    calculateSpendAndReturnTotals(
      liftData,
      selectedChannels,
      liftType,
      effectiveBaselineLift,
      [liftData, selectedChannels, liftType, effectiveBaselineLift],
    ),
  );

  const summaryKeyValuePairs = [
    {
      key: "Total channel spend",
      value: `$${totalChannelSpend}`,
    },
    {
      key: `${startCase(liftType)} revenue`,
      value: `$${totalReturn}`,
    },
  ];

  const scaleDownFactor = chartView / 100; // bigger number = more scale
  const spendChannels = [];
  const returnsChannels = [];
  liftKeys.forEach((key) => {
    const data = liftData[key];
    if (data?.is_channel) {
      spendChannels.push(data);
    } else {
      returnsChannels.push(data);
    }
  });

  const lineData = liftKeys
    .filter((key) => {
      const channelData = liftData[key];
      const isSelectedChannel = selectedChannels.includes(channelData.name);
      const isRevenueData = !channelData?.is_channel;
      return isSelectedChannel || isRevenueData;
    })
    .map((eachKey) => {
      const channelData = liftData[eachKey];
      const isReturnData = !channelData.is_channel;
      if (isReturnData) {
        // updates the line points since they get used for plotting
        // -- only returns graph(s) shall be scaled to visually see relationships b/w returns and spends
        const scaledLinePoints = channelData.line_points.map(({ x, y }) => {
          const unscaledValue =
            y + (isIncludingBaselineLift ? staticBaselineLift : 0);
          const scaledValue = unscaledValue * (scaleDownFactor || 1).toFixed(2);

          return {
            x,
            y: Number(scaledValue),
          };
        });

        const scaledChannelData = {
          ...channelData,
          ...{ linePoints: scaledLinePoints },
          ...{ applyCustomStyling: channelData.apply_custom_styling },
        };

        return scaledChannelData;
      }

      return {
        ...channelData,
        ...{ linePoints: channelData.line_points },
      };
    });

  const moreOptions = {
    xRange: [startDate, endDate],
    xScaleOptions: {
      formatTicks: (val) => dateSecondsToString(val * 1000, GRAPH_DATE_FORMAT),
    },
    tooltipPluginOptions: {
      formatTitle: (val) =>
        dateSecondsToString(val * 1000, TOOLTIP_DATE_FORMAT),
    },
  };

  const mainHeaderText = startCase(`${liftType} Lift`);

  return (
    <>
      <Box sx={styles.flexDisplay}>
        <Header1 sx={styles.headerMain}>{mainHeaderText}</Header1>
        <Chip text="New" />
      </Box>
      <OutPointCard sx={styles.cardMargin}>
        <SubHeading
          liftType={liftType}
          chartView={chartView}
          setChartView={setChartView}
          startDate={startDate}
          setStartDate={(tVal) => dispatch(setStartDate(tVal))}
          endDate={endDate}
          setEndDate={(tVal) => dispatch(setEndDate(tVal))}
        />
        <LiftGraphSummaryBar
          liftType={liftType}
          summaryKeyValuePairs={summaryKeyValuePairs}
          supportedChannels={supportedSpendChannels}
          selectedChannels={selectedChannels}
          onSelectChannels={(channels) =>
            dispatch(setSelectedChannels(channels))
          }
          chartView={chartView}
          rawData={rawData}
          isIncludingBaselineLift={isIncludingBaselineLift}
          setIsIncludingBaselineLift={
            staticBaselineLift > 0 ? setIsIncludingBaselineLift : null
          }
        />
        <LineAndScatterChart data={lineData} moreOptions={moreOptions} />
        <Box sx={styles.flexDisplay}>
          <CustomLegendBox
            items={spendChannels}
            title="channel spend"
            selectedChannels={selectedChannels}
            chartView={chartView}
            icon="circle"
          />
          <CustomLegendBox
            items={returnsChannels}
            title="revenue"
            icon="circle"
            selectedChannels={selectedChannels}
            chartView={chartView}
          />
        </Box>
      </OutPointCard>
    </>
  );
}

LiftPageTemplate.propTypes = {
  liftType: PropTypes.string,
};

export default LiftPageTemplate;
