import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

import { Line } from "react-chartjs-2";
import { kDigitConversion } from "utils/data/strings";
import { GRAPH_TICK_COLOR } from "assets/colours";
import { sortBy } from "lodash";
import { deepCopy } from "utils/data/objects";
import { LIGHT_MEDIUM_GREY } from "assets/palette";
import PropTypes from "prop-types";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  annotationPlugin,
  Filler,
);

// export this and get started in your own graph implementation
const EASILY_EXTENDABLE_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  tooltipPluginOptions: {},
  xScaleOptions: {
    ticks: {},
    formatTicks: null,
    title: "",
  },
  yScaleOptions: {
    ticks: {},
    formatTicks: null,
    title: "",
    beginAtZero: false,
  },
  titlePluginOptions: {
    display: false,
    text: "",
  },
};

export const getEasilyExtendableOptions = () =>
  deepCopy(EASILY_EXTENDABLE_OPTIONS);

function LineAndScatterChart(props) {
  const {
    data = [],
    moreOptions = {},
    lineStyle = { borderDash: [5, 5] },
    graphStyle = {},
  } = props;

  if (!data.length) return null;

  // extract options
  const {
    responsive = true,
    tooltipPluginOptions = {},
    onHover = () => {},
    xScaleOptions = {},
    yScaleOptions = {},
    titlePluginOptions = {},
    annotationsPluginOptions = [],
  } = moreOptions;

  const defaultData = {
    labels: [],
    datasets: [],
  };

  const annotations = annotationsPluginOptions.map((annotation) => {
    const { labelSettings = {}, annotationSettings = {} } = annotation;

    return {
      drawTime: "afterDatasetsDraw",
      type: "line",
      mode: "vertical",
      borderWidth: 1,
      borderColor: annotationSettings?.borderColor || "#50C878",
      borderDash: [5, 5],
      ...annotationSettings,
      label: {
        content: labelSettings?.content,
        display: true,
        position: "start",
        backgroundColor: labelSettings?.backgroundColor || "#50C878",
        ...labelSettings,
      },
    };
  });

  // common styles amongst tool tip components
  const toolTipCommonStyles = {
    font: {
      size: 16,
      fontFamily: "IBM Plex Sans",
      lineHeight: "24px",
    },
    color: "black",
  };

  const options = {
    responsive,
    plugins: {
      annotation: {
        annotations,
      },
      title: {
        display: false,
        text: "",
        ...titlePluginOptions,
      },
      tooltip: {
        mode: "nearest",
        intersect: false,
        callbacks: {
          title: (context) => {
            if (!context.length) return context;

            const { x = 0 } = context[0]?.raw || {};

            // prioritize tooltip options, fallback to x scale formatter
            if (tooltipPluginOptions.formatTitle)
              return tooltipPluginOptions.formatTitle(x);
            if (xScaleOptions.formatTicks) return xScaleOptions.formatTicks(x);

            return context;
          },
          label: (context) => {
            // NOTE: This should be changed to work similar to title
            return `${context.dataset.label}: ${
              tooltipPluginOptions.isNotDollar ? "" : "$"
            }${context.formattedValue}`;
          },
          ...(tooltipPluginOptions.label && {
            label: tooltipPluginOptions.label,
          }),
        },
        backgroundColor: "white",
        borderColor: LIGHT_MEDIUM_GREY,
        borderWidth: 1,
        titleColor: toolTipCommonStyles.color,
        titleFont: toolTipCommonStyles.font,
        bodyColor: toolTipCommonStyles.color,
        bodyFont: toolTipCommonStyles.font,
        padding: 24,
        cornerRadius: 8,
        usePointStyle: true,
        boxWidth: 12,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 8,
          ...(xScaleOptions.ticks && xScaleOptions.ticks),
          callback: function xTicksNamedCallback(value) {
            const customFormatter = xScaleOptions.formatTicks || ((k) => k);

            // eslint-disable-next-line react/no-this-in-sfc
            return customFormatter(this.getLabelForValue(value));
          },
          color: GRAPH_TICK_COLOR,
          maxRotation: 0,
          minRotation: 0,
        },
        title: {
          display: !!xScaleOptions.title,
          text: xScaleOptions.title,
        },
      },
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: (value) => {
            const customFormatter =
              yScaleOptions.formatTicks || kDigitConversion;

            return customFormatter(value);
          },
          color: GRAPH_TICK_COLOR,
          ...(yScaleOptions.ticks && yScaleOptions.ticks),
        },
        title: {
          display: !!yScaleOptions.title,
          text: yScaleOptions.title,
        },
      },
    },
    // for most intents and purposes, you likely want to keep the hover setting to nearest without intersecting
    // nearest finds the nearest element to the current point
    // intersect allows us to hover and display information without being exactly on the line series
    hover: {
      mode: "nearest",
      intersect: false,
    },
    onHover,
  };

  let xLabels = [];

  data.forEach((lineData) => {
    const sortedPoints = sortBy(lineData.linePoints, (p) => p.x);

    const dataset = {
      type: lineData.type || "line",
      label: `${lineData.name}`,
      data: sortedPoints,
      borderColor: lineData.color,
      backgroundColor: lineData.color,
      borderWidth: 1.5,
      pointRadius: lineData.type === "scatter" ? 1 : 0,
      fill: {
        ...(lineData.fill && lineData.fill),
      },
      ...(lineData.order && { order: lineData.order }),
      ...(lineData.applyCustomStyling && lineStyle),
    };

    if (dataset.data?.length > xLabels.length) {
      xLabels = dataset.data.map(({ x }) => x);
    }

    defaultData.datasets.push(dataset);
  });

  defaultData.labels = xLabels;

  if (xScaleOptions.explicitlySetRange) {
    defaultData.labels = xScaleOptions.explicitlySetRange;
  }

  return (
    <Line
      style={{
        maxHeight: "800px",
        marginTop: "30px",
        marginBottom: "30px",
        width: "100%",
        ...graphStyle,
      }}
      options={options}
      data={defaultData}
    />
  );
}

export default React.memo(LineAndScatterChart);

LineAndScatterChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  moreOptions: PropTypes.object,
  lineStyle: PropTypes.object,
  graphStyle: PropTypes.object,
};
