import React from "react";

import {
  isDownloadableData,
  addDateColumnToDataset,
} from "utils/data/downloading";

import { IconButton, Tooltip } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ArticleIcon from "@mui/icons-material/Article";

import { useCSVDownloader } from "react-papaparse";
import PropTypes from "prop-types";
import { handleDownloadImage } from "utils/data/images";
import Caption1 from "library/text/body/Caption";

function DownloadButtons({ data, filename, style = {}, componentRef }) {
  const { CSVDownloader: Downloader } = useCSVDownloader();

  const isDisabled = !isDownloadableData(data, {});
  const formattedData = isDisabled ? data : addDateColumnToDataset(data);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "4px",
        ...style,
      }}
    >
      <Caption1 color="secondary">Export</Caption1>
      {data && (
        <Downloader filename={`${filename}-data`} bom data={formattedData}>
          <Tooltip
            title="Download the data in this chart as a CSV file"
            placement="top"
          >
            <span>
              <IconButton disabled={isDisabled}>
                <ArticleIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Downloader>
      )}
      {componentRef && (
        <Tooltip title="Download this chart as an image" placement="top">
          <IconButton
            onClick={() => {
              if (componentRef.current)
                handleDownloadImage(componentRef, `${filename}-image`);
            }}
          >
            <CameraAltIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

DownloadButtons.propTypes = {
  filename: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.any),
  style: PropTypes.object,
  componentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

export default DownloadButtons;
