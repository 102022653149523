import React from "react";
import PropTypes from "prop-types";
import UploadPageTemplate from "pages/manual_upload/UploadPageTemplate";
import UploadHistoryPage from "pages/manual_upload/UploadHistoryPage";
import {
  updateIsCustomChannel,
  selectRevenueChannel,
  addRevenueCoordinates,
  addDroppedRevenueFileState,
  resetRevenueState,
} from "redux/revenueSlice";
import HorizontalTabsWithPanels from "library/display/HorizontalTabsWithPanels";

export default function RevenuePage(props) {
  const { whereFrom } = props;
  const config = {
    uploadActions: {
      selectChannel: selectRevenueChannel,
      addCoordinates: addRevenueCoordinates,
      updateDroppedFileState: addDroppedRevenueFileState,
      updateIsCustomChannel,
      resetState: resetRevenueState,
    },
    selectors: {
      channelSelector: (state) => state.revenueData.selectedChannel,
      isCustomChannelSelector: (state) => state.revenueData.isCustomChannel,
    },
  };

  const titleText = whereFrom === "onboarding" ? "" : "Revenue Upload";
  const headerText =
    whereFrom === "onboarding" ? "" : "Upload this month's revenue";

  const panelContents = [
    {
      label: "New Revenue Upload",
      component: (
        <UploadPageTemplate
          {...props}
          titleText={titleText}
          headerText={headerText}
          uploadType="revenue"
          config={config}
        />
      ),
    },
    {
      label: "Upload History",
      component: <UploadHistoryPage uploadType="revenue" />,
    },
  ];

  return <HorizontalTabsWithPanels contents={panelContents} />;
}
RevenuePage.propTypes = {
  whereFrom: PropTypes.string,
};
