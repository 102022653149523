import React, { useState, useRef } from "react";
import { Grid } from "@mui/material";
import OutPointCard from "library/surface/OutPointCard";
import { useSelector } from "react-redux";
import { filterChannelsByRoas } from "utils/dataset/recommendations";
import RecommendationsBarGraph from "pages/recommendations/components/RecommendationsBarGraph";
import KeyPerformanceInsights from "pages/overview/components/KeyPerformanceInsights";
import DownloadButtons from "library/graphing/DownloadButtons";
import SectionTemplate from "./components/SectionTemplate";
import { AGGREGATION_DAY_RANGES } from "../../utils/enums";
import SpendVsRoiGraph from "./components/SpendVsRoiGraph";
import StrategyChannelToggle from "./components/StrategyChannelToggle";

const styles = {
  subHeaderSelect: {},
  headerOne: {
    fontWeight: 400,
  },
  subHeader: {
    fontWeight: 400,
    fontSize: "20px",
  },
  aggLabelText: {
    marginRight: "10px",
  },
};

function RecommendationsSection() {
  const recomData = useSelector((state) => state.recommendationsData);
  const overviewResponse = useSelector((state) => state.overviewData);
  const overviewData = overviewResponse?.data;
  const goal = overviewData && overviewData.goal;
  const ltv = overviewData?.ltv;

  const [chosenTimeRange, setChosenTimeRange] = useState("daily");
  const [mixRange, setMixRange] = useState("Last 3 months");
  const [groupByStrategy, setGroupByStrategy] = useState(true);

  let scenarioWord;
  if (goal === "efficiency") {
    scenarioWord = "profit";
  } else if (goal === "growth") {
    scenarioWord = "revenue";
  }

  let positiveGrowthChannels = [];
  let diminishingChannels = [];
  const baselineGroup = groupByStrategy
    ? recomData.dataByStrategy?.baseline
    : recomData.data?.baseline;
  const baselineData = recomData.status === "success" ? baselineGroup : null;
  const scenarioGroup = groupByStrategy
    ? recomData.dataByStrategy?.[scenarioWord]
    : recomData.data?.[scenarioWord];
  const scenarioData = recomData.status === "success" ? scenarioGroup : null;

  const curRoasType = "marginal";
  if (baselineData) {
    // where marginal roas is equal to or greater than 1
    positiveGrowthChannels = filterChannelsByRoas(
      baselineData.category_data,
      curRoasType,
      (roas) => roas >= 1,
    );

    // where marginal roas is less than 1
    diminishingChannels = filterChannelsByRoas(
      baselineData.category_data,
      curRoasType,
      (roas) => roas < 1,
    );
  }

  const reccosSectionHeaderControlComponents = [
    <Grid item sx={styles.subHeaderSelect}>
      <StrategyChannelToggle
        groupByStrategy={groupByStrategy}
        setGroupByStrategy={setGroupByStrategy}
      />
    </Grid>,
  ];

  const roiRef = useRef(null);
  const reccosGraphs = (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <RecommendationsBarGraph
          scenarioData={scenarioData}
          baselineData={baselineData}
          groupByStrategy={groupByStrategy}
          timeRangeLabel={chosenTimeRange}
          timeRangeDays={AGGREGATION_DAY_RANGES[chosenTimeRange]}
        />
      </Grid>
      <Grid item xs={12}>
        <KeyPerformanceInsights
          groupByStrategy={groupByStrategy}
          setGroupByStrategy={setGroupByStrategy}
          baselineData={baselineData?.category_data}
          scenarioData={scenarioData?.category_data}
          positiveGrowthChannels={positiveGrowthChannels}
          diminishingChannels={diminishingChannels}
          ltv={ltv}
          timeRangeLabel={chosenTimeRange}
          timeRangeDays={AGGREGATION_DAY_RANGES[chosenTimeRange]}
        />
      </Grid>
      <Grid item xs={12}>
        <OutPointCard sx={{ position: "relative" }}>
          <DownloadButtons
            style={{ position: "absolute", right: "20px", top: "10px" }}
            filename="spend vs roi"
            componentRef={roiRef}
          />
          <div ref={roiRef}>
            <SpendVsRoiGraph
              categoryData={scenarioData?.category_data || {}}
              timeRangeDays={AGGREGATION_DAY_RANGES[chosenTimeRange]}
              timeRangeLabel={chosenTimeRange}
            />
          </div>
        </OutPointCard>
      </Grid>
    </Grid>
  );

  return (
    <SectionTemplate
      sectionHeading="Recommendations"
      sectionHeadingControlComponents={reccosSectionHeaderControlComponents}
      shouldShowTimeRangeSelector={false}
      sectionContent={reccosGraphs}
      chosenTimeRange={chosenTimeRange}
      setChosenTimeRange={setChosenTimeRange}
      mixRange={mixRange}
      setMixRange={setMixRange}
    />
  );
}

export default RecommendationsSection;
