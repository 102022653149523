import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@mui/material";
import { PRIMARY_DARK } from "assets/palette";

export function StepperButton(props) {
  const { handleStep, text, variant, color } = props;

  return (
    <Button variant={variant} onClick={handleStep} sx={{ color }}>
      {text}
    </Button>
  );
}
StepperButton.propTypes = {
  handleStep: PropTypes.func,
  text: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

export default function FileStepperButton(props) {
  const {
    uploadedFileData,
    handleStepNext,
    handleStepBack,
    frontStepText,
    backStepText,
  } = props;

  return (
    <Grid sx={{ paddingTop: "12px" }}>
      {uploadedFileData && (
        <StepperButton
          handleStep={handleStepNext}
          text={frontStepText}
          variant="contained"
        />
      )}
      <StepperButton
        handleStep={handleStepBack}
        text={backStepText}
        color={PRIMARY_DARK}
      />
    </Grid>
  );
}
FileStepperButton.propTypes = {
  uploadedFileData: PropTypes.array,
  handleStepNext: PropTypes.func,
  handleStepBack: PropTypes.func,
  frontStepText: PropTypes.string,
  backStepText: PropTypes.string,
};
