import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import DataNotEnteredYetPage from "pages/ncr/components/DataNotEnteredYetPage";
import WaitingForRecommendationsPage from "pages/ncr/components/WaitingForRecommendationsPage";
import RecommendationsDashboard from "pages/ncr/components/NewChannelRecommendationsDashboard";

export default function NewChannelRecommendationsPage() {
  const [accordionExpanded, setAccordionExpanded] = useState();

  const newChannelResponse = useSelector(
    (state) => state.newChannelRecommendations,
  );

  const { recommendations, metadataComplete } = newChannelResponse.data || {};
  const requestStatus = newChannelResponse.status;

  function handleAccordionClick(channel) {
    const tempExpanded = { ...accordionExpanded };
    tempExpanded[channel] = !tempExpanded[channel];
    setAccordionExpanded(tempExpanded);
  }

  const newChannels =
    requestStatus === "success"
      ? [...recommendations].sort((a, b) => b.score - a.score)
      : [];

  useEffect(() => {
    const tempExpanded = {};
    newChannels.forEach((item) => {
      tempExpanded[item.channel] = false;
    });
    setAccordionExpanded(tempExpanded);
  }, []);

  let component;
  if (requestStatus === "success") {
    if (recommendations?.length > 0) {
      component = (
        <RecommendationsDashboard
          handleAccordionClick={() => handleAccordionClick}
          accordionExpanded={accordionExpanded}
          newChannels={newChannels}
        />
      );
    } else if (!metadataComplete) {
      component = <DataNotEnteredYetPage />;
    } else {
      component = <WaitingForRecommendationsPage />;
    }
  } else if (requestStatus === "loading") {
    component = <CircularProgress />;
  } else {
    component = <CircularProgress />;
  }
  return component;
}
