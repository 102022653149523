//
// Generic methods for array inputs
//
import { SORT_ORDER } from "utils/enums";
import { maxBy, minBy } from "lodash";

/* Create an object where each key is an element in arr with the given value. */
export function createObjectFromArray(arr, value) {
  return arr.reduce((obj, x) => Object.assign(obj, { [x]: value }), {});
}

/* Create an object where each key is supplied by an array, and value the corresponding value in a reference object */
export function createObjectByKey(arr, refObj) {
  return arr.reduce(
    (obj, key) => Object.assign(obj, { [key]: refObj[key] }),
    {},
  );
}

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Returns a comparator that can be used on an array of objects, keyed by the
 * `orderBy` parameter that is supplied.
 * */
export function getComparator(order, orderBy) {
  return order === SORT_ORDER.ASC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const perserveKeysInObject = (data = [], keys = []) => {
  if (!data.length || !keys.length) return data;

  return data.map((record) => {
    const newRow = {};
    keys.forEach((key) => {
      newRow[key] = record[key];
    });

    return newRow;
  });
};

/**
 * Given a array of datapoints, where values are accessed via valueAccessKey,
 * returns an object representing min and max datapoints.
 * */
export const getMinMaxValuesFromDataSet = (data, valueAccessKey = "y") => {
  const minPoint = maxBy(data, (o) => o[valueAccessKey]);
  const maxPoint = minBy(data, (o) => o[valueAccessKey]);

  return {
    minPoint,
    maxPoint,
  };
};
