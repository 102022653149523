import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

import { campaignGroupTableStyles as styles } from "pages/campaigns/components/campaignsComponentsStyles";

import CustomTable from "pages/campaigns/components/CustomTable";

import { selectCampaignsUnderGroup } from "redux/campaignsSlice";

/**
 * The CampaignGroupTable component displays
 * information about campaigns grouped together.
 * */
function CampaignGroupTable(props) {
  const { id } = props;

  const nestedCampaignEntities = useSelector(selectCampaignsUnderGroup(id));

  if (!nestedCampaignEntities || nestedCampaignEntities?.length < 1) {
    return (
      <Typography sx={styles.emptyGroupText}>
        This group is currently empty.
      </Typography>
    );
  }

  const keysToDisplay = ["campaign", "medium", "channel", "source"];
  // ASSUMPTION / PRECOND : nested campaign entities must have the keys to display
  const headerKeys = nestedCampaignEntities ? keysToDisplay : [];

  const labels = {
    id: "Campaign Id",
    campaign: "Campaign Name",
    deployment_id: "Deployment Id",
    medium: "Medium",
    channel: "Channel",
    campaign_group: "Campaign Group",
    source: "Source",
  };

  const tableHeaderCells = headerKeys.map((key) => {
    return {
      id: key,
      numeric: false,
      disablePadding: false,
      label: <Typography sx={styles.tableHeaderText}>{labels[key]}</Typography>,
    };
  });

  return (
    <CustomTable
      campaignGroupId={id}
      keysToDisplay={keysToDisplay}
      headerCells={tableHeaderCells}
      rows={nestedCampaignEntities}
      initialOrderByProp="Campaign Name"
      displayDense
      initialRowsPerPage={20}
    />
  );
}

CampaignGroupTable.propTypes = {
  id: PropTypes.string,
};

export default CampaignGroupTable;
