/**
 * Contains the optimal spend breakdown for the particular scenario
 * */

import React from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { recommendedSpendBreakdownTableStyles } from "pages/recommendations/styles/recommendationsComponentsStyles";
import { calculateCac, formatMetric } from "utils/data/metrics";
import { computePercentageChange } from "utils/data/math";
import ChangeChip from "library/display/ChangeChip";
import PropTypes from "prop-types";

const styles = {
  ...recommendedSpendBreakdownTableStyles,
};

export default function RecommendedSpendBreakdownTable(props) {
  const { baselineChannelData, scenarioChannelData } = props;

  const ltv = useSelector((state) => state.overviewData.data?.ltv);
  const computeCac = (spendVal, returnVal) =>
    calculateCac(ltv, spendVal, returnVal);

  const info = {
    current: {
      revenue: formatMetric(baselineChannelData.total_revenue),
      cac: formatMetric(
        computeCac(
          baselineChannelData.spend,
          baselineChannelData.total_revenue,
        ),
      ),
      roas: formatMetric(baselineChannelData.average_roas),
    },
    predicted: {
      revenue: formatMetric(scenarioChannelData.total_revenue),
      cac: formatMetric(
        computeCac(
          scenarioChannelData.spend,
          scenarioChannelData.total_revenue,
        ),
      ),
      roas: formatMetric(scenarioChannelData.average_roas),
    },
    change: {
      revenue: computePercentageChange(
        scenarioChannelData.total_revenue,
        baselineChannelData.total_revenue,
      ),
      cac: computePercentageChange(
        computeCac(
          scenarioChannelData.spend,
          scenarioChannelData.total_revenue,
        ),
        computeCac(
          baselineChannelData.spend,
          baselineChannelData.total_revenue,
        ),
      ),
      roas: computePercentageChange(
        scenarioChannelData.average_roas,
        baselineChannelData.average_roas,
      ),
    },
  };

  const headerCells = ["", "Return", "CAC", "ROAS"].map((title) => (
    <TableCell key={title} padding="none" style={{ borderBottom: "none" }}>
      <Typography sx={styles.columnText}>{title}</Typography>
    </TableCell>
  ));

  const currentPerformanceCells = [
    { sx: styles.columnText, label: "Current" },
    { sx: styles.cellValueText, label: `$${info.current.revenue}` },
    { sx: styles.cellValueText, label: `$${info.current.cac}` },
    { sx: styles.roasCellValueText, label: info.current.roas },
  ].map(({ sx, label }, i) => (
    <TableCell
      key={`${label}_${i}`}
      padding="none"
      style={{ borderBottom: "none" }}
    >
      <Typography sx={sx}>{label}</Typography>
    </TableCell>
  ));

  const predictedPerformanceCells = [
    { sx: styles.columnText, label: "Predicted" },
    { sx: styles.cellValueText, label: `$${info.predicted.revenue}` },
    { sx: styles.cellValueText, label: `$${info.predicted.cac}` },
    { sx: styles.roasCellValueText, label: info.predicted.roas },
  ].map(({ sx, label }, i) => (
    <TableCell
      key={`${label}_${i}`}
      padding="none"
      style={{ borderBottom: "none" }}
    >
      <Typography sx={sx}>{label}</Typography>
    </TableCell>
  ));

  const changePerformanceCells = [
    {
      element: <Typography sx={styles.columnText}>Change</Typography>,
      id: "change",
    },
    {
      element: <ChangeChip change={info.change.revenue} isPercentage />,
      id: "revenue",
    },
    {
      element: <ChangeChip change={info.change.cac} inverted isPercentage />,
      id: "cac",
    },
    {
      element: <ChangeChip change={info.change.roas} isPercentage />,
      id: "roas",
    },
  ].map(({ element, id }, i) => (
    <TableCell
      key={`${id}_${i}`}
      padding="none"
      align="left"
      style={{ borderBottom: "none" }}
    >
      {element}
    </TableCell>
  ));

  const recommendedSpendBreakdownTable = (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>{headerCells}</TableRow>
        </TableHead>
        <TableBody>
          <TableRow>{currentPerformanceCells}</TableRow>
          <TableRow>{predictedPerformanceCells}</TableRow>
          <TableRow>{changePerformanceCells}</TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  const tableLabel = (
    <Typography sx={styles.tableCaption}>
      {"Recommended spend breakdown".toUpperCase()}
    </Typography>
  );

  return (
    (Boolean(ltv) && (
      <Grid container>
        {tableLabel}
        {recommendedSpendBreakdownTable}
      </Grid>
    )) ||
    null
  );
}

RecommendedSpendBreakdownTable.propTypes = {
  baselineChannelData: PropTypes.shape({
    total_revenue: PropTypes.number,
    spend: PropTypes.number,
    average_roas: PropTypes.number,
  }),
  scenarioChannelData: PropTypes.shape({
    total_revenue: PropTypes.number,
    spend: PropTypes.number,
    average_roas: PropTypes.number,
  }),
};
