import React from "react";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import PropTypes from "prop-types";
import BaseHeader from "./BaseHeader";

const styles = {
  header: {
    fontSize: "1rem",
    lineHeight: "1.25rem",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
  },
};

function SubHeadLight({
  children,
  className,
  isSentenceCase = true,
  sx = {},
  ...rest
}) {
  const mergedStyles = { ...styles.header, ...sx };

  return (
    <BaseHeader
      sx={mergedStyles}
      className={className}
      isSentenceCase={isSentenceCase}
      {...rest}
    >
      {children}
    </BaseHeader>
  );
}

export default SubHeadLight;

SubHeadLight.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  isSentenceCase: PropTypes.bool,
  sx: PropTypes.object,
};
