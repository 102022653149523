import React, { useState } from "react";
import { Grid, Hidden } from "@mui/material";
import { useLocation } from "react-router-dom";
import BodyText from "library/text/body/BodyText";
import Title from "library/text/titles/Title";
import { useTheme } from "@mui/material/styles";
import SignupForm from "./components/SignUpForm";
import LoginForm from "./components/LoginForm";
import GraphicPanel from "./components/GraphicPanel";
import { getOrgLogo } from "../../utils/resolveLogos";

const internalOrgName = process.env?.REACT_APP_INTERNAL_ORG_NAME || "outpoint";

const styles = {
  root: {
    flexGrow: 1,
    minHeight: "100vh",
  },
  column: {
    height: "100%",
    backgroundColor: "primary.dark",
  },
  leftItems: {
    maxWidth: "375px",
    paddingTop: "15%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  leftGrid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  logo: {
    height: "40px",
    marginBottom: "60px",
  },
};

export default function LogInSignUpPage() {
  const [signUpSuccessful, setSignUpSuccessful] = useState(false);
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();

  const pageContents = {
    signin: {
      title: "Sign in",
      content: <LoginForm />,
    },
    signup: {
      title: "Sign up",
      header:
        "Sign up to manage data uploads and access your suite of budget allocation decision tools.",
      content: (
        <SignupForm
          setSignUpSuccessful={setSignUpSuccessful}
          setEmail={setEmail}
          setFirstName={setFirstName}
        />
      ),
    },
    signupSuccess: {
      title: "Thanks for signing up!",
      header: "",
      content: [
        `Thank you for signing up with OutPoint, ${firstName}!`,
        `Your account for ${email} will be confirmed shortly. If you cannot log in after one day, please contact an admin.`,
      ].map((text) => (
        <BodyText style={{ marginBottom: "20px" }}>{text}</BodyText>
      )),
    },
  };

  const location = useLocation();
  const page =
    location.pathname === "/login"
      ? "signin"
      : `signup${signUpSuccessful ? "Success" : ""}`;

  const theme = useTheme();
  const colourGraphic =
    internalOrgName === "outpoint" ? (
      <div style={styles.column}>
        <GraphicPanel graphicVersion={page} />
      </div>
    ) : (
      <div
        style={{
          ...styles.column,
          backgroundColor: theme.palette.primary.dark,
        }}
      />
    );

  return (
    <Grid container sx={styles.root}>
      <Grid item xs={12} md={5} sx={styles.leftGrid}>
        <div style={{ ...styles.column, ...styles.leftItems }}>
          <img style={styles.logo} src={getOrgLogo()} alt="OutPoint Logo" />
          <Title
            style={{ marginBottom: "20px" }}
            text={pageContents[page].title}
          />
          <BodyText style={{ paddingBottom: "15px" }} color="secondary">
            {pageContents[page].header}
          </BodyText>
          <div>{pageContents[page].content}</div>
        </div>
      </Grid>
      <Hidden mdDown>
        <Grid item xs={false} md={7}>
          {colourGraphic}
        </Grid>
      </Hidden>
    </Grid>
  );
}
