import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const styles = {
  inherit: {
    height: "inherit",
    width: "inherit",
  },
};

function PngContainer({
  icon,
  boxStyle = {},
  boxClass = "",
  style = {},
  ...rest
}) {
  if (!icon) return null;

  return (
    <Box sx={boxStyle} className={boxClass}>
      <img
        src={icon}
        style={{ ...styles.inherit, style }}
        alt={`${icon} logo`}
        {...rest}
      />
    </Box>
  );
}

export default PngContainer;

PngContainer.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  boxStyle: PropTypes.object,
  boxClass: PropTypes.string,
  style: PropTypes.object,
};
