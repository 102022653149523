/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { UPLOAD_VALIDITY_STATUS, STATUS } from "utils/enums";

export const getSpend = createAsyncThunk("asyncRedux/spendAsync", async () => {
  return makeAuthenticatedGetRequest("spend");
});

const initialState = {
  channelOptions: [],
  activeStep: 0,
  selectedChannel: undefined,
  isCustomChannel: false,
  coordinates: [],
  completed: false,
  droppedFile: {
    fileId: "",
    validationStatus: UPLOAD_VALIDITY_STATUS.PENDING,
  },
  status: null,
  authError: null,
};

const spendSlice = createSlice({
  name: "spendSlice",
  initialState,
  reducers: {
    spendLogOut: () => {
      return initialState;
    },
    selectSpendChannel: (state, action) => {
      state.selectedChannel = action.payload;
    },
    addDroppedSpendFileState: (state, action) => {
      state.droppedFile = action.payload;
    },
    addSpendCoordinates: (state, action) => {
      state.coodinates = action.payload;
    },
    updateIsCustomChannel: (state, action) => {
      state.isCustomChannel = action.payload;
    },
    resetSpendState: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getSpend.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getSpend.fulfilled]: (state, { payload }) => {
      state.list = payload.data;
      state.status = STATUS.SUCCESS;
      state.authError = payload.authError;
    },
    [getSpend.rejected]: (state) => {
      state.status = STATUS.FAILED;
      state.authError = true;
    },
  },
});

export const {
  updateIsCustomChannel,
  spendLogOut,
  selectSpendChannel,
  addDroppedSpendFileState,
  addSpendCoordinates,
  resetSpendState,
} = spendSlice.actions;
export default spendSlice.reducer;
