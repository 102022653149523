import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LabelText from "library/text/body/LabelText";
import { createHTMLLabel } from "utils/data/strings";
import { OP_COLORS } from "assets/palette";
import PropTypes from "prop-types";

const styles = {
  button: {
    width: "100%",
    height: "44px",
    borderRadius: "3px",
    justifyContent: "flex-start",
  },
  getButtonStyle: (thisPath, toPath) => {
    return {
      backgroundColor:
        thisPath === toPath ? OP_COLORS.BLUE_GRAY_NEUTRAL : "white",
    };
  },
  text: {
    color: OP_COLORS.OUTER_SPACE_CRAYOLA,
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "20px",
    fontWeight: 400,
  },
};

function NavItem(props) {
  const navigate = useNavigate();

  const {
    children,
    path,
    to,
    extraClickFunction,
    startIcon,
    EndAdornment = () => <div />,
  } = props;

  const handleButtonClick = () => {
    if (extraClickFunction) {
      extraClickFunction();
    }

    navigate(to);
  };

  return (
    <Button
      data-cy={createHTMLLabel(`navitem-${children}`)}
      variant="contained"
      style={styles.getButtonStyle(path, to)}
      sx={styles.button}
      disableElevation
      onClick={handleButtonClick}
      startIcon={startIcon}
    >
      <LabelText sx={styles.text}>{children}</LabelText>
      {EndAdornment ? <EndAdornment /> : <div />}
    </Button>
  );
}

export default NavItem;

NavItem.propTypes = {
  children: PropTypes.any,
  path: PropTypes.string,
  to: PropTypes.string,
  extraClickFunction: PropTypes.func,
  startIcon: PropTypes.element,
  EndAdornment: PropTypes.func,
};
