import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authenticateToken, getToken, tokenValid } from "utils/authentication";

const PrivateRoute = ({ component: Component }) => {
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.login.authStatus);
  const onboardingStatus = useSelector((state) => state.onboarding);
  const [pageStatus, setPageStatus] = useState(1);

  const privatePage = {
    1: Component,
    2: <Navigate to="/onboarding" />,
    3: <Navigate to="/login" />,
  };

  const [token, setToken] = useState(getToken());

  useEffect(() => {
    authenticateToken(dispatch);
  }, []);

  useEffect(() => {
    const tokenStatus = tokenValid();
    if (!tokenStatus) {
      window.location.pathname = "/login";
    }
  }, []);

  useEffect(() => {
    setToken(getToken());
  }, [authStatus]);

  useEffect(() => {
    if (onboardingStatus.status === "success") {
      if (authStatus || token) {
        if (onboardingStatus.object.is_onboarded === true) {
          setPageStatus(1);
        } else if (onboardingStatus.object.is_onboarded === false) {
          setPageStatus(2);
        }
      } else {
        setPageStatus(3);
      }
    } else if (authStatus || token) {
      setPageStatus(1);
    } else {
      setPageStatus(3);
    }
  }, [
    onboardingStatus.status,
    authStatus,
    token,
    onboardingStatus.object.is_onboarded,
  ]);

  return privatePage[pageStatus];
};
export default PrivateRoute;
