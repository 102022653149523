/**
Contains regex patterns to be used throughout the code-base.
Please attach a description of what's intended and some positive and negative cases in mind.

Resource:
* https://regex101.com/ is a good resource to test it out
 * */

/**
- Must contain at least 2 characters
- Allows the use of some common non-alphanumeric characters - Allows numericals only - Must match everything enclosed
- Allows space chars to be used
 * */
export const CUSTOM_CHANNEL_REGEX = /(?:^[a-zA-Z0-9*@$%&()_ -]{2,}$)/;

/**
 * Indicates the accepted special characters as part of the password condition on special characters being used.
 * */
export const PASSWORD_CONDITION_SPECIAL_CHARS_REGEX = /[!@#$%^&*)(+=._-]/;

/**
 * This regex only matches if there are no whitespace characters before or after the string.
 * Accounts for other kinds of escape chars other than spaces as well.
 *
 * Valid:
    -  "valid string"
    -  "valid                      string"

 * Invalid:
    -  " invalid string"
    -  " invalid string "
    -  "invalid string "
 * */
// eslint-disable-next-line no-control-regex
export const STD_ASCII_CHAR_REGEX = /^[\x00-\x7F]*$/;
// eslint-disable-next-line no-control-regex
export const EXTENDED_ASCII_CHAR_REGEX = /^[\x00-\xFF]*$/;
