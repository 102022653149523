import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useDispatch } from "react-redux";

import {
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

import { Grid } from "@mui/material";
import { Cancel, CheckCircle } from "@mui/icons-material";
import Warning from "@mui/icons-material/WarningRounded";

import BodyText from "library/text/body/BodyText";
import PrimaryButton from "library/buttons/PrimaryButton";
import Hyperlink from "library/text/body/Hyperlink";
import TextFieldSimple from "library/form/OutPointTextField";
import IconText from "library/containers/IconText";
import Caption1 from "library/text/body/Caption";
import Label from "library/text/body/LabelText";
import { isValidEmail } from "utils/data/strings";
import { PASSWORD_CONDITION_SPECIAL_CHARS_REGEX } from "utils/regex";
import { BG_RED, PRIMARY_COLOR, RED } from "assets/palette";
import OutPointCard from "library/surface/OutPointCard";
import { addMessage } from "redux/snackbarSlice";

const styles = {
  textBox: {
    marginBottom: "20px",
  },
  fullTextBox: {
    width: "100%",
  },
  halfTextBoxFirst: {
    width: "47.5%",
    marginRight: "5%",
  },
  halfTextBoxSecond: {
    width: "47.5%",
  },
  formField: {
    paddingTop: "3px",
    marginBottom: "15px",
  },
  progressBar: {
    width: "100%",
  },
  checkIcon: {
    fontSize: "0.95rem",
    marginRight: "0.2em",
  },
  hyperlink: {
    fontWeight: "600",
    textDecoration: "none",
  },
  errorCard: {
    backgroundColor: BG_RED,
    padding: "16px",
    margin: "20px 0",
  },
  warningIcon: {
    fontSize: "32px",
    color: RED,
    marginRight: "16px",
  },
};

const getErrorMessage = (cognitoError) => {
  switch (cognitoError.name) {
    case "InvalidPasswordException":
      return {
        error: "Password does not satisfy the password requirements",
      };
    case "UsernameExistsException":
      return {
        error: "Email is already registered",
        title: "An account with that email already exists.",
        body: (
          <>
            Check that your email is correct or{" "}
            <Hyperlink where="/login">log in</Hyperlink>. If you cannot log in,
            please contact the admin to confirm your account.
          </>
        ),
      };
    case "NotAuthorizedException":
      return {
        error: "Sorry, the user is not authorized",
      };
    case "InternalErrorException":
      return {
        error: "Sorry, internal error. Try again later",
      };
    case "InvalidParameterException":
      return {
        error: "We have encountered an invalid parameter",
      };
    case "CodeDeliveryFailureException":
      return {
        success: true,
      };
    default:
      return {
        error:
          "Sorry we have encountered an error while signing up, please reach out to the team",
      };
  }
};

const inputFields = [
  {
    id: "firstname",
    cognitoName: "custom:firstname",
    name: "First name",
    className: "halfTextBoxFirst",
    type: "text",
  },
  {
    id: "lastname",
    cognitoName: "custom:lastname",
    name: "Last name",
    className: "halfTextBoxSecond",
    type: "text",
  },
  {
    id: "company",
    cognitoName: "custom:companyname",
    name: "Company name",
    className: "fullTextBox",
    type: "text",
  },
  {
    id: "email",
    cognitoName: "email",
    name: "Work email address",
    className: "fullTextBox",
    type: "text",
  },
];

const setUserInputState = (setState, id, data) => {
  setState((prev) => {
    const newState = { ...prev };
    newState[id] = data;
    return newState;
  });
};

const onSubmit = (
  event,
  dispatch,
  userInputData,
  userpool,
  canSubmit,
  setEmail,
  setFirstName,
  setSignUpSuccessful,
  setFocussed,
  setErrorCard,
) => {
  event.preventDefault();
  setFocussed(null);
  setErrorCard({});

  if (!canSubmit) return;

  const customerData = inputFields
    .map(
      ({ cognitoName, id }) =>
        cognitoName &&
        new CognitoUserAttribute({
          Name: cognitoName,
          Value: userInputData[id],
        }),
    )
    .filter((value) => value);
  userpool.signUp(
    userInputData.email,
    userInputData.password,
    customerData,
    null,
    (err) => {
      const response = getErrorMessage(err);
      if (!response.success) {
        const error = response;
        // eslint-disable-next-line no-console
        console.log(error);
        if (error.title && error.body) {
          setErrorCard({
            title: error.title,
            body: error.body,
          });
        } else {
          dispatch(addMessage({ message: error.error }));
        }
      } else {
        setEmail(userInputData.email);
        setFirstName(userInputData.firstname);
        setSignUpSuccessful(true);
      }
    },
  );
};

function PasswordCheckField({ condition, text, classes }) {
  return (
    <IconText style={{ marginBottom: "5px" }}>
      {condition ? (
        <CheckCircle
          className={classes.checkIcon}
          style={{
            color: "green",
          }}
        />
      ) : (
        <Cancel className={classes.checkIcon} style={{ color: "red" }} />
      )}
      <Caption1 color="secondary">{text}</Caption1>
    </IconText>
  );
}
PasswordCheckField.propTypes = {
  condition: PropTypes.bool,
  text: PropTypes.string,
  classes: PropTypes.object,
};

function ErrorCard({ title, body, classes }) {
  return (
    <OutPointCard style={classes.errorCard}>
      <IconText>
        <Warning style={classes.warningIcon} />
        <div>
          <Label style={{ marginBottom: "5px" }}>{title}</Label>
          <BodyText>{body}</BodyText>
        </div>
      </IconText>
    </OutPointCard>
  );
}
ErrorCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  classes: PropTypes.object,
};

function SignupForm(props) {
  const dispatch = useDispatch();
  const [userInputData, setUserInputData] = useState({});
  const [userInputError, setUserInputError] = useState({});
  const [errorCard, setErrorCard] = useState({});
  const [userpool, setUserpool] = useState();
  const [focussed, setFocussed] = useState();

  const { setSignUpSuccessful, setEmail, setFirstName } = props;

  const validEmail = isValidEmail(userInputData.email);

  useEffect(() => {
    const poolData = {
      UserPoolId: process.env.REACT_APP_USER_POOL_ID,
      ClientId: process.env.REACT_APP_CLIENT_ID,
    };
    setUserpool(new CognitoUserPool(poolData));
  }, []);

  const passwordConditions = [
    {
      text: "Minimum 8 characters",
      condition: userInputData.password?.length > 8,
    },
    {
      text: "Minimum one special character",
      condition: PASSWORD_CONDITION_SPECIAL_CHARS_REGEX.test(
        userInputData.password,
      ),
    },
    {
      text: "Minimum one number",
      condition: /\d/.test(userInputData.password),
    },
  ];

  const canSubmit =
    inputFields.reduce((prev, { id }) => {
      const hasText = userInputData[id]?.length > 0;
      return hasText && prev;
    }, true) &&
    passwordConditions.every(({ condition }) => condition) &&
    validEmail &&
    userInputData.password === userInputData.confirmPassword;

  return (
    <form
      style={styles.form}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(
          event,
          dispatch,
          userInputData,
          userpool,
          canSubmit,
          setEmail,
          setFirstName,
          setSignUpSuccessful,
          setFocussed,
          setErrorCard,
        );
      }}
    >
      <Grid>
        <BodyText style={{ marginBottom: "20px" }} color="secondary">
          Already have an account?{" "}
          <Hyperlink
            sx={{ ...styles.hyperlink, color: PRIMARY_COLOR }}
            where="/login"
          >
            Log in
          </Hyperlink>
        </BodyText>

        {errorCard?.title && <ErrorCard classes={styles} {...errorCard} />}

        {inputFields.map(({ id, name, type, className, ...fields }) => (
          <TextFieldSimple
            key={name}
            name={name}
            type={type}
            sx={{
              ...styles.textBox,
              ...styles[className],
            }}
            {...fields}
            onFocus={() => setFocussed(id)}
            onBlur={() => {
              if (!userInputData[id])
                setUserInputState(setUserInputError, id, `${name} is required`);
              if (id === "email") {
                if (userInputData.email && !validEmail)
                  setUserInputState(
                    setUserInputError,
                    "email",
                    "Not a valid email address.",
                  );
              }
            }}
            onChange={(text) => {
              setUserInputState(setUserInputData, id, text);
              if (text) setUserInputState(setUserInputError, id, "");
            }}
            error={Boolean(userInputError[id])}
            errorMsg={userInputError[id]}
          />
        ))}

        <div style={styles.textBox}>
          <TextFieldSimple
            id="password"
            name="Password"
            type="password"
            sx={styles.fullTextBox}
            onFocus={() => setFocussed("password")}
            onChange={(text) => {
              setUserInputState(setUserInputData, "password", text);
            }}
          />
          {focussed === "password" && (
            <div style={{ margin: "10px 0" }}>
              {passwordConditions.map((fields) => (
                <PasswordCheckField
                  key={fields.text}
                  classes={styles}
                  {...fields}
                />
              ))}
            </div>
          )}
        </div>

        <div style={styles.textBox}>
          <TextFieldSimple
            name="Confirm password"
            id="confirmPassword"
            type="password"
            sx={styles.fullTextBox}
            onFocus={() => setFocussed("confirmPassword")}
            onChange={(text) => {
              setUserInputState(setUserInputData, "confirmPassword", text);
            }}
          />
          {userInputData.confirmPassword &&
            userInputData.password === userInputData.confirmPassword && (
              <div style={{ margin: "10px 0" }}>
                <PasswordCheckField
                  classes={styles}
                  condition
                  text="Passwords match"
                />
              </div>
            )}
        </div>

        <BodyText color="secondary" sx={styles.terms}>
          By continuing you agree to our{" "}
          <Hyperlink
            where="https://www.outpoint.app/terms-of-service"
            newPage
            sx={styles.hyperlink}
          >
            terms
          </Hyperlink>{" "}
          and{" "}
          <Hyperlink
            where="https://www.outpoint.app/privacy-policy"
            newPage
            sx={styles.hyperlink}
          >
            privacy policy.
          </Hyperlink>
        </BodyText>

        <PrimaryButton
          type="submit"
          style={{ marginTop: "15px", marginBottom: "25px" }}
          size="large"
          disabled={!canSubmit}
        >
          Create Account
        </PrimaryButton>
      </Grid>
    </form>
  );
}
SignupForm.propTypes = {
  setSignUpSuccessful: PropTypes.func,
  setEmail: PropTypes.func,
  setFirstName: PropTypes.func,
};

export default SignupForm;
