import React from "react";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";

function GridItem({ children }) {
  return (
    <Grid item xs={6} sm={4} md={3}>
      {children}
    </Grid>
  );
}

export default GridItem;

GridItem.propTypes = {
  children: PropTypes.any,
};
