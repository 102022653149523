/* Helpers to aggregate/manipulate predictions data */

import { getQuotientFixed } from "utils/data/math";
import { normalizeChannelName } from "utils/data/strings";

/**
 *
 * @param {array} platformSpendChannelData - collection of { spend: number, channel: string }
 * @param {Object} predictionsTableData
 * @returns {Object}
 *
 * This function iterates over channels to calculate channel level
 * and total metrics
 *
 * metrics:
 *    - spend
 *    - return
 *    - profit
 *    - roas
 */
export function calculatePredictionScenario({
  platformSpendChannelData,
  predictionsTableData,
}) {
  if (!platformSpendChannelData?.length || !predictionsTableData) return null;

  const predictionScenario = {};
  let totalSpend = 0;
  let totalReturn = 0;
  let totalProfit = 0;

  platformSpendChannelData.forEach(({ spend, channel }) => {
    // todo normalzie channelName key
    const spendFloat = parseFloat(spend || 0);

    if (
      !predictionsTableData[channel] ||
      !predictionsTableData[channel][spend]
    ) {
      // eslint-disable-next-line no-console
      console.error("Unable to find prediction data", {
        channel,
        spend,
      });
      return;
    }

    const { total_predicted_revenue: predictedReturn } =
      predictionsTableData[channel][spend];

    const returnFloat = parseFloat(predictedReturn || 0);
    const profit = returnFloat - spendFloat;
    const averageRoas = getQuotientFixed(returnFloat, spend);

    totalSpend += spendFloat;
    totalReturn += returnFloat;
    totalProfit += profit;

    predictionScenario.category_data = {
      ...predictionScenario.category_data,
      [channel]: {
        spend: spendFloat,
        total_revenue: returnFloat,
        profit,
        average_roas: averageRoas,
      },
    };
  });

  predictionScenario.profit = totalProfit;
  predictionScenario.spend = totalSpend;
  predictionScenario.total_revenue = totalReturn;
  predictionScenario.roas = getQuotientFixed(
    predictionScenario.total_revenue,
    predictionScenario.spend,
  );

  return predictionScenario;
}

export function convertResponseToScenario(optimizationResponse) {
  const newChannels = {};
  Object.keys(optimizationResponse).forEach((channel) => {
    const channelData = optimizationResponse[channel];
    const newChannel = normalizeChannelName(channel);
    newChannels[newChannel] = {
      average_roas: channelData.roas,
      marginal_roas: channelData.marginal_roas,
      profit: channelData.predicted_revenue - channelData.spend,
      spend: channelData.spend,
      total_revenue: channelData.predicted_revenue,
    };
  });
  const totalSpend = Object.keys(optimizationResponse).reduce(
    (total, channel) => total + optimizationResponse[channel].spend,
    0,
  );
  const totalRevenue = Object.keys(optimizationResponse).reduce(
    (total, channel) => total + optimizationResponse[channel].predicted_revenue,
    0,
  );
  const totalRoas = Object.keys(optimizationResponse).reduce(
    (total, channel) =>
      total +
      (optimizationResponse[channel].roas *
        optimizationResponse[channel].spend) /
        totalSpend,
    0,
  );
  return {
    category_data: newChannels,
    spend: totalSpend,
    total_revenue: totalRevenue,
    roas: totalRoas,
  };
}

export default calculatePredictionScenario;
